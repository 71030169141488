import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let continueToSignSpaceBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CONTINUE_TO_SIGNSPACE_BTN_CLICKED
    });
};

export default continueToSignSpaceBtnClicked;
