import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { Message, PageHeader, FormRow, FormColumn, FormGroup, ValidationMessages, TextInput} from '@vaultit/stv-utils-frontend';
import resendAccountActivationLinkBackBtnClicked from  '../../actions/actionCreators/users/ResendAccountActivationLinkBackBtnClicked.js';


class ResendAccountActivationLinkPage extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let resendAccountActivationLinkForm = this.state.users.resendAccountActivationLinkForm;
        let resendAccountActivationLinkFormFields = resendAccountActivationLinkForm ? resendAccountActivationLinkForm.fields : null;
        let showResendAccountActivationLinkNoManualIdVerificationMsg = this.state.users.showResendAccountActivationLinkNoManualIdVerificationMsg;
        let showAccountActivationLinkSuccessfullyResentMsg = this.state.users.showAccountActivationLinkSuccessfullyResentMsg;
        let resendAccountActivationLinkEmail = this.state.users.resendAccountActivationLinkEmail;

        const messages = defineMessages({
            emailPlaceHolder: {
                id: 'users.resendAccountActivationLinkFieldPlaceholder',
                defaultMessage: 'Enter email',
                description: 'Placeholder for email to which registration invitation should be resent',
            }
        });


        return (
            <div>
                <PageHeader
                    pageTitleMessageId="users.resendAccountActivationLinkPageHeader"
                    sharedStore={this.state.shared}
                />

                {showResendAccountActivationLinkNoManualIdVerificationMsg
                ?
                    <Message id='resend_account_activation_link_no_manual_id_verification' type="danger" defaultIcon={true} >
                        <FormattedMessage id="users.resendAccountActivationLinkError" defaultMessage="There is no manual id verification associated with the email {email}" values={{email: <strong>{resendAccountActivationLinkEmail}</strong>}} />.
                    </Message>

                : null}

                {showAccountActivationLinkSuccessfullyResentMsg
                ?
                    <Message id='resend_account_activation_link_success' type="success" defaultIcon={true} >
                        <FormattedMessage id="users.resendAccountActivationLinkSuccess" defaultMessage="Account activation link was successfully sent to {email}" values={{email: <strong>{resendAccountActivationLinkEmail}</strong>}} />.
                    </Message>

                : null}

                {resendAccountActivationLinkForm
                ?
                    <form name="resendAccountActivationLinkForm" noValidate="">
                        <FormRow>
                            <FormColumn width="12">

                                <FormRow>
                                    <FormColumn width="12">
                                        <FormGroup>
                                            <ValidationMessages field={resendAccountActivationLinkFormFields.email}>
                                                <TextInput
                                                    id="resend_account_activation_link_email"
                                                    field={resendAccountActivationLinkFormFields.email}
                                                    messageId="users.resendAccountActivationLinkPageTitle"
                                                    placeholder={formatMessage(messages.emailPlaceHolder) }
                                                    required={true}
                                                    disabled={false}
                                                />
                                            </ValidationMessages>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>

                                <hr />

                                <FormRow>
                                    <FormColumn width="12">
                                        <FormGroup className="mt-3">
                                            <button
                                                id="resend_account_activation_link_button"
                                                className="btn btn-primary btn-lg mr-3"
                                                role="button"
                                                onClick={resendAccountActivationLinkForm.handleOnSubmitBtnClicked}
                                            >
                                                <FormattedMessage id="users.resendAccountActivationLinkResend" defaultMessage="Resend" />
                                            </button>
                                            <button
                                                id="resend_account_activation_link_back_button"
                                                className="btn btn-default btn-lg mr-3"
                                                role="button"
                                                onClick={resendAccountActivationLinkBackBtnClicked}
                                            >
                                                <FormattedMessage id="users.resendAccountActivationLinkBack" defaultMessage="Back" />
                                            </button>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>

                            </FormColumn>
                        </FormRow>
                    </form>
                : null
                }

            </div>
        );
    }

}
export default ResendAccountActivationLinkPage;
