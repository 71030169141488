import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let createPersonAccount = function (values) {
    postToBackend('/registration/create-account',
        ActionTypes.REGISTRATION_STEP1_FLOW2_SUBMIT_SUCCESS,
        ActionTypes.REGISTRATION_STEP1_FLOW2_SUBMIT_ERROR,
        values);
};

export default createPersonAccount;
