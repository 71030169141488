import React from 'react';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import StoreComponent from '../../base/StoreComponent.jsx';
import YourDetailsNav from '../../common/YourDetailsNav.jsx';
import { Message, PageHeader, ConfirmationModal} from '@vaultit/stv-utils-frontend';
import deleteUserAccountBtnClicked from '../../../actions/actionCreators/profile/yourDetails/DeleteUserAccountBtnClicked.js';
import userAccountDeleted from '../../../actions/actionCreators/profile/yourDetails/UserAccountDeleted.js';


const messages = defineMessages({
    AccountDeletionModalTitle: {
        id: 'profile.details.accountDeletion',
        description: 'Title for account deletion modal',
        defaultMessage: 'Account deletion'
    },
    AccountDeletionModalConfirmBtnText: {
        id: 'profile.accountDeletion.yes',
        description: 'Confirm button for account deletion modal',
        defaultMessage: 'Yes'
    },
    AccountDeletionModalDeclineBtnText: {
        id: 'profile.accountDeletion.no',
        description: 'Decline button for account deletion modal',
        defaultMessage: 'No'
    },
    DeletedAccountModalTitle: {
        id: 'profile.details.deletedAccount',
        description: 'Title for deleted account modal',
        defaultMessage: 'Account Deleted'
    },
    DeletedAccountModalConfirmation: {
        id: 'profile.deletedAccount.confirm',
        description: 'Confirm button for deleted account modal',
        defaultMessage: 'Confirm'
    }
});


class DeleteMyAccount extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        return (
            <div>
                <PageHeader pageTitleMessageId="profile.details.title" sharedStore={this.state.shared} />
                {!this.state.shared.authInfo.topMenuParameters.configuration.thirdLevelMenu
                    ? <YourDetailsNav ulClassName="flex-column flex-md-row mb-4" activeTab="delete-my-account" {...this.state.yourDetails.tabVisibility} />
                    : null
                }
                {this.state.yourDetails.showUserAccountHasDependenciesMessage
                ?
                    <Message id="errorMessage" type="danger">
                        <FormattedMessage
                            id="profile.accountDeletion.accountHasDependencies"
                            defaultMessage="Your user account has dependencies and cannot be deleted."
                        />
                    </Message>
                : null}
                {this.state.yourDetails.showDependenciesWarning
                ?
                    <Message id="errorMessage" type="warning">
                        <FormattedMessage
                            id="profile.accountDeletion.accountDependenciesWarningTop"
                            defaultMessage="Your user account can not be deleted for the following reasons:"
                        />
                        <ul className="mt-4">
                            {this.state.yourDetails.showUserAccountHasCardsWarning
                            ?
                                <li><FormattedMessage
                                    id="profile.accountDeletion.accountHasCards"
                                    defaultMessage="You have one or more cards (orders);"
                                    />
                                </li>
                            : null}
                            {this.state.yourDetails.showAccountHasSignedContractsWarning
                            ?
                                <li><FormattedMessage
                                    id="profile.accountDeletion.accountHasSignedContracts"
                                    values={{signedContractCompanyName: this.state.yourDetails.signedContractCompanyName}}
                                    defaultMessage="You have signed contract for the company;"
                                    />
                                </li>
                            : null}
                            {this.state.yourDetails.showVerifiedByManualIDWarning
                            ?
                                <li><FormattedMessage
                                    id="profile.accountDeletion.accountVerifiedByManualID"
                                    defaultMessage="Your identity was verified at Manual ID verification partner;"
                                    />
                                </li>
                            : null}
                            {this.state.yourDetails.showLastMainUserWarning
                            ?
                                <li><FormattedMessage
                                    id="profile.accountDeletion.accountLastMainUser"
                                    values={{lastMainUserCompanyName: this.state.yourDetails.lastMainUserCompanyName}}
                                    defaultMessage="You are the last main user in the company;"
                                    />
                                </li>
                            : null}
                        </ul>
                        <FormattedMessage
                            id="profile.accountDeletion.accountDependenciesWarningBottom"
                            defaultMessage="If you have any questions regarding your user account, contact our customer service."
                        />
                    </Message>
                :
                <div>
                    <Message id="errorMessage" type="info">
                        <FormattedMessage
                            id="profile.accountDeletion.warning"
                            defaultMessage="Note that account deletion cannot be undone."
                        />
                    </Message>

                    <button
                        id="profile-delete-my-account-btn"
                        type="button"
                        className="btn btn-primary btn-lg mr-3"
                        data-toggle="modal"
                        data-target="#delete-my-account-modal"
                        role="button"
                    >
                        <FormattedMessage
                            id="profile.accountDeletion.deleteMyAccount"
                            defaultMessage="Delete my account"
                        />
                    </button>
                </div>
                }
                <ConfirmationModal
                    id='delete-my-account-modal'
                    okButtonText={formatMessage(messages.AccountDeletionModalConfirmBtnText)}
                    cancelButtonText={formatMessage(messages.AccountDeletionModalDeclineBtnText)}
                    title={formatMessage(messages.AccountDeletionModalTitle)}
                    handleActionType={deleteUserAccountBtnClicked}
                    okBtnClass="btn btn-primary"
                    modalClass="text-left"
                    dismissModalAfterClick={true}
                    sharedStore={this.state.yourDetails.shared}
                >
                    <FormattedMessage
                        id="profile.accountDeletion.confirmationMessage"
                        defaultMessage="Are you sure you want to delete your account?"
                    />
                </ConfirmationModal>
                <ConfirmationModal
                    id='account-deleted-modal'
                    okButtonText={formatMessage(messages.DeletedAccountModalConfirmation)}
                    title={formatMessage(messages.DeletedAccountModalTitle)}
                    okBtnClass="btn btn-primary"
                    handleActionType={userAccountDeleted}
                    modalClass="text-left"
                    dismissModalAfterClick={true}
                    showModal={this.state.yourDetails.showUserAccountDeletedModal}
                    hideCloseButton={true}
                    sharedStore={this.state.yourDetails.shared}
                >
                    <FormattedMessage
                        id="profile.deletedAccount.successMessage"
                        defaultMessage="Your account has been deleted successfully."
                    />
                </ConfirmationModal>

            </div>
        )
    }
}

export default DeleteMyAccount;
