import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import clearFilterBtnClickedHandler from '../../actions/actionCreators/users/ClearFilterBtnClickedHandler.js';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import { Message, PageHeader, FormRow, FormTable, FormColumn, TextInput,} from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    searchPlaceholder: {
        id: 'users.cpbSearchList.searchInputPlaceholder',
        description: 'Search input placeholder',
        default: 'Name or e-mail',
    },
});

class FindCpbUsers extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getTabClass(tab) {
        let currenTab = this.state.shared.currentRoute.params.serviceName
                        ? this.state.shared.currentRoute.params.serviceName
                        : this.state.shared.currentRoute.fullPath.split('/').pop();
        return tab === currenTab ? ' active' : '';
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let findCpbUsersForm = this.state.users.findCpbUsersForm;

        let allUsers = this.state.users.searchCpbUserList;

        let limit = this.state.shared.config ? this.state.shared.config.usersLimitInUsersList : 200;

        let exceededUsersLimit = this.state.users.exceededUsersWithoutCompanyLimit;

        let searchTooNarrow = findCpbUsersForm.fields.usersFilterValue.value.length < 3;

        return (
            <div className="users-management">
                <PageHeader
                    pageTitleMessageId="users.cpbSearchList.title"
                    sharedStore={this.state.shared}
                />

                <div>
                    { findCpbUsersForm
                        ? (
                            <div>
                                <div id="users-filter" className="card card-default mb-0">
                                    <div className="card-block">
                                        <form id="find-cpb-users-form" onSubmit={ () => { findCpbUsersForm.handleOnSubmitBtnClicked; }}>
                                            <FormRow>
                                                <FormColumn widthLg="6" widthMd="6">
                                                    <div className="form-group mb-0">
                                                        <TextInput
                                                            id="find_cpb_users_list_filter_input"
                                                            field={findCpbUsersForm.fields.usersFilterValue}
                                                            inputGroup={true}
                                                            addonPossition="left"
                                                            autoFocus={true}
                                                            placeholder={formatMessage(messages.searchPlaceholder) }
                                                        >
                                                            <span role="button" onClick={findCpbUsersForm.handleOnSubmitBtnClicked} className="input-group-addon text-primary bg-white">
                                                                <i id="filter-icon" className="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </TextInput>
                                                    </div>
                                                </FormColumn>
                                            </FormRow>
                                        </form>
                                    </div>
                                </div>
                                { findCpbUsersForm.fields.usersFilterValue.value.length
                                    ?
                                        <div role="button" id="clear-filters" className="text-primary text-right mb-6 mt-2 bg-white" onClick={clearFilterBtnClickedHandler}>
                                            <i className="fa fa-trash-o fa-mr" aria-hidden="true"></i>
                                            <FormattedMessage id="users.list.clearFilters" defaultMessage="Clear filters" />
                                        </div>
                                    : null
                                }
                            </div>
                        )
                        : null
                    }

                    {searchTooNarrow
                        ?
                            <Message id="to-much-users-in-list" type="warning" className="mt-4">
                                <FormattedMessage
                                    id="users.cpbSearchList.specifyAtLestThreeSearchCharacters"
                                    defaultMessage="Please specify at least 3 characters of user's full name or email"
                                />
                            </Message>
                        : null
                    }


                    {!allUsers.length && !exceededUsersLimit && !searchTooNarrow && this.state.users.showSearchStatusMessages
                        ?
                            <Message id="no-users-found-list" type="warning">
                                <FormattedMessage
                                    id="users.list.noUsersFoundMessage"
                                    defaultMessage="No users found."
                                />
                            </Message>
                        : null
                    }

                    {exceededUsersLimit && this.state.users.showSearchStatusMessages
                        ?
                            <Message id="to-much-users-in-list" type="warning">
                                <FormattedMessage
                                    id="users.list.listLimitMessage"
                                    defaultMessage="To much users found. Please make your search more specific."
                                    values={{number: limit}}
                                />
                            </Message>
                        : null
                    }
                    {allUsers.length
                        ? (
                            <div className="mt-6">
                                <FormTable id="cpb_users_list" className="form-table-striped form-table-hover mt-4">

                                    <FormRow header={true}>
                                        <FormColumn width="4">
                                            <a id="user_column">
                                                <FormattedMessage id="users.cpbSearchList.title.user" defaultMessage="User" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <a id="user_type_column">
                                                <FormattedMessage id="users.cpbSearchList.title.emailVerified" defaultMessage="Email verified" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <a id="permissions_column">
                                                <FormattedMessage id="users.cpbSearchList.title.bankIdAuthenticated" defaultMessage="Authenticated via BankID" />
                                            </a>
                                        </FormColumn>
                                    </FormRow>

                                    {allUsers.map((u, i) => {
                                        return (
                                            <FormRow key={i} role="button">

                                                <FormColumn mobileHeader={true} width="12">
                                                    <FormattedMessage id="users.cpbSearchList.title.user" defaultMessage="User" />
                                                </FormColumn>
                                                <FormColumn width="4">
                                                    <div className="pull-left">
                                                        <p id={"cpb_users_list_name_" + i} className={u.isActive ? 'font-weight-bold':''}>{u.fullName}</p>
                                                        <p id={"cpb_users_list_email_" + i}  className="small text-muted">{u.email}</p>
                                                        <p id={"cpb_users_list_phone_" + i}  className="small text-muted">{u.phoneNumber}</p>
                                                    </div>
                                                </FormColumn>

                                                <FormColumn mobileHeader={true} width="12">
                                                    <FormattedMessage id="users.cpbSearchList.title.emailVerified" defaultMessage="Email verified" />
                                                </FormColumn>
                                                <FormColumn width="4">
                                                    <p id={'cpb_users_list_email_verified_' + i}>
                                                        { u.emailIsVerified
                                                            ? <FormattedMessage id="users.cpbSearchList.yes" defaultMessage="Yes" />
                                                            : <FormattedMessage id="users.cpbSearchList.no" defaultMessage="No" />
                                                        }
                                                    </p>
                                                </FormColumn>

                                                <FormColumn mobileHeader={true} width="12">
                                                    <FormattedMessage id="users.cpbSearchList.title.bankIdAuthenticated" defaultMessage="Authenticated via BankID" />
                                                </FormColumn>
                                                <FormColumn width="4">
                                                    <p id={'cpb_users_list_identity_verified_' + i}>
                                                        { u.identityVerified
                                                            ? <FormattedMessage id="users.cpbSearchList.yes" defaultMessage="Yes" />
                                                            : <FormattedMessage id="users.cpbSearchList.no" defaultMessage="No" />
                                                        }
                                                    </p>
                                                </FormColumn>

                                            </FormRow>
                                        );
                                    })}
                                </FormTable>
                            </div>
                        )
                            : null
                    }
                </div>
            </div>
        );

    }
}

export default FindCpbUsers;
