import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

let finishEmailVerificationAndMerging = function(token) {

    getFromBackend('/profile/merge-accounts/' + token,
        ActionTypes.MERGE_ACCOUNTS_SUCCESS,
        ActionTypes.MERGE_ACCOUNTS_ERROR,
        { token: token });
};

export default finishEmailVerificationAndMerging;
