import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let getResendInvitationBtnClickedHandler = function (userId) {
    let resendInvitationBtnClickedHandler = function (event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ORGANIZATION_USERS_RESEND_INVITATION_BTN_CLICKED,
            userId: userId
        });
    };

    return resendInvitationBtnClickedHandler;
};

export default getResendInvitationBtnClickedHandler;
