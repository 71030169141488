import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let createId06LmaUserAccount = function (data) {
    postToBackend(`/registration/create-id06-lma-user-account`,
        ActionTypes.ID06_LMA_CREATE_USER_ACCOUNT_SUCCESS,
        ActionTypes.ID06_LMA_CREATE_USER_ACCOUNT_ERROR,
        data
    );
};
export default createId06LmaUserAccount;