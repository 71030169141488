import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import {intlShape, FormattedMessage, defineMessages} from 'react-intl';
import {PageHeader, FormRow, FormColumn, FormGroup, ValidationMessages, TextInput, CheckboxInput, Message} from '@vaultit/stv-utils-frontend';
import prepareUrl from '../../../helpers/PrepareUrl.js';


const messages = defineMessages({
    lmaUserPasswordPlaceholder: {
        id: 'createId06LmaUserAccount.form.lmaUserPasswordPlaceholder',
        description: 'Password',
        defaultMessage: 'Password'
    },
    lmaUserPasswordAgainPlaceholder: {
        id: 'createId06LmaUserAccount.form.lmaUserPasswordAgainPlaceholder',
        description: 'Repeat password',
        defaultMessage: 'Repeat password'
    }
});


class CreateID06LmaUserAccount extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let store = this.state.id06LmaUserStore;
        let form = store.createId06LmaUserAccountForm;

        if (!form) {
            return null;
        }

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="createId06LmaUserAccount.pageTitle"
                    sharedStore={this.state.shared}
                />

                <FormRow>
                    <FormColumn width="12">
                        { store.userAlreadyExists
                            ?
                                <Message type="warning">
                                    <FormattedMessage id="createId06LmaUserAccount.userAlreadyExists" defaultMessage="User already registered." />
                                </Message>
                            : null
                        }
                    </FormColumn>
                    <FormColumn width="6">
                        <FormGroup>
                            <ValidationMessages field={form.fields.firstName}>
                                <TextInput
                                    id="create-id06-lma-user-first-name-input"
                                    field={form.fields.firstName}
                                    messageId="createId06LmaUserAccount.form.lmaUserFirstNameInputTitle"
                                    defaultMessage="First name"
                                    required={true}
                                    disabled={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="6">
                        <FormGroup>
                            <ValidationMessages field={form.fields.lastName}>
                                <TextInput
                                    id="create-id06-lma-user-last-name-input"
                                    field={form.fields.lastName}
                                    messageId="createId06LmaUserAccount.form.lmaUserLastNameInputTitle"
                                    defaultMessage="Last name"
                                    required={true}
                                    disabled={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="6">
                        <FormGroup>
                            <ValidationMessages field={form.fields.phoneNumber}>
                                <TextInput
                                    id="create-id06-lma-user-phone-number-input"
                                    field={form.fields.phoneNumber}
                                    messageId="createId06LmaUserAccount.form.lmaUserPhoneNumberInputTitle"
                                    defaultMessage="Phone number"
                                    required={true}
                                    disabled={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="6">
                        <FormGroup>
                            <ValidationMessages field={form.fields.emailAddress}>
                                <TextInput
                                    id="create-id06-lma-user-email-address-input"
                                    field={form.fields.emailAddress}
                                    messageId="createId06LmaUserAccount.form.lmaUserEmailAddressInputTitle"
                                    defaultMessage="Email address"
                                    required={true}
                                    disabled={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="6">
                        <FormGroup>
                            <ValidationMessages field={form.fields.password}>
                                <TextInput
                                    type="password"
                                    id="create-id06-lma-user-password-input"
                                    field={form.fields.password}
                                    messageId="createId06LmaUserAccount.form.lmaUserPasswordInputTitle"
                                    defaultMessage="Password"
                                    required={true}
                                    placeholder={formatMessage(messages.lmaUserPasswordPlaceholder)}
                                />
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="6">
                        <FormGroup>
                            <ValidationMessages field={form.fields.passwordAgain}>
                                <TextInput
                                    type="password"
                                    id="create-id06-lma-user-password-again-input"
                                    field={form.fields.passwordAgain}
                                    messageId="createId06LmaUserAccount.form.lmaUserPasswordAgainInputTitle"
                                    defaultMessage="Repeat password"
                                    placeholder={formatMessage(messages.lmaUserPasswordPlaceholder)}
                                    required={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="12">
                        <FormGroup>
                            <ValidationMessages field={form.fields.acceptTOS} required="createId06LmaUserAccount.form.personalDataPolicy.required">
                                <CheckboxInput
                                    id="create-id06-lma-user-tos-cbx"
                                    checked={form.fields.acceptTOS.value}
                                    field={form.fields.acceptTOS}
                                    messageId="createId06LmaUserAccount.form.acceptanceOfTOS"
                                    defaultMessage="I accept the "
                                >
                                    <a id="create-id06-lma-user-terms-of-service-printable-version-link" onClick={ (e) => prepareUrl.openUrlInPopUpWindow(e, store.termsOfServicePdfUrl) } href="#">
                                        <FormattedMessage id="createId06LmaUserAccount.form.personalDataPolicyPart1" defaultMessage="ID06 General Terms and Conditions" />
                                    </a>
                                    <FormattedMessage id="createId06LmaUserAccount.form.personalDataPolicyPart2" defaultMessage="and agree that my information is processed in accordance with the terms of this policy." />
                                </CheckboxInput>
                            </ValidationMessages>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn width="12">
                        <FormGroup className="mt-6">
                            <button
                                id="create-id06-lma-user-submit-btn"
                                className="btn btn-primary btn-lg"
                                onClick={form.handleOnSubmitBtnClicked}
                                role="button"
                            >
                                <FormattedMessage id="createId06LmaUserAccount.form.createAccount.btn" defaultMessage="Create account" />
                                <i aria-hidden="true" className="fa fa-angle-right fa-ml"></i>
                            </button>
                        </FormGroup>
                    </FormColumn>
                </FormRow>
            </div>
        );
    }


}
export default CreateID06LmaUserAccount;
