import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let authenticateToSignBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.AUTHENTICATE_TO_SIGN_BTN_CLICKED
    });
};

export default authenticateToSignBtnClicked;
