import ActionTypes from '../ActionTypes.js';
import {getFromBackend} from './generic/CallBackend.js';

let getSsnFormats = function(country) {
    getFromBackend('/general/ssn-formats/' + country,
    ActionTypes.GET_SSN_FORMATS_SUCCESS,
    ActionTypes.GET_SSN_FORMATS_ERROR);
};

export default getSsnFormats;
