import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import {intlShape, FormattedMessage, defineMessages} from 'react-intl';
import {PageHeader, FormRow, FormColumn, FormGroup, ValidationMessages, TextInput, Message} from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    lmaCardNumberPlaceholder: {
        id: 'searchID06LmaCardOrder.form.lmaCardNumberPlaceholder',
        description: 'Input placeholder',
        defaultMessage: '50-008920/4'
    },
    emailAddressPlaceholder: {
        id: 'searchID06LmaCardOrder.form.emailAddressPlaceholder',
        description: 'Input placeholder',
        defaultMessage: 'Email address'
    },
    signInUrlText: {
        id: 'searchID06LmaCardOrder.personIsFullyRegistered.signInUrlText',
        description: 'Sign in url text',
        defaultMessage: 'sign in here'
    },
    resetPasswordUrlText: {
        id: 'searchID06LmaCardOrder.personIsFullyRegistered.resetPasswordUrlText',
        description: 'Reset password url text',
        defaultMessage: 'reset it here'
    }
});


class SearchID06LmaCardOrder extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let store = this.state.id06LmaUserStore;
        let form = store.id06LmaCardOrderSearchForm;

        if (!form) {
            return null;
        }

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="searchID06LmaCardOrder.pageTitle"
                    sharedStore={this.state.shared}
                    simpleChildren={true}
                >
                    <p className="h5 mt-3 font-weight-normal text-muted">
                        <FormattedMessage
                            id="searchID06LmaCardOrder.pageTitle.description"
                            defaultMessage="To access your card order you have to provide your LMA card number and email address which were provided with card order"
                        />
                    </p>
                </PageHeader>

                <FormRow>
                    <FormColumn width="12">
                        { store.noPendingOrderForUser
                            ?
                                <Message type="warning">
                                    <FormattedMessage
                                        id="searchID06LmaCardOrder.personHasNoPendingCardOrders"
                                        defaultMessage="There is no LMA card order for provided information"
                                    />
                                </Message>
                            : null
                        }

                        { store.userNeedsToLogin
                            ?
                                <Message type="warning">
                                    <FormattedMessage
                                        id="searchID06LmaCardOrder.personIsFullyRegistered"
                                        values={{
                                            signInUrl: (<a href={store.signInUrl}>{formatMessage(messages.signInUrlText)}</a>),
                                            resetPasswordUrl: (<a href={store.resetPasswordUrl}>{formatMessage(messages.resetPasswordUrlText)}</a>)
                                        }}
                                        defaultMessage="You already have an existing account and can {signInUrl}. In case you forgot your password - you can {resetPasswordUrl}."
                                    />
                                </Message>
                            : null
                        }
                        { store.personNeedsToLoginWithThisEmail
                            ?
                                <Message type="warning">
                                    <FormattedMessage
                                        id="searchID06LmaCardOrder.personNeedsToLoginWithThisEmail"
                                        values={{
                                            signInUrl: (<a href={store.signInUrl}>{formatMessage(messages.signInUrlText)}</a>),
                                            resetPasswordUrl: (<a href={store.resetPasswordUrl}>{formatMessage(messages.resetPasswordUrlText)}</a>),
                                            username: (<strong>{store.personNeedsToLoginWithThisEmail}</strong>)
                                        }}
                                        defaultMessage="You already have an existing account with this email - {username} and can {signInUrl}. In case you forgot your password - you can {resetPasswordUrl}."
                                    />
                                </Message>
                            : null
                        }
                        { store.enteredEmailIsTaken
                            ?
                                <Message type="warning">
                                    <FormattedMessage id="searchID06LmaCardOrder.emailBelongsToAnotherPerson" defaultMessage="Entered email belongs to another user." />
                                </Message>
                            : null
                        }
                    </FormColumn>
                    <FormColumn width="8">
                        <FormGroup>
                            <ValidationMessages field={form.fields.lmaCardNumber}>
                                <TextInput
                                    id="search-id06-lma-card-order-number-input"
                                    field={form.fields.lmaCardNumber}
                                    messageId="searchID06LmaCardOrder.form.lmaCardNumberInputTitle"
                                    defaultMessage="LMA card number"
                                    placeholder={formatMessage(messages.lmaCardNumberPlaceholder) }
                                    required={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                        <FormGroup>
                            <ValidationMessages field={form.fields.emailAddress}>
                                <TextInput
                                    id="search-id06-lma-card-order-email-address-input"
                                    field={form.fields.emailAddress}
                                    messageId="searchID06LmaCardOrder.form.emailAddressInputTitle"
                                    defaultMessage="Email address"
                                    placeholder={formatMessage(messages.emailAddressPlaceholder) }
                                    required={true}
                                />
                            </ValidationMessages>
                        </FormGroup>
                        <FormGroup className="mt-6">
                            <button
                                id="search-id06-lma-card-order-submit-btn"
                                className="btn btn-primary btn-lg"
                                onClick={form.handleOnSubmitBtnClicked}
                                role="button"
                            >
                                <FormattedMessage id="searchID06LmaCardOrder.form.continue.btn" defaultMessage="Continue" />
                                <i aria-hidden="true" className="fa fa-angle-right fa-ml"></i>
                            </button>
                        </FormGroup>
                    </FormColumn>
                </FormRow>
            </div>
        );
    }
}
export default SearchID06LmaCardOrder;
