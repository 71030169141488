import ActionTypes from '../ActionTypes.js';
import {getFromBackend} from './generic/CallBackend.js';

let checkPostAuthError = function() {

    getFromBackend('/general/check-post-auth-error',
        ActionTypes.CHECK_POST_AUTH_ERROR_SUCCESS,
        ActionTypes.CHECK_POST_AUTH_ERROR_ERROR,
        null,
        true);
};

export default checkPostAuthError;
