import BaseStore from './base/BaseStore.js';

class InfoPageStore extends BaseStore {

    getInitialState() {
        let initialState = {
            userGuideUrl: ''
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/info-page',
                loadHandler: this.onInfoPageRouteLoaded
            }
        ];
    }

    onInfoPageRouteLoaded(state, action, sharedStoreState) {
        this.constructPdfUrl(state, action, sharedStoreState);
    }

    onAnyAction(state, action, sharedStoreState) {
        this.constructPdfUrl(state, action, sharedStoreState);
    }

    constructPdfUrl(state, action, sharedStoreState) {
        let currentLang = sharedStoreState.localizations.messagesLanguage;
        let userGuidePrefix = sharedStoreState.tosPrefix;
        state.userGuideUrl = "/binary/" + userGuidePrefix + "_userguide." + currentLang + ".pdf";
    }

}
export default InfoPageStore;