import BaseStore from '../base/BaseStore.js';

import prepareUrl from '../../helpers/PrepareUrl.js';
import ActionTypes from '../../actions/ActionTypes.js';
import finishEmailVerification from '../../actions/actionCreators/profile/emailVerification/FinishEmailVerification.js';
import mergeAccounts from '../../actions/actionCreators/profile/emailVerification/MergeAccounts.js';
import beginEmailVerification from '../../actions/actionCreators/profile/emailVerification/BeginEmailVerification.js';
import resendVerificationEmail from '../../actions/actionCreators/profile/emailVerification/ResendVerificationEmail.js';
import resendMergeVerificationEmail from '../../actions/actionCreators/profile/emailVerification/ResendMergeVerificationEmail.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import getAuthInfo from '../../actions/actionCreators/GetAuthInfo.js';

class EmailVerificationStore extends BaseStore {

    getInitialState() {
        return {
            showLoadingIndicator: false,
            visibleElements: {},
            uiConfiguration: {},
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            showEditPersonalInformationLink: false,
            editEnteredInfoUrl: null
        };
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/emailverification/verificationneededafterstep1',
                loadHandler: this.onVerificationNeededAfterStep1RouteLoaded
            },
            {
                fullPath: '/profile/emailverification/verificationneeded',
                loadHandler: this.onVerificationNeededRouteLoaded
            },
            {
                fullPath: '/profile/emailverification/mergeverificationneeded',
                loadHandler: this.onMergeVerificationNeededRouteLoaded
            },
            {
                fullPath: '/profile/emailverification/begin/:token',
                loadHandler: this.onBeginRouteLoaded
            },
            {
                fullPath: '/profile/emailverification/merge/:token',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_ACCOUNT_MERGE_IN_CURRENT_SESSION',
                loadHandler: this.onMergeAccountsRouteLoaded
            },
            {
                fullPath: '/profile/emailverification/loggedoff/:token/:username',
                loadHandler: this.onLoggedOffRouteLoaded
            },
            {
                fullPath: '/profile/emailverification/finish/:token',
                loadHandler: this.onFinishRouteLoaded
            },
            {
                fullPath: '/emailverificationfinished',
                loadHandler: this.onFinishedRouteLoaded
            },
            {
                fullPath: '/registration-after-manualid-verification/verify-email/:email',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_TRY_TO_REGISTER_ACCOUNT_AFTER_ID_VERIFICATION',
                loadHandler: this.onEmailNeesVerificationRouteLoaded
            }
        ];
    }

    onVerificationNeededAfterStep1RouteLoaded(state, action, sharedStoreState) {
        state.visibleElements = {
            verificationNeededMsg: true,
            resendEmailOnlyText: true,
        };

        let emailToPrefill = sessionStorage.getItem('registrationStep1EmailToPrefill');
        let firstNameToPrefill = sessionStorage.getItem('registrationStep1FirstNameToPrefill');
        let lastNameToPrefill = sessionStorage.getItem('registrationStep1LastNameToPrefill');
        let phoneNumberToPrefill = sessionStorage.getItem('registrationStep1PhoneNumberToPrefill');

        state.showEditPersonalInformationLink = emailToPrefill && firstNameToPrefill && lastNameToPrefill && phoneNumberToPrefill;
        state.editEnteredInfoUrl = prepareUrl.prepareRouteUrl(`registration/createaccount/${emailToPrefill}/${firstNameToPrefill}/${lastNameToPrefill}/${phoneNumberToPrefill}`, false, false);

        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
        sharedStoreState.emailToVerify = sharedStoreState.emailToVerify ? sharedStoreState.emailToVerify : emailToPrefill;
    }

    onVerificationNeededRouteLoaded(state, action, sharedStoreState) {
        state.visibleElements = {
            verificationNeededMsg: true,
            resendEmailBtn: true
        };

        state.showEditPersonalInformationLink = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CREATE_FULL_ACCOUNT');
        state.editEnteredInfoUrl = prepareUrl.prepareRouteUrl(`profile/create-full-account`, false, false);
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
    }

    onMergeVerificationNeededRouteLoaded(state, action, sharedStoreState) {
        state.visibleElements = {
            verificationNeededMsg: true,
            resendMergeEmailBtn: true
        };
        let createAccountFormEmail = sessionStorage.getItem('mergeVerificationEmail');
        sharedStoreState.emailToVerify = sharedStoreState.emailToVerify ? sharedStoreState.emailToVerify : createAccountFormEmail;
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
    }

    onBeginRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;

        beginEmailVerification(sharedStoreState.currentRoute.params.token);
    }

    onMergeAccountsRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
        mergeAccounts(sharedStoreState.currentRoute.params.token);
    }

    onEmailNeesVerificationRouteLoaded(state, action, sharedStoreState) {
        state.visibleElements = {
            verificationNeededMsg: true
        };

        state.showEditPersonalInformationLink = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_TRY_TO_REGISTER_ACCOUNT_AFTER_ID_VERIFICATION');
        let tokenSavedInSession = sessionStorage.getItem('registrationAfterManualidVerificationToken');

        state.editEnteredInfoUrl = prepareUrl.prepareRouteUrl(`/registration-after-manualid-verification/create-account/${tokenSavedInSession}`, false, false);

        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
    }

    [ActionTypes.MERGE_ACCOUNTS_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
        if (action.result && action.result.alreadyVerified) {
            if (!sharedStoreState.loadingAuthInfo
                && sharedStoreState.isSignedIn) {
                state.visibleElements.alreadyVerifiedUserLoggedInAndContinueBtn = true;
            } else {
                state.visibleElements.alreadyVerifiedUserNotLoggedIn = true;
                state.visibleElements.showContinueButtonForAlreadyVerifiedUserNotLoggedIn  = sharedStoreState.showMinimalRegistrationFlow;
            }

        } else if (action.result && (action.result.needToLoginWithBankId || action.result.needsToLoginWithDokobit)) {
            state.visibleElements.showMessageThatUserNeedsToLoginWithBankId = true;

            let returnUrl = prepareUrl.prepareRouteUrl(sharedStoreState.currentRoute.fullUrl);
            let returnUrlEncoded = encodeURIComponent(prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl));

            state.signInWithBankId = action.result.needToLoginWithBankId;
            state.signInWithDokobit = action.result.needsToLoginWithDokobit;

            state.eIdSignInUrl = prepareUrl.getStrongAuthForAnonymousPath(state.uiConfiguration, returnUrlEncoded, false, state.signInWithDokobit);

            sharedStoreState.overrideTopMenuSignInBtnAction = () => {
                redirectToUrl(state.eIdSignInUrl, true, true, false);
            }
        } else if (action.result && action.result.userMismatch) {
            redirectToRoute("/", true);
        } else if (action.result && action.result.emailTaken) {
            state.redirectAfterAuthInfoLoaded = true;
            sessionStorage.setItem('showEmailAlreadyVerifiedMsg', true);
            getAuthInfo(sharedStoreState.representedOrganizationCode);
        } else {
            redirectToRoute("/", true);
        }
    }

    [ActionTypes.MERGE_ACCOUNTS_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_BEGIN_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;

        if (action.result.needsLogoff) {
            let returnUrl = prepareUrl.prepareRouteUrl('/profile/emailverification/loggedoff/' + action.token + '/' + action.result.username, true);
            returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            let signOutUrlWithReturnUrl = prepareUrl.getSignOutRelativeUrl(returnUrlEncoded);
            state.visibleElements = {};
            redirectToUrl(signOutUrlWithReturnUrl);
        } else if (action.result.verificationBeginOk) {
            this.redirectToSignInPage(state, action.token, action.result.username, sharedStoreState, action.result.loginWithSms, action.result.loginWithBank, action.result.loginWithDokobit, action.result.externalAppRedirectUrl);
        } else if (action.result.alreadyVerified) {
            state.visibleElements = {};
            if (!sharedStoreState.loadingAuthInfo
                && sharedStoreState.isSignedIn) {
                state.visibleElements.alreadyVerifiedUserLoggedInAndContinueBtn = true;
            } else {
                state.visibleElements.alreadyVerifiedUserNotLoggedIn = true;
                state.visibleElements.showContinueButtonForAlreadyVerifiedUserNotLoggedIn  = sharedStoreState.showMinimalRegistrationFlow;
            }
        }
    }

    redirectToSignInPage(state, token, username, sharedStoreState, smsLogin=false, bankLogin=false, dokobitLogin=false, redirectUriToExternalApp=false) {
        let returnUrl = prepareUrl.prepareRouteUrl('/profile/emailverification/finish/' + token, true);

        returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
        let returnUrlEncoded = encodeURIComponent(returnUrl);
        let returnUrlToExternalAppEncoded = false;
        if (redirectUriToExternalApp) {
            returnUrlToExternalAppEncoded = encodeURIComponent(redirectUriToExternalApp);
        }
        let signInUrlWithReturnUrl = "";
        state.visibleElements = {};
        if (sharedStoreState.isSignedIn) {
            redirectToRoute('/profile/emailverification/finish/' + token, true);
        } else if (smsLogin) {
            signInUrlWithReturnUrl = prepareUrl.getSmsAuthPath(sharedStoreState.authInfo.topMenuParameters.configuration, returnUrlEncoded, username, returnUrlToExternalAppEncoded);
            redirectToUrl(signInUrlWithReturnUrl, false, true, false);
        } else if (bankLogin) {
            signInUrlWithReturnUrl = prepareUrl.getStrongAuthForAnonymousPath(sharedStoreState.authInfo.topMenuParameters.configuration, returnUrlEncoded, returnUrlToExternalAppEncoded);
            redirectToUrl(signInUrlWithReturnUrl, false, true, false);
        } else if (dokobitLogin) {
            signInUrlWithReturnUrl = prepareUrl.getStrongAuthForAnonymousPath(sharedStoreState.authInfo.topMenuParameters.configuration, returnUrlEncoded, returnUrlToExternalAppEncoded, 'dokobit_identitygw_provision');
            redirectToUrl(signInUrlWithReturnUrl, false, true, false);
        } else {
            signInUrlWithReturnUrl = prepareUrl.getSignInRelativeUrl(returnUrlEncoded, username);
            redirectToUrl(signInUrlWithReturnUrl);
        }
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_BEGIN_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    onLoggedOffRouteLoaded(state, action, sharedStoreState) {
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
        this.redirectToSignInPage(state, sharedStoreState.currentRoute.params.token, sharedStoreState.currentRoute.params.username, sharedStoreState);
    }

    onFinishRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;

        finishEmailVerification(sharedStoreState.currentRoute.params.token);
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_END_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        sharedStoreState.emailToVerify = null;

        let registrationConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration.servicePortal.registrationSettings;
        let currentRegistrationFlow = registrationConfiguration.registrationFlow;
        if (action.result.verificationEndOk) {
            if (currentRegistrationFlow === 'flow-1') {
                var newOrganizationCode = sharedStoreState.authInfo.topMenuParameters.allRepresentationCodes
                    ? sharedStoreState.authInfo.topMenuParameters.allRepresentationCodes[0]
                    : null;
                redirectToRoute("/emailverificationfinished", true, false, newOrganizationCode);
            } else if (currentRegistrationFlow === 'flow-2') {
                this.onFinishedRouteLoaded(state, action, sharedStoreState);
            }
        } else if (action.result.alreadyVerified) {
            state.visibleElements = {
                alreadyVerifiedUserLoggedInAndContinueBtn: true
            };
        } else if (action.result.needsLogoff) {
            let returnUrl = prepareUrl.prepareRouteUrl('/profile/emailverification/loggedoff/' + action.token + '/' + action.result.username, true);
            returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            let signOutUrlWithReturnUrl = prepareUrl.getSignOutRelativeUrl(returnUrlEncoded);
            state.visibleElements = {};
            redirectToUrl(signOutUrlWithReturnUrl);
        }
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_END_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    onFinishedRouteLoaded(state, action, sharedStoreState) {
        state.visibleElements = {
            emailVerificationSuccessMessageAndContinueBtn: true,
            userNeedsToVerifyIdentity: this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VERIFY_NATIONAL_IDENTITY_NUMBER')
        };
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;

        sessionStorage.removeItem('registrationStep1EmailToPrefill');
        sessionStorage.removeItem('registrationStep1FirstNameToPrefill');
        sessionStorage.removeItem('registrationStep1LastNameToPrefill');
        sessionStorage.removeItem('registrationStep1PhoneNumberToPrefill');
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_CONTINUE_BTN_CLICKED]() {
        redirectToRoute("/", true);
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        resendVerificationEmail();
    }

    [ActionTypes.PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_EMAIL_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        resendMergeVerificationEmail();
    }

    [ActionTypes.PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.redirectAfterAuthInfoLoaded = true;
        getAuthInfo(sharedStoreState.representedOrganizationCode);
    }

    [ActionTypes.PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    [ActionTypes.AUTH_INFO_GET_SUCCESS](state) {
        if (state.redirectAfterAuthInfoLoaded) {
            state.showLoadingIndicator = false;
            state.redirectAfterAuthInfoLoaded = false;
            redirectToRoute("/", true);
        }
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_SUCCESS](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {
            verificationEmailResentSuccessfully: true
        };
    }

    [ActionTypes.PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }
}

export default EmailVerificationStore;
