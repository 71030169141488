import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormRow, FormColumn, FormGroup, ValidationMessages, FormValidationMessage, PageHeader, InputLabel, SelectInput, TextInput, Message, prepareText } from '@vaultit/stv-utils-frontend';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';

const messages = defineMessages({
    continue: {
        id: 'profile.activateAccount.submitBtn',
        description: 'Creating user form submit btn',
        defaultMessage: 'Proceed'
    },

    optionFinland: {
        id: 'registration.step1.flow2.country.finland',
        description: 'Finland option',
        defaultMessage: 'Finland'
    },

    optionOtherCountry: {
        id: 'registration.step1.flow2.country.otherCountry',
        description: 'Other country option',
        defaultMessage: 'Other country'
    },
    countrySelectionInfo: {
        id: 'registration.step1.flow2.country.explanation',
        description: 'Country selection explanation',
        defaultMessage: 'Country of your National Person ID number'
    },

    nationalIdentityNumberInfo: {
        id: 'registration.step1.flow2.nationalIdentityNumber.explanation',
        description: 'National Person ID number input field explanation',
        defaultMessage: 'Required to verify your identity'
    },

    optionSweden: {
        id: 'registration.step1.flow2.country.sweden',
        description: 'Sweden option',
        defaultMessage: 'Sweden'
    },

    otherCountriesNotSuported: {
        id: 'registrations.step1.flow2.onlyOneCountrySuppoerted',
        description: 'Other countries are not supported for NIN',
        defaultMessage: 'Other countries not supported at the moment'
    }
});


class updateStrongAuthInformation extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {

        const { formatMessage } = this.context.intl;

        let store = this.state.updateStrongAuthInformation;

        let form = store.updateStrongAuthInformationForm;
        let fields = form ? form.fields : null;

        let inputClass = 'form-control';

        let personCodeCountryOptions =[];

        let registrationsSettings = store.currentRegistrationFlowSettings;
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration.style;

        for (let key in this.state.shared.classifiers.countries) {
            let item = this.state.shared.classifiers.countries[key];
            if (item.code) {
                if (registrationsSettings.availableNationalPersonIdNumberCountries.indexOf(item.code) > -1) {
                    personCodeCountryOptions.push({
                        value: item.code,
                        displayedText: item.name
                    });
                }
            }
        }

        let personalNumberFieldTexts = prepareText.preparePersonalNumberFieldTextInputAttributes(
            'change_strong_auth_info_national_identity_number',
            fields ? fields.country.value : null,
            this.context.intl
        );

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.strongAuth.title"
                    sharedStore={this.state.shared}
                    hideSeparator={true}
                />


                { store.visibleElements.updateStrongAuthInformationForm
                    ? <div>
                        { store.showNINMismatchError
                        ?
                            <Message id="nin-mismatch-error-message" type="warning">
                                <FormattedMessage
                                    id="general.NINmismatch"
                                    defaultMessage="It seems that provided personal information mismatches. Please check your National Person ID number and try again."
                                />
                            </Message>
                        : null
                        }
                        { store.showFailedStrongAuthError
                        ?
                            <Message id="strong-auth-failed-error-message" type="warning">
                                <FormattedMessage
                                    id="general.failedStrongAuth"
                                    defaultMessage="Confirmation of your personal information has failed. Please check your National Person ID number and try again."
                                />
                            </Message>
                        : null
                        }
                        { store.showcurrentNINAlreadyVerifiedError
                        ?
                            <Message id="already-verified-error-message" type="warning">
                                <FormattedMessage
                                    id="general.ssnValueAlreadyVerified"
                                    defaultMessage="National Person ID number with the same value is already verified. Please check your ntional person ID number and try again."
                                />
                            </Message>
                        : null
                        }
                        { store.showInformationAboutSPUpdate
                        ?
                            <Message id="already-verified-error-message" type="warning">
                                <FormattedMessage
                                    id="general.spUpdateInfoMessage"
                                    defaultMessage="After Service portal update current users need to pass strong authentication. Please provide your Person ID number and click Continue."
                                />
                            </Message>
                        : null
                        }
                        <form name="updateStrongAuthInformationForm" noValidate="">
                            <FormRow>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.firstName}>
                                            <InputLabel
                                                required={true}
                                                htmlFor="change_strong_auth_info_first_name"
                                                messageId="profile.activateAccount.firstName"
                                                defaultMessage="First name"
                                            />
                                            <input
                                                disabled
                                                id="change_strong_auth_info_first_name"
                                                type="text"
                                                className={inputClass}
                                                autoComplete="off"
                                                value={fields.firstName.value || ""}
                                                onChange={fields.firstName.handleOnChange}
                                                onKeyDown={fields.firstName.handleOnEnterKeyDownToSubmitForm}
                                                autoFocus
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.lastName}>
                                            <InputLabel
                                                required={true}
                                                htmlFor="change_strong_auth_info_last_name"
                                                messageId="profile.activateAccount.lastName"
                                                defaultMessage="Last name"
                                            />
                                            <input
                                                disabled
                                                id="change_strong_auth_info_last_name"
                                                type="text"
                                                className={inputClass}
                                                autoComplete="off"
                                                value={fields.lastName.value || ""}
                                                onChange={fields.lastName.handleOnChange}
                                                onKeyDown={fields.lastName.handleOnEnterKeyDownToSubmitForm}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.country}>
                                            <SelectInput
                                                id="change_strong_auth_info_country"
                                                field={fields.country}
                                                options={personCodeCountryOptions}
                                                messageId="registration.step1.flow2.country"
                                                defaultMessage="Country"
                                                required={true}
                                                disabled={false}
                                            />
                                        </ ValidationMessages>
                                        <div className="text-muted small mt-2">
                                            <em>{formatMessage(messages.countrySelectionInfo)}</em>
                                        </div>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.personalNumber}>
                                            <TextInput
                                                type={uiConfiguration.ninIsHidden ? 'password' : 'text'}
                                                field={fields.personalNumber}
                                                required={true}
                                                {...personalNumberFieldTexts}
                                            />
                                        </ValidationMessages>
                                        <div className="text-muted small mt-2">
                                            <em>
                                                {formatMessage(messages.nationalIdentityNumberInfo) }
                                            </em>
                                        </div>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>

                        </form>
                        {/*
                            Submit button is outside the form on purpose - form contains password field and we want to disable password manager here.
                            Default button behaviour is done manually through 'handleOnEnterKeyDownToSubmitForm' handlers.
                        */}
                        <FormValidationMessage form={form} />

                        <FormGroup className="mt-6">
                            <input
                                id="change_strong_auth_info_submit"
                                type="submit"
                                className="btn btn-primary btn-lg"
                                value={formatMessage(messages.continue) || "" }
                                onClick={form.handleOnSubmitBtnClicked}
                                role="button"
                            />
                        </FormGroup>
                    </div>
                    : null
                }
            </div>
        );
    }
}

export default updateStrongAuthInformation;
