import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes';

import constants from '../../Constants.js';
import prepareUrl from '../../helpers/PrepareUrl.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';

import checkActivateAccountToken from '../../actions/actionCreators/profile/activateAccount/CheckActivateAccountToken.js';
import activateAccount from '../../actions/actionCreators/profile/activateAccount/ActivateAccount.js';

import storeFormManager from '../validation/StoreFormManager.js';
import {getPersonalNumberMask, requiredValidator, getMatchValidator, passwordValidator, getMaxLengthValidator, getMinLengthValidator} from '@vaultit/stv-utils-frontend';
import getAuthInfo from '../../actions/actionCreators/GetAuthInfo.js';
import getPersonalNumberValidation from '../../actions/actionCreators/GetPersonalNumberValidation.js';
import getSsnFormats from '../../actions/actionCreators/GetSsnFormats.js';
import getHandlerForFormFieldBlur from '../../actions/actionCreators/generic/GetHandlerForFormFieldBlur.js';


const storeKey = "activateAccount";

class ActivateAccountStore extends FormStore {

    getInitialState() {
        let initialState = {
            visibleElements: {},
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            showLoadingIndicator: false,
            passwordValidationMinimumDigits: constants.passwordValidationMinimumDigits,
            registrationFlow2Enabled: false,
            showSignInWithOtherYourAccountLink: false,
            currentEmail: '',
            signInAndAddSecondaryEmail: '',
            showSignInLinkForUser: false,
            showMessageNINIsAlreadyVerified: false,
            forgotPasswordLink: true,
            currentRegistrationFlowSettings: {}
        };

        let activateAccountFormFieldDefinitions = {
            firstName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            email: {
                defaultValue: ''
            },
            phoneNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
            country: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            personalNumber: {
                validateOnAnyFieldChange: true,
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    getMinLengthValidator(4)
                ],
                masks: [
                    getPersonalNumberMask('country')
                ]
            },
            ssnFormats: {
                defaultValue: ''
            },
            acceptTOS: {
                defaultValue: false,
                validators: [
                    requiredValidator
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, "activateAccountForm", activateAccountFormFieldDefinitions, this.onActivateAccountFormSubmitted);

        return initialState;
    }

    getFieldValueChangedHandlerCfg() {
        return [
            {
                storeKey: storeKey,
                formKey: 'activateAccountForm',
                handler: this.fieldValueChanged
            }
        ]
    }

    fieldValueChanged(state, action) {
        if (action.fieldKey == 'country') {
            let country = state.activateAccountForm.fields.country.value;
            let personalNumber = state.activateAccountForm.fields.personalNumber.value;
            state.showLoadingIndicator = true;
            getSsnFormats(country);
            this.getPossiblePersonalNumberValidation(state, country, personalNumber);
        }
    }

    [ActionTypes.GET_SSN_FORMATS_SUCCESS](state, action) {
        state.activateAccountForm.fields.ssnFormats.value = action.result;
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GET_SSN_FORMATS_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.FORM_FIELD_BLUR](state, action) {
        if (action.fieldKey == 'personalNumber') {
            let country = state.activateAccountForm.fields.country.value;
            let personalNumber = state.activateAccountForm.fields.personalNumber.value;
            this.getPossiblePersonalNumberValidation(state, country, personalNumber);
        }
    }

    getPossiblePersonalNumberValidation(state, country, personalNumber) {
        if (!(country == '' || personalNumber == '' || state.activateAccountForm.fields.personalNumber.errors['hasErrors'])) {
            state.showLoadingIndicator = true;
            getPersonalNumberValidation(country, personalNumber);
        }
    }

    [ActionTypes.VALIDATE_PERSONAL_NUMBER_SUCCESS](state, action) {
        if (!action.result) {
            state.activateAccountForm.fields.personalNumber.errors['hasErrors'] = true;
            state.activateAccountForm.fields.personalNumber.errors['list'] = [{'key': 'generalRegexpPersonalNumber', 'msgFormattingParams': {'exampleFormat': state.activateAccountForm.fields.ssnFormats.value}}];
        }
        state.showLoadingIndicator = false;
    }

    [ActionTypes.VALIDATE_PERSONAL_NUMBER_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/create/:token',
                loadHandler: this.onActivateAccountLoaded
            }
        ];
    }

    onActivateAccountLoaded(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        let token = sharedStoreState.currentRoute.params.token;
        let uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        let currentRegistrationFlow = uiConfiguration.servicePortal.registrationSettings.registrationFlow;
        state.currentRegistrationFlowSettings = uiConfiguration.servicePortal.registrationSettings[currentRegistrationFlow];
        sharedStoreState.showMinimalRegistrationFlow = uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
        state.showSignInLinkForUser = sharedStoreState.showMinimalRegistrationFlow;
        checkActivateAccountToken(token);
    }

    [ActionTypes.PROFILE_ACTIVATE_ACCOUNT_CHECK_TOKEN_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        if (action.result.recalculateAuthInfo) {
            getAuthInfo(sharedStoreState.representedOrganizationCode);
        }

        if (action.result.username) {
            state.signInUrl = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl('/', action.result.username));
        }

        if (action.result.tokenValid) {
            let tosType = '';
            let currentRegistrationFlow = '';
            let configuration = {};
            if (sharedStoreState.authInfo
                && !sharedStoreState.loadingAuthInfo) {
                configuration = sharedStoreState.authInfo.topMenuParameters.configuration;
                let registrationConfiguration = configuration.servicePortal.registrationSettings;
                currentRegistrationFlow = registrationConfiguration.registrationFlow;
                tosType = "person";
                state.showSignInWithOtherYourAccountLink = registrationConfiguration[currentRegistrationFlow].supportMultipleAccountEmails;
            }
            state.registrationFlow2Enabled = currentRegistrationFlow === "flow-2";

            let currentLang = sharedStoreState.localizations.messagesLanguage;
            let needToRecalculateTermsOfServicePdfUrl = (!state.termsOfServicePdfUrlCachedForLanguage
            || state.termsOfServicePdfUrlCachedForLanguage !== currentLang || sharedStoreState.authInfo);

            if (needToRecalculateTermsOfServicePdfUrl) {
                state.termsOfServicePdfUrlCachedForLanguage = currentLang;
                state.termsOfServicePdfUrl = "/binary/" + sharedStoreState.tosPrefix + "_" + tosType + "_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";
            }

            state.formDefinitions.activateAccountForm.fieldDefinitions.country.defaultValue = state.currentRegistrationFlowSettings.defaultNINCountry;
            storeFormManager.setFormInitialState(state, "activateAccountForm");
            state.activateAccountForm.fields.personalNumber.handleOnBlur = getHandlerForFormFieldBlur(storeKey, "activateAccountForm", "personalNumber", true);
            state.activateAccountForm.fields.email.value = action.result.username;
            state.currentEmail = action.result.username;

            state.countryCode = "";

            if (action.result.countryCodes && action.result.countryCodes.length) {
                state.countryCode = action.result.countryCodes[0].toLowerCase();
                state.showLoadingIndicator = true;
                getSsnFormats(state.countryCode)
            }

            if (state.showSignInWithOtherYourAccountLink) {
                let token = sharedStoreState.currentRoute.params.token;
                let returnUrl = prepareUrl.prepareRouteUrl('/profile/addAnotherEmailToAccount/' + token);
                let returnUrlEncoded = encodeURIComponent(returnUrl);
                let signInUrlWithReturnUrl = prepareUrl.getSignInRelativeUrl(returnUrlEncoded);
                state.signInAndAddSecondaryEmail = prepareUrl.prepareAbsoluteUrl(signInUrlWithReturnUrl);
            }

            state.visibleElements = {
                activateAccountForm: true
            };
        } else {
            this.handleExceptionalSuccessCases(state, action, sharedStoreState);
        }
    }

    [ActionTypes.PROFILE_ACTIVATE_ACCOUNT_CHECK_TOKEN_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    onActivateAccountFormSubmitted(state, values, sharedStoreState) {
        let token = sharedStoreState.currentRoute.params.token;
        state.showLoadingIndicator = true;
        state.showMessageNINIsAlreadyVerified = false;

        let phoneNumber = "";
        if (state.activateAccountForm.fields.phoneNumber && state.activateAccountForm.fields.phoneNumber.isVisible && state.activateAccountForm.fields.phoneNumber.additionalInfo) {
            phoneNumber = state.activateAccountForm.fields.phoneNumber.additionalInfo.fullNumber;
        }

        activateAccount(token, values.password, values.firstName, values.lastName, phoneNumber, values.country, values.personalNumber);
    }

    [ActionTypes.PROFILE_ACTIVATE_ACCOUNT_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;

        if (action.result.successfullyActivated) {
            state.visibleElements = {
                activateAccountSuccess: true
            };
        } else if (action.result.ninIsAlreadyVerified) {
            state.showSignInWithOtherYourAccountLink = false;
            state.showMessageNINIsAlreadyVerified = true;
            state.forgotPasswordLink = prepareUrl.prepareRouteUrl('/profile/forgot-password');
        } else {
            this.handleExceptionalSuccessCases(state, action, sharedStoreState);
        }
    }

    [ActionTypes.PROFILE_ACTIVATE_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    handleExceptionalSuccessCases(state, action, sharedStoreState) {
        if (action.result.alreadyActivated) {
            state.visibleElements = {
                alreadyActivated: true
            };
        } else if (action.result.needsLogoff) {
            let token = sharedStoreState.currentRoute.params.token;
            let returnUrl = prepareUrl.prepareRouteUrl('/profile/create/' + token, true);
            returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            let signOutUrlWithReturnUrl = prepareUrl.getSignOutRelativeUrl(returnUrlEncoded);
            state.visibleElements = {};
            redirectToUrl(signOutUrlWithReturnUrl);
        }
    }
}

export default ActivateAccountStore;
