import React from 'react';
import { FormattedMessage } from 'react-intl';
import StoreComponent from '../../base/StoreComponent.jsx';
import YourDetailsNav from '../../common/YourDetailsNav.jsx';
import { FormRow, PageHeader, FormTable, Message, FormColumn } from '@vaultit/stv-utils-frontend';


class Permissions extends StoreComponent {

    renderWhenReady() {
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let configuration = this.state.yourDetails.uiConfiguration;

        let basicUserText = <FormattedMessage id="profile.permissions.userTypeBasic" defaultMessage="Basic user" />;
        let mainUserText = <FormattedMessage id="profile.permissions.userTypeMain" defaultMessage="Main user" />;

        let constants = this.state.yourDetails.constants;

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;
        let addNewOrgUrl = uiConfiguration.servicePortal.registrationSettings.registerNewCompanyLink;

        let permissionNameDisplay = (companyPermission, isMainRepresentationForUser, servicesManagedByCurrentUser) => {

            for (var service in configuration.services) {

                let showNotSuportedPermissionWarning = !isMainRepresentationForUser && servicesManagedByCurrentUser.serviceIsLimitedToOneRepresentedOrg[service];
                let markPermissionAsInactiveInOrg = !servicesManagedByCurrentUser.permissionIsActiveInOrg[service];

                if (configuration.services[service]['permission'] && configuration.services[service]['permission'] === companyPermission) {
                    return (
                        <span className={showNotSuportedPermissionWarning || markPermissionAsInactiveInOrg ? 'strikethrough':''}>{ configuration.services[service]['title'][currentLanguageCode] }</span>
                    );
                }
            }

            for (var service in configuration.otherServices) {

                let showNotSuportedGlobalPermissionWarning = !isMainRepresentationForUser && servicesManagedByCurrentUser.serviceIsLimitedToOneRepresentedOrg[service];

                if (configuration.otherServices[service]['permission'] && configuration.otherServices[service]['permission'] === companyPermission) {
                    return (
                        <span className={showNotSuportedGlobalPermissionWarning ? 'strikethrough':''}>{ configuration.otherServices[service]['title'][currentLanguageCode] }</span>
                    );
                }
            }
        };


        return (
            <div>
                <PageHeader pageTitleMessageId="profile.details.title" sharedStore={this.state.shared} />
                {!this.state.shared.authInfo.topMenuParameters.configuration.thirdLevelMenu
                    ? <YourDetailsNav ulClassName="flex-column flex-md-row mb-4" activeTab="permissions" {...this.state.yourDetails.tabVisibility} />
                    : null
                }
                { this.state.yourDetails.orgRepresentations.length
                    ?
                        <FormTable id="permissions-list">
                            <FormRow header={true}>
                                <FormColumn width="4">
                                    <FormattedMessage id="profile.permissions.table.company" defaultMessage="Company" />
                                </FormColumn>
                                <FormColumn width="8">
                                    <FormattedMessage id="profile.permissions.table.permissions" defaultMessage="Permissions" />
                                </FormColumn>
                            </FormRow>
                            { this.state.yourDetails.orgRepresentations.map((company, companyIndex) => (
                                <FormRow id={'represented-company-list-' + companyIndex} key={companyIndex} className="keep-inner-row-border">
                                    <FormColumn mobileHeader={true} width="12">
                                        <FormattedMessage id="profile.permissions.table.company" defaultMessage="Company" />
                                    </FormColumn>
                                    <FormColumn width="4">
                                        <p id={'represented-company-' + companyIndex + '-name'} className="font-weight-bold">{ company.orgName }</p>
                                        <p>{company.orgCode} [{company.orgCountry}]</p>
                                    </FormColumn>
                                    <FormColumn mobileHeader={true} width="12">
                                        <FormattedMessage id="profile.permissions.table.permissions" defaultMessage="Permissions" />
                                    </FormColumn>
                                    <FormColumn width="8">
                                        <FormTable id="service-providers-list" className="form-table-striped form-table-hover mb-0">
                                            <FormRow header={true} />
                                            { this.state.yourDetails.orgRepresentations[companyIndex].representations.map((representation, representationIndex) => (
                                                <FormRow id={'represented-company-' + companyIndex} key={representationIndex} role="button" onClick={representation.handleRowClick}>
                                                    <FormColumn mobileHeader={true} width="12">
                                                        <FormattedMessage id="profile.permissionsList.serviceProvider" defaultMessage="Company" />
                                                    </FormColumn>
                                                    <FormColumn width="4">
                                                        <p id={'represented-company-' + companyIndex + '-service-provider-' + representationIndex}>{ representation.serviceProviderName }</p>
                                                        <p id={'represented-company-' + companyIndex + '-user-type-' + representationIndex} className="small text-muted">
                                                            { representation.role === constants.userTypes.main
                                                                ? mainUserText
                                                                : basicUserText
                                                            }
                                                        </p>
                                                    </FormColumn>
                                                    <FormColumn mobileHeader={true} width="12">
                                                        <FormattedMessage id="profile.permissions.table.permissions" defaultMessage="Permissions" />
                                                    </FormColumn>
                                                    <FormColumn width="8">
                                                        { representation.permissions.length
                                                            ? representation.permissions.map((permission, permissionIndex) => (
                                                                <p id={'represented-company-' + companyIndex + '-user-permission-' + permissionIndex + '-sp-' + representationIndex} key={permissionIndex}>
                                                                    {permissionNameDisplay(permission, representation.isMainForUser, representation.servicesManagedByCurrentUser)}
                                                                </p>
                                                            ))
                                                            : null
                                                        }
                                                        { representation.globalPermissions.length
                                                            ? representation.globalPermissions.map((globalPermission, globalPermissionIndex) => (
                                                                <p id={'represented-company-' + companyIndex + '-user-globaPermission-' + globalPermissionIndex + '-sp-' + representationIndex} key={globalPermissionIndex}>
                                                                    {globalPermission.name}
                                                                    &nbsp;
                                                                    <FormattedMessage id="profile.permissions.table.globalpermissionExplanation" defaultMessage="(global permission)" />
                                                                </p>
                                                            ))
                                                            : null
                                                        }
                                                    </FormColumn>
                                                </FormRow>
                                            ))}
                                        </FormTable>
                                    </FormColumn>
                                </FormRow>
                            ))}
                        </FormTable>
                    :
                    <Message type="info">
                        <FormattedMessage id="profile.permissions.table.noRepresentations" defaultMessage="You have no permissions for any company. You can register a new company " />
                        &nbsp;
                        { !this.state.shared.authInfo.topMenuParameters.menuItemsVisibility.hideAddOrgForStronglyAuthenticatedAnonymous
                        ?
                            <span>
                                <FormattedMessage id="profile.permissions.table.register" defaultMessage="You can register a new company" />
                                &nbsp;
                                <a id="your-details-permissions-empty-registration-link" href={addNewOrgUrl}>
                                    <FormattedMessage id="profile.permissions.registerNewOrganisationLink" defaultMessage="here" />
                                </a>.
                            </span>
                        : null
                        }
                    </Message>
                }
            </div>
        );
    }
}

export default Permissions;
