import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let adminInitiateEmailChangeBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ADMIN_INITIATE_EMAIL_CHANGE_BTN_CLICKED
        });
    };

export default adminInitiateEmailChangeBtnClicked;
