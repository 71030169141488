import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let changeEmailContinueAfterChangeBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.PROFILE_CHANGE_EMAIL_CONTINUE_AFTER_CHANGE_BTN_CLICKED
        });
    };

export default changeEmailContinueAfterChangeBtnClicked;