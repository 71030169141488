import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import {ConfirmationModal} from '@vaultit/stv-utils-frontend';
import proceedToCompanyBtnClicked from '../../actions/actionCreators/navigation/ProceedToCompanyBtnClicked.js';

const modalMessages = defineMessages({
    informationModalTitle: {
        id: 'serviceRedirect.modal.title',
        description: 'Information modal window title',
        defaultMessage: 'CHANGING THE COMPANY'
    },
    informationModalConfirmBtn: {
        id: 'informationAboutRedirection.modal.confirmBtn',
        description: 'Information modal window confirm button',
        defaultMessage: 'Proceed'
    },
    informationModalCancelBtn: {
        id: 'informationAboutRedirection.modal.cancelBtn',
        description: 'Information modal window cancel button',
        defaultMessage: 'Cancel'
    }
});

class InformationModalWrapper extends React.Component {

    static get propTypes() {
        return {
            modalData: PropTypes.object,
            currentState: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;


        return (
            <ConfirmationModal
                id="open-the-previously-selected-company"
                idPrefix="redirect_to_company"
                title={formatMessage(modalMessages.informationModalTitle)}
                okButtonText={formatMessage(modalMessages.informationModalConfirmBtn)}
                cancelButtonText={formatMessage(modalMessages.informationModalCancelBtn)}
                handleActionType={() => proceedToCompanyBtnClicked({"actionName":this.props.modalData.actionName, "orgId":this.props.modalData.organisationId})}
                okBtnClass="btn btn-primary"
                showModal={Boolean(this.props.modalData && this.props.modalData.actionName)}
                sharedStore={this.props.currentState}
            >
                <FormattedMessage id="informationAboutRedirection.modal.openingPreviousOrg" defaultMessage="Opening the previously selected organisation " />
                <b> {this.props.modalData.organisationName} </b>
                <FormattedMessage id="informationAboutRedirection.modal.proceed" defaultMessage=" to proceed" />
            </ConfirmationModal>
        );
    }
}

export default InformationModalWrapper;
