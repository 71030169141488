import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes';

import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';

import acceptPersonToS from '../../actions/actionCreators/profile/yourDetails/acceptPersonTos.js';

import storeFormManager from '../validation/StoreFormManager.js';
import {requiredValidator} from '@vaultit/stv-utils-frontend';

const storeKey = "acceptToS";

class AcceptTOSStore extends FormStore {

    getInitialState() {
        let initialState = {
            visibleElements: {
                acceptToSForm: false
            },
            showLoadingIndicator: false,
            termsOfServicePdfUrl:'',
            userName: '',
            userCanViewTos: false
        };

        let acceptToSFormFieldDefinitions = {
            acceptTOS: {
                defaultValue: false,
                validators: [
                    requiredValidator
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, "acceptToSForm", acceptToSFormFieldDefinitions, this.onAcceptToSFormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/accept-tos',
                loadHandler: this.onAcceptToSRouteLoaded
            }
        ];
    }

    onAcceptToSRouteLoaded(state, values, sharedStoreState) {
        if (!sharedStoreState.authInfo.otherUiInfo.isSignedIn) {
            redirectToRoute('/', true);
        } else {
            let tosType = "person";
            if (sharedStoreState.authInfo
                && !sharedStoreState.loadingAuthInfo) {
                state.userName = sharedStoreState.authInfo.topMenuParameters.signedInUserFirstName;
            }

            let currentLang = sharedStoreState.localizations.messagesLanguage;
            let needToRecalculateTermsOfServicePdfUrl = (!state.termsOfServicePdfUrlCachedForLanguage
            || state.termsOfServicePdfUrlCachedForLanguage !== currentLang || sharedStoreState.authInfo);

            if (needToRecalculateTermsOfServicePdfUrl) {
                state.termsOfServicePdfUrlCachedForLanguage = currentLang;
                state.termsOfServicePdfUrl = "/binary/" + sharedStoreState.tosPrefix + "_" + tosType + "_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";
            }

            storeFormManager.setFormInitialState(state, "acceptToSForm");
            state.visibleElements = {
                acceptToSForm: true
            };
        }
    }

    onAcceptToSFormSubmitted(state) {
        state.showLoadingIndicator = true;
        acceptPersonToS();
    }

    [ActionTypes.ACCEPT_PERSON_TOS_SUCCESS](state) {
        state.showLoadingIndicator = false;
        redirectToRoute('/', true);
    }

    [ActionTypes.ACCEPT_PERSON_TOS_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default AcceptTOSStore;
