import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes.js';
import constants from '../../Constants.js';
import storeFormManager from '../validation/StoreFormManager.js';
import prepareUrl from '../../helpers/PrepareUrl.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import createFullUserAccount from '../../actions/actionCreators/profile/createFullAccount/CreateFullUserAccount.js';
import checkForExistingEmail from '../../actions/actionCreators/profile/createFullAccount/CheckForExistingEmail.js';
import initiateElevateId from '../../actions/actionCreators/profile/createFullAccount/InitiateElevateId.js';
import cancelCreateFullUserAccount from '../../actions/actionCreators/profile/createFullAccount/CancelCreateFullUserAccount.js';
import getAuthInfo from '../../actions/actionCreators/GetAuthInfo.js';
import {requiredValidator, emailValidator, passwordValidator, getMatchValidator, getMaxLengthValidator, whiteSpaceRestrictor, getBlacklistedEmailValidator} from '@vaultit/stv-utils-frontend';


const storeKey = "createFullAccountStore";
class CreateFullAccountStore extends FormStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            showAnotherUserVerifiedEmailMessage: false,
            showAnotherUserVerifiedEmailModal: false,
            showUserCanceledUserAccountCreationSuccessModal: false,
            duplicateEmail: "",
            emailSearchOngoing: false,
            emailModified: false,
            showUserIsNotDeletedHasCardsMsg: false,
            accountIsNotDeleted: false
        };

        let createFullAccountFormFieldDefinitions = {
            email: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            },
            emailAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'email',
                        validator: getMatchValidator('email')
                    }
                ]
            },
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
            phoneNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, 'createFullAccountForm', createFullAccountFormFieldDefinitions, this.onCreateFullAccountFormSubmited);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/create-full-account(/:email)(/:phone)',
                loadHandler: this.onCreateFullAccountRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CREATE_FULL_ACCOUNT'
            }
        ];
    }

    defineEmailKeyDownHandlers(state) {
        state.createFullAccountForm.fields.email.handleOnEnterKeyDownToSubmitForm = () => {
            // When email is change on the email field, a new search is needed
            state.emailModified = true;
        }

        state.createFullAccountForm.fields.emailAgain.handleOnEnterKeyDownToSubmitForm = () => {
            // When email is typed on the email field, the existing duplicate is searched
            if (state.emailModified && !state.emailSearchOngoing) {
                state.emailSearchOngoing = true;
                checkForExistingEmail({email: state.createFullAccountForm.fields.email.value});
            }
        }
    }

    onCreateFullAccountRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.showUserCanceledUserAccountCreationSuccessModal = false;
        state.showUserIsNotDeletedHasCardsMsg = false;
        state.accountIsNotDeleted = false;
        state.showAnotherUserVerifiedEmailMessage = false;
        state.showAnotherUserVerifiedEmailMessage = JSON.parse(sessionStorage.getItem('showEmailAlreadyVerifiedMsg'));
        sessionStorage.setItem('showEmailAlreadyVerifiedMsg', false);

        state.showSessionExpiredInContractSignMessage = JSON.parse(sessionStorage.getItem('sessionExpiredInContractSign'));
        sessionStorage.setItem('sessionExpiredInContractSign', false)

        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };

        storeFormManager.setFormInitialState(state, 'createFullAccountForm');

        let routeParams = sharedStoreState.currentRoute.params;

        if (sharedStoreState.profile.username && sharedStoreState.profile.phoneNumber) {
            state.createFullAccountForm.fields.email.value = sharedStoreState.profile.username;
            state.createFullAccountForm.fields.emailAgain.value = sharedStoreState.profile.username;
            state.createFullAccountForm.fields.phoneNumber.value = sharedStoreState.profile.phoneNumber;
        } else {

            state.createFullAccountForm.fields.email.value = routeParams && routeParams.email ? routeParams.email : '';
            state.createFullAccountForm.fields.emailAgain.value = routeParams && routeParams.email ? routeParams.email : '';
            state.createFullAccountForm.fields.phoneNumber.value = sharedStoreState.profile && sharedStoreState.profile.phoneNumber
                ? sharedStoreState.profile.phoneNumber : routeParams && routeParams.phone ? routeParams.phone : '';
        }
    this.defineEmailKeyDownHandlers(state);
    }

    onCreateFullAccountFormSubmited(state, values) {
        state.showAnotherUserVerifiedEmailMessage = false;
        state.showLoadingIndicator = true;
        createFullUserAccount(values);
    }

    [ActionTypes.CHECK_FOR_EXISTING_EMAIL_SUCCESS](state, action) {
        state.emailSearchOngoing = false;
        state.emailModified = false;
        this.defineEmailKeyDownHandlers(state);
        if (action.result.emailExists) {
            state.duplicateEmail = state.createFullAccountForm.fields.email.value;
            state.showAnotherUserVerifiedEmailModal = true;
        }
    }

    [ActionTypes.CREATE_FULL_USER_ACCOUNT_SUCCESS](state, action, sharedStoreState) {
        if (action.result && action.result.anotherUserVerifiedEmail) {
            state.showLoadingIndicator = false;
            state.showAnotherUserVerifiedEmailMessage = true;
        } else if (action.result && action.result.accountMergingStarted) {
            state.redirectAfterAuthInfoLoaded = true;
            // get auth info before redirecting
            getAuthInfo(sharedStoreState.representedOrganizationCode);
        } else {
            state.showLoadingIndicator = false;
            let route = prepareUrl.prepareRouteUrl('/');
            redirectToRoute(route, true);
        }
    }

    [ActionTypes.AUTH_INFO_GET_SUCCESS](state) {
        if (state.redirectAfterAuthInfoLoaded) {
            state.showLoadingIndicator = false;
            state.redirectAfterAuthInfoLoaded = false;
            if (state.createFullAccountForm) {
                sessionStorage.setItem('mergeVerificationEmail', state.createFullAccountForm.fields.email.value);
            }
            redirectToRoute("/profile/emailverification/mergeverificationneeded", true);
        }
    }


    [ActionTypes.CREATE_FULL_USER_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CREATE_FULL_USER_ACCOUNT_CANCEL_BTN_CLICKED](state) {
        cancelCreateFullUserAccount();
        state.showLoadingIndicator = true;
    }

    [ActionTypes.EMAIL_EXISTS_INFO_BTN_CLICKED](state) {
        state.showAnotherUserVerifiedEmailModal = false;
        initiateElevateId(state.createFullAccountForm.fields.email.value);

    }

    [ActionTypes.EMAIL_EXISTS_INFO_CLOSED](state) {
        state.showAnotherUserVerifiedEmailModal = false;
    }

    [ActionTypes.CANCEL_CREATE_FULL_USER_ACCOUNT_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.showUserCanceledUserAccountCreationSuccessModal = true;
        state.showUserIsNotDeletedHasCardsMsg = false;
        state.accountIsNotDeleted = false;

        if (action.result && action.result.userHasCards) {
            state.accountIsNotDeleted = true;
            state.showUserIsNotDeletedHasCardsMsg = true;
        }

    }

    [ActionTypes.CANCEL_CREATE_FULL_USER_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CREATE_FULL_USER_ACCOUNT_CANCELED_SUCCESS](state, action, sharedStoreState) {
        state.showUserCanceledUserAccountCreationSuccessModal = false;
        if (!state.accountIsNotDeleted) {
            let configuration = sharedStoreState.authInfo.topMenuParameters.configuration;
            redirectToUrl(prepareUrl.getSignOutRelativeUrl(configuration.urlAfterSignOut));
        }
    }
}
export default CreateFullAccountStore;
