import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let finishEmailChangeVerification = function(token, values = null) {

    postToBackend('/profile/email-change-finish/' + token, 
        ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_FINISH_SUCCESS, 
        ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_FINISH_ERROR,
        { token: token, currentPassword: values['currentPassword'] });
};

export default finishEmailChangeVerification;