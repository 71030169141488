import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let resendVerificationEmailBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_BTN_CLICKED
    });
};

export default resendVerificationEmailBtnClicked;
