import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let checkToken = function (token) {
    postToBackend(`/registration/after-manual-id/begin`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CHECK_TOKEN_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CHECK_TOKEN_ERROR,
        {'token': token});
};

export default checkToken;
