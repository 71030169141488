import BaseStore from '../base/BaseStore.js';
import ActionTypes from '../../actions/ActionTypes';
import updateUserInfoWithStrongAuthInfo from '../../actions/actionCreators/profile/yourDetails/UpdateUserNameWithStrongAuthInfo.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';


class UpdateUserInfoToMatchOfficialSourceStore extends BaseStore {

    getInitialState() {
        let initialState = {};

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/update-user-info',
                loadHandler: this.onUpdateUserInfoRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_UPDATE_USER_INFO_FROM_STRONGAUTH_INFO',
            }
        ];
    }

    onUpdateUserInfoRouteLoaded() {

    }

    [ActionTypes.PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        updateUserInfoWithStrongAuthInfo();
    }

    [ActionTypes.PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_SUCCESS](state) {
        state.showLoadingIndicator = false;
        redirectToRoute("/", true);
    }

    [ActionTypes.PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_ERROR](state) {
        state.showLoadingIndicator = false;
    }


}

export default UpdateUserInfoToMatchOfficialSourceStore;
