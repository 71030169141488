import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getInvitationLink = function(orgId, userId) {

    getFromBackend(`/users/${orgId}/generate-invitation-link/${userId}`, 
        ActionTypes.GET_GENERATED_INVITATION_LINK_SUCCESS, 
        ActionTypes.GET_GENERATED_INVITATION_LINK_ERROR);
};

export default getInvitationLink;