import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

let getPersonInfoForRegistration = function (token) {
    getFromBackend(`/registration/get-person-info/${token}`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_PERSON_INFO_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_PERSON_INFO_ERROR);
};

export default getPersonInfoForRegistration;
