import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormGroup, TextInput, PageHeader} from '@vaultit/stv-utils-frontend';
import { intlShape, defineMessages } from 'react-intl';

const messages = defineMessages({
    continue: {
        id: 'profile.addSecondaryEmail.submitBtn',
        description: 'Adding secondary email to account form submit btn',
        defaultMessage: 'Add email to account'
    }
});

class addSecondaryEmail extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let store = this.state.addSecondaryEmail;

        let form = store.addSecondaryEmailForm;
        let fields = form ? form.fields : null;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.addSecondaryEmail.title"
                    sharedStore={this.state.shared}
                    hideSeparator={true}
                />
                { store.visibleElements.addSecondaryEmailForm
                    ? <form name="addSecondaryEmailForm" noValidate="">
                        <FormGroup>
                            <TextInput
                                id="add_secondary_email_field_main_email"
                                field={fields.mainEmail}
                                messageId="profile.addSecondaryEmail.mainEmail"
                                defaultMessage="Main account email"
                                disabled={true}
                            />
                        </FormGroup>
                        <FormGroup>
                            <TextInput
                                id="add_secondary_email_field_secondary_email"
                                field={fields.secondaryEmail}
                                messageId="profile.addSecondaryEmail.secondaryEmail"
                                defaultMessage="Additional account email"
                                disabled={true}
                            />
                        </FormGroup>
                        <FormGroup className="mt-6">
                            <input
                                id="add_secondary_email_confirm_button"
                                type="submit"
                                className="btn btn-primary btn-lg"
                                value={formatMessage(messages.continue) || "" }
                                onClick={form.handleOnSubmitBtnClicked}
                                role="button"
                            />
                        </FormGroup>
                    </form>
                    : null }

            </div>
        );
    }
}

export default addSecondaryEmail;
