import React from 'react';

import StoreComponent from './base/StoreComponent.jsx';
import { FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';

class Http404 extends StoreComponent {

    renderWhenReady() {
        return (
            <div>
                <PageHeader
                    pageTitleMessageId="errors.pageNotFound"
                    sharedStore={this.state.shared}
                />
                <Message id="errorMessage" type="danger">
                    <FormattedMessage
                        id="errors.pageNotFound.message"
                        defaultMessage="The page you are looking for can't be found"
                        values={this.state.users.userModalData}
                    />
                </Message>
            </div>
        );
    }
}

export default Http404;
