import FormStore from '../../base/FormStore.js';
import storeFormManager from '../../validation/StoreFormManager.js';
import ActionTypes from '../../../actions/ActionTypes.js';
import {requiredValidator} from '@vaultit/stv-utils-frontend';
import checkToken from '../../../actions/actionCreators/registration/AfterManualIDVerification/CheckToken.js';
import verifyEnteredNIN from '../../../actions/actionCreators/registration/AfterManualIDVerification/VerifyEnteredNIN.js';
import redirectToRoute from '../../../actions/actionCreators/navigation/RedirectToRoute.js';
import getAuthInfo from '../../../actions/actionCreators/GetAuthInfo.js';
import prepareUrl from '../../../helpers/PrepareUrl.js';

const store = 'landingPageProveYourIdentityStore';
const formKey = 'identityProvingWithNINForm';

class LandingPageProveYourIdentityStore extends FormStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            initialPersonSituationChecked: false,
            alreadyExistingGluuUser: false,
            ninVerificationFailed: false,
            gluuUsername: '',
            signInUrl: '',
            cardHolderRegistration: false,
            ninCountry: '',
            ninMatches: false,
            identityType: false,
            lmaNumberWithVersion: false
        }

        let identityProvingWithNINFormFieldDefinitions = {
            ninValue: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            }
        }

        storeFormManager.defineForm(initialState, store, formKey, identityProvingWithNINFormFieldDefinitions,this.onIdentityProvingWithNINFormSubmited);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/registration-after-manualid-verification/begin/:token',
                loadHandler: this.onBeginRegistrationAfterManualIdRouteLoaded
            }
        ];
    }

    onBeginRegistrationAfterManualIdRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.cardHolderRegistration = false;
        state.initialPersonSituationChecked = false;
        state.ninVerificationFailed = false;
        state.ninMatches = false;
        state.identityType = false;
        state.lmaNumberWithVersion = false;
        let token = sharedStoreState.currentRoute.params.token;
        checkToken(token);
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CHECK_TOKEN_SUCCESS] (state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.initialPersonSituationChecked = true;

        let result = action.result;

        state.cardHolderRegistration = result.cardHoldersRegistration;
        if (result.displayNINValidationForm) {
            state.ninCountry = result.ninCountry;
            state.identityType = result.identityType;
            state.lmaNumberWithVersion = result.lmaNumberWithVersion;
            storeFormManager.setFormInitialState(state, formKey);
        } else if (result.alreadyExistingGluuUser) {
            state.alreadyExistingGluuUser = true;
            state.gluuUsername = result.username;

            state.signInUrl = '';

            if (result.verifiedWithDokobit) {
                prepareUrl.getStrongAuthForAnonymousPath(sharedStoreState.authInfo.topMenuParameters.configuration, null, false, 'dokobit_identitygw_provision');
            } else if (result.verifiedWithBank) {
                prepareUrl.getStrongAuthForAnonymousPath(sharedStoreState.authInfo.topMenuParameters.configuration, null, false, 'bankid_provision');
            } else if (result.verifiedWithManualId) {
                prepareUrl.getSmsAuthPath(sharedStoreState.authInfo.topMenuParameters.configuration);
            } else {
                prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl());
            }
        }
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CHECK_TOKEN_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    onIdentityProvingWithNINFormSubmited(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.ninVerificationFailed = false;
        let token = sharedStoreState.currentRoute.params.token;
        values["token"] = token;
        verifyEnteredNIN(values);
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_NIN_SUCCESS] (state, action) {
        let ninMatches = action.result && action.result.ninMatches;
        let emailAlreadyInUse = action.result && action.result.emailAlreadyInUse;
        state.emailAlreadyInUse = emailAlreadyInUse;
        state.ninVerificationFailed = !ninMatches;
        state.ninMatches = ninMatches;

        state.showLoadingIndicator = false;

        if (ninMatches) {
            getAuthInfo();
        } else {
            state.showLoadingIndicator = false;
        }
    }

    [ActionTypes.AUTH_INFO_GET_SUCCESS](state, action, sharedStoreState) {
        if (state.ninMatches) {
            state.showLoadingIndicator = false;
            let token = sharedStoreState.currentRoute.params.token;
            if (state.emailAlreadyInUse) {
                state.emailAlreadyInUse = null;
                redirectToRoute(`/registration-after-manualid-verification/has-existing-account/${token}`, false, false, false);
            } else {
                redirectToRoute(`/registration-after-manualid-verification/create-account/${token}`, false, false, false);
            }
        }
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_NIN_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

}

export default LandingPageProveYourIdentityStore;
