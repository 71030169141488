import ActionTypes from "../../ActionTypes";
import { postToBackend } from "../generic/CallBackend";

let generateCsvReport = function (permission) {
    postToBackend(
        "/users/csv-reports",
        ActionTypes.CSV_REPORT_SUCCESS,
        ActionTypes.CSV_REPORT_ERROR,
        {permission}
    );
};

export default generateCsvReport;
