import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';


class IdentityVerificiationIsHidden extends StoreComponent {

    render() {

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.strongAuth.identityVerificationIsHidden.title"
                    sharedStore={this.state.shared}
                />
                <Message type="warning" id="profile.strongAuth.identityVerificationIsHidden.message">
                    <FormattedMessage
                        values={{scanningpartnersLink: <a href="https://id06.se/english/#scanningpartners" target="_blank" rel="noopener noreferrer">https://id06.se/english/#scanningpartners</a>}}
                        id="profile.strongAuth.identityVerificationIsHidden.message"
                        defaultMessage="Your personal data is protected in BankID. Please visit one of our Manual ID verification partners {scanningpartnersLink} After your successful identity verification you will be able to sign in with BankID."
                    />
                </Message>
            </div>
        );
    }
}

export default IdentityVerificiationIsHidden;
