import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let postToHubspot = function (data) {
    postToBackend('/general/post-to-hubspot',
        ActionTypes.POST_TO_HUBSPOT_SUCCESS,
        ActionTypes.POST_TO_HUBSPOT_ERROR,
        data);
};

export default postToHubspot;
