import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let deleteJobResult = function(jobId) {
    postToBackend('users/csv-reports/' + jobId + '/delete',
        ActionTypes.CSV_REPORT_DELETE_JOB_RESULT_SUCCESS,
        ActionTypes.CSV_REPORT_DELETE_JOB_RESULT_ERROR
    );
};

export default deleteJobResult;
