import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes';

import prepareUrl from '../../helpers/PrepareUrl.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import checkActivateAccountToken from '../../actions/actionCreators/profile/addSecondaryEmail/CheckActivateAccountToken.js';
import addSecondaryEmailToAccount from '../../actions/actionCreators/profile/addSecondaryEmail/AddSecondaryEmailToAccount.js';

import storeFormManager from '../validation/StoreFormManager.js';


const storeKey = "addSecondaryEmail";

class AddSecondaryEmailStore extends FormStore {

    getInitialState() {
        let initialState = {
            visibleElements: {
                emailAndOrgAddedToAccount: false,
                alreadyActivated: false,
                addSecondaryEmailForm: false,
                onlyEmailAddedToAccount: false
            },
            uiConfiguration: {},
            newUserEmail:'',
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            showLoadingIndicator: false
        };

        let addSecondaryEmailFormFieldDefinitions = {
            mainEmail: {
                defaultValue: ''
            },
            secondaryEmail: {
                defaultValue: ''
            }
        };

        storeFormManager.defineForm(initialState, storeKey, "addSecondaryEmailForm", addSecondaryEmailFormFieldDefinitions, this.onaddSecondaryEmailFormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/addAnotherEmailToAccount/:token',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_ALLOW_TO_ADD_MULTIPLE_EMAILS_TO_ACCOUNT',
                loadHandler: this.onAddSecondaryEmailForm
            },
            {
                fullPath: '/profile/addAnotherEmailResult/:result',
                loadHandler: this.onAddSecondaryEmailResult
            }
        ];
    }

    onAddSecondaryEmailForm(state, values, sharedStoreState) {
        state.visibleElements = {
            alreadyActivated: false,
            emailAddedToAccount: false,
            emailAndOrgAddedToAccount: false
        };
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;

        let token = sharedStoreState.currentRoute.params.token;
        checkActivateAccountToken(token);
        state.showLoadingIndicator = true;
    }

    [ActionTypes.ADD_ANOTHER_EMAIL_TO_ACCOUNT_CHECK_TOKEN_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;

        if (action.result.username) {
            state.signInUrl = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl('/', action.result.username));
        }

        if (action.result.tokenValid) {

            storeFormManager.setFormInitialState(state, "addSecondaryEmailForm");

            state.addSecondaryEmailForm.fields.secondaryEmail.value = action.result.username;
            state.newUserEmail = action.result.username;
            state.addSecondaryEmailForm.fields.mainEmail.value = sharedStoreState.authInfo.userProfile.username;

            state.visibleElements = {
                addSecondaryEmailForm: true
            };
        } else {
            this.handleExceptionalSuccessCases(state, action, sharedStoreState);
        }
    }

    [ActionTypes.ADD_ANOTHER_EMAIL_TO_ACCOUNT_CHECK_TOKEN_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    onaddSecondaryEmailFormSubmitted(state, values, sharedStoreState) {
        let token = sharedStoreState.currentRoute.params.token;
        state.showLoadingIndicator = true;
        addSecondaryEmailToAccount(token);
    }

    [ActionTypes.ADD_ANOTHER_EMAIL_TO_ACCOUNT_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let route = '/profile/addAnotherEmailResult/';

        if (action.result.emailAddedToAccount && !action.result.orgLimitIsReached) {
            route += 'emailAndOrgAddedToAccount';
            redirectToRoute(route, true, false, null);
        } else if (action.result.emailAddedToAccount && action.result.orgLimitIsReached) {
            route += 'onlyEmailAddedToAccount';
            redirectToRoute(route, true, false, null);
        } else {
            this.handleExceptionalSuccessCases(state, action, sharedStoreState);
        }
    }

    [ActionTypes.ADD_ANOTHER_EMAIL_TO_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CONTINUE_TO_SIGNSPACE_BTN_CLICKED](state) {
        let redirectUrl = state.uiConfiguration.servicePortal.userManagement.redirectToSignSpaceAfterUserAction;
        redirectToUrl(redirectUrl, true, true);
    }

    handleExceptionalSuccessCases(state, action) {
        if (action.result.alreadyActivated) {
            let route = '/profile/addAnotherEmailResult/';
            route += 'alreadyActivated';
            redirectToRoute(route, true, false, null);
        }
    }

    onAddSecondaryEmailResult(state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.visibleElements = {
            alreadyActivated: false,
            emailAddedToAccount: false,
            emailAndOrgAddedToAccount: false
        };
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        let visibleMessage = sharedStoreState.currentRoute.params.result;
        state.visibleElements[visibleMessage] = true;
    }
}

export default AddSecondaryEmailStore;
