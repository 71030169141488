import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let grantUserPermissions = function (orgId, userId, data) {
    postToBackend('/users/' + orgId + '/update-user/' + userId,
        ActionTypes.ORGANIZATION_USERS_PERMISSIONS_GRANTED_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_PERMISSIONS_GRANTED_ERROR,
        data);
};

export default grantUserPermissions;
