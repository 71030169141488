import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let verifyEnteredNIN = function (values) {
    postToBackend(`/registration/verify-entered-nin`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_NIN_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_NIN_ERROR,
        values);
};

export default verifyEnteredNIN;
