import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let searchUsersGlobally = function(filter) {
    postToBackend('/users/search-users-globally',
        ActionTypes.SEARCH_USERS_GLOBALLY_GET_SUCCESS,
        ActionTypes.SEARCH_USERS_GLOBALLY_GET_ERROR,
        filter);
};

export default searchUsersGlobally;