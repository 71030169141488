import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let deleteJobResultBtnClicked = function(jobId, event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CSV_REPORT_DELETE_JOB_RESULT_BTN_CLICKED,
        jobId: jobId
    });
}

export default deleteJobResultBtnClicked;
