import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage, intlShape } from 'react-intl';
import { Message } from '@vaultit/stv-utils-frontend';


class PersonalDataPolicyRejected extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }


    renderWhenReady() {

        return (
            <div>
                <Message id="rejected_personal_data_policy" type="warning" className="mt-6 text-center">
                    <FormattedMessage
                        id="profile.rejectedPDP.youNotAccepted"
                        defaultMessage="You did not accept"
                    />&nbsp;
                    <a href={this.state.personalDataPolicyRejectedStore.personDataPolicyLink} id="personal_data_policy_rejected_pdp">
                        <FormattedMessage
                            id="profile.rejectedPDP.personalDataPolicy"
                            defaultMessage="ID06 General Terms and Conditions"
                        />
                    </a>
                    &nbsp;
                    <FormattedMessage
                        id="profile.rejectedPDP.continueActions"
                        defaultMessage="so could not continue your action"
                    />.
                    <br />
                    <FormattedMessage
                        id="profile.rejectedPDP.restartActions"
                        defaultMessage="If you want to restart your action"
                    />&nbsp;
                    <a id="personal_data_policy_rejected_sign_in_with_bank" href={this.state.personalDataPolicyRejectedStore.bankIdSignInLink}>
                        <FormattedMessage
                            id="profile.rejectedPDP.signInWithBankId"
                            defaultMessage="Sign in with BankID"
                        />&nbsp;
                    </a>
                    <FormattedMessage
                        id="profile.rejectedPDP.signInOrWith"
                        defaultMessage="or"
                    />
                    &nbsp;
                    <a id="personal_data_policy_rejected_sign_in_with_dokobit" href={this.state.personalDataPolicyRejectedStore.dokobitSignInLink}>
                        <FormattedMessage
                            id="profile.rejectedPDP.signInWithDokobit"
                            defaultMessage="Sign in with Mobile-ID, SmartID (non Swedish users)"
                        />
                    </a>
                    .
                </Message>
            </div>
        );
    }
}

export default PersonalDataPolicyRejected;
