import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

let beginEmailChangeVerification = function(token) {
    getFromBackend('/profile/email-change/verify/' + token, 
        ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_BEGIN_SUCCESS, 
        ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_BEGIN_ERROR,
        { token: token });
};

export default beginEmailChangeVerification;