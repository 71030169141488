import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let getGlobalUserSearchListRowClickedHandler = function(currentPersonId) {
    let globalUserSearchListRowClicked = function(event) {
            event.preventDefault();
            dispatcher.dispatch({
                type: ActionTypes.SEARCH_USERS_GLOBALLY_LIST_ROW_CLICKED,
                currentPersonId
            });
        };

    return globalUserSearchListRowClicked;
}

export default getGlobalUserSearchListRowClickedHandler;


