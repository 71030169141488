import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let continueAfterVerificationBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.PROFILE_EMAIL_VERIFICATION_CONTINUE_BTN_CLICKED
    });
};

export default continueAfterVerificationBtnClicked;
