import FormStore from '../../base/FormStore.js';
import storeFormManager from '../../validation/StoreFormManager.js';
import {emailValidator, requiredValidator, getMaxLengthValidator, passwordValidator, getMatchValidator} from '@vaultit/stv-utils-frontend';
import searchForID06LmaCardOrder from '../../../actions/actionCreators/registration/id06LmaUserFlow/SearchForID06LmaCardOrder.js';
import createId06LmaUserAccount from '../../../actions/actionCreators/registration/id06LmaUserFlow/CreateId06LmaUserAccount.js';
import ActionTypes from '../../../actions/ActionTypes.js';
import redirectToRoute from '../../../actions/actionCreators/navigation/RedirectToRoute.js';
import constants from '../../../Constants.js';
import prepareUrl from '../../../helpers/PrepareUrl.js';

const storeKey = 'ID06LmaUserStore';
const id06LmaCardOrderSearchFormKey = 'id06LmaCardOrderSearchForm';
const createId06LmaUserAccountFormKey = 'createId06LmaUserAccountForm';

class ID06LmaUserStore extends FormStore {

    getInitialState() {
        let initialState = {
            personNeedsToLoginWithThisEmail: false,
            userNeedsToLogin: false,
            enteredEmailIsTaken: false,
            noPendingOrderForUser: false,
            termsOfServicePdfUrl: null,
            userAlreadyExists: false,
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            resetPasswordUrl: prepareUrl.prepareRouteUrl('/profile/forgot-password'),
            emailToVerify: null,
            createAccountData: null
        };

        let id06LmaCardOrderSearchFieldDefinitions = {
            lmaCardNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            emailAddress: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    emailValidator
                ]
            }
        };


        let createId06LmaUserAccountFieldDefinitions = {
            firstName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            emailAddress: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    emailValidator
                ]
            },
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
            phoneNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            acceptTOS: {
                defaultValue: false,
                validators: [
                    requiredValidator
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, id06LmaCardOrderSearchFormKey, id06LmaCardOrderSearchFieldDefinitions, this.onID06LmaCardOrderSearchFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, createId06LmaUserAccountFormKey, createId06LmaUserAccountFieldDefinitions, this.oncreateId06LmaUserAccountFormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/create-id06-lma-user/order-search',
                loadHandler: this.onID06LmaCardOrderSearchRouteLoaded
            },
            {
                fullPath: '/create-id06-lma-user/create-account',
                loadHandler: this.onCreateID06LmaUserAccountRouteLoaded
            },
            {
                fullPath: '/create-id06-lma-user/email-verification-needed/:emailToVerify',
                loadHandler: this.onEmailVerificationNeededRouteLoaded
            }
        ];
    }

    onID06LmaCardOrderSearchRouteLoaded(state) {
        state.personNeedsToLoginWithThisEmail = false;
        state.userNeedsToLogin = false;
        state.enteredEmailIsTaken = false;
        state.noPendingOrderForUser = false;
        state.createAccountData = null;
        storeFormManager.setFormInitialState(state, id06LmaCardOrderSearchFormKey);
    }

    onCreateID06LmaUserAccountRouteLoaded(state, action, sharedStoreState) {
        state.termsOfServicePdfUrl = "/binary/" + sharedStoreState.tosPrefix + "_person_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";
        state.userAlreadyExists = false;

        if (!state.createAccountData) {
            redirectToRoute('create-id06-lma-user/order-search');
        } else {
            let fieldDefinitions = state.formDefinitions[createId06LmaUserAccountFormKey].fieldDefinitions;

            fieldDefinitions.firstName.defaultValue = state.createAccountData.firstName || '';
            fieldDefinitions.lastName.defaultValue = state.createAccountData.lastName || '';
            fieldDefinitions.emailAddress.defaultValue = state.createAccountData.email || '';
            fieldDefinitions.phoneNumber.defaultValue = state.createAccountData.phone || '';

            storeFormManager.setFormInitialState(state, createId06LmaUserAccountFormKey);
        }
    }

    onEmailVerificationNeededRouteLoaded(state, action, sharedStoreState) {
        state.emailToVerify = sharedStoreState.currentRoute.params.emailToVerify;
        state.createAccountData = null;
    }

    onID06LmaCardOrderSearchFormSubmitted(state, values) {
        state.showLoadingIndicator = true;
        state.personNeedsToLoginWithThisEmail = false;
        state.userNeedsToLogin = false;
        state.enteredEmailIsTaken = false;
        state.noPendingOrderForUser = false;

        searchForID06LmaCardOrder({
            lmaCardNumber: values.lmaCardNumber,
            email: values.emailAddress
        });
    }

    oncreateId06LmaUserAccountFormSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.userAlreadyExists = false;

        values.userLanguageCode = sharedStoreState.localizations.messagesLanguage;
        createId06LmaUserAccount(values);
    }

    [ActionTypes.ID06_LMA_CARD_ORDER_SEARCH_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.personNeedsToLoginWithThisEmail = false;
        state.userNeedsToLogin = false;
        state.enteredEmailIsTaken = false;
        state.noPendingOrderForUser = false;

        if (action.result) {
            if (action.result.createAccountData) {
                state.createAccountData = action.result.createAccountData;
                redirectToRoute('create-id06-lma-user/create-account');
            } else if (action.result.loginWithThisEmail) {
                state.personNeedsToLoginWithThisEmail = action.result.loginWithThisEmail;
                state.signInUrl = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl('/', state.personNeedsToLoginWithThisEmail));
            } else if (action.result.userNeedsToLogin) {
                state.userNeedsToLogin = true;
                state.signInUrl = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl())
            } else if (action.result.emailIsTaken) {
                state.enteredEmailIsTaken = true
            } else {
                state.noPendingOrderForUser = true;
            }
        }
    }

    [ActionTypes.ID06_LMA_CARD_ORDER_SEARCH_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ID06_LMA_CREATE_USER_ACCOUNT_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        if (action.result) {
            if (action.result.userAlreadyExists) {
                state.userAlreadyExists = true;
            } else if (action.result.userCreated) {
                redirectToRoute(`/create-id06-lma-user/email-verification-needed/${state.createAccountData.email}`);
            }
        }

    }

    [ActionTypes.ID06_LMA_CREATE_USER_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default ID06LmaUserStore;
