import constants from "../Constants.js";

const messageMapping = {
    "DocumentNumber": [
        constants.idVerificationType.passportNumber,
        constants.idVerificationType.idCardNumber
    ],
    "NIN": [
        constants.idVerificationType.socialSecurityNumber
    ],
    "LMAnumber": [
        constants.idVerificationType.lmaNumber
    ]
}

let getTranslationKeyManualIdPages = function(identityType, translationKey) {
    if (identityType) {
        Object.keys(messageMapping).map((key) => {
            let supportedTypes = messageMapping[key];
            if (supportedTypes.indexOf(identityType) > -1) {
                translationKey += key;
                return translationKey;
            }
        })
    }
    return translationKey;
}

export default {
    getTranslationKeyManualIdPages: getTranslationKeyManualIdPages
};