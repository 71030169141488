import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let mergePersonsBtnClicked = function() {
    dispatcher.dispatch({
        type: ActionTypes.GLOBAL_USER_INFO_MERGE_PERSONS_BTN_CLICKED
    });
};

export default mergePersonsBtnClicked;
