import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { intlShape, FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';


class AccountRegistrationResultPage extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {

        let store = this.state.accountRegistrationStore;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="registration.afterManualIdVerification.createAccountTitle"
                    sharedStore={this.state.shared}
                    hideSeparator={false}
                    description={false}
                />

                {store.accountSuccessfullyCreated
                ?
                    <div>
                        <Message type="success" className="text-center mb-6 mt-6" id="create_account_after_identity_verification_account_created">
                            <FormattedMessage
                                id="registration.afterManualIdVerification.accountCreated"
                                defaultMessage="User account was successfully created"
                            />.
                        </Message>

                        <p>
                            {store.cardHolderRegistration
                            ?
                                <FormattedMessage
                                    id="registration.afterManualIdVerification.reviewOrdersContinue"
                                    defaultMessage="Thank you, your account is now ready. To see card order(s) please"
                                />
                            :
                            <FormattedMessage
                                id="registration.afterManualIdVerification.continueWithCompanyRegistration"
                                defaultMessage="Thank you, your account is now ready, please"
                            />
                            }
                            &nbsp;
                            <FormattedMessage
                                id="registration.afterManualIdVerification.signInSms"
                                defaultMessage="sign in"
                            />.
                        </p>
                        <a
                            id="create_account_after_identity_verification_signin_sms"
                            href={store.signInUrl}
                            className="btn btn-primary btn-lg mt-6"
                            role="button"
                        >
                            <FormattedMessage
                                id="registration.afterManualIdVerification.yourAccount"
                                defaultMessage="Sign in to your account"
                            />
                        </a>
                    </div>
                : null}

                {store.emailAlreadyBelongsToUser || store.ninIsNotMatching
                    ?
                        <Message type="warning" id="create_account_after_identity_verification_email_needs_verification">
                            { store.emailAlreadyBelongsToUser
                                ?
                                    <FormattedMessage
                                        id="registration.afterManualIdVerification.emailNeedsVerification"
                                        defaultMessage="You need to verify a new entered email"
                                    />
                                : null

                            }
                            { store.ninIsNotMatching
                                ?
                                    <FormattedMessage
                                        id="registration.afterManualIdVerification.anotherUserVerifiedEmail"
                                        defaultMessage="Another person has verified that email-address, you can not use it."
                                    />
                                : null
                            }
                        </Message>
                    : null
                }
            </div>
        );
    }


}

export default AccountRegistrationResultPage;
