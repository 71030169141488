import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let searchCpbUsers = function(filter, orgId) {

    postToBackend('/users/' + orgId + '/search-cpb-users',
        ActionTypes.SEARCH_CPB_USERS_GET_SUCCESS,
        ActionTypes.SEARCH_CPB_USERS_GET_ERROR,
        filter,
        {
            clearPreviousErrors: true
        });

};

export default searchCpbUsers;
