import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import constants from "../../Constants";
import downloadJobResultBtnClicked from '../../actions/actionCreators/users/DownloadJobResultBtnClicked.js';
import deleteJobResultBtnClicked from '../../actions/actionCreators/users/DeleteJobResultBtnClicked.js';
import deleteJobConfirmedBtnClicked from '../../actions/actionCreators/users/DeleteJobConfirmedBtnClicked.js';
import getGlobalUserSearchListRowClickedHandler from '../../actions/actionCreators/users/SearchUsersGloballyListRowClicked.js';

import {
    ConfirmationModal,
    PageHeader,
    Message,
    FormGroup,
    FormTable,
    FormRow,
    FormColumn,
    DateLabel,
    Pagination,
    SelectInput,
} from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    choosePermission: {
        id: 'users.csvReports.choosePermission',
        description: 'Choose permission for the csv report',
        defaultMessage: 'Choose permission'
    },
    confirmationModalTitle: {
        id: 'users.csvReports.confirmationModalTitle',
        description: 'Modal title to confirm report deletion',
        defaultMessage: 'Confirm report deletion'
    },
    confirmationModalYesContinue: {
        id: 'users.csvReports.confirmationModalYesContinue',
        description: 'Confirmation modal yes button text',
        defaultMessage: 'Yes'
    },
    confirmationModalCancel: {
        id: 'users.csvReports.confirmationModalCancel',
        description: 'Confirmation modal cancel button text',
        defaultMessage: 'Cancel'
    },
});

class CsvReport extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }


    render() {
        let state = this.state.csvReportStore;
        const { formatMessage } = this.context.intl;
        const currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        const isSuperAdmin = this.state.shared.isSuperAdmin;
        const translatedPermission = (permission, conf) => {
            // Translation from service
            const serviceKeys = Object.keys(conf.services).filter((k) => (conf.services[k].permission === permission));
            if (serviceKeys.length > 0) {
                return conf.services[serviceKeys[0]].title[currentLanguageCode];
            }
            // Translation from Global permissions
            const matchingGlobalPermissions = this.state.shared.classifiers.globalPermissions.filter((p) => p.code === permission);
            if (matchingGlobalPermissions.length > 0) {
                return matchingGlobalPermissions[0].name;
            }
            return permission;
        };
        const permissions = state.permissions;
        const permissionOptions = permissions.map((permission) => (
            {
                value: permission,
                displayedText: translatedPermission(permission, state.uiConfiguration),
            })
        );
        permissionOptions.unshift({value: "", displayedText: formatMessage(messages.choosePermission)});
        let csvReportForm  = state.csvReportForm;
        let fields = csvReportForm ? csvReportForm.fields : null;
        if (!csvReportForm) {
            return null;
        }

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="users.csvReports.title"
                    sharedStore={this.state.shared}
                />
                {
                    state.showCsvReportSuccessMsg
                        ? <Message id="SuccessMessage" type="success">
                            <FormattedMessage
                                id="users.csvReports.successfullyImported"
                                defaultMessage="CSV report generation successfully initiated"
                            />
                        </Message>
                        : null
                }
                {
                    state.showForm
                    ? <form>
                        <div className="row justify-content-end align-items-end">
                            <div className="col-md-6">
                                <FormGroup>
                                    <SelectInput
                                        inputClassName="form-control-sm"
                                        id="csv_report_select_permission"
                                        field={fields.permission}
                                        options={permissionOptions}
                                        messageId="users.csvReports.selectPermission"
                                        defaultMessage="Permission"
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup>
                                    <button
                                        id="csv_report_submit"
                                        type="button"
                                        className="btn btn-primary btn-lg ml-6"
                                        onClick={csvReportForm.handleOnSubmitBtnClicked}
                                        role="button"
                                        disabled={state.permission === "" || state.submitted}
                                        style={{verticalAlign: "center"}}
                                    >
                                        <FormattedMessage id="users.csvReports.new" defaultMessage="New report" />
                                    </button>
                                </FormGroup>
                            </div>
                        </div>
                    </form>
                    : null
                }
                {state.jobList && state.jobList.length
                    ? (
                        <div>
                            <FormTable id="jobs_list" className="form-table-striped form-table-hover mt-4">
                                <FormRow header={true}>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.csvReports.list.submittedAt" defaultMessage="Submitted at" />
                                    </FormColumn>
                                    <FormColumn width="2">
                                        <FormattedMessage id="users.csvReports.list.submitter" defaultMessage="Submitter" />
                                    </FormColumn>
                                    <FormColumn width="2">
                                        <FormattedMessage id="users.csvReports.list.state" defaultMessage="State" />
                                    </FormColumn>
                                    <FormColumn width="5">
                                        <FormattedMessage id="users.csvReports.list.permission" defaultMessage="Permission" />
                                    </FormColumn>
                                </FormRow>
                                {state.jobList.map((u, i) => {
                                    return (
                                        <FormRow key={i}>
                                            <FormColumn mobileHeader={true} width="12">
                                                <FormattedMessage id="users.csvReports.list.submittedAt" defaultMessage="Submitted at" />
                                            </FormColumn>
                                            <FormColumn width="3">
                                                <div className="pull-left">
                                                    <DateLabel id={"jobs_list_submit_date_" + i} format="YYYY-MM-DD HH:mm">{u.created_at}</DateLabel>
                                                </div>
                                            </FormColumn>
                                            <FormColumn mobileHeader={true} width="12">
                                                <FormattedMessage id="users.csvReports.list.submitter" defaultMessage="Submitter" />
                                            </FormColumn>
                                            <FormColumn width="2">
                                                <div className="pull-left">
                                                    {
                                                        u.person_name
                                                        ? (
                                                            <a href='' id='jobs_list_submited_by_' role="button" onClick={getGlobalUserSearchListRowClickedHandler(u.created_by)}>
                                                                {u.person_name}
                                                            </a>
                                                        )
                                                        : <div>-</div>
                                                    }
                                                </div>
                                            </FormColumn>

                                            <FormColumn mobileHeader={true} width="12">
                                                <FormattedMessage id="users.csvReports.list.state" defaultMessage="State" />
                                            </FormColumn>
                                            <FormColumn width="2">
                                                {
                                                    u.status == constants.csvReportStatus.done
                                                    ? (<p id={"jobs_list_status_" + i}  className="small text-muted">
                                                        <i className="fa fa-lg fa-check-circle-o mr-3 text-success"></i>
                                                        <FormattedMessage id="users.csvReports.list.stateDone" defaultMessage="Completed" />
                                                    </p>)
                                                    : u.status === constants.csvReportStatus.inProgress
                                                    ? (<p id={"jobs_list_status_" + i}  className="small text-muted">
                                                        <i className="fa fa-lg fa-spinner fa-pulse mr-3"></i>
                                                        <FormattedMessage id="users.csvReports.list.stateInProgress" defaultMessage="In progress" />
                                                    </p>)
                                                    : u.status === constants.csvReportStatus.pending
                                                    ? (<p id={"jobs_list_status_" + i}  className="small text-muted">
                                                        <i className="fa fa-lg fa-clock-o mr-3"></i>
                                                        <FormattedMessage id="users.csvReports.list.statePending" defaultMessage="In queue" />
                                                    </p>)
                                                    : u.status == constants.csvReportStatus.failed
                                                    ? (<p id={"jobs_list_status_" + i}  className="small text-muted">
                                                        <i className="fa fa-lg fa-exclamation-circle mr-3 text-danger"></i>
                                                        <FormattedMessage id="users.csvReports.list.stateFailed" defaultMessage="Failed" />
                                                    </p>)
                                                    : null
                                                }
                                            </FormColumn>

                                            <FormColumn mobileHeader={true} width="12">
                                                <FormattedMessage id="users.csvReports.list.permission" defaultMessage="Permission" />
                                            </FormColumn>
                                            <FormColumn width="3">
                                                <div className="pull-left">
                                                    <p id={"jobs_list_permission_" + i}>{translatedPermission(u.permission, state.uiConfiguration)}</p>
                                                </div>
                                            </FormColumn>

                                            <FormColumn width="1">
                                                {
                                                    u.status == constants.csvReportStatus.done || u.status == constants.csvReportStatus.failed
                                                    ? (
                                                        <div className="pull-left">
                                                            <a className="mt-6" onClick={ (e) => downloadJobResultBtnClicked(u.id, e)} href="#">
                                                                <div className="text-center">
                                                                    <i className="fa fa-download fa-lg"></i>
                                                                </div>
                                                                <div className="small text-center">
                                                                    <FormattedMessage id="users.csvReports.list.downloadResult" defaultMessage="Download report" />
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ) : null
                                                }
                                            </FormColumn>
                                            {isSuperAdmin
                                                ? (
                                                    <FormColumn width="1">
                                                        {
                                                            u.status == constants.csvReportStatus.done || u.status == constants.csvReportStatus.failed
                                                                ? (
                                                                    <div className="pull-left">
                                                                        <a className="mt-6" onClick={ (e) => deleteJobResultBtnClicked(u.id, e)} href="#">
                                                                            <div className="text-center">
                                                                                <i className="fa fa-trash fa-lg"></i>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                ) : null
                                                        }
                                                    </FormColumn>
                                                ) : <FormColumn width="1" />
                                            }
                                        </FormRow>
                                    )
                                })}
                            </FormTable>

                        </div>
                    )
                    : null
                }
                {state.jobListTotal
                    ? (
                        <Pagination
                            totalItems={state.jobListTotal}
                            itemsPerPage={state.jobListItemsPerPage}
                            currentPage={state.currentCsvReportListPage}
                            currentFullPath={state.fullPathForPagination}
                        />
                    )
                    : null
                }
                <ConfirmationModal
                    id="delete-job-confirmation-modal"
                    title={formatMessage(messages.confirmationModalTitle)}
                    okButtonText={formatMessage(messages.confirmationModalYesContinue)}
                    cancelButtonText={formatMessage(messages.confirmationModalCancel)}
                    handleActionType={deleteJobConfirmedBtnClicked}
                    sharedStore={this.state.users.shared}
                    showModal={state.showConfirmationModal}
                >
                    <FormattedMessage
                        id="users.csvReports.confirmationModalMessage"
                        defaultMessage="Do you really want to delete this report data?"
                    />
                </ConfirmationModal>
            </div>
        );
    }
}

export default CsvReport;
