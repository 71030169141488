import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let updateUserInfoWithStrongAuthInfo = function (data) {
    postToBackend('/profile/update-user-info-with-strong-auth-info',
        ActionTypes.PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_SUCCESS,
        ActionTypes.PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_ERROR,
        data, data);
};
export default updateUserInfoWithStrongAuthInfo;
