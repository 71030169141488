import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let getUsersListRowClickedHandler = function(userId, orgCode) {
    let usersListRowClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ORGANIZATION_USERS_LIST_ROW_CLICKED,
            orgCode: orgCode,
            userId: userId
        });
    };

    return usersListRowClicked;
};

export default getUsersListRowClickedHandler;
