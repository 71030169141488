import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let getRemovePermissionsBtnClickedHandler = function(userId, permission) {
    let removePermissionsBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ORGANIZATION_USERS_REMOVE_PERMISSIONS_BTN_CLICKED,
            userId: userId,
            permission: permission
        });
    };

    return removePermissionsBtnClicked;
};

export default getRemovePermissionsBtnClickedHandler;
