/* System imports */
import React from 'react';
import { Link } from 'react-router';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
/* Component related imports */
import StoreComponent from '../../base/StoreComponent.jsx';
/* Form related imports */
import { ValidationMessages, PageHeader, FormRow, FormGroup, TextInput, FormColumn } from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    oldPasswordPlaceholder: {
        id: 'profile.changePassword.currentPassword',
        description: 'Current password placeholder',
        defaultMessage: 'Current password'
    },
    newPasswordPlaceholder: {
        id: 'profile.changePassword.newPassword',
        description: 'New password placeholder',
        defaultMessage: 'Password'
    },
    newPasswordAgainPlaceholder: {
        id: 'profile.changePassword.newPasswordAgain',
        description: 'New password again placeholder',
        defaultMessage: 'Password again'
    },
});

class ChangePassword extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        /* Make sure form state is initialized. */
        let store = this.state ? this.state.yourDetails : {};
        let form = store.passwordChangeForm;

        let fields = form ? form.fields : null;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.changePassword.title"
                    sharedStore={this.state.shared}
                />

                { form
                    ?
                        <form name="passwordChangeForm" noValidate="">
                            <FormRow>
                                <FormColumn width="10">
                                    <FormGroup>
                                        <FormattedMessage
                                            id="profile.changePassword.goodToChangeRegulary"
                                            defaultMessage="It is a good practice changing your password regularly. First, to check this is really you, enter your existing password:"
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <ValidationMessages field={fields.oldPassword}>
                                            <TextInput
                                                id="change_password_old_password"
                                                type="password"
                                                field={fields.oldPassword}
                                                messageId="profile.changePassword.currentPassword"
                                                defaultMessage="Current password"
                                                required={true}
                                                placeholder={formatMessage(messages.oldPasswordPlaceholder) }
                                                autoFocus={true}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                    <FormGroup className="mt-6">
                                        <FormattedMessage
                                            id="profile.changePassword.selectNewPassword"
                                            defaultMessage="Select the new password. Password has to contain at least 8 characters:"
                                        />
                                    </FormGroup>
                                    <FormRow>
                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.newPassword} mismatch="errors.validation.field.mismatch.passwords">
                                                    <TextInput
                                                        id="change_password_new_password"
                                                        type="password"
                                                        field={fields.newPassword}
                                                        messageId="profile.changePassword.newPassword"
                                                        defaultMessage="New password"
                                                        required={true}
                                                        placeholder={formatMessage(messages.newPasswordPlaceholder) }
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.newPasswordAgain} match="errors.validation.field.match.passwords">
                                                    <TextInput
                                                        id="change_password_new_password_again"
                                                        type="password"
                                                        field={fields.newPasswordAgain}
                                                        messageId="profile.changePassword.newPasswordAgain"
                                                        defaultMessage="Current password"
                                                        required={true}
                                                        placeholder={formatMessage(messages.newPasswordAgainPlaceholder) }
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                    </FormRow>

                                    <FormGroup className="mt-6">
                                        <button
                                            id="change_password_submit_button"
                                            type="button"
                                            className="btn btn-primary btn-lg mr-3"
                                            onClick={form.handleOnSubmitBtnClicked}
                                            role="button"
                                        >
                                            <FormattedMessage
                                                id="profile.changePassword.setPassword"
                                                defaultMessage="Set password"
                                            />
                                        </button>
                                        <Link
                                            id="change_password_cancel_button"
                                            to={this.state.yourDetails.pathToBasicDetails}
                                            className="btn btn-default btn-lg"
                                        >
                                            <FormattedMessage
                                                id="profile.changePassword.cancel"
                                                defaultMessage="Cancel"
                                            />
                                        </Link>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                        </form>
                    : null
                }
            </div>
        );
    }
}

export default ChangePassword;
