import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import AddingUserMessage from './AddingUserMessage.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import ImportUserEmailsModalWrapper from './ImportUserEmailsModalWrapper.jsx';
import openImportEmailsModalBtnClicked from './../../actions/actionCreators/users/OpenImportEmailsModalBtnClicked.js';
import { FormRow, PageHeader, FormTable, FormColumn, FormGroup, FormValidationMessage, ValidationMessages, VisibilityComponent,
    InputLabel, TextInput, CheckboxInput, RadioInput } from '@vaultit/stv-utils-frontend';


const messages = defineMessages({
    submit: {
        id: 'users.adding.submitBtn',
        description: 'Users add form submit btn',
        defaultMessage: 'Send invitation'
    },
    spAdminDescription: {
        id: 'users.adding.spAdminPermission.description',
        description: 'Description for sp_admin permission',
        defaultMessage: 'Permission to manage organisation data, services and global permissions.'
    }
});

class AddingUser extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    displayPermissions() {
        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let permissions = [];
        let form = this.state.users.addingUserForm;
        let preSelectedPermissions = this.state.users.permissionsToPreselectInAddForm;

        for (var serviceName in configuration.services) {
            let service = configuration.services[serviceName];
            let joinedByPermissionService = service.joinedByPermission && configuration.services[service.joinedByPermission] ? configuration.services[service.joinedByPermission] : null;
            let isVisible = this.state.users.servicesManagedByCurrentUser.permissionCheckboxIsVisibleForCurrentUser[serviceName];
            let permissionManagedByOtherService = service['permissionManagedByOtherService'] ? service['permissionManagedByOtherService'] : false;
            let permissionOptionIsVisible = isVisible && !permissionManagedByOtherService;
            let userPermission = joinedByPermissionService ? service['permission'] + ' ' + joinedByPermissionService['permission'] : service['permission'];
            let isDisabled = !this.state.users.servicesManagedByCurrentUser.permissionCanBeManagedByCurrentUser[serviceName] || !this.state.users.servicesManagedByCurrentUser.permissionCheckboxIsEnabledForCurrentUser[serviceName];
            let isNoticeVisible = this.state.users.servicesManagedByCurrentUser.showNoticeMsgNextToCheckboxForCurrentUser[serviceName];

            if (permissionOptionIsVisible && userPermission) {

                let serviceTitle = joinedByPermissionService
                    ? service['title'][currentLanguageCode] + ', ' + joinedByPermissionService['title'][currentLanguageCode]
                    : service['title'][currentLanguageCode];
                let description = service['description'] && service['description'][currentLanguageCode] ? service['description'][currentLanguageCode] : '';
                let formField = form.fields['permissionToUse' + serviceName.charAt(0).toUpperCase() + serviceName.slice(1)];
                let noticeMessage = service['input_notice_message'] && service['input_notice_message'][currentLanguageCode] ? service['input_notice_message'][currentLanguageCode] : '';

                if (preSelectedPermissions.length === 0 || (preSelectedPermissions.length > 0 && preSelectedPermissions.indexOf(service['permission']) > -1)) {
                    permissions.push(
                        <FormRow key={serviceName}>
                            <FormColumn width="3">
                                <div className="checkbox mb-0">
                                    <CheckboxInput
                                        id={"users_adding_user_permissions_" + serviceName.toLowerCase()}
                                        value={userPermission}
                                        checked={formField.value ? true : false}
                                        field={formField}
                                        labelClassName="m-0"
                                        name="user_permissions"
                                        disabled={isDisabled}
                                        bold={true}
                                    >
                                        { serviceTitle }
                                    </CheckboxInput>
                                </div>
                            </FormColumn>
                            <FormColumn width="9">
                                <p>{ description }</p>
                                { isNoticeVisible
                                    ?
                                        <p className="text-danger small">
                                            { noticeMessage }
                                        </p>
                                    : null
                                }
                            </FormColumn>
                        </FormRow>
                    );
                }
            }
        }
        return permissions;
    }

    renderWhenReady() {

        const { formatMessage } = this.context.intl;

        let form = this.state.users.addingUserForm;

        let fields = form ? form.fields : null;

        let numberOfVisibleItems = 0;
        let indexOfLastVisibleItem = 0;

        if (fields) {
            fields.email.forEach((item, index) => {
                if (item.isVisible) {
                    numberOfVisibleItems++;
                    indexOfLastVisibleItem = index;
                }
            });
        }

        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let serviceCode = configuration['servicePortalServiceCode'];

        let deleteButtonsVisible = numberOfVisibleItems > 1;

        let permissionsManagedBySuperAdmin = this.state.shared.classifiers.permissionsManagedBySuperAdmin;
        let permissionsManagedByBasicAdmin = this.state.shared.classifiers.permissionsManagedByBasicAdmin;

        let importUserEmailsForm = this.state.users.importUserEmailsForm;

        let modalWindow = <ImportUserEmailsModalWrapper  form={importUserEmailsForm} dismissModalAfterClick={this.state.users.dismissImportEmailsModalAfterClick} currentState={this.state.users.shared} />;

        let customerSupport = configuration ? configuration['customerSupportEmail'] : '';

        return (
            <div className="users-management">
                {modalWindow}
                <h3 className="mt-6 mb-0 back-to-list">
                    <a id="users_adding_back_to_list_link" href={this.state.users.backToListUrl} className="hidden-sm-down">
                        <i className="fa fa-list fa-mr"></i>
                        <FormattedMessage
                            id="users.adding.backToList"
                            description="Back to list link"
                            defaultMessage="Back to list"
                        />
                    </a>
                </h3>
                <PageHeader
                    smallerPageTitle={false}
                    hideSeparator={false}
                    pageTitleMessageId="users.management.title"
                    values={{organizationName: this.state.shared.representedOrgName}}
                    sharedStore={this.state.shared}
                />
                <div className="clearfix"></div>
                <AddingUserMessage
                    showMessage={this.state.users.showMessageInCard}
                    messageType={this.state.users.messageType}
                    addedUsersList={this.state.users.addedUsersList}
                    skippedAlreadyInOrgUsersList={this.state.users.skippedAlreadyInOrgUsersList}
                    skippedWithManyOrgUsersList={this.state.users.skippedWithManyOrgUsersList}
                    allAdded={this.state.users.allAdded}
                    someAddedSomeSkipped={this.state.users.someAddedSomeSkipped}
                    allAlreadyAddedSkipped={this.state.users.allAlreadyAddedSkipped}
                    allAddedAndError={this.state.users.allAddedAndError}
                    someAddedSomeSkippedAndError={this.state.users.someAddedSomeSkippedAndError}
                    allAlreadyAddedSkippedAndError={this.state.users.allAlreadyAddedSkippedAndError}
                    allHaveTooManyOrg={this.state.users.allHaveTooManyOrg}
                    someUsersWereSkipped={this.state.users.someUsersWereSkipped}
                    customerSupport={customerSupport}
                />

                <h3>
                    <FormattedMessage id="users.adding.title" defaultMessage="New User" />
                </h3>
                <hr />

                { form
                    ? <form name="addingUserForm" noValidate="">
                        <p className="lead">
                            <FormattedMessage id="users.adding.addingExplanation" defaultMessage="Invitations to become users of the organisation account are sent to the given email addresses." />
                        </p>
                        <div className="card card-default">
                            <div className="card-block scrollbar-in-add-user-emails-form" >
                                { fields.email.map((emailRepeatedField, index) => {

                                    let inputElementId = 'users_adding_email_address_' + index;
                                    let removeElementId = 'users_adding_email_address_remove_btn_' + index;
                                    let addElementId = 'users_adding_email_address_add_btn';
                                    let importEmailsId = 'users_import_email_addresses_btn';


                                    let addAnotherBtnIsVisible = index === indexOfLastVisibleItem && this.state.users.showAddAndImportUsersBtn;
                                    let showEmailImportBtn = configuration.allowEmailImport === true && this.state.users.showAddAndImportUsersBtn;

                                    return (
                                        <VisibilityComponent key={index} field={emailRepeatedField}>
                                            <FormGroup row={true}>
                                                <FormColumn widthXs="12" widthSm="4" widthMd="3" widthLg="2">
                                                    <InputLabel
                                                        htmlFor={inputElementId}
                                                        messageId="users.adding.emailAddress"
                                                        defaultMessage="Email address:"
                                                        inline={true}
                                                    />
                                                </FormColumn>
                                                <FormColumn widthXs="10" widthSm="7" widthMd="5" widthLg="6">
                                                    <ValidationMessages field={emailRepeatedField}>
                                                        <TextInput
                                                            id={inputElementId}
                                                            field={emailRepeatedField}
                                                            autoFocus={true}
                                                        />
                                                    </ValidationMessages>
                                                </FormColumn>
                                                <FormColumn widthXs="2" widthSm="1" widthMd="1" widthLg="1">
                                                    { deleteButtonsVisible
                                                        ? <a className="d-inline-block form-control-static" id={removeElementId} href="#" onClick={emailRepeatedField.handleOnRepeatedFieldRemoved}>
                                                            <i className="fa fa-times "></i>
                                                        </a>
                                                        : null
                                                    }
                                                </FormColumn>
                                                { addAnotherBtnIsVisible || showEmailImportBtn
                                                    ? <FormColumn widthXs="12" widthSm="12" widthMd="3" widthLg="3">
                                                        <FormGroup className="mb-0">
                                                            {addAnotherBtnIsVisible
                                                            ?
                                                                <p className="mb-0">
                                                                    <a id={addElementId} href="#" onClick={emailRepeatedField.handleOnRepeatedFieldAdded}>
                                                                        <i className="fa fa-plus fa-mr"></i>
                                                                        <FormattedMessage
                                                                            id="users.adding.addAnotherEmail"
                                                                            description="Add new user form add another email"
                                                                            defaultMessage="Add another"
                                                                        />
                                                                    </a>
                                                                </p>
                                                            : null }
                                                            {showEmailImportBtn && addAnotherBtnIsVisible
                                                            ?
                                                                <p className="mb-0">
                                                                    <a id={importEmailsId} href="#" data-toggle="modal" data-target="#import_user_emails_modal" onClick={() => openImportEmailsModalBtnClicked()}>
                                                                        <i className="fa fa-plus fa-mr"></i>
                                                                        <FormattedMessage
                                                                            id="users.adding.importAnotherEmails"
                                                                            description="Add new user form import emails"
                                                                            defaultMessage="Import email addresses"
                                                                        />
                                                                    </a>
                                                                </p>
                                                            : null }
                                                        </ FormGroup>
                                                    </FormColumn>
                                                    : null
                                                }
                                            </FormGroup>
                                        </VisibilityComponent>);
                                })}
                            </div>
                        </div>
                        <VisibilityComponent isVisible={!this.state.users.hideUserTypesInAddForm}>
                            <h3>
                                <FormattedMessage id="users.adding.userType" defaultMessage="User type:" />
                            </h3>

                            <FormTable striped={true}>
                                <ValidationMessages field={fields.userType} required="users.adding.type.required">
                                    <FormRow>
                                        <FormColumn width="3">
                                            <RadioInput
                                                id="users_adding_user_type_main"
                                                name="user_type"
                                                field={fields.userType}
                                                value={this.state.users.constants.userTypes.main || ""}
                                                checked={fields.userType.value === this.state.users.constants.userTypes.main}
                                                messageId="users.adding.userTypeMain"
                                                defaultMessage="Main user"
                                                bold={true}
                                                labelClassName="m-0"
                                                disabled={!this.state.users.canInviteMainUser}
                                            />
                                        </FormColumn>
                                        <FormColumn width="9">
                                            {configuration[serviceCode].userManagement['mainUserDescription']
                                                ? <p>{configuration[serviceCode].userManagement['mainUserDescription'][currentLanguageCode]}</p>
                                                : null
                                            }
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn width="3">
                                            <RadioInput
                                                id="users_adding_user_type_basic"
                                                name="user_type"
                                                field={fields.userType}
                                                value={this.state.users.constants.userTypes.basic || ""}
                                                checked={fields.userType.value === this.state.users.constants.userTypes.basic}
                                                messageId="users.adding.userTypeBasic"
                                                defaultMessage="Basic user"
                                                bold={true}
                                                labelClassName="m-0"
                                            />
                                        </FormColumn>
                                        <FormColumn width="9">
                                            {configuration[serviceCode].userManagement['basicUserDescription']
                                             ? <p>{configuration[serviceCode].userManagement['basicUserDescription'][currentLanguageCode]}</p>
                                            : null
                                            }
                                        </FormColumn>
                                    </FormRow>
                                </ValidationMessages>
                            </FormTable>
                        </VisibilityComponent>

                        <VisibilityComponent isVisible={true}>
                            <h3>
                                <FormattedMessage id="users.adding.userPermissions" defaultMessage="Permissions:" />
                            </h3>
                            <FormTable striped={true}>
                                { this.displayPermissions() }
                            </FormTable>
                        </VisibilityComponent>

                        <VisibilityComponent isVisible={this.state.users.canManageGlobalPermissions && this.state.users.permissionsToShowInAddForm.length === 0}>
                            <h3>
                                <FormattedMessage id="users.adding.userGlobalPermissions" defaultMessage="Global permissions:" />
                            </h3>
                            <FormTable striped={true}>
                                {this.state.shared.classifiers.globalPermissions.map((p, index) => {
                                    if ((permissionsManagedByBasicAdmin.indexOf(p.code) > -1 && this.state.users.canManageGlobalPermissions) ||
                                    (permissionsManagedBySuperAdmin.indexOf(p.code) > -1 && this.state.users.canManageAdminAndQATPermissions)) {
                                        let description = p.description;
                                        if (p.code === "sp_admin") {
                                          description = formatMessage(messages.spAdminDescription);
                                        }
                                        return (
                                            <FormRow key={index}>
                                                <FormColumn width="3">
                                                    <CheckboxInput
                                                        id={"users_adding_user_" + p.code + "_permission"}
                                                        value={p.code || ""}
                                                        checked={fields[p.code].value ? true : false}
                                                        field={fields[p.code]}
                                                        labelClassName="m-0"
                                                        name="user_global_permissions"
                                                        bold={true}
                                                    >
                                                        { p.name }
                                                    </CheckboxInput>
                                                </FormColumn>
                                                <FormColumn width="9">
                                                    <p>
                                                        { description }
                                                    </p>
                                                </FormColumn>
                                            </FormRow>
                                        );
                                    }
                                })}
                            </FormTable>
                        </VisibilityComponent>

                        <FormValidationMessage form={form} messageId="users.adding.formHasErrors" />

                        <FormRow>
                            <FormColumn width="12" className="mt-6">
                                <input
                                    id="users_adding_submit"
                                    type="submit"
                                    className="btn btn-primary btn-lg mr-3"
                                    value={formatMessage(messages.submit) || "" }
                                    onClick={form.handleOnSubmitBtnClicked}
                                    role="button"
                                />
                                <a
                                    id="users_adding_cancel"
                                    className="btn btn-default btn-lg"
                                    href={this.state.users.backToListUrl}
                                >
                                    <FormattedMessage id="users.adding.cancelBtn" defaultMessage="Cancel" />
                                </a>
                            </FormColumn>
                        </FormRow>

                    </form>
                    : null
                }
            </div>
        );
    }
}

export default AddingUser;
