import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';

let closeModal = function() {
    dispatcher.dispatch({
        type: ActionTypes.CLOSE_MODAL
    });
};

export default closeModal;
