import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let updateUser = function (orgId, userId, data) {
    postToBackend('/users/' + orgId + '/update-user/' + userId,
        ActionTypes.ORGANIZATION_USERS_UPDATE_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_UPDATE_ERROR,
        data);
};

export default updateUser;
