import BaseStore from '../../base/BaseStore.js';
import ActionTypes from '../../../actions/ActionTypes.js';
import getInvitationInformation from '../../../actions/actionCreators/registration/invitedSignatoryFlow/GetInvitationInformation.js';
import prepareUrl from '../../../helpers/PrepareUrl.js';
import initiateStrongAuthForAnonymous from '../../../actions/actionCreators/registration/invitedSignatoryFlow/InitiateStrongAuthForAnonymous.js';
import redirectToUrl from '../../../actions/actionCreators/navigation/RedirectToUrl.js';

class ChooseAuthenticationMethodStore extends BaseStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            invitationDetails: null,
            createAccountLink: '',
            configuration: {},
            signInLink: ''
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/choose-authentication-method',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS',
                loadHandler: this.onChooseAuthenticationMethodRouteLoaded
            }
        ];
    }

    onChooseAuthenticationMethodRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.configuration = sharedStoreState.authInfo.topMenuParameters.configuration;
        state.signInLink = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl());
        getInvitationInformation();
    }

    [ActionTypes.REGISTRATION_GET_INVITATION_INFORMATION_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.invitationDetails = action.result;

        if (state.invitationDetails) {
            let createAccountRouteWithParams =
            `/registration/createaccount/${state.invitationDetails.email}/${state.invitationDetails.firstName}/${state.invitationDetails.lastName}/${state.invitationDetails.phoneNumber}`;
            state.createAccountLink = prepareUrl.prepareRouteUrl(createAccountRouteWithParams, false, false);
        }
    }

    [ActionTypes.REGISTRATION_GET_INVITATION_INFORMATION_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.AUTHENTICATE_TO_SIGN_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;

        let sms2FLoginEnabled = state.configuration.servicePortal.smsAuthentication.enabled;
        let signInWithSmsLink = sms2FLoginEnabled ? prepareUrl.getSmsAuthPath(state.configuration) : '';

        if (sms2FLoginEnabled && state.invitationDetails.displaySmsOtpMethodBtn) {
            redirectToUrl(signInWithSmsLink, false, true, false);
        } else {
            initiateStrongAuthForAnonymous();
        }

    }

    [ActionTypes.REGISTRATION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS_SUCCESS] (state, action) {
        if (action.result && action.result.strongAuthUrl) {
            redirectToUrl(action.result.strongAuthUrl, true, true, false);
        }
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REGISTRATION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS_ERROR] (state) {
        state.showLoadingIndicator = false;
    }
}

export default ChooseAuthenticationMethodStore;