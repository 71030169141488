import ActionTypes from '../../ActionTypes.js';
import { getFromBackend } from '../generic/CallBackend.js';

let getDashboardInfo = function (orgId) {

    getFromBackend('/general/' + orgId + '/dashboard-info',
        ActionTypes.DASHBOARD_INFO_GET_SUCCESS,
        ActionTypes.DASHBOARD_INFO_GET_ERROR);
};

export default getDashboardInfo;
