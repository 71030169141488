import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

const getTransferToken = function (token) {
    getFromBackend(`/registration/transfer/get-transfer-token/${token}`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_TRANSFER_TOKEN_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_TRANSFER_TOKEN_ERROR);
};

export default getTransferToken;
