import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let searchForID06LmaCardOrder = function (data) {
    postToBackend(`/registration/search-id06-lma-card-order`,
        ActionTypes.ID06_LMA_CARD_ORDER_SEARCH_SUCCESS,
        ActionTypes.ID06_LMA_CARD_ORDER_SEARCH_ERROR,
        data
    );
};
export default searchForID06LmaCardOrder;