import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let generateInvitationLinkBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GENERATE_INVITATION_LINK_BTN_CLICKED
    });
}

export default generateInvitationLinkBtnClicked;