import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getEditUserInfo = function(orgId, userId) {

    getFromBackend('/users/' + orgId + '/user/' + userId,
        ActionTypes.EDIT_USER_INFO_GET_SUCCESS,
        ActionTypes.EDIT_USER_INFO_GET_ERROR);
};

export default getEditUserInfo;
