import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let adminBlockUnblockAccount = function(userId, blockValue, reasonValue) {
    postToBackend(`/users/admin-block-unblock-account/${userId}`,
        ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_SUCCESS,
        ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_ERROR,
        {
            block: blockValue,
            reason: reasonValue
        });    
};

export default adminBlockUnblockAccount;