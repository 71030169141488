import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let emailExistsInfoBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.EMAIL_EXISTS_INFO_BTN_CLICKED
    });
};

export default emailExistsInfoBtnClicked;
