import BaseStore from './base/BaseStore.js';
import ActionTypes from '../actions/ActionTypes.js';
import redirectToUrl from '../actions/actionCreators/navigation/RedirectToUrl.js';
import redirectToExternalApp from '../actions/actionCreators/RedirectToExternalApp.js';

class RedirectToExternalAppStore extends BaseStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/redirect-to-external-app',
                loadHandler: this.onRedirectToExternalAppRouteEntered,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_REDIRECT_TO_APP_THAT_INITIATED_LOGIN'
            }
        ];
    }

    onRedirectToExternalAppRouteEntered(state) {
        state.showLoadingIndicator = true;
        redirectToExternalApp();
    }

    [ActionTypes.REDIRECT_TO_EXTERNAL_APP_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        if (action && action.result) {
            let returnUrl = action.result.redirectUrl;
            redirectToUrl(returnUrl, true, true, false);
        }
    }

    [ActionTypes.REDIRECT_TO_EXTERNAL_APP_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

}
export default RedirectToExternalAppStore;