import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let showMergePersonsModalBtnClicked = function(event, personForMerge) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GLOBAL_SEARCH_SHOW_MODAL_BTN_CLICKED,
        personForMerge: personForMerge
    });
};

export default showMergePersonsModalBtnClicked;
