import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let createApiAcccount = function (orgId, data) {
    postToBackend('/users/' + orgId + '/create-api-account',
        ActionTypes.ORGANIZATION_USERS_CREATE_API_ACCOUNT_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_CREATE_API_ACCOUNT_ERROR,
        data);
};

export default createApiAcccount;
