import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let getUnfinishedCsvReportJobs = function(ids) {
    postToBackend('users/csv-reports/unfinished-jobs',
        ActionTypes.CSV_REPORT_GET_UNFINISHED_JOBS_SUCCESS,
        ActionTypes.CSV_REPORT_GET_UNFINISHED_JOBS_ERROR,
        {'ids': ids}
    );
};

export default getUnfinishedCsvReportJobs;
