import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let adminBlockUnblockAccountConfirmBtnClicked = function(event, blockValue, reasonValue) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_CONFIRM_BTN_CLICKED,
            block: blockValue,
            reason: reasonValue
        });
    };

export default adminBlockUnblockAccountConfirmBtnClicked;
