import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import {
    Message, PageHeader, FormRow, FormTable,
    FormColumn, ConfirmationModal, DateLabel,
    emailValidator, ValidationMessages, TextInput,
    PhoneNumberInputComponent, CheckboxInput
} from '@vaultit/stv-utils-frontend';
import PdfViewer from '../common/PdfViewer.jsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import constants from '../../Constants.js';
import generateGlobalSearchEmailLinkBtnClicked from '../../actions/actionCreators/users/GenerateGlobalSearchEmailLinkBtnClicked.js';
import generateGlobalSearchPasswordBtnClicked from '../../actions/actionCreators/users/GenerateGlobalSearchPasswordBtnClicked.js';
import changeEmailGlobalSearchResendVerificationBtnClicked from '../../actions/actionCreators/profile/yourDetails/ChangeEmailGlobalSearchResendVerificationBtnClicked.js';
import closeModal from '../../actions/actionCreators/generic/confirmationModal/CloseModal.js';
import globalSearchEditIconClicked from '../../actions/actionCreators/users/GlobalSearchEditIconClicked.js';
import globalSearchCancelIconClicked from '../../actions/actionCreators/users/GlobalSearchCancelIconClicked.js';
import globalSearchSaveIconClicked from '../../actions/actionCreators/users/GlobalSearchSaveIconClicked.js';
import showMergePersonsModalBtnClicked from '../../actions/actionCreators/users/ShowMergePersonsModalBtnClicked.js';
import mergePersonsBtnClicked from '../../actions/actionCreators/users/MergePersonsBtnClicked.js';
import { Link } from 'react-router';
import adminChangeEmailCancelBtnClicked from '../../actions/actionCreators/users/AdminChangeEmailCancelBtnClicked.js';
import adminInitiateEmailChangeBtnClicked from '../../actions/actionCreators/users/AdminInitiateEmailChangeBtnClicked.js';
import adminBlockUnblockAccountPromptBtnClicked from '../../actions/actionCreators/users/AdminBlockUnblockAccountPromptBtnClicked.js';
import adminBlockUnblockAccountConfirmBtnClicked from '../../actions/actionCreators/users/AdminBlockUnblockAccountConfirmBtnClicked.js';
import openBlockHistoryModalBtnClicked from './../../actions/actionCreators/users/OpenBlockHistoryModalBtnClicked.js';
import globalSearchBlockUserCancelIconClicked from '../../actions/actionCreators/users/GlobalSearchBlockUserCancelIconClicked.js';
import BlockHistoryModalWrapper from './BlockHistoryModalWrapper.jsx';
import openIdentityDocumentImageModalBtnClicked from '../../actions/actionCreators/users/OpenIdentityDocumentImageModalBtnClicked.js';


const messages = defineMessages({
    accountActive: {
        id: 'users.global.list.accountActive',
        defaultMessage: 'Active'
    },
    accountInactive: {
        id: 'users.global.list.accountInactive',
        defaultMessage: 'Not active'
    },
    emailVerified: {
        id: 'users.global.list.emailVerified',
        defaultMessage: 'Verified'
    },
    emailNotVerified: {
        id: 'users.global.list.emailNotVerified',
        defaultMessage: 'Not verified'
    },
    emailChangeInitiated: {
        id: 'users.global.list.emailChangeInitiated',
        defaultMessage: 'Initiated'
    },
    emailChangeNotInitiated: {
        id: 'users.global.list.emailChangeNotInitiated',
        defaultMessage: 'Not initiated'
    },
    _365id: {
        id: 'users.global.list.manualIDVerification',
        defaultMessage: 'Manual ID Verification'
    },
    _manual: {
        id: 'users.global.list.manualIDRegistration',
        defaultMessage: 'Manual elevID registration'
    },
    _bankid: {
        id: 'users.global.list.bankIDVerification',
        defaultMessage: 'Bank ID verification'
    },
    _bankidprovision: {
        id: 'users.global.list.bankIDProvision',
        defaultMessage: 'Bank ID verification'
    },
    _sms2fa: {
        id: 'users.global.list.sms2FAVerification',
        defaultMessage: 'SMS 2FA Verification'
    },
    _dokobitmobile: {
        id: 'users.global.list.dokobitMobileVerification',
        defaultMessage: 'Dokobit mobile ID verification'
    },
    _dokobitsmartid: {
        id: 'users.global.list.dokobitSmartidVerification',
        defaultMessage: 'Dokobit Smart-ID verification'
    },
    _dokobitsc: {
        id: 'users.global.list.dokobitScVerification',
        defaultMessage: 'Dokobit smart card verification'
    },
    _dokobitbankid_no: {
        id: 'users.global.list.dokobitBankIdNorway',
        defaultMessage: 'Dokobit Bank ID Norway'
    },
    _elevidlma: {
        id: 'users.global.list.elevId',
        defaultMessage: 'elevID LMA'
    },
    socialSecurityNumber: {
        id: 'users.global.list.ninVerification',
        defaultMessage: 'National person ID number:'
    },
    passportNumber: {
        id: 'users.global.list.passportNumber',
        defaultMessage: 'Passport number'
    },
    idCardNumber: {
        id: 'users.global.list.idCardNumber',
        defaultMessage: 'ID card number'
    },
    confirmationModalTitle: {
        id: 'users.editing.remove.permissions.confirmation.modal.title',
        description: 'Confirmation modal title',
        defaultMessage: 'Remove user'
    },
    confirmationModalOk: {
        id: 'users.editing.remove.permissions.confirmation.modal.ok',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, remove the permissions'
    },
    confirmationModalYesContinue: {
        id: 'users.editing.remove.permissions.confirmation.modal.yesContinue',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, continue'
    },
    confirmationModalCancel: {
        id: 'users.editing.remove.permissions.confirmation.modal.cancel',
        description: 'Confirmation modal cancel button text',
        defaultMessage: 'No, cancel'
    },
    generatePswResetConfirmationTitle: {
        id: 'users.editing.generatePswConfirmationTitle',
        description: 'Password recovery modal title',
        defaultMessage: 'Generate a password reset link'
    },
    generateLinkGenerateBtn: {
        id: 'users.editing.generatePswConfirmationYesBtn',
        description: 'Password recovery modal continue button',
        defaultMessage: 'Yes, generate'
    },
    generateLinkCancelGenerateBtn: {
        id: 'users.editing.generatePswConfirmationNoBtn',
        description: 'Password recovery modal cancel button',
        defaultMessage: 'Cancel'
    },
    generateInitiateEmailChangeYesBtn: {
        id: 'users.editing.generateInitiateEmailChangeConfirmationYesBtn',
        description: 'Initiate email change confirmation modal continue button',
        defaultMessage: 'Yes, continue'
    },
    generateInitiateEmailChangeCancelBtn: {
        id: 'users.editing.generateInitiateEmailChangeConfirmationNoBtn',
        description: 'Initiate email change confirmation  modal cancel button',
        defaultMessage: 'Cancel'
    },
    linkGeneratedPasswordTitle: {
        id: 'users.editing.passwordLinkGenerated',
        description: 'Password link generated title',
        defaultMessage: 'Password reset link generated'
    },
    linkGeneratedChangeEmailTitle: {
        id: 'users.global.list.changeEmailModalTitle',
        description: 'Change email link generated title',
        defaultMessage: 'Change email link generated'
    },
    linkGeneratedEmailTitle: {
        id: 'users.editing.emailLinkGenerated',
        description: 'Email verification link generated titile',
        defaultMessage: 'Email verification link generated'
    },
    linkGeneratedCancelBtn: {
        id: 'users.editing.linkGeneratedCancelBtn',
        description: 'Generated link modal window cancel btn',
        defaultMessage: 'Cancel'
    },
    generateEmailLinkConfirmationTitle: {
        id: 'users.editing.generateEmailConfirmationTitle',
        description: 'Email verification modal title',
        defaultMessage: 'Generate an email verification link'
    },
    generateChangeEmailLinkTitle: {
        id: 'users.global.list.changeEmailLinkModalTitle',
        description: 'ChangeEmail link generation modal window title',
        defaultMessage: 'Generate a changeEmail link'
    },
    generateEmailChangeConfirmationTitle: {
        id: 'users.global.list.generateEmailChangeConfirmationTitle',
        description: 'Email change modal title',
        defaultMessage: 'Generate an email change link'
    },
    generateInitiateEmailChangeConfirmationTitle: {
        id: 'users.global.list.initiateEmailChangeConfirmationTitle',
        description: 'Initiate email change modal title',
        defaultMessage: 'Initiate an email (username) change'
    },
    mergePersonsTitle: {
        id: 'users.global.list.mergeTitle',
        description: 'Merge persons title',
        defaultMessage: 'Merge persons'
    },
    mergePersonsBtn: {
        id: 'users.global.list.mergePersonsBtn',
        description: 'Merge persons accept button',
        defaultMessage: 'Yes'
    },
    mergePersonsCancelBtn: {
        id: 'users.global.list.mergePersonsCancelBtn',
        description: 'Merge persons cancel button',
        defaultMessage: 'No'
    },
    accountBlockText: {
        id: 'users.global.list.accountBlock',
        description: 'block',
        defaultMessage: 'block'
    },
    accountUnblockText: {
        id: 'users.global.list.accountUnblock',
        description: 'unblock',
        defaultMessage: 'unblock'
    },
    accountBlockedText: {
        id: 'users.global.list.accountBlocked',
        description: 'blocked',
        defaultMessage: 'blocked'
    },
    accountUnblockedText: {
        id: 'users.global.list.accountUnblocked',
        description: 'unblocked',
        defaultMessage: 'unblocked'
    },
    accountBlockUnblockConfirmationTitle: {
        id: 'users.global.list.accountBlockUnblockConfirmationTitle',
        description: '{action} user account',
        defaultMessage: '{action} user account'
    },
    scannedDocumentImageTitle: {
        id: 'users.global.list.scannedDocumentImageModal.title',
        description: 'Scanned document image',
        defaultMessage: 'Scanned document image'
    },
    uploadedDocumentImageTitle: {
        id: 'users.global.list.uploadedDocumentImageModal.title',
        description: 'Uploaded document image',
        defaultMessage: 'Uploaded document image'
    },
    identityDocumentImageCloseBtn: {
        id: 'users.global.list.identityDocumentImageModal.closeBtn',
        description: 'close',
        defaultMessage: 'Close'
    },
});

class ViewSingleUserGlobalSearchResults extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getFullCountryName(countryCode) {
        let countryName = '';
        for (let key in this.state.shared.classifiers.countries) {
            let item = this.state.shared.classifiers.countries[key];
            if (item.code == countryCode) {
                countryName = item.name;
                break;
            }
        }
        return countryName;
    };

    displayIdVerificationStatus(govId) {
        const { formatMessage } = this.context.intl;

        let IDVerificationStatusKey = govId && govId.IDVerificationStatus ? govId.IDVerificationStatus : null;
        switch (IDVerificationStatusKey) {
            case 'verified':
                return formatMessage(messages.emailVerified);
            default:
                return formatMessage(messages.emailNotVerified);
        }
    }


    getPhoneNumber(field, key) {
        let textInputId = 'view-user-input-' + key;
        let editIconId = 'view-user-edit-icon-' + key;
        let cancelIconId = 'view-user-cancel-icon-' + key;
        let saveIconId = 'view-user-save-icon-' + key;
        let tableColumn = (
            <ValidationMessages field={field} componentCustomValidation="errors.validation.field.componentCustomValidation.phoneNumber">
                {field.editingVisible
                    ?
                        <div className="input-group mb-3">
                            <PhoneNumberInputComponent
                                id={textInputId}
                                withAddon={true}
                                value={field.value || ""}
                                onChange={field.handleOnChange.bind(this)}
                                preferredCountries={['fi', 'ee', 'se']}
                            />
                            <span className="input-group-addon bg-white">
                                { field.value && field.value.length
                                    ?
                                        <span>
                                            <span id={saveIconId} role="button" onClick={(e) => globalSearchSaveIconClicked(e, field)} className="text-primary bg-white">
                                                <i id="filter-icon" className="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            <span id={cancelIconId} role="button" onClick={(e) => globalSearchCancelIconClicked(e, field)} className="text-primary bg-white ml-4">
                                                <i id="cancel-icon" className="fa fa-times" aria-hidden="true"></i>
                                            </span>
                                        </span>
                                    :
                                    <span role="button" onClick={(e) => globalSearchCancelIconClicked(e, field)} className="text-primary bg-white">
                                        <i id="revert-icon" className="fa fa-undo" aria-hidden="true"></i>
                                    </span>
                                }
                            </span>
                        </div>
                    :
                    <span role="button" id={editIconId} onClick={(e) => {globalSearchEditIconClicked(e, field)}}>
                        {field.value} <i id="filter-icon" className="fa fa-pencil text-primary" aria-hidden="true"></i>
                    </span>
                }
            </ValidationMessages>
        )
        return tableColumn;
    }


    getTextInput(field, key) {
        let textInputId = 'view-user-input-' + key;
        let editIconId = 'view-user-edit-icon-' + key;
        let cancelIconId = 'view-user-cancel-icon-' + key;
        let saveIconId = 'view-user-save-icon-' + key;
        let tableColumn = (
            <ValidationMessages field={field}>
                {field.editingVisible
                    ?
                        <TextInput
                            field={field}
                            disabled={false}
                            addonPossition="right"
                            inputGroup={true}
                            id={textInputId}
                        >
                            <span className="input-group-addon bg-white d-inline-block">
                                { field.value && field.value.length
                                    ?
                                        <span>
                                            <span id={saveIconId} role="button" onClick={(e) => globalSearchSaveIconClicked(e, field)} className="text-primary bg-white">
                                                <i id="filter-icon" className="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            <span id={cancelIconId} role="button" onClick={(e) => globalSearchCancelIconClicked(e, field)} className="text-primary bg-white ml-4">
                                                <i id="cancel-icon" className="fa fa-times" aria-hidden="true"></i>
                                            </span>
                                        </span>
                                    :
                                    <span role="button" onClick={(e) => globalSearchCancelIconClicked(e, field)} className="text-primary bg-white">
                                        <i id="revert-icon" className="fa fa-undo" aria-hidden="true"></i>
                                    </span>
                                }

                            </span>
                        </TextInput>
                    :
                    <span role="button" id={editIconId} onClick={(e) => {globalSearchEditIconClicked(e, field)}}>
                        {field.value} <i id="filter-icon" className="fa fa-pencil text-primary" aria-hidden="true"></i>
                    </span>
                }
            </ValidationMessages>
        )
        return tableColumn;
    }

    getUsernameInput(field, key) {
        let textInputId = 'view-user-input-' + key;
        let editIconId = 'view-user-edit-icon-' + key;
        let cancelIconId = 'view-user-cancel-icon-' + key;
        let saveIconId = 'view-user-save-icon-' + key;
        let tableColumn = (
            <ValidationMessages field={field}>
                {field.editingVisible
                    ?
                        <TextInput
                            field={field}
                            disabled={false}
                            addonPossition="right"
                            inputGroup={true}
                            id={textInputId}
                        >
                            <span className="input-group-addon bg-white d-inline-block">
                                { field.value && field.value.length
                                    ?
                                        <span>
                                            <span id={saveIconId} role="button" onClick={adminInitiateEmailChangeBtnClicked} className="text-primary bg-white">
                                                <i id="filter-icon" className="fa fa-check" aria-hidden="true"></i>
                                            </span>
                                            <span id={cancelIconId} role="button" onClick={(e) => globalSearchCancelIconClicked(e, field)} className="text-primary bg-white ml-4">
                                                <i id="cancel-icon" className="fa fa-times" aria-hidden="true"></i>
                                            </span>
                                        </span>
                                    :
                                    <span role="button" onClick={(e) => globalSearchCancelIconClicked(e, field)} className="text-primary bg-white">
                                        <i id="revert-icon" className="fa fa-undo" aria-hidden="true"></i>
                                    </span>
                                }

                            </span>
                        </TextInput>
                    :
                    <span role="button" id={editIconId} onClick={(e) => {globalSearchEditIconClicked(e, field)}}>
                        {field.value} <i id="filter-icon" className="fa fa-pencil text-primary" aria-hidden="true"></i>
                    </span>
                }
            </ValidationMessages>
        )
        return tableColumn;
    }

    getBlockInput(canBlockUser, fieldChk, keyChk, fieldTxt, keyTxt, timestamp) {
        let chekboxInputId = 'view-user-input-' + keyChk;
        let editIconId = 'view-user-edit-icon-' + keyChk;
        let cancelIconId = 'view-user-cancel-icon-' + keyChk;
        let saveIconId = 'view-user-save-icon-' + keyChk;
        let timestampId = 'view-user-timestamp-' + keyChk;
        let textboxInputId = 'view-user-input-' + keyTxt;
        let tableColumn = (
            fieldChk.editingVisible
                ?
                    <span className="d-flex flex-row align-items-center">
                        <CheckboxInput
                            id={chekboxInputId}
                            checked={fieldChk.value}
                            field={fieldChk}
                            disabled={!fieldChk.editingVisible}
                        />
                        <div className='flex_fill'>
                            <TextInput
                                id={textboxInputId}
                                field={fieldTxt}
                                disabled={false}
                                addonPossition="right"
                                inputGroup={true}
                                placeholder='Reason'
                            />
                        </div>
                        <span className="input-group-addon bg-white d-inline-block">
                            <span id={saveIconId} role="button" onClick={adminBlockUnblockAccountPromptBtnClicked} className="text-primary bg-white">
                                <i id="filter-icon" className="fa fa-check" aria-hidden="true"></i>
                            </span>
                            <span id={cancelIconId} role="button" onClick={(e) => globalSearchBlockUserCancelIconClicked(e, fieldChk, fieldTxt)} className="text-primary bg-white ml-4">
                                <i id="cancel-icon" className="fa fa-times" aria-hidden="true"></i>
                            </span>
                        </span>
                    </span>
                :
                <span>
                    <CheckboxInput
                        id={chekboxInputId}
                        checked={fieldChk.value}
                        field={fieldChk}
                        disabled={!fieldChk.editingVisible}
                        labelClassName="mb-0 cursor_default"
                    >
                        {canBlockUser &&
                        <span role="button" id={editIconId} onClick={(e) => {globalSearchEditIconClicked(e, fieldChk)}}>
                            <i id="filter-icon" className="fa fa-pencil text-primary" aria-hidden="true"></i>
                        </span>
                        }
                        {!canBlockUser &&
                        <span>&nbsp;</span>
                        }
                    </CheckboxInput>
                    <span>
                        <span>
                            {fieldTxt ? fieldTxt.value : null}
                            &nbsp;
                            {timestamp ? <DateLabel id={timestampId} format="(YYYY-MM-DD HH:mm)">{timestamp.defaultValue}</DateLabel>: null}
                        </span>
                        &nbsp;&nbsp;
                        {canBlockUser && timestamp.defaultValue &&
                        <span role="button" id={editIconId} onClick={(e) => {openBlockHistoryModalBtnClicked(e, fieldChk)}}>
                            <i id="popup-icon" className="fa fa-history text-primary" aria-hidden="true"></i>
                        </span>
                        }
                    </span>
                </span>
        )
        return tableColumn;
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        if (!this.state.usersGlobalSearch.showCurrentPersonPage) {
            return null;
        }

        let form = this.state.usersGlobalSearch.editUserDataForm;
        let formFields = form ? form.fields : null;

        let basicUserText = <FormattedMessage id="users.global.list.userTypeBasic" defaultMessage="Basic user" />;
        let mainUserText = <FormattedMessage id="users.global.list.userTypeMain" defaultMessage="Main user" />;
        let configuration = this.state.usersGlobalSearch.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let userDetails  = null;
        let userOrganizations = [];

        if (this.state.usersGlobalSearch.currentPerson) {
            userDetails = this.state.usersGlobalSearch.currentPerson;
            userOrganizations = userDetails.organizations;
        }

        let permissionNameDisplay = (companyPermission, isMainRepresentationForUser, servicesManagedByCurrentUser) => {
            for (var service in configuration.services) {
                let showNotSuportedPermissionWarning = !isMainRepresentationForUser && servicesManagedByCurrentUser.serviceIsLimitedToOneRepresentedOrg[service];
                if (configuration.services[service]['permission'] && configuration.services[service]['permission'] === companyPermission) {
                    return (
                        <span className={showNotSuportedPermissionWarning ? 'strikethrough':''}>{ configuration.services[service]['title'][currentLanguageCode] }</span>
                    );
                }
            }
            for (var service in configuration.otherServices) {
                let showNotSuportedGlobalPermissionWarning = !isMainRepresentationForUser && servicesManagedByCurrentUser.serviceIsLimitedToOneRepresentedOrg[service];
                if (configuration.otherServices[service]['permission'] && configuration.otherServices[service]['permission'] === companyPermission) {
                    return (
                        <span className={showNotSuportedGlobalPermissionWarning ? 'strikethrough':''}>{ configuration.otherServices[service]['title'][currentLanguageCode] }</span>
                    );
                }
            }
        }

        let generatedLinkModalWindowTitle = this.state.usersGlobalSearch.showEmailVerificationLinkModal
        ? formatMessage(messages.linkGeneratedEmailTitle)
        : this.state.usersGlobalSearch.showPasswordResetLinkModal
        ? formatMessage(messages.linkGeneratedPasswordTitle)
        : this.state.usersGlobalSearch.showEmailChangeLinkModal
        ? formatMessage(messages.linkGeneratedChangeEmailTitle): '';

        let showGeneratedLinkModal = this.state.usersGlobalSearch.showEmailVerificationLinkModal || this.state.usersGlobalSearch.showPasswordResetLinkModal || this.state.usersGlobalSearch.showEmailChangeLinkModal;

        let personVerificationSource = (sourceCode) => {
            let msgKey = sourceCode ? "_" + sourceCode.replace('_', '').toLowerCase() : null;
            let verificationSource = msgKey && messages[msgKey] ? formatMessage(messages[msgKey]) : sourceCode;
            return verificationSource;
        };

        let sendToEmailAddressInGeneratedLinkModal = this.state.usersGlobalSearch.showEmailChangeLinkModal
        ? userDetails.emailChangeToEmail
        : userDetails.email;

        let blockHistoryModalWindow = <BlockHistoryModalWrapper  showModal={this.state.usersGlobalSearch.showBlockHistoryModal} blockHistory={this.state.usersGlobalSearch.currentPerson.userBlockHistory} />;

        return (
            <div>
                {blockHistoryModalWindow}
                <PageHeader
                    pageTitleMessageId="users.global.list.details.title"
                    sharedStore={this.state.shared}
                    hideSeparator={true}
                />

                { this.state.usersGlobalSearch.showChangedEmailDuplicateMessage
                    ?
                        <Message id="errorMessage" type="danger">
                            <FormattedMessage
                                id="users.editing.duplicateEmail"
                                defaultMessage="E-mail {email} is already used for another user account."
                                values={{
                                    email: this.state.usersGlobalSearch.duplicatedEmail
                                }}
                            />
                        </Message>
                    : null
                }

                { this.state.usersGlobalSearch.showEmailChangeSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="users.editing.adminEmailChangeSuccess"
                                defaultMessage="Username updated."
                            />
                        </Message>
                    : null
                }

                { this.state.usersGlobalSearch.showCancelEmailChangeSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="profile.changeEmail.cancel.success.message"
                                defaultMessage="E-mail change request was cancelled"
                            />
                        </Message>
                    : null
                }

                { this.state.usersGlobalSearch.showAdminAccountBlockUnblockMissingReasonMessage
                    ?
                        <Message id="errorMessage" type="danger">
                            <FormattedMessage
                                id="users.editing.adminBlockUnblockMissingReason"
                                defaultMessage="Please provide the reason"
                            />
                        </Message>
                    : null
                }

                {
                    this.state.usersGlobalSearch.showAdminAccountBlockUnblockSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="users.editing.adminBlockUnblockSuccess"
                                defaultMessage="User account is {action}"
                                values={{action: formFields.userAccountIsBlocked.value ? formatMessage(messages.accountBlockedText) : formatMessage(messages.accountUnblockedText)}}
                            />
                        </Message>
                    : null
                }


                { userDetails
                    ?
                        <FormTable id="user-globalsearch-user-details">
                            <div className="mb-6">
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.firstName" defaultMessage="First name:" />
                                    </FormColumn>
                                    <FormColumn width="8" id="users-global-firstName">
                                        { userDetails.canEditFullName && formFields && formFields.firstName
                                            ? this.getTextInput(formFields.firstName, 'firstName')
                                            : userDetails.firstName
                                        }
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.lastName" defaultMessage="Last name:" />
                                    </FormColumn>
                                    <FormColumn width="8" id="users-global-lastName">
                                        { userDetails.canEditFullName && formFields && formFields.lastName
                                            ? this.getTextInput(formFields.lastName, 'lastName')
                                            : userDetails.lastName
                                        }
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.dateOfBirth" defaultMessage="Date of birth:" />
                                    </FormColumn>
                                    <FormColumn width="8">
                                        <DateLabel id="users-global-dateOfBirth" format="YYYY-MM-DD">{userDetails.dataOfBirth}</DateLabel>
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn  width="3">
                                        <FormattedMessage id="users.global.list.phoneNumber" defaultMessage="Phone number:" />
                                    </FormColumn>
                                    <FormColumn width="8" id="users-global-phoneNumber">
                                        { userDetails.canEditPhoneNumber && formFields && formFields.phoneNumber
                                            ? this.getPhoneNumber(formFields.phoneNumber, 'phoneNumber')
                                            : userDetails.phoneNumber
                                        }
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.userAccountEmail" defaultMessage="User account email:" />
                                    </FormColumn>
                                    <FormColumn width="8" id="users-global-user-account-email">
                                        {!userDetails.email || emailValidator(userDetails.email)
                                            ? <FormattedMessage id="users.global.list.noEmail" defaultMessage="N/A" />
                                            : (userDetails.emailChangeInitiated
                                                  ? <FormattedMessage id="users.global.list.usernameChangeInitiated" defaultMessage="Username change initiated to {newEmail}" values={{newEmail: userDetails.emailChangeToEmail}} />
                                                  : ( userDetails.canEditUserDetails
                                                    ? this.getUsernameInput(formFields.userAccountEmail, 'userAccountEmail')
                                                    : userDetails.email
                                                    )
                                              )
                                        }
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3" className="align-self-center">
                                        <FormattedMessage id="users.global.list.userAccountIsBlocked" defaultMessage="User account is blocked:" />
                                    </FormColumn>
                                    <FormColumn width="8" id="users-global-user-account-is-blocked">
                                        { this.getBlockInput(userDetails.canBlockUser, formFields.userAccountIsBlocked, 'userAccountIsBlocked', formFields.userAccountBlockedReason, 'userAccountBlockedReason', formFields.userAccountBlockedTimestamp)}
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.contactEmail" defaultMessage="Contact email:" />
                                    </FormColumn>
                                    <FormColumn width="8" id="users-global-contact-email">
                                        {userDetails.contactEmails && userDetails.contactEmails.length
                                            ?
                                                userDetails.contactEmails.map((email, index) => {
                                                    if (email.self && userDetails.canEditUserName) {
                                                        return (
                                                            <div key={index} className="clearfix">
                                                                <div className="pull-left">{this.getTextInput(formFields.selfContactEmail, 'selfContactEmail')}</div>
                                                                <a
                                                                    className="pull-right"
                                                                    id={`global_search_resend_account_activation_link_${index}`}
                                                                    href={`${this.state.usersGlobalSearch.resendAccountActivationLink}/${email.email}`}
                                                                >
                                                                    <FormattedMessage id="users.global.list.resendAccountActivationLink" defaultMessage="Resend account activation link" />
                                                                </a>
                                                            </div>
                                                        )
                                                    } else {
                                                        return (<div key={index}>{email.email}</div>);
                                                    }
                                                })
                                            : '-'
                                        }
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.registrationDate" defaultMessage="Registration date:" />
                                    </FormColumn>
                                    <FormColumn width="8">
                                        <DateLabel id="users-global-registration-date" format="YYYY-MM-DD HH:mm">{userDetails.registrationDate}</DateLabel>
                                    </FormColumn>
                                </FormRow>

                                {   userDetails.govIds
                                    ?
                                        <FormRow>
                                            <FormColumn width="12" className="bg-light" />
                                        </FormRow>
                                    : null
                                }

                                {userDetails.govIds.map((item, index) => {

                                    let titleTranslationKey = 'users.global.list.NIN';
                                    let translationKeyPart = Object.keys(constants.idVerificationType).find((key) => constants.idVerificationType[key].toLowerCase() == item.IDType.toLowerCase());

                                    if (translationKeyPart) {
                                        let otherTitleTranslationKey = `users.global.list.${translationKeyPart}Title`;

                                        if (this.context.intl.messages[otherTitleTranslationKey]) {
                                            titleTranslationKey = otherTitleTranslationKey;
                                        }
                                    }

                                    return (
                                        <div key={index}>
                                            <FormRow>
                                                <FormColumn width="3" id={'users-global-id-type-' + index}>
                                                    <FormattedMessage id={titleTranslationKey} defaultMessage="ID document number:" />
                                                </FormColumn>
                                                <FormColumn width="3"  id={'users-global-id-number-' + index}>
                                                    { item.NIN } ({ item.ninCountry.toUpperCase() })
                                                    &nbsp;
                                                    {item.scannedDocumentImage
                                                        ?
                                                            <span role="button" id={'users-global-list-residenceIdImage-' + index} onClick={(e) => {openIdentityDocumentImageModalBtnClicked(e, index, false)}}>
                                                                <i id={'popup-icon-image-' + index} className="fa fa-picture-o text-primary" aria-hidden="true"></i>
                                                            </span>
                                                        :
                                                        null
                                                    }
                                                </FormColumn>
                                            </FormRow>
                                            <FormRow>
                                                <FormColumn width="3">
                                                    <FormattedMessage id="users.global.list.IDVerified" defaultMessage="ID verification status:" />
                                                </FormColumn>
                                                <FormColumn width="7" id={'users-global-id-verification-status-'+ index}>
                                                    { this.displayIdVerificationStatus(item) }
                                                </FormColumn>
                                            </FormRow>
                                            <FormRow>
                                                <FormColumn width="3" >
                                                    <FormattedMessage id="users.global.list.IDSource" defaultMessage="ID verification source:" />
                                                </FormColumn>
                                                <FormColumn width="9"  id={'users-global-id-verification-source-' + index}>
                                                    {item.IDVerificationSources.map((IDSource, sourceIndex) => (
                                                        <p className="mb-2" id={'id-verification-source-' + sourceIndex} key={sourceIndex}>
                                                            {personVerificationSource(IDSource.IDVerificationSource)}
                                                            &nbsp;
                                                            {IDSource.IDVerificationTime
                                                                ? <DateLabel id={'users-global-id-verification-time-' + sourceIndex} format="(YYYY-MM-DD HH:mm)">{IDSource.IDVerificationTime}</DateLabel>
                                                            :null}
                                                            &nbsp;
                                                            {IDSource.IDVerificationPartner ? "- " + IDSource.IDVerificationPartner: null}
                                                            &nbsp;
                                                            {IDSource.IDVerificationOperator ? "(" + IDSource.IDVerificationOperator + ")": null}
                                                        </p>
                                                    ))}
                                                </FormColumn>
                                            </FormRow>
                                            <FormRow>
                                                <FormColumn width="12" className="bg-light" />
                                            </FormRow>
                                            {Object.keys(item.permitScannedDocument).length > 0
                                            ?
                                                <div>
                                                    <FormRow>
                                                        <FormColumn width="3">
                                                            <FormattedMessage id='users.global.list.residencePermitNumber' defaultMessage="Residence permit number:" />
                                                        </FormColumn>
                                                        <FormColumn width="7"  id={'users-global-list-residencePermitNumber-' + index}>
                                                            { item.permitScannedDocument.documentNumber } ({ item.permitScannedDocument.documentIssuingCountry.toUpperCase() })
                                                            &nbsp;
                                                            {item.permitScannedDocument.scannedDocumentImage
                                                            ?
                                                                <span role="button" id={'users-global-list-permitImage-' + index} onClick={(e) => {openIdentityDocumentImageModalBtnClicked(e, index, true)}}>
                                                                    <i id={'popup-icon-permit-image-' + index} className="fa fa-picture-o text-primary" aria-hidden="true"></i>
                                                                </span>
                                                            :
                                                            null
                                                            }
                                                        </FormColumn>
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormColumn width="3">
                                                            <FormattedMessage id='users.global.list.residencePermitExpiryDate' defaultMessage="Expiry date:" />
                                                        </FormColumn>
                                                        <FormColumn width="7">
                                                            <DateLabel id={'users-global-list-residencePermitExpiryDate-' + index} format="YYYY-MM-DD">{item.permitScannedDocument.expiryDate}</DateLabel>
                                                        </FormColumn>
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormColumn width="12" className="bg-light" />
                                                    </FormRow>
                                                </div>
                                            : null
                                            }
                                            {Object.keys(item.permitUploadedDocument).length > 0
                                            ?
                                                <div>
                                                    <FormRow>
                                                        <FormColumn width="3">
                                                            <FormattedMessage id='users.global.list.residencePermitNumber' defaultMessage="Residence permit number:" />
                                                        </FormColumn>
                                                        <FormColumn width="7"  id={'users-global-list-residencePermitNumber-' + index}>
                                                            { formatMessage(messages.uploadedDocumentImageTitle) }
                                                            &nbsp;
                                                            {item.permitUploadedDocument.uploadedDocumentImage
                                                            ?
                                                                <span role="button" id={'users-global-list-permitImage-' + index} onClick={(e) => {openIdentityDocumentImageModalBtnClicked(e, index, true)}}>
                                                                    <i id={'popup-icon-permit-image-' + index} className="fa fa-picture-o text-primary" aria-hidden="true"></i>
                                                                </span>
                                                            :
                                                            null
                                                            }
                                                        </FormColumn>
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormColumn width="3">
                                                            <FormattedMessage id='users.global.list.residencePermitExpiryDate' defaultMessage="Expiry date:" />
                                                        </FormColumn>
                                                        <FormColumn width="7">
                                                            <DateLabel id={'users-global-list-residencePermitExpiryDate-' + index} format="YYYY-MM-DD">{item.permitUploadedDocument.expiryDate}</DateLabel>
                                                        </FormColumn>
                                                    </FormRow>
                                                    <FormRow>
                                                        <FormColumn width="12" className="bg-light" />
                                                    </FormRow>
                                                </div>
                                            : null
                                            }
                                        </div>
                                    );
                                })}
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.userAccountStatus" defaultMessage="Account status:" />
                                    </FormColumn>
                                    <FormColumn width="8">
                                        {userDetails.userAccountStatus == 'active' ? formatMessage(messages.accountActive) : formatMessage(messages.accountInactive)}
                                    </FormColumn>
                                </FormRow>
                                <FormRow>
                                    <FormColumn width="3">
                                        <FormattedMessage id="users.global.list.emailVerification" defaultMessage="Email verification:" />
                                    </FormColumn>
                                    <FormColumn width="3">
                                        {userDetails.emailVerified ? formatMessage(messages.emailVerified) : formatMessage(messages.emailNotVerified)}
                                        {userDetails.emailVerifiedTime
                                        ?
                                            <i><DateLabel id="users-global-email-verification-time" format=" (YYYY-MM-DD HH:mm)">{userDetails.emailVerifiedTime}</DateLabel></i>
                                        : null }
                                    </FormColumn>
                                    {!userDetails.emailVerified && userDetails.emailIsValid && userDetails.inputDataProvided && userDetails.hasGluuUser
                                    ?
                                        (userDetails.canEditUserDetails
                                        ?
                                            <FormColumn width="6">
                                                <a
                                                    id="global_search_email_verification_link"
                                                    href="#global_search_generate_email_verification_modal_window"
                                                    data-toggle="modal"
                                                >
                                                    <FormattedMessage id="users.global.list.resendEmailVerificationLink" defaultMessage="Resend an email verification link" />
                                                </a>
                                            </FormColumn>
                                        : null)
                                    :
                                        !userDetails.emailVerified && userDetails.emailIsValid && userDetails.inputDataProvided && !userDetails.hasGluuUser
                                        ?
                                            <FormColumn width="6">
                                                <span className="text-muted">
                                                    <FormattedMessage id="users.global.list.resendEmailVerificationLink" defaultMessage="Resend an email verification link" />
                                                </span>
                                                <span className="text-muted">
                                                    <FormattedMessage id="users.global.list.hasNoGluu" defaultMessage=" disabled - no active Gluu ID" />
                                                </span>
                                            </FormColumn>
                                    : null }
                                    {!userDetails.emailIsValid || !userDetails.inputDataProvided
                                    ?
                                        <FormColumn width="6">
                                            <span className="text-muted">
                                                <FormattedMessage id="users.global.list.resendEmailVerificationLink" defaultMessage="Resend an email verification link" />
                                            </span>
                                            <span className="text-muted">
                                                <FormattedMessage id="users.global.list.linkDisabled" defaultMessage=" disabled - user has not begun registration" />
                                            </span>
                                        </FormColumn>
                                    : null }
                                </FormRow>
                                {userDetails.canEditUserDetails
                                ?
                                    <FormRow>
                                        <FormColumn width="3">
                                            <FormattedMessage id="users.global.list.emailChange" defaultMessage="Email change request:" />
                                        </FormColumn>
                                        <FormColumn width="3">
                                            {userDetails.emailChangeInitiated ? formatMessage(messages.emailChangeInitiated) : formatMessage(messages.emailChangeNotInitiated)}<i>{" (" + userDetails.emailChangeToEmail + ")"}</i>
                                        </FormColumn>
                                        { userDetails.emailChangeInitiated
                                        ?
                                            <FormColumn width="6">
                                                <FormRow>
                                                    <FormColumn width="12">
                                                        <a
                                                            id="global_search_email_change_link"
                                                            href="#global_search_generate_email_change_modal_window"
                                                            data-toggle="modal"
                                                        >
                                                            <FormattedMessage id="users.global.list.generateChangeEmailLink" defaultMessage="Resend an email change verification link" />
                                                        </a>
                                                        <strong className="px-2">|</strong>
                                                        <a id="resend-verification-email" href="#" onClick={adminChangeEmailCancelBtnClicked}>
                                                            <FormattedMessage
                                                                id="profile.changeEmail.cancelChangeRequest"
                                                                defaultMessage="Cancel the change request"
                                                            />
                                                        </a>

                                                    </FormColumn>
                                                </FormRow>
                                            </FormColumn>
                                        : null }
                                    </FormRow>
                                : null}
                                {userDetails.canEditUserDetails
                                ?
                                    <FormRow>
                                        <FormColumn width="3" >
                                            <FormattedMessage id="users.global.list.passwordReset" defaultMessage="Password reset:" />
                                        </FormColumn>
                                        {userDetails.emailIsValid && userDetails.inputDataProvided && userDetails.hasGluuUser
                                        ?
                                            <FormColumn width="6" >
                                                <a
                                                    id="global_search_password_reset_link"
                                                    href="#global_search_generate_password_modal_window"
                                                    data-toggle="modal"
                                                >
                                                    <FormattedMessage id="users.global.list.resetPasswordLink" defaultMessage="Generate a password reset link" />
                                                </a>
                                            </FormColumn>
                                        :
                                            userDetails.emailIsValid && userDetails.inputDataProvided && !userDetails.hasGluuUser
                                            ?
                                                <FormColumn width="6">
                                                    <span className="text-muted">
                                                        <FormattedMessage id="users.global.list.resetPasswordLink" defaultMessage="Generate a password reset link" />
                                                    </span>
                                                    <span className="text-muted">
                                                        <FormattedMessage id="users.global.list.hasNoGluu" defaultMessage=" disabled - no active Gluu ID" />
                                                    </span>
                                                </FormColumn>
                                        : null }
                                        {!userDetails.emailIsValid || !userDetails.inputDataProvided
                                        ?
                                            <FormColumn width="6">
                                                <span className="text-muted">
                                                    <FormattedMessage id="users.global.list.resetPasswordLink" defaultMessage="Generate a password reset link" />
                                                </span>
                                                <span className="text-muted">
                                                    <FormattedMessage id="users.global.list.linkDisabled" defaultMessage=" disabled - user has not begun registration" />
                                                </span>
                                            </FormColumn>
                                        : null }
                                    </FormRow>
                                : null}
                                <FormRow>
                                    <FormColumn width="3" >
                                        <FormattedMessage id="users.global.list.userCardsList" defaultMessage="User cards:" />
                                    </FormColumn>
                                    <FormColumn width="6" >
                                        <a id="user_global_search_cards_list" href={this.state.usersGlobalSearch.userCardsListLink}>
                                            <FormattedMessage id="users.global.list.userCardListLink" defaultMessage="Open user cards list page" />
                                        </a>
                                    </FormColumn>
                                </FormRow>
                                {userDetails.canEditUserDetails && userDetails.personsToMerge && userDetails.personsToMerge.length > 0
                                ?
                                    userDetails.personsToMerge.map((person, personIndex) => (
                                        <FormRow key={personIndex}>
                                            <FormColumn width="12">
                                                <FormRow>
                                                    <FormColumn id={`person_name_title_${personIndex}`} width="3">
                                                        <FormattedMessage
                                                            id="users.global.list.mergePersonNames"
                                                            defaultMessage="Possible duplicate identity found"
                                                        />:
                                                    </FormColumn>
                                                    <FormColumn id={`person_name_${personIndex}`} width="6">
                                                        {person.fullName}
                                                    </FormColumn>
                                                </FormRow>
                                                <FormRow>
                                                    <FormColumn id={`person_buttons_title_${personIndex}`} width="3">
                                                        <FormattedMessage
                                                            id="users.global.list.mergePersonActions"
                                                            defaultMessage="Actions"
                                                        />:
                                                    </FormColumn>
                                                    <FormColumn id={`person_buttons_${personIndex}`} width="6">
                                                        <Link
                                                            id={`view_person_info-${personIndex}`}
                                                            to={`/search-users-globally/view-user/${person.person_id}`}
                                                            target="_blank"
                                                        >
                                                            <FormattedMessage
                                                                id="users.global.list.mergePersonActions.viewUser"
                                                                defaultMessage="View user details"
                                                            />
                                                        </Link>
                                                        <span>;&nbsp;</span>
                                                        <button id={`merge_persons-${personIndex}`} onClick={(e) => showMergePersonsModalBtnClicked(e, person.person_id)} className="btn-link">
                                                            <FormattedMessage
                                                                id="users.global.list.mergePersonActions.mergePersons"
                                                                defaultMessage="Merge persons"
                                                            />
                                                        </button>
                                                    </FormColumn>
                                                </FormRow>
                                            </FormColumn>
                                        </FormRow>
                                    ))
                                : null
                                }
                            </div>
                            <FormRow header={true}>
                                <FormColumn width="3">
                                    <FormattedMessage id="users.global.list.permissions.table.company" defaultMessage="Company" />
                                </FormColumn>
                                <FormColumn width="8">
                                    <FormattedMessage id="users.global.list.permissions.table.permissions" defaultMessage="Permissions" />
                                </FormColumn>
                            </FormRow>
                            { userOrganizations.length ?
                                 userOrganizations.map((company, companyIndex) => (
                                     <FormRow id={'represented-company-list-' + companyIndex} key={companyIndex} className="keep-inner-row-border">
                                         <FormColumn mobileHeader={true} width="12">
                                             <FormattedMessage id="users.global.list.permissions.table.company" defaultMessage="Company" />
                                         </FormColumn>
                                         <FormColumn width="3">
                                             <p id={'represented-company-' + companyIndex + '-name'} className="font-weight-bold">{ company.orgName }</p>
                                             <p>{company.orgCode} [{company.orgCountry}]</p>
                                             { company.orgAdditionalIds.map((additionalId, index) => {
                                                    return <p className="small mb-0 text-muted" key={index}>{additionalId.code} [{additionalId.country}]</p>
                                                 })
                                             }
                                         </FormColumn>
                                         <FormColumn mobileHeader={true} width="12">
                                             <FormattedMessage id="users.global.list.permissions.table.permissions" defaultMessage="Permissions" />
                                         </FormColumn>
                                         <FormColumn width="9">
                                             <FormTable id="service-providers-list" className="form-table-striped form-table-hover mb-0">
                                                 <FormRow header={true} />
                                                 { userOrganizations[companyIndex].representations.map((representation, representationIndex) => (
                                                     <FormRow id={'represented-company-' + companyIndex} key={representationIndex} role="button" onClick={representation.handleRowClick}>
                                                         <FormColumn mobileHeader={true} width="12">
                                                             <FormattedMessage id="users.globalList.permissionsList.serviceProvider" defaultMessage="Company" />
                                                         </FormColumn>
                                                         <FormColumn width="4">
                                                             <p id={'represented-company-' + companyIndex + '-service-provider-' + representationIndex}>{ representation.serviceProviderName }</p>
                                                             <p id={'represented-company-' + companyIndex + '-user-type-' + representationIndex} className="small text-muted">
                                                                 { representation.role === constants.userTypes.main
                                                                     ? mainUserText
                                                                     : basicUserText
                                                                 }
                                                             </p>
                                                         </FormColumn>
                                                         <FormColumn mobileHeader={true} width="12">
                                                             <FormattedMessage id="users.globalList.permissions.table.permissions" defaultMessage="Permissions" />
                                                         </FormColumn>
                                                         <FormColumn width="8">
                                                             { representation.permissions.length
                                                                 ? representation.permissions.map((permission, permissionIndex) => (
                                                                     <p id={'represented-company-' + companyIndex + '-user-permission-' + permissionIndex + '-sp-' + representationIndex} key={permissionIndex}>
                                                                         {permissionNameDisplay(permission, representation.isMainForUser, representation.servicesManagedByCurrentUser)}
                                                                     </p>
                                                                 ))
                                                                 : null
                                                             }
                                                             { representation.globalPermissions.length
                                                                 ? representation.globalPermissions.map((globalPermission, globalPermissionIndex) => (
                                                                     <p id={'represented-company-' + companyIndex + '-user-globaPermission-' + globalPermissionIndex + '-sp-' + representationIndex} key={globalPermissionIndex}>
                                                                         {globalPermission.name}
                                                                         &nbsp;
                                                                         <FormattedMessage id="profile.permissions.table.globalpermissionExplanation" defaultMessage="(global permission)" />
                                                                     </p>
                                                                 ))
                                                                 : null
                                                             }
                                                         </FormColumn>
                                                     </FormRow>
                                                ))}
                                             </FormTable>
                                         </FormColumn>
                                     </FormRow>
                                ))
                                :
                                <Message id="noGlobalUserOrganizationsFound" type="info">
                                    <FormattedMessage id="users.global.list.details.noOrgsFound" defaultMessage="No organizations found for this user" />
                                </Message>
                            }

                            <ConfirmationModal
                                idPrefix="global_search_merge_persons"
                                id="global_search_merge_persons_modal_window"
                                title={formatMessage(messages.mergePersonsTitle)}
                                okButtonText={formatMessage(messages.mergePersonsBtn)}
                                cancelButtonText={formatMessage(messages.mergePersonsCancelBtn)}
                                handleActionType={mergePersonsBtnClicked}
                                sharedStore={this.state.usersGlobalSearch.shared}
                                showModal={this.state.usersGlobalSearch.showMergePersonsModal}
                            >
                                <span>
                                    <FormattedMessage
                                        id="users.global.list.mergeMessage"
                                        defaultMessage="Do you want to merge persons"
                                    />?
                                </span>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix="global_search_generate_email_verification"
                                id="global_search_generate_email_verification_modal_window"
                                title={formatMessage(messages.generateEmailLinkConfirmationTitle)}
                                okButtonText={formatMessage(messages.generateLinkGenerateBtn)}
                                cancelButtonText={formatMessage(messages.generateLinkCancelGenerateBtn)}
                                handleActionType={generateGlobalSearchEmailLinkBtnClicked}
                                sharedStore={this.state.usersGlobalSearch.shared}
                            >
                                <span>
                                    <FormattedMessage
                                        id="users.editing.areYouSureAboutLinkGeneration"
                                        defaultMessage="Are you sure that you want to generate an email verification link for user"
                                    />
                                    &nbsp;
                                    <b>{userDetails.email}</b>
                                    ?
                                    &nbsp;
                                    <b><FormattedMessage id="users.editing.note" defaultMessage="Please note" /></b>
                                    &nbsp;
                                    <FormattedMessage
                                        id="users.editing.noteMsg"
                                        defaultMessage="that before using this function, it should be confirmed with other methods (sending an email from another address, other means) that the requester actually has access to this email address"
                                    />.
                                </span>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix="global_search_generate_password"
                                id="global_search_generate_password_modal_window"
                                title={formatMessage(messages.generatePswResetConfirmationTitle)}
                                okButtonText={formatMessage(messages.generateLinkGenerateBtn)}
                                cancelButtonText={formatMessage(messages.generateLinkCancelGenerateBtn)}
                                handleActionType={generateGlobalSearchPasswordBtnClicked}
                                sharedStore={this.state.usersGlobalSearch.shared}
                            >
                                <span>
                                    <FormattedMessage id="users.editing.confirmationMsg" defaultMessage="Are you sure that you want to generate a password reset link for user" />
                                    &nbsp;
                                    <b>{userDetails.email}</b>?
                                </span>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix="global_search_generate_email_change"
                                id="global_search_generate_email_change_modal_window"
                                title={formatMessage(messages.generateEmailChangeConfirmationTitle)}
                                okButtonText={formatMessage(messages.generateLinkGenerateBtn)}
                                cancelButtonText={formatMessage(messages.generateLinkCancelGenerateBtn)}
                                handleActionType={changeEmailGlobalSearchResendVerificationBtnClicked}
                                sharedStore={this.state.usersGlobalSearch.shared}
                            >
                                <span>
                                    <FormattedMessage
                                        id="users.global.list.areYouSureAboutLinkGeneration"
                                        defaultMessage="Are you sure that you want to generate a change email verification link for user"
                                    />
                                    &nbsp;
                                    <b>{userDetails.email}</b>
                                    ?
                                    &nbsp;
                                    <b><FormattedMessage id="users.editing.note" defaultMessage="Please note" /></b>
                                    &nbsp;
                                    <FormattedMessage
                                        id="users.editing.noteMsg"
                                        defaultMessage="that before using this function, it should be confirmed with other methods (sending an email from another address, other means) that the requester actually has access to this email address"
                                    />
                                </span>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix='generated_link'
                                id='generated_link_modal_window'
                                title={generatedLinkModalWindowTitle}
                                cancelButtonText={formatMessage(messages.linkGeneratedCancelBtn)}
                                hideConfirmButton={true}
                                showModal={showGeneratedLinkModal}
                                onCloseAction={closeModal}
                                sharedStore={this.state.usersGlobalSearch.shared}
                            >
                                <p>
                                    <FormattedMessage id="users.editing.pleaseCopyGeneratedEmailLink" defaultMessage="Please copy the link below, and send it to" />
                                    &nbsp;
                                    <b>{sendToEmailAddressInGeneratedLinkModal}</b>:
                                </p>
                                <p>
                                    <span className="text-wrap" id="generated_global_search_link_value_from_modal">
                                        {this.state.usersGlobalSearch.generatedLink}
                                        &nbsp;
                                        <CopyToClipboard text={this.state.usersGlobalSearch.generatedLink}>
                                            <span role='button' className='text-primary'>
                                                <FormattedMessage id="users.global.list.ToClip" defaultMessage="(Copy to clipboard)" />
                                            </span>
                                        </CopyToClipboard>
                                    </span>
                                </p>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix="global_search_initiate_email_change"
                                id="global_search_initiate_email_change_modal_window"
                                title={formatMessage(messages.generateInitiateEmailChangeConfirmationTitle)}
                                okButtonText={formatMessage(messages.generateInitiateEmailChangeYesBtn)}
                                cancelButtonText={formatMessage(messages.generateInitiateEmailChangeCancelBtn)}
                                handleActionType={(e) => globalSearchSaveIconClicked(e, formFields.userAccountEmail)}
                                sharedStore={this.state.usersGlobalSearch.shared}
                                showModal={this.state.usersGlobalSearch.showAdminEmailChangeInitiateModal}
                                onCloseAction={closeModal}
                            >
                                <span>
                                    <FormattedMessage
                                        id="users.global.list.areYouSureAboutEmailChange"
                                        defaultMessage="Please, note that this action will change this user account's email address to:"
                                    />
                                    <br /><br />
                                    <b><i>{formFields.userAccountEmail.value}</i></b>
                                    <br /><br />
                                    <b><FormattedMessage id="users.global.list.areYouSureAboutEmailChangeEnd" defaultMessage="and grants that email address owner a possibility to start using this user account." /></b>
                                </span>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix="global_search_account_block"
                                id="global_search_account_block_modal_window"
                                title={formatMessage(messages.accountBlockUnblockConfirmationTitle,
                                    {
                                        action: formFields.userAccountIsBlocked.value
                                        ?
                                            formatMessage(messages.accountBlockText).charAt(0).toUpperCase() + formatMessage(messages.accountBlockText).slice(1)
                                        :
                                        formatMessage(messages.accountUnblockText).charAt(0).toUpperCase() + formatMessage(messages.accountUnblockText).slice(1)})
                                    }
                                okButtonText={formatMessage(messages.generateInitiateEmailChangeYesBtn)}
                                cancelButtonText={formatMessage(messages.generateInitiateEmailChangeCancelBtn)}
                                handleActionType={(e) => adminBlockUnblockAccountConfirmBtnClicked(e, formFields.userAccountIsBlocked, formFields.userAccountBlockedReason)}
                                sharedStore={this.state.usersGlobalSearch.shared}
                                showModal={this.state.usersGlobalSearch.showAdminAccountBlockUnblockConfirmationModal}
                                onCloseAction={closeModal}
                            >
                                <span>
                                    <FormattedMessage
                                        id="users.global.list.areYouSureAboutAccountBlockUnblock"
                                        defaultMessage="Are you sure that you want to {action} this user account ?"
                                        values={
                                            {
                                                action: formFields.userAccountIsBlocked.value
                                                ?
                                                    <b><FormattedMessage id="users.global.list.accountBlock" defaultMessage="block" /></b>
                                                :
                                                <b><FormattedMessage id="users.global.list.accountUnblock" defaultMessage="unblock" /></b>
                                            }
                                        }
                                    />
                                </span>
                            </ConfirmationModal>

                            <ConfirmationModal
                                idPrefix="global_search_identity_document_image"
                                id="global_search_identity_document_image_modal_window"
                                title={this.state.usersGlobalSearch.identityDocumentToShowIsUploaded ? formatMessage(messages.uploadedDocumentImageTitle) : formatMessage(messages.scannedDocumentImageTitle)}
                                hideConfirmButton={true}
                                cancelButtonText={formatMessage(messages.identityDocumentImageCloseBtn)}
                                modalDialogClass="modal-lg"
                                showModal={this.state.usersGlobalSearch.showIdentityDocumentImageModal}
                                onCloseAction={closeModal}
                            >
                                {this.state.usersGlobalSearch.identityDocumentToShowIsPdf
                                ?
                                    <div className='d-flex'>
                                        <div className='mx-auto'>
                                            <PdfViewer file={this.state.usersGlobalSearch.identityDocumentImageToShow} />
                                        </div>
                                    </div>
                                :
                                <img id="identity_document_image" className="img-fluid mx-auto d-block" src={this.state.usersGlobalSearch.identityDocumentImageToShow} />
                                }
                            </ConfirmationModal>

                        </FormTable>
                    :
                    <Message id="noGlobalUserOrganizationsFound" type="info">
                        <FormattedMessage id="users.global.list.details.userNotFound" defaultMessage="User not found" />
                    </Message>
                }
            </div>
        );
    }
}

export default ViewSingleUserGlobalSearchResults;
