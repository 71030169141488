import BaseStore from '../base/BaseStore.js';

class InformEperehdytysUserStore extends BaseStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/insufficient-session-open',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_INFORM_EPEREHDYTYS_USER',
                loadHandler: this.onInformEperehdytysRouteLoaded
            }
        ];
    }

    onInformEperehdytysRouteLoaded(state) {
        state.showLoadingIndicator = false;
    }
}

export default InformEperehdytysUserStore;
