import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let deleteUser = function (orgId, userId) {
    postToBackend(`/users/${orgId}/remove-user/${userId}`,
        ActionTypes.ORGANIZATION_USERS_DELETE_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_DELETE_ERROR
    );
};

export default deleteUser;
