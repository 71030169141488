import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import { Message, PageHeader, FormRow, FormColumn, ValidationMessages, TextInput, emailValidator, DateLabel, RadioInput } from '@vaultit/stv-utils-frontend';
import getGlobalUserSearchListRowClickedHandler from '../../actions/actionCreators/users/SearchUsersGloballyListRowClicked.js';

const messages = defineMessages({
    usersGlobalSearchPlaceholder: {
        id: 'users.global.search.searchboxPlaceholder',
        description: 'Search string',
        defaultMessage: 'Search string'
    },
    blockHistoryModalTextNo: {
        id: 'users.global.list.blockHistoryModal.textNo',
        description: 'Block history modal window No text',
        defaultMessage: 'No'
    },
    blockHistoryModalTextYes: {
        id: 'users.global.list.blockHistoryModal.textYes',
        description: 'Block history modal window Yes text',
        defaultMessage: 'Yes'
    }
});

class SearchUsersGlobally extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        }
    }

    renderWhenReady() {

        const { formatMessage } = this.context.intl;
        let store = this.state.usersGlobalSearch;
        let form = store.searchUsersGloballyForm;
        let fields = form ? form.fields : null;
        let userList = store.userList;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="users.global.search.title"
                    sharedStore={this.state.shared}
                />
                { form ?
                    <div>
                        <div id="users_global_list_searchbox" className="card card-default">
                            <div className="card-block">
                                <form id="users_global_list_search_form" onSubmit={form.handleOnSubmitBtnClicked}>
                                    <FormRow>
                                        <FormColumn widthLg="6" widthMd="6">
                                            <RadioInput
                                                id="users_global_list_search_type_full_name"
                                                name="search_type"
                                                field={fields.searchType}
                                                value={store.constants.globalSearchTypes.fullName || ""}
                                                checked={fields.searchType.value === store.constants.globalSearchTypes.fullName}
                                                messageId="users.global.search.fullName"
                                                defaultMessage="Full name"
                                                bold={false}
                                                labelClassName="m-0"
                                            />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn widthLg="6" widthMd="6">
                                            <RadioInput
                                                id="users_global_list_search_type_email"
                                                name="search_type"
                                                field={fields.searchType}
                                                value={store.constants.globalSearchTypes.email || ""}
                                                checked={fields.searchType.value === store.constants.globalSearchTypes.email}
                                                messageId="users.global.search.emailAddress"
                                                defaultMessage="Email address"
                                                bold={false}
                                                labelClassName="m-0"
                                            />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn widthLg="6" widthMd="6">
                                            <RadioInput
                                                id="users_global_list_search_type_gov_id"
                                                name="search_type"
                                                field={fields.searchType}
                                                value={store.constants.globalSearchTypes.govId || ""}
                                                checked={fields.searchType.value === store.constants.globalSearchTypes.govId}
                                                messageId="users.global.search.govId"
                                                defaultMessage="Personal identification number"
                                                bold={false}
                                                labelClassName="m-0"
                                            />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn widthLg="6" widthMd="6">
                                            <RadioInput
                                                id="users_global_list_search_type_card_id"
                                                name="search_type"
                                                field={fields.searchType}
                                                value={store.constants.globalSearchTypes.number || ""}
                                                checked={fields.searchType.value === store.constants.globalSearchTypes.number}
                                                messageId="users.global.search.cardId"
                                                defaultMessage="Card number"
                                                bold={false}
                                                labelClassName="m-0"
                                            />
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn widthLg="6" widthMd="6">
                                            <ValidationMessages field={fields.searchString}>
                                                <div className="form-group m-0 p-0">
                                                    <TextInput
                                                        id="users_global_list_search_by_string"
                                                        field={fields.searchString}
                                                        placeholder={formatMessage(messages.usersGlobalSearchPlaceholder)}
                                                        autoFocus={true}
                                                        inputGroup={true}
                                                        addonPossition="left"
                                                    >
                                                        <span role="button" onClick={form.handleOnSubmitBtnClicked} className="input-group-addon text-primary bg-white">
                                                            <i id="filter-icon" className="fa fa-search" aria-hidden="true"></i>
                                                        </span>
                                                    </TextInput>
                                                </div>
                                            </ValidationMessages>
                                        </FormColumn>
                                    </FormRow>
                                </form>
                            </div>
                        </div>
                        { store.showUsersNotFoundMessage ?
                            <Message id="noGlobalUsersFoundMessage" type="info">
                                <FormattedMessage id="users.global.search.notFound" defaultMessage="No users found" />
                            </Message>
                            : null
                        }
                        { store.UsersGlobalSearchLimitedMessage
                            ?
                                <Message id="usersListLimitedMessage" type="info">
                                    <FormattedMessage
                                        id="users.global.search.limited"
                                        defaultMessage="The results were limited because more than {listMaxLength} matching users were found. Please, be more specific."
                                        values={{listMaxLength: store.listMaxLength}}
                                    />
                                </Message>
                            : null
                        }
                    </div>
                    : null
                }
                { userList.length ?
                    <div>
                        <table id="user_globalsearch_users_list" className="table table-striped table-hover table-fixed-layout">
                            <thead>
                                <tr>
                                    <th className="">
                                        <FormattedMessage id="users.global.search.user" defaultMessage="User" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="users.global.search.blocked" defaultMessage="Blocked" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="users.global.search.companies" defaultMessage="Companies" />
                                    </th>
                                    <th>
                                        <FormattedMessage id="users.global.search.govId" defaultMessage="Personal IDs" />
                                    </th>
                                    { fields.searchType.value === store.constants.globalSearchTypes.number ?
                                        <th>
                                            <FormattedMessage id="users.global.search.cardId" defaultMessage="Card IDs" />
                                        </th>
                                        : null
                                    }
                                    <th >
                                        <FormattedMessage id="users.global.search.registrationDate" defaultMessage="Registration date" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                { userList.map((user, index) => {
                                    return (
                                        <tr key={index} role="button" onClick={getGlobalUserSearchListRowClickedHandler(user.personId)}>
                                            <td >
                                                <div id={'users_globalsearch_organizations_list_' + index + '_name'}>
                                                    <p className='font-weight-bold'>{user.personFullName}</p>
                                                    <p className='small text-muted'>
                                                        {!user.email || emailValidator(user.email)
                                                            ? <FormattedMessage id="users.global.search.noEmail" defaultMessage="N/A" />
                                                            : user.email
                                                        }
                                                    </p>
                                                </div>
                                            </td>
                                            <td id={'users_globalsearch_organizations_list_' + index + '_blocked'} >
                                                { user.isBlocked ? formatMessage(messages.blockHistoryModalTextYes) : formatMessage(messages.blockHistoryModalTextNo)}
                                            </td>
                                            <td id={'users_globalsearch_organizations_list_' + index + '_country'} >
                                                { user.organizations.length
                                                    ? (user.organizations.map((organization, key) => <p key={key} className='small'>{organization.orgName}</p>))
                                                    : '-'
                                                }
                                            </td>
                                            <td id={'users_globalsearch_gov_id_list_' + index + '_gov_ids'} >
                                                { user.govIds.length
                                                    ? (user.govIds.map((govId, key) => <p key={key} className='small'>{govId.gov_id + ' ('+ govId.country.toUpperCase() + ', ' + govId.id_type.toUpperCase() + (govId.verification_status ? ', VERIFIED' : '') +')'} </p>))
                                                    : '-'
                                                }
                                            </td>
                                            { fields.searchType.value === store.constants.globalSearchTypes.number ?
                                                <td id={'users_globalsearch_card_id_list_' + index + '_card_ids'} >
                                                    { user.cardIds.length
                                                        ? (user.cardIds.filter((cardId) => cardId.card_id_type !== "card_supplier_client_id").map((cardId, key) => <p key={key} className='small'>{cardId.card_id} </p>))
                                                        : '-'
                                                    }
                                                </td>
                                            : null
                                           }


                                            <td id={'users_globalsearch_organizations_list_' + index + '_reg_date'} >
                                                <DateLabel id={'users_globalsearch_organizations_list_' + index + '_reg_date_value'} format="YYYY-MM-DD HH:mm">{user.registrationDate}</DateLabel>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div> : null
                }
            </div>
        )
    }
}

export default SearchUsersGlobally;
