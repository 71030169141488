import React from 'react';
import { FormColumn, Message } from '@vaultit/stv-utils-frontend';
import { FormattedMessage } from 'react-intl';
import topMenuOrDashboardItemClicked from './../../actions/actionCreators/menu/TopMenuOrDashboardItemClicked.js';


class DashboardServiceCard extends React.Component {

    render() {
        return (
            <FormColumn widthLg={(12 / this.props.cols_lg).toString()} widthMd={(12 / this.props.cols_md).toString()}>
                <div className="service">
                    <h4>
                        <div className={this.props.icon}></div>
                        {this.props.title}
                    </h4>
                    <div className="text-wrapper">
                        <p>
                            {this.props.description}
                        </p>
                    </div>
                    {
                        this.props.noServiceAccessMessage
                        ?
                        (
                            <Message type="warning" className="w-100">
                                {this.props.noServiceAccessMessage}
                            </Message>
                        )
                        :
                        (
                        <div>
                            { this.props.serviceHasException
                            ?

                                <a role="button" onClick={() => topMenuOrDashboardItemClicked(this.props.service)} className="go-to-service" id={this.props.elementButtonId}>
                                    <FormattedMessage id="services.goToServiceLink" defaultMessage="Go to service" />
                                </a>

                            :<a href={this.props.url} className="go-to-service" id={this.props.elementButtonId}>
                                <FormattedMessage id="services.goToServiceLink" defaultMessage="Go to service" />
                            </a>
                            }
                        </div>
                    )
                    }
                </div>
            </FormColumn>
        );
    }
}

export default DashboardServiceCard;
