import constants from '../../Constants.js';
import ActionTypes from '../../actions/ActionTypes';
import prepareUrl from '../../helpers/PrepareUrl.js';
import storeFormManager from '../validation/StoreFormManager.js';
import changeName from '../../actions/actionCreators/profile/yourDetails/changeName.js';
import changePhone from '../../actions/actionCreators/profile/yourDetails/changePhone.js';
import changeEmailInitiate from '../../actions/actionCreators/profile/yourDetails/ChangeEmailInitiate.js';
import {whiteSpaceRestrictor, getBlacklistedEmailValidator,emailValidator,  getMatchValidator, getMaxLengthValidator, getMismatchValidator, passwordValidator,requiredValidator} from '@vaultit/stv-utils-frontend';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import getAllUserPermissions from '../../actions/actionCreators/profile/yourDetails/getAllUserPermissions.js';
import getUserAccountDependencies from '../../actions/actionCreators/profile/yourDetails/getUserAccountDependencies.js';
import deleteUserAccount from '../../actions/actionCreators/profile/yourDetails/DeleteUserAccount.js';
import changeEmailResendVerificationEmail from '../../actions/actionCreators/profile/yourDetails/ChangeEmailResendVerificationEmail.js';
import changeEmailCancel from '../../actions/actionCreators/profile/yourDetails/ChangeEmailCancel.js';
import FormStore from '../base/FormStore.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import changePassword from '../../actions/actionCreators/profile/yourDetails/changePassword.js';
import getPermissionRowClickedHandler from '../../actions/actionCreators/profile/yourDetails/PermissionsRowClicked.js';

const storeKey = 'YourDetails';

class YourDetailsStore extends FormStore {

    getInitialState() {
        let initialState =  {
            backToBaseDetailsUrl: null,
            showLoadingIndicator: false,
            showChangedNameSuccessMessage: false,
            showEmailChangeInitiateSuccessMessage: false,
            showEmailChangeVericationResendSuccessMessage: false,
            showCancelEmailChangeSuccessMessage: false,
            showChangedPhoneSuccessMessage: false,
            showPasswordChangeSuccessMessage: false,
            showChangedEmailDuplicateMessage: false,
            showUserAccountHasDependenciesMessage: false,
            showUserAccountDeletedModal: false,
            showDependenciesWarning: false,
            showUserAccountHasCardsWarning: false,
            showAccountHasSignedContractsWarning: false,
            showVerifiedByManualIDWarning: false,
            showLastMainUserWarning: false,
            signedContractCompanyName: false,
            lastMainUserCompanyName: false,
            duplicateEmail: false,
            showChangedEmailInvalidPasswordMessage: false,
            orgRepresentations: [],
            userCanUpdateUsername: true,
            changeEmail: false,
            constants: {
                userTypes: constants.userTypes,
                permissions: constants.permissions,
                passwordValidationMaximumDigits: constants.passwordValidationMaximumDigits
            },
            changePassword: false,
            showCreateFullAccountTab: false,
            createAccountUrl: '',
            tabVisibility: {
                'showBasicDetailsTab': false,
                'showDeleteAccountTab': false
            }
        };

        let changeNameFormFieldDefinitions = {
            firstName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, 'changeNameForm', changeNameFormFieldDefinitions, this.onChangeNameFormSubmitted);

        let changePhoneFormFieldDefinitions = {
            phoneNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, 'changePhoneForm', changePhoneFormFieldDefinitions, this.onChangePhoneFormSubmitted);


        let passwordChangeFormFieldDefinitions = {
            oldPassword: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ],
                isSensitiveInformation: true
            },
            newPassword: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator,
                    {
                        validateOnOtherFieldChange: 'oldPassword',
                        validator: getMismatchValidator('oldPassword')
                    }
                ],
                isSensitiveInformation: true
            },
            newPasswordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    {
                        validateOnOtherFieldChange: 'newPassword',
                        validator: getMatchValidator('newPassword')
                    }
                ],
                isSensitiveInformation: true
            },
        };

        storeFormManager.defineForm(initialState, storeKey, 'passwordChangeForm', passwordChangeFormFieldDefinitions, this.onPasswordChangeFormSubmitted);

        let changeEmailFormFieldDefinitions = {
            newEmail: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            },
            newEmailAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'newEmail',
                        validator: getMatchValidator('newEmail')
                    }
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, 'changeEmailForm', changeEmailFormFieldDefinitions, this.onChangeEmailFormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/change-name',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_UPDATE_USERNAME',
                loadHandler: this.onProfileChangeNameRouteLoaded
            },
            {
                fullPath: '/profile/change-phone',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_USER_PROFILE',
                loadHandler: this.onProfileChangePhoneRouteLoaded
            },
            {
                fullPath: '/profile/basic-details',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_USER_PROFILE',
                loadHandler: this.onProfileBasicDetailsRouteLoaded
            },
            {
                fullPath: '/profile/permissions',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_USER_PROFILE_PERMISSIONS',
                loadHandler: this.onProfilePermissionsRouteLoaded
            },
            {
                fullPath: '/profile/changepassword',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CHANGE_PASSWORD',
                loadHandler: this.onPasswordChangeRouteLoaded
            },
            {
                fullPath: '/profile/change-email',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CHANGE_USER_EMAIL',
                loadHandler: this.onProfileChangeEmailRouteLoaded
            },
            {
                fullPath: '/profile/identity-verification-is-hidden',
                loadOnlyIfHasPermission: 'SEPPO_IDENTITY_VERIFICATION_IS_HIDDEN',
                loadHandler: this.onIdentityVerificationIsHiddenRouteLoaded,
            },
            {
                fullPath: '/profile/delete-my-account',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_DELETE_USER_ACCOUNT',
                loadHandler: this.onDeleteAccountRouteLoaded
            }
        ];
    }

    checkTabVisibility(state, action, sharedStoreState) {
        state.tabVisibility = {
            'showBasicDetailsTab': this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VIEW_USER_PROFILE'),
            'showDeleteAccountTab': this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_DELETE_USER_ACCOUNT')
        }
    }

    onProfileBasicDetailsRouteLoaded(state, action, sharedStoreState) {
        state.changePassword = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CHANGE_PASSWORD');
        state.changeEmail = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CHANGE_USER_EMAIL');
        state.userCanUpdateUsername = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_UPDATE_USERNAME');
        state.createAccountUrl = prepareUrl.prepareRouteUrl('/profile/create-full-account');

        this.checkTabVisibility(state, action, sharedStoreState);
    }

    onProfilePermissionsRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        this.checkTabVisibility(state, action, sharedStoreState);
        getAllUserPermissions();
    }

    onDeleteAccountRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        this.checkTabVisibility(state, action, sharedStoreState);
        state.showUserAccountHasDependenciesMessage = false;
        state.showUserAccountDeletedModal = false;
        state.showDependenciesWarning = false;
        state.showUserAccountHasCardsWarning = false;
        state.showAccountHasSignedContractsWarning = false;
        state.showVerifiedByManualIDWarning = false;
        state.showLastMainUserWarning = false;
        state.lastMainUserCompanyName = false;
        state.signedContractCompanyName = false;
        getUserAccountDependencies();
    }

    [ActionTypes.PROFILE_GET_ALL_USER_PERMISSIONS_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;


        state.orgRepresentations = [];
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        let representations = action.result.organisations;
        let globalPermissionsList = action.result.allGlobalPermissions;
        let mappedCompanyObject = {};

        if (representations) {
            Object.keys(representations).forEach((key) => {
                mappedCompanyObject = representations[key];

                representations[key].representations.forEach((currentRepresentation) => {

                    currentRepresentation.handleRowClick = getPermissionRowClickedHandler(representations[key].orgId, currentRepresentation.url);

                    if (currentRepresentation.globalPermissions && currentRepresentation.globalPermissions.length) {
                        globalPermissionsList.forEach(function(globalPermission) {
                            let foundGlobalPermissionKey = currentRepresentation.globalPermissions.indexOf(globalPermission.code);
                            if (foundGlobalPermissionKey > -1) {
                                currentRepresentation.globalPermissions[foundGlobalPermissionKey] = globalPermission;
                            }
                        });
                    }
                });

                state.orgRepresentations.push(mappedCompanyObject);
            });
        }
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_INITIATE_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        if (action.result.duplicateEmail) {
            state.showChangedEmailDuplicateMessage = true;
            state.duplicatedEmail = action.newEmail;
        } else {
            state.showEmailChangeInitiateSuccessMessage = true;
            redirectToRoute('/profile/basic-details', true);
        }
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_INITIATE_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_RESEND_EMAIL_VERIFICATION_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        state.showEmailChangeInitiateSuccessMessage = false;
        state.showEmailChangeVericationResendSuccessMessage = false;
        state.showCancelEmailChangeSuccessMessage = false;
        changeEmailResendVerificationEmail();
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_RESEND_EMAIL_VERIFICATION_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        if (action.result.duplicateEmail || action.result.emailChangeWasCanceled) {
            redirectToRoute('/profile/basic-details', true);
        } else {
            state.showEmailChangeVericationResendSuccessMessage = true;
            state.showCancelEmailChangeSuccessMessage = false;
        }
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_RESEND_EMAIL_VERIFICATION_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_CANCEL_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        state.showEmailChangeInitiateSuccessMessage = false;
        state.showEmailChangeVericationResendSuccessMessage = false;
        state.showCancelEmailChangeSuccessMessage = false;
        changeEmailCancel();
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_CANCEL_SUCCESS](state) {
        state.showLoadingIndicator = false;
        state.showCancelEmailChangeSuccessMessage = true;
        state.showEmailChangeVericationResendSuccessMessage = false;
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_CANCEL_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_GET_ALL_USER_PERMISSIONS_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_YOUR_DETAILS_PERMISSIONS_ROW_CLICKED](state, action) {
        if (action.url) {
            let url = action.url.replace("[ORG_ID]", action.orgCode);
            redirectToUrl(url, true, true, true);
        } else {
            redirectToRoute("/", false, true, action.orgCode);
        }
    }

    onProfileChangeNameRouteLoaded(state, action, sharedStoreState) {
        state.backToBaseDetailsUrl = prepareUrl.prepareRouteUrl('/profile/basic-details');

        let fieldDefinitions = state.formDefinitions.changeNameForm.fieldDefinitions;
        fieldDefinitions.firstName.defaultValue = sharedStoreState.profile ? sharedStoreState.profile.firstName : null;
        fieldDefinitions.lastName.defaultValue = sharedStoreState.profile ? sharedStoreState.profile.lastName : null;

        storeFormManager.setFormInitialState(state, 'changeNameForm');
    }

    onProfileChangePhoneRouteLoaded(state, action, sharedStoreState) {
        state.backToBaseDetailsUrl = prepareUrl.prepareRouteUrl('/profile/basic-details');

        let fieldDefinitions = state.formDefinitions.changePhoneForm.fieldDefinitions;
        fieldDefinitions.phoneNumber.defaultValue = sharedStoreState.profile ? sharedStoreState.profile.phoneNumber : null;

        storeFormManager.setFormInitialState(state, 'changePhoneForm');
    }

    onProfileChangeEmailRouteLoaded(state, action, sharedStoreState) {
        state.showChangedEmailDuplicateMessage = false;
        state.duplicateEmail = false;
        state.showChangedEmailInvalidPasswordMessage = false;
        state.backToBaseDetailsUrl = prepareUrl.prepareRouteUrl('/profile/basic-details');
        storeFormManager.setFormInitialState(state, 'changeEmailForm');
        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };
    }

    onPasswordChangeRouteLoaded(state) {
        state.pathToBasicDetails = '/profile/basic-details';
        storeFormManager.setFormInitialState(state, 'passwordChangeForm');
    }

    onChangeNameFormSubmitted(state, values) {
        state.showLoadingIndicator = true;
        changeName(values);
    }

    onChangePhoneFormSubmitted(state, values) {
        state.showLoadingIndicator = true;

        if (state.changePhoneForm.fields.phoneNumber && state.changePhoneForm.fields.phoneNumber.isVisible && state.changePhoneForm.fields.phoneNumber.additionalInfo) {
            values.phoneNumber = state.changePhoneForm.fields.phoneNumber.additionalInfo.fullNumber;
        }

        changePhone(values);
    }

    onChangeEmailFormSubmitted(state, values) {
        state.showChangedEmailDuplicateMessage = false;
        state.duplicateEmail = false;
        state.showChangedEmailInvalidPasswordMessage = false;
        state.showLoadingIndicator = true;
        changeEmailInitiate(values);
    }

    onPasswordChangeFormSubmitted(state, values) {
        state.showLoadingIndicator = true;
        changePassword(values);
    }

    [ActionTypes.ROUTE_ENTERED](state, action) {
        if (action.route.fullPath !== '/profile/basic-details') {
            state.showChangedNameSuccessMessage = false;
            state.showEmailChangeInitiateSuccessMessage = false;
            state.showChangedPhoneSuccessMessage = false;
            state.showPasswordChangeSuccessMessage = false;
            state.showEmailChangeVericationResendSuccessMessage = false;
            state.showCancelEmailChangeSuccessMessage = false;
        }
    }

    [ActionTypes.AUTH_INFO_GET_SUCCESS](state, action, sharedStoreState) {
        state.showCreateFullAccountTab = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CREATE_FULL_ACCOUNT');
    }

    [ActionTypes.PROFILE_CHANGE_NAME_SUCCESS](state) {
        redirectToRoute('/profile/basic-details', true);
        state.showLoadingIndicator = false;
        state.showChangedNameSuccessMessage = true;
    }

    [ActionTypes.PROFILE_CHANGE_NAME_ERROR](state) {
        state.showLoadingIndicator = false;
    }


    [ActionTypes.PROFILE_CHANGE_PHONE_SUCCESS](state, action, sharedStoreState) {
        redirectToRoute('/profile/basic-details', true);

        if (sharedStoreState.profile) {
            sharedStoreState.profile.phoneNumber = action.phoneNumber;
        }

        state.showLoadingIndicator = false;
        state.showChangedPhoneSuccessMessage = true;
    }

    [ActionTypes.PROFILE_CHANGE_PHONE_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_PASSWORD_CHANGE_SUCCESS](state) {
        redirectToRoute('/profile/basic-details', true);
        state.showLoadingIndicator = false;
        state.showPasswordChangeSuccessMessage = true;
    }

    [ActionTypes.PROFILE_PASSWORD_CHANGE_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_DELETE_USER_ACCOUNT_BTN_CLICKED](state) {
        state.showUserAccountHasDependenciesMessage = false;
        state.showUserAccountDeletedModal = false;
        state.showLoadingIndicator = true;
        deleteUserAccount()
    }

    [ActionTypes.PROFILE_DELETE_USER_ACCOUNT_SUCCESS](state) {
        state.showLoadingIndicator = false;
        state.showUserAccountDeletedModal = true;
    }

    [ActionTypes.PROFILE_DELETE_USER_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_USER_ACCOUNT_DELETED_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let configuration = sharedStoreState.authInfo.topMenuParameters.configuration;
        redirectToUrl(prepareUrl.getSignOutRelativeUrl(configuration.urlAfterSignOut));
    }

    [ActionTypes.PROFILE_GET_USER_ACCOUNT_DEPENDENCIES_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.showUserAccountHasCardsWarning = action.result.accountHasCards;
        state.showAccountHasSignedContractsWarning = action.result.accountHasSignedContracts;
        state.showVerifiedByManualIDWarning = action.result.verifiedByManualID;
        state.showLastMainUserWarning = action.result.lastMainUser;
        state.lastMainUserCompanyName = action.result.companyName;
        state.signedContractCompanyName = action.result.contractCompany;
        state.showDependenciesWarning = state.showUserAccountHasCardsWarning || state.showAccountHasSignedContractsWarning || state.showVerifiedByManualIDWarning || state.showLastMainUserWarning
    }

    [ActionTypes.PROFILE_GET_USER_ACCOUNT_DEPENDENCIES_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default YourDetailsStore;
