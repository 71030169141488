import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage, intlShape } from 'react-intl';
import {Message, PageHeader } from '@vaultit/stv-utils-frontend';

class InformToVisitManualIdVerificationPartner extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }


    renderWhenReady() {

        let manualIDPartnersURL = "https://id06.se/english/#scanningpartners";

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="informToVisitManualIdVerificationPartner.title"
                    sharedStore={this.state.shared}
                />
                <Message id="inform-to-visit-manual-id-verification-partner-message" type="warning" className="text-center">
                    <FormattedMessage
                        id="informToVisitManualIdVerificationPartner.message.part1"
                        defaultMessage="You need to verify your identity."
                    />
                    <br />
                    <FormattedMessage
                        id="informToVisitManualIdVerificationPartner.message.part2"
                        defaultMessage="Please visit one of the Manual ID verification partners."
                    />
                    &nbsp;
                    <a target="_blank" rel="noopener noreferrer" href={manualIDPartnersURL}>{manualIDPartnersURL}</a>
                    <br />
                    <FormattedMessage
                        id="informToVisitManualIdVerificationPartner.message.part3"
                        defaultMessage="After your successful identity verification you will be able to finish your registration."
                    />
                </Message>
            </div>
        );
    }
}

export default InformToVisitManualIdVerificationPartner;
