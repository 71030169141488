import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';


let changeEmailGenerateVerificationLink = function (data) {
        postToBackend('/profile/change-email-generate',
        ActionTypes.GET_GLOBAL_SEARCH_CHANGE_EMAIL_LINK_SUCCESS,
        ActionTypes.GET_GLOBAL_SEARCH_CHANGE_EMAIL_LINK_ERROR,
        data);
};

export default changeEmailGenerateVerificationLink;