import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let changeEmailCancel = function (data) {
    postToBackend('/profile/change-email-cancel',
        ActionTypes.PROFILE_CHANGE_EMAIL_CANCEL_SUCCESS,
        ActionTypes.PROFILE_CHANGE_EMAIL_CANCEL_ERROR,
        data, data);
};
export default changeEmailCancel;
