import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

let validateEmailVerificationToken = function(token) {
    getFromBackend('/profile/email-change/verify/' + token,
        ActionTypes.PROFILE_CHANGE_EMAIL_TOKEN_VERIFICATION_SUCCESS,
        ActionTypes.PROFILE_CHANGE_EMAIL_TOKEN_VERIFICATION_ERROR,
        { token: token });
};

export default validateEmailVerificationToken;