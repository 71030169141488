import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let deleteUserAccountBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.PROFILE_DELETE_USER_ACCOUNT_BTN_CLICKED
        });
    };

export default deleteUserAccountBtnClicked;
