import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let finishEmailChangeVerificationAfterBankId = function(token, values = null) {

    postToBackend('/profile/email-change-finish-after-bankid/' + token,
        ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_AFTER_BANKID_FINISH_SUCCESS,
        ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_AFTER_BANKID_FINISH_ERROR,
        { token: token, newPassword: values['newPassword'] }
    );
};

export default finishEmailChangeVerificationAfterBankId;