import React from 'react';
import { FormattedMessage } from 'react-intl';
import StoreComponent from '../../base/StoreComponent.jsx';

class MasterPage extends StoreComponent {

    render() {

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters && this.state.shared.authInfo.topMenuParameters
            ? this.state.shared.authInfo.topMenuParameters.configuration
            : null;

        let brandLogo = uiConfiguration && uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow
            ? <span className="brand-logo"></span>
            : null;

        return (
            <div>
                { brandLogo }
                <h1 id="master_page_title" className="master-page-title">
                    <FormattedMessage
                        id="registration.step1.flow2.title"
                        description="Registration wizard title"
                        defaultMessage="Register for a new account"
                    />
                    <div className="text-muted small mt-3">
                        <FormattedMessage
                            id="registration.step1.flow2.underTitle"
                            description="Registration wizard title"
                            defaultMessage="You are registering as a private person."
                        />
                    </div>
                </h1>
                { this.props.children }
            </div>
        );
    }
}

export default MasterPage;
