import BaseStore from '../base/BaseStore.js';
import prepareUrl from '../../helpers/PrepareUrl.js';

class PersonalDataPolicyRejectedStore extends BaseStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            bankIdSignInLink: '',
            dokobitSignInLink: '',
            personDataPolicyLink: ''
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/personal-data-policy-rejected',
                loadHandler: this.onPersonalDataPolicyRejectedRouteLoaded
            }
        ];
    }

    onPersonalDataPolicyRejectedRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let configuration = sharedStoreState.authInfo.topMenuParameters.configuration;

        let strongAuthForAnonymousEnabled = configuration.servicePortal.strongAuthenticationForAnonymous.enabled;
        state.bankIdSignInLink = strongAuthForAnonymousEnabled ? prepareUrl.getStrongAuthForAnonymousPath(configuration) : '';
        state.dokobitSignInLink = strongAuthForAnonymousEnabled ? prepareUrl.getStrongAuthForAnonymousPath(configuration, null, false, 'dokobit_identitygw_provision') : '';

        let tosType = "person";
        state.personDataPolicyLink = "/binary/" + sharedStoreState.tosPrefix + "_" + tosType + "_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";

    }

}

export default PersonalDataPolicyRejectedStore;
