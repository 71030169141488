/* System imports */
import React from 'react';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
/* Component related imports */
import StoreComponent from '../base/StoreComponent.jsx';
/* Form related imports */
import { ValidationMessages, PageHeader, FormValidationMessage, FormRow, FormColumn, FormGroup, Message, TextInput } from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    button: {
        id: 'resetPassword.button',
        description: 'Password reset button text',
        defaultMessage: 'Set password'
    },
    passwordPlaceholder: {
        id: 'resetPassword.passwordPlaceholder',
        description: 'Password placeholder',
        defaultMessage: 'Your password'
    },
    passwordAgainPlaceholder: {
        id: 'resetPassword.passwordAgainPlaceholder',
        description: 'Password again placeholder',
        defaultMessage: 'Password again'
    }
});

const storeKey = 'passwordReset';

class ResetPassword extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {

        const { formatMessage } = this.context.intl;

        /* Make sure form state is initialized. */
        let store = this.state ? this.state[storeKey] : {};
        let form = store.resetPasswordForm;

        let fields = form ? form.fields : null;

        let pwdMessageValues = {
            numberOfDigits: store.passwordValidationMinimumDigits
        };

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="resetPassword.title"
                    sharedStore={this.state.shared}
                />

                { store.visibleElements.resetPasswordSuccess
                    ? <Message id="succesMessage" type="success">
                        <FormattedMessage id="resetPassword.success.part1" defaultMessage="Password reset! You may now" />
                        <span>&nbsp;</span>
                        <a id="reset_password_success_sing_in" href={store.signInUrl}>
                            <FormattedMessage id="resetPassword.success.part2" defaultMessage="sign in" />
                        </a>
                        <span>&nbsp;</span>
                        <FormattedMessage id="resetPassword.success.part3" defaultMessage="using your new password." />

                        { store.visibleElements.additionallyEmailWasVerified
                            ? <span>
                                <span>&nbsp;</span>
                                <FormattedMessage id="resetPassword.success.additionallyEmailWasverified" defaultMessage="Additionally, your email was verified." />
                            </span>
                            : null
                        }

                    </Message>
                    : null
                }
                { store.visibleElements.resetPasswordForm
                    ? <div>
                        <p>
                            <FormattedMessage id="resetPassword.description" values={pwdMessageValues} />
                        </p>
                        <form name="resetPasswordForm">
                            <FormRow>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.password}>
                                            <TextInput
                                                id="password_reset_password"
                                                type="password"
                                                field={fields.password}
                                                messageId="registration.step1.password"
                                                defaultMessage="Your password"
                                                required={true}
                                                placeholder={formatMessage(messages.passwordPlaceholder) }
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.passwordAgain} match="errors.validation.field.match.passwords">
                                            <TextInput
                                                id="password_reset_password_again"
                                                type="password"
                                                field={fields.passwordAgain}
                                                messageId="registration.step1.passwordAgain"
                                                defaultMessage="Password again"
                                                required={true}
                                                placeholder={formatMessage(messages.passwordAgainPlaceholder) }
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                        </form>
                        {/*
                            Submit button is outside the form on purpose - form contains password field and we want to disable password manager here.
                            Default button behaviour is done manually through 'handleOnEnterKeyDownToSubmitForm' handlers.
                        */}
                        <FormGroup className="mt-6">
                            <FormValidationMessage form={form} />
                            <span className="text-center">
                                <input
                                    id="reset_password_btn"
                                    className="btn btn-primary btn-lg"
                                    type="submit"
                                    value={formatMessage(messages.button) || ""}
                                    onClick={form.handleOnSubmitBtnClicked}
                                    role="button"
                                />
                            </span>
                        </FormGroup>
                    </div>
                    : null
                }

                {store.showPasswordResetTimedOutMsg
                ?
                    <Message id="password_reset_timed_out" type="warning">
                        <FormattedMessage id="resetPassword.passwordTimedOut.msg" defaultMessage="Your link for password change has expired" />,&nbsp;
                        <a href={store.resetPasswordUrl} id="reset_password_again">
                            <FormattedMessage id="resetPassword.passwordTimedOut.pleaseReset" defaultMessage="please reset your password" />
                        </a>
                        &nbsp;
                        <FormattedMessage id="resetPassword.passwordTimedOut.again"  defaultMessage="again" />.
                    </Message>
                : null
                }
            </div>
        );
    }
}

export default ResetPassword;
