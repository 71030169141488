import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let closeRedirectNotificationBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CLOSE_REDIRECT_NOTICE_BTN_CLICKED
    });
};

export default closeRedirectNotificationBtnClicked;
