import React from 'react';
import PropTypes from 'prop-types';
import { FormColumn } from '@vaultit/stv-utils-frontend';
import { FormattedMessage } from 'react-intl';

class SignatoryDashboardCard extends React.Component {

    render() {
        return (
            <FormColumn widthLg={(12 / this.props.cols_lg).toString()} widthMd={(12 / this.props.cols_md).toString()}>
                <div className="service">
                    <h4>
                        <div className={this.props.icon}></div>
                        {this.props.title}
                    </h4>
                    <div className="text-wrapper">
                        <p>
                            {this.props.description}
                        </p>
                    </div>
                    <div>
                        <a href={this.props.url} className="go-to-service create-creditsafe-account" id={this.props.elementButtonId}>
                            <FormattedMessage id="services.bol.registerCreditsafe.dashboard.buttonTitle" defaultMessage="Register a Creditsafe account" />
                        </a>
                    </div>
                </div>
            </FormColumn>
        );
    }
}

SignatoryDashboardCard.propTypes = {
    cols_lg: PropTypes.number.isRequired,
    cols_md: PropTypes.number.isRequired,
    description: PropTypes.array.isRequired,
    elementButtonId: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
}

export default SignatoryDashboardCard;
