import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import getDeleteBtnClickedHandler from '../../actions/actionCreators/users/GetDeleteBtnClickedHandler.js';
import getRemovePermissionsBtnClickedHandler from '../../actions/actionCreators/users/GetRemovePermissionsBtnClickedHandler.js';
import getResendInvitationBtnClickedHandler from '../../actions/actionCreators/users/GetResendInvitationBtnClickedHandler.js';
import clearFilterBtnClickedHandler from '../../actions/actionCreators/users/ClearFilterBtnClickedHandler.js';
import okBtnClicked from '../../actions/actionCreators/generic/confirmationModal/OkBtnClicked.js';
import AddingUserMessage from './AddingUserMessage.jsx';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import { Link } from 'react-router';
import { Message, PageHeader, FormRow, FormTable, FormColumn, FormGroup, InputLabel, TextInput, SelectInput, TypeaheadInputComponent,
    ConfirmationModal, emailValidator, DateLabel } from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    confirmationModalTitle: {
        id: 'users.list.remove.permissions.confirmation.modal.title',
        description: 'Confirmation modal title',
        defaultMessage: 'Remove user'
    },
    confirmationModalOk: {
        id: 'users.list.remove.permissions.confirmation.modal.ok',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, remove the permissions'
    },
    confirmationModalYesContinue: {
        id: 'users.list.remove.permissions.confirmation.modal.yesContinue',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, continue'
    },
    confirmationModalCancel: {
        id: 'users.list.remove.permissions.confirmation.modal.cancel',
        description: 'Confirmation modal cancel button text',
        defaultMessage: 'No, cancel'
    },
    selectedFastPermissionGlobal: {
        id: 'users.list.selectedFastPermissionGlobal',
        description: 'Selected tab permission name',
        default: 'Global',
    },
    selectPendingUser: {
        id: 'users.list.selectPendingUser',
        description: 'Selected tab permission name',
        default: 'Pending invitation',
    },
    selectNewUser: {
        id: 'users.list.selectNewUser',
        description: 'New user',
        default: 'New user',
    },
    usersListSelectPlaceholder: {
        id: 'users.list.usersListSelectPlaceholder',
        description: 'Placeholder for custom dropdown',
        default: 'Select user',
    },
    usersListEmpty: {
        id: 'users.list.usersListEmpty',
        description: 'Value displayed when list is empty',
        default: 'List is empty',
    },
    userPendingInvitation: {
        id: 'users.list.pendingInvitation',
        description: 'User name when user is not active',
        default: '(Pending invitation)',
    },
    searchPlaceholder: {
        id: 'users.list.searchInputPlaceholder',
        description: 'Search input placeholder',
        default: 'Name, e-mail or permission',
    },
});

class UserList extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getTabClass(tab) {
        let currenTab = this.state.shared.currentRoute.params.serviceName
                        ? this.state.shared.currentRoute.params.serviceName
                        : this.state.shared.currentRoute.fullPath.split('/').pop();
        return tab === currenTab ? ' active' : '';
    }

    getUserDropdownItems(user, index, idPrefix, permissions, selectedTab, removePermissionButtonMessage) {
        const pathToEditView = '/' + this.state.shared.representedOrganizationCode + '/users/edit/';

        let userDropdownMenu = (
            <div className="dropdown-menu dropdown-menu-right">
                {
                    user.isActive
                        ? null
                        :
                        <a
                            id={idPrefix + "users_list_resend_invitation_link" + index}
                            className="dropdown-item"
                            onClick={getResendInvitationBtnClickedHandler(user.userId)}
                        >
                            <FormattedMessage id="users.list.resendInvitation" defaultMessage="Resend the invitation" />
                        </a>
                }
                <Link
                    id={idPrefix + "users_list_edit_permissions_link" + index}
                    to={pathToEditView + user.userId}
                    className="dropdown-item"
                >
                    <FormattedMessage id="users.list.editPermissions" defaultMessage="Edit permissions" />
                </Link>
                {
                    this.state.users.canInviteMainUser
                        ? <div>
                            <div className="dropdown-divider"></div>
                            {
                                selectedTab == 'all'
                                    ? <a
                                        id={idPrefix + "users_list_delete_link" + index}
                                        href="#"
                                        data-toggle="modal"
                                        data-target="#remove-user-confirmation-modal"
                                        className="dropdown-item"
                                        onClick={getDeleteBtnClickedHandler(user.userId)}
                                      >
                                        <FormattedMessage id="users.list.removeUser" defaultMessage="Remove all permissions from user" />
                                    </a>
                                    :
                                    <a
                                        id={idPrefix + "users_list_delete_link" + index}
                                        href="#"
                                        className="dropdown-item"
                                        onClick={getRemovePermissionsBtnClickedHandler(user.userId, selectedTab)}
                                    >
                                        { selectedTab == permissions.global ? <FormattedMessage id="users.list.removeUserAllGlobalPermissions" defaultMessage="Remove all Global permissions from user" /> : null}
                                        { selectedTab !== permissions.global && selectedTab !== 'all' ? removePermissionButtonMessage : null}
                                    </a>
                            }
                        </div>
                        : null
                }
            </div>
        );
        return userDropdownMenu;
    }

    showUserAfterFiltering(user) {
        const { formatMessage } = this.context.intl;

        let filterValue = this.state.users.filterUsersForm.fields.usersFilterValue.value;

        if (filterValue && filterValue.length) {
            let filterValueLowerCase = filterValue.toLowerCase();

            if ((user.email && user.email.toLowerCase().includes(filterValueLowerCase)) ||
                (user.fullName && user.fullName.toLowerCase().includes(filterValueLowerCase))) {
                return true;
            }

            if (user.globalPermissions && user.globalPermissions.length) {

                let clGlobalPermissions = this.state.shared.classifiers.globalPermissions;

                for (let key in user.globalPermissions) {

                    for (let clPermissionKey in clGlobalPermissions) {

                        if (clGlobalPermissions[clPermissionKey].code === user.globalPermissions[key]) {
                            let permissionText = clGlobalPermissions[clPermissionKey].name;

                            if (permissionText && permissionText.toLowerCase().includes(filterValueLowerCase)) {
                                return true;
                            }
                        }
                    }

                }
            }

            if (user.permissions && user.permissions.length) {
                for (let key of user.permissions) {
                    let permission = this.state.users.constants.userPermissions[key];
                    if (permission) {
                        let permissionText = permission[this.state.shared.authInfo.otherUiInfo.uiLanguage];

                        if (permissionText && permissionText.toLowerCase().includes(filterValueLowerCase)) {
                            return true;
                        }
                    }
                }
            }

            if (user.userRole && user.permissions.length) {
                let userRoleText = formatMessage( { id: "users.list." + user.userRole + "UserText" } );

                if (userRoleText && userRoleText.toLowerCase().includes(filterValueLowerCase)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;
        const permissions = this.state.users.constants.permissions;
        const userTypes = this.state.users.constants.userTypes;

        let configuration = this.state.users.uiConfiguration;
        let customerSupport = configuration ? configuration['customerSupportEmail'] : '';
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;

        let selectedTab = this.state.shared.currentRoute.params.serviceName
                        ? this.state.shared.currentRoute.params.serviceName
                        : this.state.shared.currentRoute.fullPath.split('/').pop();

        let pathToUsersListView = '/' + this.state.shared.representedOrganizationCode + '/users/list/';
        let pathToAddUserView = '/' + this.state.shared.representedOrganizationCode + '/users/add/';

        let form = this.state.users.quickPermissionsGrantForm;
        let fields = form ? form.fields : null;

        let filterUsersForm = this.state.users.filterUsersForm;

        let userOpenedHisProfileModal = this.state.users.userModalData
            && this.state.shared.profile
            && this.state.users.confirmationModalUserId === this.state.shared.profile.personId;

        let usersInDropdown = this.state.users.removedUsersFromList.map((user, index) => {
            var option = {};
            option['id'] = index;
            option['label'] = user.isActive ? user.fullName : formatMessage(messages.selectPendingUser);
            option['email'] = user.email;
            return option;
        });

        usersInDropdown.unshift({
            "id": "0",
            "label": formatMessage(messages.selectNewUser)
        });

        let emailParser = (result) => {
            return result[0] ? result[0].email: '';
        };

        let users = [];
        let allUsers = this.state.users.userList;

        allUsers.forEach((user) => {

            user.fullName = user.maskUserInfo ? user.fullName : user.isActive ? user.lastName + ', ' + user.firstName : formatMessage(messages.userPendingInvitation);

            if (this.showUserAfterFiltering(user)) {
                users.push(user);
            }
        });

        let permissionNameDisplay = (permission, index, isMainRepresentationForUser, key) => {

            for (var serviceName in configuration.services) {
                let service = configuration.services[serviceName];
                let joinedByPermissionService = service.joinedByPermission && configuration.services[service.joinedByPermission]
                    ? configuration.services[service.joinedByPermission]
                    : null;
                let joinedPermissionTitle = joinedByPermissionService && selectedTab == service['permission']
                    ? joinedByPermissionService['title'][currentLanguageCode]
                    : '';
                let showNotSuportedPermissionWarning = !isMainRepresentationForUser && this.state.users.servicesManagedByCurrentUser.serviceIsLimitedToOneRepresentedOrg[serviceName];
                let markPermissionAsInactiveInOrg = !this.state.users.servicesManagedByCurrentUser.permissionIsActiveInOrg[serviceName];
                let showNotSuportedJoinedPermissionWarning = joinedByPermissionService ? !isMainRepresentationForUser && this.state.users.servicesManagedByCurrentUser.serviceIsLimitedToOneRepresentedOrg[joinedByPermissionService] : false;

                let notSupportedUserPermissionWarning = (
                    <span>
                        <a id={"not_supported_permission_warning_link_" + permission + "_" + index} href={this.state.users.notSupportedUserPermissionsWarningUrl}>
                            <span className="fa fa-ml fa-question-circle"></span>
                        </a>
                    </span>
                );

                if (permission == service['permission'] && (
                    selectedTab == service['permission'] || selectedTab === 'all'
                )) {
                    return (
                        <div className="small" key={key}>
                            <div className={showNotSuportedPermissionWarning || markPermissionAsInactiveInOrg ? 'strikethrough': null}>
                                {service['title'][currentLanguageCode]}
                                {showNotSuportedPermissionWarning ? notSupportedUserPermissionWarning : null}
                            </div>
                            {joinedPermissionTitle
                            ?
                                <div className={showNotSuportedJoinedPermissionWarning ? 'strikethrough': null}>
                                    {joinedPermissionTitle}
                                    {showNotSuportedJoinedPermissionWarning ? notSupportedUserPermissionWarning : null}
                                </div>
                            : null
                            }
                        </div>
                    );
                }
            }
        };

        let permissionsManagedBySuperAdmin = this.state.shared.classifiers.permissionsManagedBySuperAdmin;

        let serviceQuickGrantPermissionTabs = [];

        let currentlySelectedPermissionTabMessage = null;
        let currentlySelectedPermissionTabTableHeader = null;
        let removePermissionButtonMessage = null;
        let servicePermissionQuickGrantSelectOption = [];
        let tabIsVisible = true;
        let permissionCanBeManagedByCurrentUserPermission = true;
        let tabWithOnlyLink =  null;
        let tabWithOnlyLinkServiceName = null;
        let tabWithOnlyLinkId = null;
        let tabWithOnlyLinkUrl = null;

        for (var serviceName in configuration.services) {
            let service = configuration.services[serviceName];

            tabIsVisible = this.state.users.servicesManagedByCurrentUser.visibleTabs && this.state.users.servicesManagedByCurrentUser.visibleTabs[serviceName];
            let permissionManagedByOtherService = service['permissionManagedByOtherService'] ? service['permissionManagedByOtherService'] : false;
            permissionCanBeManagedByCurrentUserPermission = this.state.users.servicesManagedByCurrentUser.permissionCanBeManagedByCurrentUser && this.state.users.servicesManagedByCurrentUser.permissionCanBeManagedByCurrentUser[serviceName];
            let id = "users_list_tab_" + serviceName;
            let joinedByPermissionService = service.joinedByPermission && configuration.services[service.joinedByPermission] ? configuration.services[service.joinedByPermission] : null;
            let serviceTitle = joinedByPermissionService
                ? service['title'][currentLanguageCode] + ', ' + joinedByPermissionService['title'][currentLanguageCode]
                : service['title'][currentLanguageCode];
            let tabClassAndPath = '';

            if (tabIsVisible && !permissionManagedByOtherService) {
                if (permissionCanBeManagedByCurrentUserPermission) {

                    if (selectedTab == service['permission']) {
                        let messageValues = {serviceName: serviceTitle};

                        currentlySelectedPermissionTabMessage = (<FormattedMessage id="users.list.selectedTabExplanation" defaultMessage="Add and remove users of service." values={messageValues} />);
                        currentlySelectedPermissionTabTableHeader = (<FormattedMessage id="users.list.selectedTabTablePermission" defaultMessage="{serviceName} permissions" values={messageValues} />);
                        removePermissionButtonMessage = (<FormattedMessage id="users.list.removeUserSelectedTabPermissions" defaultMessage="Remove all {serviceName} permissions from user" values={messageValues} />);

                        servicePermissionQuickGrantSelectOption.push({
                            value: serviceName,
                            displayedText: serviceTitle
                        });
                    }

                    tabClassAndPath = service['permission'];
                } else {
                    tabClassAndPath = 'link_to_'+ serviceName;
                    tabWithOnlyLink = tabClassAndPath;
                    tabWithOnlyLinkServiceName = serviceTitle;
                    tabWithOnlyLinkId = 'users_list_' + serviceName + '_link';
                    tabWithOnlyLinkUrl = service['url'] + service['url_to_users_management'];
                }

                serviceQuickGrantPermissionTabs.push(
                    <Link
                        id={id}
                        key={id}
                        to={pathToUsersListView + tabClassAndPath}
                        className={"nav-link" + this.getTabClass(tabClassAndPath)}
                    >
                        {serviceTitle}
                    </Link>
                );
            }
        }

        if (this.state.users.canViewAllPermissionsTab) {
            serviceQuickGrantPermissionTabs.splice(0, 0, (
                <Link
                    id="users_list_tab_all"
                    key="users_list_tab_all"
                    to={pathToUsersListView + 'all'}
                    className={"nav-link" + this.getTabClass('all')}
                >
                    <FormattedMessage id="users.list.allTab" defaultMessage="All" />
                </Link>
            ));
        }

        if (this.state.users.canManageGlobalPermissions) {
            serviceQuickGrantPermissionTabs.push(
                <Link
                    id="users_list_tab_global"
                    key="users_list_tab_global"
                    to={pathToUsersListView + permissions.global}
                    className={"nav-link" + this.getTabClass(permissions.global)}
                >
                    <FormattedMessage id="users.list.globalTab" defaultMessage="Global" />
                </Link>
            );
        }

        if (selectedTab === permissions.global) {
            for (let key in this.state.shared.classifiers.globalPermissions) {
                let item = this.state.shared.classifiers.globalPermissions[key];
                if (permissionsManagedBySuperAdmin.indexOf(item.code) == -1
                    || (permissionsManagedBySuperAdmin.indexOf(item.code) > -1 && this.state.users.canManageAdminAndQATPermissions)) {
                    servicePermissionQuickGrantSelectOption.push({
                        value: item.code,
                        displayedText: item.name
                    });
                }
            }
        }

        // emailValidator() Returns null if valid
        let showUsersListQuickAddLink = filterUsersForm && filterUsersForm.fields.usersFilterValue.value && !emailValidator(filterUsersForm.fields.usersFilterValue.value);

        return (
            <div className="users-management">
                <PageHeader
                    pageTitleMessageId={this.state.users.canInviteNewUsers ? "users.management.title" : "users.management.title.noedit"}
                    values={{organizationName: this.state.shared.representedOrgName}}
                    sharedStore={this.state.shared}
                />
                { this.state.users.showSignatoryRegisteredThroughEmailInvitationMessage
                    ?
                        <Message id="successMessage" type="warning">
                            <FormattedMessage id="users.list.signatoryRegisteredThroughEmailInvitation.message" defaultMessage="Check users list and assign corresponding user type and permissions." />
                        </Message>
                    : null
                }
                { this.state.users.showDeletedSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="users.list.remove.user.success.message"
                                defaultMessage="User {firstName} {lastName} ({email}) was removed from the users of {organizationName}"
                                values={this.state.users.userModalData}
                            />
                        </Message>
                    : null
                }
                { this.state.users.showPermissionsRemovedSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="users.list.remove.permissions.success.message"
                                defaultMessage="Permissions were successfully removed"
                            />
                        </Message>
                    : null
                }
                { this.state.users.showUpdatedSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="users.list.updated.success.message"
                                defaultMessage="User updated successfully"
                            />
                        </Message>
                    : null
                }
                { this.state.users.showPermissionsGrantedSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="users.list.updatedUserPermissions.success.message"
                                defaultMessage="User permissions updated successfully"
                            />
                        </Message>
                    : null
                }

                { this.state.users.showInvitationResendSuccessMessage
                    ?
                        <Message id="invitationResendSuccessMessage" type="success">
                            <FormattedMessage
                                id="users.list.resendInvitation.success.message"
                                defaultMessage="Invitation was successfully resent"
                            />
                        </Message>
                    : null
                }

                { this.state.users.showApiAccountSuccessMessage
                    ?
                        <Message id="ApiAccountSuccessMessage" type="success">
                            <FormattedMessage
                                id="users.list.creatingApiAccount.successMessage"
                                defaultMessage="API account created successfully"
                            />
                        </Message>
                    : null
                }

                {this.state.users.showUserAlreadyBelongsToOrgMessage
                    ?
                        <Message id="userAllreadyExistInOrg" type="success">
                            <FormattedMessage
                                id="users.list.successUserAlreadyExistsInOrg"
                                defaultMessage="User {email} already belongs to company."
                                values={{'email': <strong>{this.state.users.showUserAlreadyBelongsToOrgMessage}</strong>}}
                            />
                        </Message>
                    : null
                }

                <ConfirmationModal
                    id="remove-user-confirmation-modal"
                    title={formatMessage(messages.confirmationModalTitle)}
                    okButtonText={formatMessage(userOpenedHisProfileModal ? messages.confirmationModalYesContinue : messages.confirmationModalOk)}
                    cancelButtonText={formatMessage(messages.confirmationModalCancel)}
                    handleActionType={okBtnClicked}
                    sharedStore={this.state.users.shared}
                >
                    {userOpenedHisProfileModal
                        ?
                            <FormattedMessage
                                id="users.list.remove.yourPermissions.confirmation.modal.message"
                                defaultMessage="You are about to drop ALL your permissions to use and manage {organizationName}. Do you really want to continue?"
                                values={this.state.users.userModalData}
                            />
                        :
                        <FormattedMessage
                            id="users.list.remove.permissions.confirmation.modal.message"
                            defaultMessage="Do you really want to remove all {organizationName} permissions from user {firstName} {lastName} ({email})?"
                            values={this.state.users.userModalData}
                        />
                    }
                </ConfirmationModal>

                <AddingUserMessage
                    showMessage={this.state.users.showInvitedSuccessMessage}
                    messageType={this.state.users.messageType}
                    addedUsersList={this.state.users.addedUsersList}
                    skippedAlreadyInOrgUsersList={this.state.users.skippedAlreadyInOrgUsersList}
                    skippedWithManyOrgUsersList={this.state.users.skippedWithManyOrgUsersList}
                    allAdded={this.state.users.allAdded}
                    someAddedSomeSkipped={this.state.users.someAddedSomeSkipped}
                    allAlreadyAddedSkipped={this.state.users.allAlreadyAddedSkipped}
                    allAddedAndError={this.state.users.allAddedAndError}
                    someAddedSomeSkippedAndError={this.state.users.someAddedSomeSkippedAndError}
                    allAlreadyAddedSkippedAndError={this.state.users.allAlreadyAddedSkippedAndError}
                    allHaveTooManyOrg={this.state.users.allHaveTooManyOrg}
                    someUsersWereSkipped={this.state.users.someUsersWereSkipped}
                    customerSupport={customerSupport}
                />

                {serviceQuickGrantPermissionTabs.length > 1
                    ?
                        <nav className="nav nav-pills flex-column flex-md-row mb-4" role="navigation">
                            {serviceQuickGrantPermissionTabs}
                        </nav>
                    : null
                }

                { selectedTab === tabWithOnlyLink
                    ?
                        <p className="lead clearfix">
                            <FormattedMessage id="user.list.addAndRemoveUsersOf" defaultMessage="Add and remove users of" />
                            &nbsp;
                            <a
                                id={tabWithOnlyLinkId}
                                href={tabWithOnlyLinkUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {tabWithOnlyLinkServiceName}
                            </a>
                        </p>
                    :
                    <div>
                        <p className="lead">

                            {currentlySelectedPermissionTabMessage}

                            {selectedTab === permissions.global
                                ? <FormattedMessage id="users.list.selectedTabelExplanationGlobal" defaultMessage="Add and remove global permissions to users." />
                                : null
                            }
                            {selectedTab === 'all' ?
                                <FormattedMessage
                                    id={this.state.users.canEditUserPermissions ? "users.list.explanation" : "users.list.explanation.noedit"}
                                    defaultMessage={this.state.users.canEditUserPermissions ? "Change and remove users and permissions" : "See users and permissions"}
                                />
                            : null }
                        </p>
                        { form && this.state.users.showFastPermissionAssignment
                            ?
                                <form>
                                    <div className="row justify-content-end">
                                        <div className="col-md-4">
                                            <FormGroup>
                                                <SelectInput
                                                    inputClassName="form-control-sm"
                                                    id="users_list_select_permission"
                                                    field={fields.permission}
                                                    options={servicePermissionQuickGrantSelectOption}
                                                    messageId="users.list.selectPermission"
                                                    defaultMessage="Permission shown"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-4">
                                            <FormGroup>
                                                <InputLabel
                                                    htmlFor="users_list_select_user"
                                                    messageId="users.list.selectUser"
                                                    defaultMessage="Grant to user"
                                                />
                                                <TypeaheadInputComponent
                                                    align="left"
                                                    name='users_list_select_user'
                                                    onChange={fields.email.getOnChangeHndler(emailParser)}
                                                    disabled={this.state.users.removedUsersFromList.length ? false : true}
                                                    options={usersInDropdown}
                                                    multiple={false}
                                                    selected={usersInDropdown.length ? [usersInDropdown[0]] : []}
                                                    emptyLabel={formatMessage(messages.usersListEmpty)}
                                                    placeholder={formatMessage(messages.usersListSelectPlaceholder)}
                                                    maxResults={1000}
                                                    size="small"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col col-auto">
                                            <FormGroup>
                                                <div className="invisible hidden-sm-down">
                                                    <label className="form-control-label" htmlFor="users_list_select_permission">&nbsp;</label>
                                                </div>
                                                <button
                                                    type="button"
                                                    className="btn btn-sm btn-primary"
                                                    id="users_list_give_permission"
                                                    onClick={form.handleOnSubmitBtnClicked}
                                                    disabled={this.state.users.fastPermissionAssignmentBtnDisabled}
                                                    role="button"
                                                >
                                                    <FormattedMessage id="users.list.grantFastPermissionBtn" defaultMessage="Add" />
                                                </button>
                                            </FormGroup>
                                        </div>
                                    </div>
                                </form>
                            : null
                        }
                        { filterUsersForm
                            ? (
                                <div>
                                    <div id="users-filter" className="card card-default mb-0">
                                        <div className="card-block">
                                            <form>
                                                <FormRow>
                                                    <FormColumn widthLg="6" widthMd="6">
                                                        <div className="form-group mb-0">
                                                            <TextInput
                                                                id="users_list_filter_input"
                                                                field={filterUsersForm.fields.usersFilterValue}
                                                                inputGroup={true}
                                                                addonPossition="left"
                                                                autoFocus={true}
                                                                placeholder={formatMessage(messages.searchPlaceholder) }
                                                            >
                                                                <span className="input-group-addon text-primary bg-white">
                                                                    <i id="filter-icon" className="fa fa-search" aria-hidden="true"></i>
                                                                </span>
                                                            </TextInput>
                                                        </div>
                                                    </FormColumn>
                                                </FormRow>
                                            </form>
                                        </div>
                                    </div>
                                    { filterUsersForm.fields.usersFilterValue.value.length
                                        ?
                                            <div role="button" id="clear-filters" className="text-primary text-right mb-6 mt-2 bg-white" onClick={clearFilterBtnClickedHandler}>
                                                <i className="fa fa-trash-o fa-mr" aria-hidden="true"></i>
                                                <FormattedMessage id="users.list.clearFilters" defaultMessage="Clear filters" />
                                            </div>
                                        : null
                                    }
                                </div>
                            )
                            : null
                        }


                        { this.state.users.showInviteUserButton && this.state.users.canInviteNewUsers
                            ?
                                <div className="text-right lead">
                                    <a
                                        className={ this.state.users.canInviteNewUsers ? "" : "disabled" }
                                        id="users_list_add_new_user_link"
                                        href={ this.state.users.canInviteNewUsers ? this.state.users.addUserPageUrl : null }
                                    >
                                        <i className="fa fa-plus fa-mr"></i>
                                        <FormattedMessage id="users.list.addUser" defaultMessage="Add user" />
                                    </a>
                                </div>
                            : null
                        }
                        { this.state.users.showCreateNewApiAccountButton
                            ?
                                <div className="text-right lead">
                                    <a
                                        id="users_list_add_new_api_user_link"
                                        href={this.state.users.createNewApiAccountPageUrl}
                                    >
                                        <i className="fa fa-plus fa-mr"></i>
                                        <FormattedMessage id="users.list.newApiUser" defaultMessage="New API account" />
                                    </a>
                                </div>
                            : null
                        }

                        {!allUsers.length
                            ?
                                <p className="text-primary">
                                    <i className="fa fa-info-circle fa-mr" aria-hidden="true"></i>
                                    <FormattedMessage id="users.list.noUsersWithSelectedPermission" defaultMessage="There are no users with selected permission" />
                                </p>
                            : null
                        }

                        {allUsers.length && !users.length
                            ?
                                <Message id="no-users-found-list" type="warning">
                                    <FormattedMessage
                                        id="users.list.noUsersFoundMessage"
                                        defaultMessage="No users found."
                                    />
                                    &nbsp;
                                    { showUsersListQuickAddLink && this.state.users.canInviteNewUsers
                                        ?
                                            <Link
                                                id="users_list_quick_add"
                                                to={pathToAddUserView + filterUsersForm.fields.usersFilterValue.value}
                                            >
                                                <FormattedMessage
                                                    id="users.list.listQuickAddUser"
                                                    defaultMessage="Add user"
                                                    values={{email: filterUsersForm.fields.usersFilterValue.value}}
                                                />
                                            </Link>
                                        : null
                                    }
                                </Message>
                            : null
                        }

                        {users.length
                            ? (
                                <div className="mt-6">
                                    <p className="text-primary">
                                        <i className="fa fa-info-circle fa-mr" aria-hidden="true"></i>
                                        <FormattedMessage
                                            id="users.list.numberOfUsers"
                                            defaultMessage="Number of users: {users}"
                                            values={{users: users.length}}
                                        />
                                    </p>
                                    <FormTable id="users_list" className="form-table-striped form-table-hover mt-4">
                                        <FormRow header={true}>
                                            <FormColumn width={ selectedTab === 'all' ? '4' : '6' }>
                                                <a id="user_column">
                                                    <FormattedMessage id="users.list.title.user" defaultMessage="User" />
                                                </a>
                                            </FormColumn>
                                            {selectedTab === 'all'
                                                ? (
                                                    <FormColumn width="3">
                                                        <a id="user_type_column">
                                                            <FormattedMessage id="users.list.title.userType" defaultMessage="User type" />
                                                        </a>
                                                    </FormColumn>
                                                )
                                                : null
                                            }
                                            <FormColumn width={ selectedTab === 'all' ? '4' : '5' }>
                                                <a id="permissions_column">
                                                    {
                                                        selectedTab !== 'all' && selectedTab !== permissions.global
                                                        ? currentlySelectedPermissionTabTableHeader
                                                        : selectedTab === permissions.global
                                                        ? <FormattedMessage id="users.list.title.globalPermissions" defaultMessage="Global permissions" />
                                                        : selectedTab === 'all'
                                                        ? <FormattedMessage id="users.list.title.permissions" defaultMessage="Permissions" />
                                                        : null

                                                    }
                                                </a>
                                            </FormColumn>
                                            { this.state.users.canEditUserPermissions ?
                                                <FormColumn width="1" className="text-center">
                                                    <a>
                                                        <i className="fa fa-gear" aria-hidden="true"></i>
                                                    </a>
                                                </FormColumn>
                                            : null }
                                        </FormRow>
                                        {users.map((u, i) => {
                                            return (
                                                <FormRow key={i} role={this.state.users.canEditUserPermissions ? "button" : null}>
                                                    <FormColumn mobileHeader={true} width="12">
                                                        <FormattedMessage id="users.list.title.user" defaultMessage="User" />
                                                    </FormColumn>
                                                    <FormColumn width={ selectedTab === 'all' ? '4' : '6' } onClick={u.handleRowClick}>
                                                        <div className="pull-left">
                                                            <p id={"users_list_name_" + i} className={u.isActive ? 'font-weight-bold':''}>{u.fullName}</p>
                                                            <p id={"users_list_email_" + i}  className="small text-muted">{u.email}</p>
                                                            { !u.isActive && u.firstInvitedAt ?
                                                                <p id="users_list_first_invited_at" className="small text-muted">
                                                                    <FormattedMessage
                                                                        id="users.list.title.firstinvited"
                                                                        defaultMessage="First invited at"
                                                                    />:&nbsp;
                                                                    <DateLabel id="first-invited-at" format="YYYY-MM-DD HH:mm">{u.firstInvitedAt}</DateLabel>
                                                                </p>
                                                            :
                                                                null
                                                            }
                                                        </div>
                                                        <div className="pull-right hidden-md-up" onClick={(e) => e.stopPropagation()}>
                                                            <div id="mobile_users_list_options_dropdown" className="dropdown">
                                                                <button role="button" className="btn btn-link btn-sm py-1 px-3" id={"mobile_users_list_options_dropdown_" + i} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fa fa-cog p-0 fa-lg text-secondary" aria-hidden="true"></i>
                                                                </button>
                                                                {this.getUserDropdownItems(u, i, 'mobile_', permissions, selectedTab, removePermissionButtonMessage)}
                                                            </div>
                                                        </div>
                                                        <div className="clearfix"></div>
                                                    </FormColumn>
                                                    {selectedTab === 'all'
                                                        ? (
                                                            <FormColumn mobileHeader={true} width="12">
                                                                <FormattedMessage id="users.list.title.userType" defaultMessage="User type" />
                                                            </FormColumn>
                                                        )
                                                        : null
                                                    }
                                                    {selectedTab === 'all'
                                                        ? (
                                                            <FormColumn width="3" onClick={u.handleRowClick}>
                                                                <p id={'users_list_user_type_' + i}>
                                                                    { u.userRole === userTypes.main
                                                                        ? <FormattedMessage id="users.list.mainUserText" defaultMessage="Main user" />
                                                                        : u.userRole === userTypes.basic
                                                                        ? <FormattedMessage id="users.list.basicUserText" defaultMessage="Basic user" />
                                                                        : u.userRole === userTypes.inactive
                                                                        ? <FormattedMessage id="users.list.inactiveUserText" defaultMessage="Inactive user" />
                                                                        : null
                                                                    }
                                                                </p>
                                                            </FormColumn>
                                                        )
                                                        : null
                                                    }
                                                    <FormColumn mobileHeader={true} width="12">
                                                        {
                                                            selectedTab !== 'all' && selectedTab !== permissions.global
                                                            ? currentlySelectedPermissionTabTableHeader
                                                            : selectedTab === permissions.global
                                                            ? <FormattedMessage id="users.list.title.globalPermissions" defaultMessage="Global permissions" />
                                                            : selectedTab === 'all'
                                                            ? <FormattedMessage id="users.list.title.permissions" defaultMessage="Permissions" />
                                                            : null
                                                        }
                                                    </FormColumn>
                                                    <FormColumn id={"users_list_permission_" + i} width={ selectedTab === 'all' ? '3' : '4' }>
                                                        {
                                                            u.permissions && u.permissions.length
                                                            ? u.permissions.map((p,j) => (
                                                                permissionNameDisplay(p, i, u.isMainRepresentationForUser, j)
                                                            ))
                                                            : null
                                                        }
                                                        {
                                                            this.state.users.canViewGlobalPermissions
                                                                && (selectedTab === permissions.global || selectedTab === 'all')
                                                                && u.globalPermissions
                                                            ? u.globalPermissions.map((p, index) => (
                                                                this.state.shared.classifiers.globalPermissions.map((value) => (
                                                                    value.code === p
                                                                    ?
                                                                        <p id={"users_list_user_" + i + "_global_permission_" + index} className="small" key={index}>
                                                                            {value.name}
                                                                            &nbsp;
                                                                            <FormattedMessage id="users.list.globalpermissionExplanation" defaultMessage="(global permission)" />
                                                                        </p>
                                                                    : null
                                                                ))
                                                            ))
                                                            : null
                                                        }
                                                    </FormColumn>
                                                    { this.state.users.canEditUserPermissions ?
                                                        <FormColumn width="2" className="text-right hidden-sm-down">
                                                            <div id="users_list_options_dropdown" className="dropdown">
                                                                <button disabled={!this.state.users.canEditUserPermissions} role="button" className="btn btn-primary btn-sm" id={"users_list_options_dropdown_" + i} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <i className="fa-fw fa fa-caret-down pr-0" aria-hidden="true"></i>
                                                                </button>
                                                                {this.getUserDropdownItems(u, i, '', permissions, selectedTab, removePermissionButtonMessage)}
                                                            </div>
                                                        </FormColumn>
                                                    : null }
                                                </FormRow>
                                            );
                                        })}
                                    </FormTable>
                                </div>
                            )
                                : null
                        }
                    </div>
                }
            </div>
        );

    }
}

export default UserList;
