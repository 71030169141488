import FormStore from '../base/FormStore.js';

import prepareUrl from '../../helpers/PrepareUrl.js';
import ActionTypes from '../../actions/ActionTypes.js';
import beginEmailChangeVerification from '../../actions/actionCreators/profile/emailVerification/BeginEmailChangeVerification.js';
import validateEmailVerificationToken from '../../actions/actionCreators/profile/emailVerification/ValidateEmailVerificationToken.js';
import finishEmailChangeVerification from '../../actions/actionCreators/profile/emailVerification/FinishEmailChangeVerification.js';
import finishEmailChangeVerificationAfterBankId from '../../actions/actionCreators/profile/emailVerification/finishEmailChangeVerificationAfterBankId.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import {requiredValidator, getMaxLengthValidator, getMatchValidator, passwordValidator} from '@vaultit/stv-utils-frontend';
import storeFormManager from '../validation/StoreFormManager.js';
import constants from '../../Constants.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';

const storeKey = 'ChangeEmailVerification';


class ChangeEmailVerificationStore extends FormStore {

    getInitialState() {
        let initialState =  {
            showLoadingIndicator: false,
            updatedUsername: '',
            visibleElements: {},
            uiConfiguration: {},
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            usernameWhichInitiated: null
        };

        let changeEmailFormFieldDefinitions = {
            currentPassword: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits)
                ],
                isSensitiveInformation: true
            },
        };

        let changeEmailSetPasswordFormFieldDefinitions = {
            newPassword: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            newPasswordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    {
                        validateOnOtherFieldChange: 'newPassword',
                        validator: getMatchValidator('newPassword')
                    }
                ],
                isSensitiveInformation: true
            },
        };

        storeFormManager.defineForm(initialState, storeKey, 'changeEmailForm', changeEmailFormFieldDefinitions, this.onChangeEmailFormSubmitted);
        storeFormManager.defineForm(initialState, storeKey, 'changeEmailSetPasswordForm', changeEmailSetPasswordFormFieldDefinitions, this.onChangeEmailSetPasswordFormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/emailchange/begin/:token',
                loadHandler: this.onEmailChangeBeginRouteLoaded
            },
            {
                fullPath: '/profile/emailchange/finish/:token',
                loadHandler: this.onEmailChangeFinishRouteLoaded
            },
            {
                fullPath: '/profile/emailchange/set-new-password/:token',
                loadHandler: this.onEmailChangeSetPasswordRouteLoaded
            },
            {
                fullPath: '/profile/emailchange/success',
                loadHandler: this.onEmailSuccessRouteLoaded
            }
        ];
    }

    onEmailChangeBeginRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
        storeFormManager.setFormInitialState(state, 'changeEmailForm');
        beginEmailChangeVerification(sharedStoreState.currentRoute.params.token);
    }

    onEmailChangeFinishRouteLoaded(state) {
        storeFormManager.setFormInitialState(state, 'changeEmailForm');
        state.visibleElements = {
            passwordVerificationNeeded: true
        };
    }

    onEmailChangeSetPasswordRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        validateEmailVerificationToken(sharedStoreState.currentRoute.params.token);
    }


    onEmailSuccessRouteLoaded(state, action, sharedStoreState) {
      state.showLoadingIndicator = false;
      let passwordVerificationNeeded = JSON.parse(sessionStorage.getItem('passwordVerificationNeeded'));
      state.visibleElements = {
          passwordVerificationNeeded: passwordVerificationNeeded == null ? true : false,
          emailChangeSuccessMessageAndContinueBtn: JSON.parse(sessionStorage.getItem('emailChangeSuccessMessageAndContinueBtn')),
          showWarningAboutCompanyDataWithOldEmail: sharedStoreState.authInfo.topMenuParameters.configuration.showWarningAboutCompanyDataWithOldEmail ? JSON.parse(sessionStorage.getItem('showWarningAboutCompanyDataWithOldEmail')) : false
      };
    }

    onChangeEmailFormSubmitted(state, values, sharedStoreState) {
        state.showChangedEmailDuplicateMessage = false;
        state.showChangedEmailInvalidPasswordMessage = false;
        state.showChangedEmailPasswordSameAsPreviousMessage = false;
        state.showLoadingIndicator = true;
        finishEmailChangeVerification(sharedStoreState.currentRoute.params.token, values);
    }

    onChangeEmailSetPasswordFormSubmitted(state, values, sharedStoreState) {
        state.showChangedEmailDuplicateMessage = false;
        state.showChangedEmailInvalidPasswordMessage = false;
        state.showChangedEmailPasswordSameAsPreviousMessage = false;
        state.showLoadingIndicator = true;
        finishEmailChangeVerificationAfterBankId(sharedStoreState.currentRoute.params.token, values);
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_TOKEN_VERIFICATION_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        if (action.result && action.result.needsLogoff) {
            state.usernameWhichInitiated = action.result.username;
            state.visibleElements = {
                userMismatch: true
            };
        } else if (action.result && action.result.ninValidationFailed) {
            state.usernameWhichInitiated = action.result.username;
            state.visibleElements = {
                ninValidationFailed: true
            };
        } else {
            storeFormManager.setFormInitialState(state, 'changeEmailSetPasswordForm');
            state.visibleElements = {
                setPasswordNeeded: true
            };
        }

    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_BEGIN_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        if (action.result.verificationBeginOk) {
            let url = prepareUrl.prepareRouteUrl('/profile/emailchange/finish/' + action.token, false);
            let returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, url);
            redirectToUrl(returnUrl, true, true);
        } else if (action.result.needsLogoff) {
            let url = prepareUrl.prepareRouteUrl('/profile/emailchange/finish/' + action.token, false);
            let returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, url);
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            let signOutUrlWithReturnUrl = prepareUrl.getSignOutRelativeUrl(returnUrlEncoded);
            state.visibleElements = {};
            redirectToUrl(signOutUrlWithReturnUrl);
        } else if (action.result.alreadyVerified) {
            state.userIsAlreadyVerified = true;
            state.forgotPasswordLink = `${sharedStoreState.authInfo.otherUiInfo.frontendUrl}profile/forgot-password`;
        } else if (action.result && action.result.ninValidationFailed) {
            state.usernameWhichInitiated = action.result.username;
            state.visibleElements = {
                ninValidationFailed: true
            };
        }
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_BEGIN_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_FINISH_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        this.finishEmailChange(state, action, sharedStoreState);
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_FINISH_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_CONTINUE_AFTER_CHANGE_BTN_CLICKED](state, action, sharedStoreState) {
        sessionStorage.removeItem('passwordVerificationNeeded');
        sessionStorage.removeItem('emailChangeSuccessMessageAndContinueBtn');
        if (sharedStoreState.authInfo.topMenuParameters.configuration.showWarningAboutCompanyDataWithOldEmail) {
            sessionStorage.removeItem('showWarningAboutCompanyDataWithOldEmail');
        }
        redirectToRoute("/", true);
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_BANKID_BTN_CLICKED](state, action, sharedStoreState) {
        this.prepareUrlToRedirectToStrongAuthAndRedirect(state, action, sharedStoreState, 'bankid_provision');
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_FREJA_EID_SWEDISH_BTN_CLICKED](state, action, sharedStoreState) {
        this.prepareUrlToRedirectToStrongAuthAndRedirect(state, action, sharedStoreState, 'freja_eid_provision');
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_FREJA_EID_NON_SWEDISH_BTN_CLICKED](state, action, sharedStoreState) {
        this.prepareUrlToRedirectToStrongAuthAndRedirect(state, action, sharedStoreState, 'freja_eid_ext_provision');
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_DOKOBIT_BTN_CLICKED](state, action, sharedStoreState) {
        this.prepareUrlToRedirectToStrongAuthAndRedirect(state, action, sharedStoreState, 'dokobit_identitygw_provision');
    }

    prepareUrlToRedirectToStrongAuthAndRedirect(state, action, sharedStoreState, acrValue) {
        let token = sharedStoreState.currentRoute.params.token;

        let returnUrl = prepareUrl.prepareRouteUrl(
            `/profile/emailchange/set-new-password/${token}`,
            true,
            sharedStoreState.currentRoute.params.organizationCode
        );
        returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
        let returnUrlEncoded = encodeURIComponent(returnUrl);
        let personId = sharedStoreState.authInfo && sharedStoreState.authInfo.userProfile && sharedStoreState.authInfo.userProfile.personId ? sharedStoreState.authInfo.userProfile.personId : '';
        let redirectUrl = '';

            if (personId) {
                redirectUrl = prepareUrl.getStrongAuthRelativeUrl(
                    sharedStoreState.authInfo.topMenuParameters.configuration,
                    personId,
                    returnUrlEncoded,
                    true,
                    acrValue
                );
            } else {
                redirectUrl = prepareUrl.getStrongAuthForAnonymousPath(sharedStoreState.authInfo.topMenuParameters.configuration, returnUrlEncoded, false, acrValue);
            }
        redirectToUrl(redirectUrl, true, true);
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_AFTER_BANKID_FINISH_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        this.finishEmailChange(state, action, sharedStoreState);
    }

    [ActionTypes.PROFILE_CHANGE_EMAIL_VERIFICATION_AFTER_BANKID_FINISH_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    finishEmailChange(state, action, sharedStoreState) {
        if (action.result.verificationEndOk) {
            state.updatedUsername = action.result.username;
            state.visibleElements = {
                passwordVerificationNeeded: false,
                setPasswordNeeded: false,
                userMismatch: false,
                ninValidationFailed: false,
                emailChangeSuccessMessageAndContinueBtn: true
            };
            sessionStorage.setItem('passwordVerificationNeeded', false);
            sessionStorage.setItem('emailChangeSuccessMessageAndContinueBtn', true);
            if (sharedStoreState.authInfo.topMenuParameters.configuration.showWarningAboutCompanyDataWithOldEmail) {
                sessionStorage.setItem('showWarningAboutCompanyDataWithOldEmail', action.result.showWarningAboutCompanyDataWithOldEmail);
            }
            redirectToRoute("/profile/emailchange/success", true);
        } else if (action.result.passwordInvalid) {
            state.showChangedEmailInvalidPasswordMessage = true;
            state.changeEmailForm.fields.currentPassword.value = null;
        } else if (action.result.duplicateEmail) {
            state.showChangedEmailDuplicateEmailMessage = true;
        } else if (action.result.alreadyVerified) {
            state.userIsAlreadyVerified = true;
            state.forgotPasswordLink = `${sharedStoreState.authInfo.otherUiInfo.frontendUrl}profile/forgot-password`;
        } else if (action.result.passwordSameAsPrevious) {
            state.showChangedEmailPasswordSameAsPreviousMessage = true;
            state.changeEmailSetPasswordForm.fields.newPassword.value = null;
            state.changeEmailSetPasswordForm.fields.newPasswordAgain.value = null;
        }
    }

}

export default ChangeEmailVerificationStore;
