import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes';
import storeFormManager from '../validation/StoreFormManager.js';
import searchUsersGlobally from '../../actions/actionCreators/users/SearchUsersGlobally.js';
import getUserGlobalInfo from '../../actions/actionCreators/users/GetUserGlobalInfo.js';
import updateUserInfo from '../../actions/actionCreators/users/UpdateUserInfo.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import {getMinLengthValidator, getMaxLengthValidator, requiredValidator, getBlacklistedEmailValidator, emailValidator} from '@vaultit/stv-utils-frontend';
import getGlobalSearchEmailVerificationLink from '../../actions/actionCreators/users/GetGlobalSearchEmailVerificationLink.js';
import getGlobalSearchPasswordRecoveryLink from '../../actions/actionCreators/users/GetGlobalSearchPasswordRecoveryLink.js';
import changeEmailGenerateVerificationLink from '../../actions/actionCreators/profile/yourDetails/ChangeEmailGenerateVerificationLink.js';
import adminInitiateEmailChangeBtnClicked from '../../actions/actionCreators/users/AdminInitiateEmailChangeBtnClicked.js';
import adminMergesPersons from '../../actions/actionCreators/users/AdminMergesPersons.js';
import constants from '../../Constants.js';
import prepareUrl from '../../helpers/PrepareUrl.js';
import adminChangeEmailCancel from '../../actions/actionCreators/users/AdminChangeEmailCancel.js';
import adminBlockUnblockAccount from '../../actions/actionCreators/users/AdminBlockUnblockAccount.js';

const storeKey = "userGlobalSearch";
const contentTypePdf = 'application/pdf';

class UsersGlobalSearchStore extends FormStore {

    getInitialState() {
        let initialState = {
            userList: [],
            currentPerson: null,
            showCurrentPersonPage: false,
            showUsersNotFoundMessage: false,
            searchSubmited: false,
            showEmailVerificationLinkModal: false,
            showInitiateEmailChangeModal: false,
            showPasswordResetLinkModal: false,
            showEmailChangeLinkModal: false,
            cardsInfo: null,
            cardsInfoLoaded: false,
            onlyCheckForFormErrors: false,
            resendAccountActivationLink: null,
            showMergePersonsModal: false,
            possiblePersonToMerge: null,
            showChangedEmailDuplicateMessage: false,
            duplicatedEmail: false,
            showEmailChangeSuccessMessage: false,
            showCancelEmailChangeSuccessMessage: false,
            showAdminEmailChangeInitiateModal: false,
            showAdminAccountBlockUnblockConfirmationModal: false,
            showAdminAccountBlockUnblockSuccessMessage: false,
            showAdminAccountBlockUnblockMissingReasonMessage: false,
            showBlockHistoryModal: false,
            showIdentityDocumentImageModal: false,
            identityDocumentImageToShow: null,
            identityDocumentToShowIsPdf: false,
            identityDocumentToShowIsUploaded: false,
            constants: {
                globalSearchTypes: constants.globalSearchTypes,
            },
        };

        let usersGlobalSearchFormFieldDefinitions = {
            searchString: {
                defaultValue: "",
                validators: [
                    requiredValidator,
                    getMinLengthValidator(constants.usersGlobalSearchMinLength),
                    getMaxLengthValidator()
                ]
            },
            searchType: {
                defaultValue: constants.globalSearchTypes.fullName,
                validators: [
                    requiredValidator
                ]
            }
        };

        let editUserDataFormFieldDefinitions = {
            firstName: {
                defaultValue: "",
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: "",
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            userAccountEmail: {
                defaultValue: "",
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ]
            },
            selfContactEmail: {
                defaultValue: "",
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ]
            },
            phoneNumber: {
                defaultValue: "",
                validators: [
                    requiredValidator
                ]
            },
            userAccountIsBlocked: {
                defaultValue: false
            },
            userAccountBlockedReason: {
                defaultValue: ""
            },
            userAccountBlockedTimestamp: {
                defaultValue: ""
            }
        }

        storeFormManager.defineForm(initialState, storeKey, "searchUsersGloballyForm", usersGlobalSearchFormFieldDefinitions, this.onSearchUsersGloballyFormSubmit);
        storeFormManager.defineForm(initialState, storeKey, "editUserDataForm", editUserDataFormFieldDefinitions, this.onEditUserDataFormSubmit);
        return initialState;
    }

    getFieldValueChangedHandlerCfg() {
        return [
            {
                storeKey: storeKey,
                formKey: 'searchUsersGloballyForm',
                handler: this.fieldValueChanged
            }
        ];
    }

    fieldValueChanged(state, action, sharedStore) {
        if (action.fieldKey === "searchType") {
            state.showUsersNotFoundMessage = false;
            state.UsersGlobalSearchLimitedMessage = false;
            state.userList = [];
            sharedStore.errorMessage.show = false;
        }
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/search-users-globally',
                loadOnlyIfHasPermission: 'SEARCH_USERS_GLOBALLY',
                loadHandler: this.onUsersGlobalSearchRouteLoaded
            },
            {
                fullPath: '/search-users-globally/view-user/:userId',
                loadOnlyIfHasPermission: 'SEARCH_USERS_GLOBALLY',
                loadHandler: this.onUsersGlobalSearchViewUserRouteLoaded
            }
        ];
    }

    onUsersGlobalSearchRouteLoaded(state) {
        state.userList = [];
        state.showLoadingIndicator = false;
        storeFormManager.setFormInitialState(state, "searchUsersGloballyForm");
        state.showUsersNotFoundMessage = false;
        state.searchSubmited = false;
    }

    onUsersGlobalSearchViewUserRouteLoaded(state, action, sharedStoreState) {
        let userId = sharedStoreState.currentRoute.params.userId;
        getUserGlobalInfo(userId);
        state.searchSubmited = false;
        state.showLoadingIndicator = true;
        state.showCurrentPersonPage = false;
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = false;
        state.generatedLink = '';
        state.showMergePersonsModal = false;
        state.showAdminEmailChangeInitiateModal = false;
        state.showAdminAccountBlockUnblockConfirmationModal = false;
        state.showBlockHistoryModal = false;
        state.showIdentityDocumentImageModal = false;
        state.identityDocumentImageToShow = null;
        state.identityDocumentToShowIsPdf = false;
        state.identityDocumentToShowIsUploaded = false;
        state.possiblePersonToMerge = null;
        let uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        let cardsKey = uiConfiguration.cardsCode;
        let cardsAppUrl = uiConfiguration.otherApps[cardsKey].url;
        let userCardsList = uiConfiguration.otherApps[cardsKey].relativePages.userCards;
        userCardsList = userCardsList.replace("[PERSON_ID]", userId);
        let linkToCardsList = cardsAppUrl + userCardsList;
        state.userCardsListLink = linkToCardsList;
        storeFormManager.setFormInitialState(state, "editUserDataForm");
        // editUserDataForm userAccountEmail field should not be submitted by enter
        // instead the confirmation dialog should be shown
        state.editUserDataForm.fields.userAccountEmail.handleOnEnterKeyDownToSubmitForm = (event) => {
             if (event.keyCode === 13) {
                 adminInitiateEmailChangeBtnClicked(event);
             }
        };

        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };
    }

    onSearchUsersGloballyFormSubmit(state, values, sharedStore) {
        searchUsersGlobally(values);
        state.searchSubmited = true;
        state.showLoadingIndicator = true;
        sharedStore.errorMessage.show = false;
    }

    [ActionTypes.SEARCH_USERS_GLOBALLY_GET_SUCCESS](state, action) {
        state.userList = action.result.items;
        state.showUsersNotFoundMessage = state.searchSubmited && state.userList.length === 0 && !action.result.limited;
        state.showLoadingIndicator = false;
        state.listMaxLength = action.result.listMaxLength;
        state.UsersGlobalSearchLimitedMessage = action.result.limited;
    }

    [ActionTypes.SEARCH_USERS_GLOBALLY_GET_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GET_USER_GLOBAL_INFO_SUCCESS](state, action, sharedStoreState) {
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        state.currentPerson = action.result;
        state.showLoadingIndicator = false;

        if (state.currentPerson) {
            state.showCurrentPersonPage = true;
            let orgRepresentations = [];
            let representations = action.result.organizations;
            let globalPermissionsList = action.result.allGlobalPermissions;
            let mappedCompanyObject = {};

            state.editUserDataForm.fields.firstName.value = state.currentPerson.firstName;
            state.editUserDataForm.fields.firstName.defaultValue = state.currentPerson.firstName;
            state.editUserDataForm.fields.lastName.value = state.currentPerson.lastName;
            state.editUserDataForm.fields.lastName.defaultValue = state.currentPerson.lastName;
            state.editUserDataForm.fields.phoneNumber.value = state.currentPerson.phoneNumber;
            state.editUserDataForm.fields.phoneNumber.defaultValue = state.currentPerson.phoneNumber;
            state.editUserDataForm.fields.userAccountEmail.value = state.currentPerson.email;
            state.editUserDataForm.fields.userAccountEmail.defaultValue = state.currentPerson.email;

            if (state.currentPerson.userBlockHistory && state.currentPerson.userBlockHistory.length > 0) {
                state.editUserDataForm.fields.userAccountIsBlocked.value = state.currentPerson.userBlockHistory[0].block;
                state.editUserDataForm.fields.userAccountIsBlocked.defaultValue = state.currentPerson.userBlockHistory[0].block;
                state.editUserDataForm.fields.userAccountBlockedReason.value = state.currentPerson.userBlockHistory[0].reason;
                state.editUserDataForm.fields.userAccountBlockedReason.defaultValue = state.currentPerson.userBlockHistory[0].reason;
                state.editUserDataForm.fields.userAccountBlockedTimestamp.defaultValue = state.currentPerson.userBlockHistory[0].timestamp;
            } else {
                state.editUserDataForm.fields.userAccountIsBlocked.value = false;
                state.editUserDataForm.fields.userAccountIsBlocked.defaultValue = false;
                state.editUserDataForm.fields.userAccountBlockedReason.value = "";
                state.editUserDataForm.fields.userAccountBlockedReason.defaultValue = "";
                state.editUserDataForm.fields.userAccountBlockedTimestamp.defaultValue = "";
            }

            let selfContactEmail = state.currentPerson.contactEmails.find((email) => email.self === true);
            selfContactEmail = selfContactEmail ? selfContactEmail.email : '';

            state.resendAccountActivationLink = prepareUrl.prepareRouteUrl('/resend-account-activation-link');

            state.editUserDataForm.fields.selfContactEmail.value = selfContactEmail;
            state.editUserDataForm.fields.selfContactEmail.defaultValue = selfContactEmail;
            if (representations) {
                Object.keys(representations).forEach((key) => {
                    mappedCompanyObject = representations[key];
                    representations[key].representations.forEach((currentRepresentation) => {
                        if (currentRepresentation.globalPermissions && currentRepresentation.globalPermissions.length) {
                            globalPermissionsList.forEach(function(globalPermission) {
                                let foundGlobalPermissionKey = currentRepresentation.globalPermissions.indexOf(globalPermission.code);
                                if (foundGlobalPermissionKey > -1) {
                                    currentRepresentation.globalPermissions[foundGlobalPermissionKey] = globalPermission;
                                }
                            });
                        }
                    })
                    orgRepresentations.push(mappedCompanyObject);
                });
            }
            state.currentPerson.organizations = orgRepresentations;
        }
    }

    [ActionTypes.GET_USER_GLOBAL_INFO_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showCurrentPersonPage = true;
    }

    [ActionTypes.GENERATE_GLOBAL_SEARCH_EMAIL_LINK_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let userId = sharedStoreState.currentRoute.params.userId;
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = false;
        state.generatedLink = '';
        getGlobalSearchEmailVerificationLink(userId);
    }

    [ActionTypes.GENERATE_GLOBAL_SEARCH_EMAIL_VERIFICATION_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        state.showEmailVerificationLinkModal = true;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = false;
        state.generatedLink = action.result.emailVerificationLink;
    }

    [ActionTypes.GENERATE_GLOBAL_SEARCH_EMAIL_VERIFICATION_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GENERATE_GLOBAL_SEARCH_PASSWORD_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let userId = sharedStoreState.currentRoute.params.userId;
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = false;
        state.generatedLink = '';
        getGlobalSearchPasswordRecoveryLink(userId);
    }

    [ActionTypes.GET_GLOBAL_SEARCH_PASSWORD_RECOVERY_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = true;
        state.showEmailChangeLinkModal = false;
        state.generatedLink = action.result.passwordRecoveryLink;
    }

    [ActionTypes.GET_GLOBAL_SEARCH_PASSWORD_RECOVERY_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GLOBAL_SEARCH_CHANGE_EMAIL_RESEND_VERIFICATION_BTN_CLICKED] (state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let userId = sharedStoreState.currentRoute.params.userId;
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = false;
        state.generatedLink = '';
        changeEmailGenerateVerificationLink({'userId':userId});
    }

    [ActionTypes.GET_GLOBAL_SEARCH_CHANGE_EMAIL_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = true;
        state.generatedLink = action.result.globalSearchChangeEmailLink;
    }

    [ActionTypes.GET_GLOBAL_SEARCH_CHANGE_EMAIL_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }


    [ActionTypes.GET_GLOBAL_SEARCH_USER_CARDS_LIST_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = true;
    }

    [ActionTypes.SEARCH_USERS_GLOBALLY_LIST_ROW_CLICKED](state, action) {
        redirectToRoute("/search-users-globally/view-user/" + action.currentPersonId, false, true);
    }

    [ActionTypes.CLOSE_MODAL](state) {
        state.showEmailVerificationLinkModal = false;
        state.showPasswordResetLinkModal = false;
        state.showEmailChangeLinkModal = false;
        state.showAdminEmailChangeInitiateModal = false;
        state.showAdminAccountBlockUnblockConfirmationModal = false;
        state.showBlockHistoryModal = false;
        state.showIdentityDocumentImageModal = false;
        state.identityDocumentImageToShow = null;
        state.identityDocumentToShowIsPdf = false;
        state.identityDocumentToShowIsUploaded = false;
    }

    [ActionTypes.GLOBAL_SEARCH_EDIT_ICON_CLICKED](state, action) {
        state.showAdminAccountBlockUnblockSuccessMessage = false;
        action.field.editingVisible = true;
    }

    [ActionTypes.GLOBAL_SEARCH_CANCEL_ICON_CLICKED](state, action, sharedStoreState) {
        action.field.editingVisible = false;
        action.field.value = action.field.defaultValue;
        if (action.field.additionalInfo && action.field.additionalInfo.fullNumber) {
            action.field.additionalInfo.isValid = true;
            action.field.additionalInfo.fullNumber = action.field.value;
        }
        this.submitEditUserDataForm(state, action, sharedStoreState, true);
    }

    [ActionTypes.GLOBAL_SEARCH_SAVE_ICON_CLICKED](state, action, sharedStoreState) {
        if (!action.field.showError) {
            let onlyCheckForErrors = false;
            let defaultValue = action.field.defaultValue;
            let currentValue = action.field.value;

            if (defaultValue != currentValue) {
                action.field.defaultValue = action.field.value;
            } else {
                action.field.editingVisible = false;
                onlyCheckForErrors = true;
            }

            this.submitEditUserDataForm(state, action, sharedStoreState, onlyCheckForErrors);
        }
    }

    [ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_PROMPT_BTN_CLICKED](state) {
        let blockField = state.editUserDataForm.fields['userAccountIsBlocked'];
        let reasonField = state.editUserDataForm.fields['userAccountBlockedReason'];
        if (blockField.defaultValue != blockField.value && reasonField.defaultValue != reasonField.value) {
            if (reasonField.value === "") {
                state.showAdminAccountBlockUnblockMissingReasonMessage = true;
            } else {
                state.showAdminAccountBlockUnblockMissingReasonMessage = false;
                state.showAdminAccountBlockUnblockConfirmationModal = true;
            }
        } else {
            state.showAdminAccountBlockUnblockMissingReasonMessage = false;
            blockField.editingVisible = false;
            blockField.value = blockField.defaultValue;
            reasonField.value = reasonField.defaultValue;
        }
    }

    [ActionTypes.GLOBAL_SEARCH_BLOCK_USER_CANCEL_ICON_CLICKED](state) {
        let blockField = state.editUserDataForm.fields['userAccountIsBlocked'];
        let reasonField = state.editUserDataForm.fields['userAccountBlockedReason'];
        blockField.editingVisible = false;
        blockField.value = blockField.defaultValue;
        reasonField.value = reasonField.defaultValue;
        state.showAdminAccountBlockUnblockMissingReasonMessage = false;
    }

    [ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_CONFIRM_BTN_CLICKED](state, action, sharedStoreState) {
        state.showAdminAccountBlockUnblockConfirmationModal = false;
        state.showLoadingIndicator = true;
        let blockField = state.editUserDataForm.fields['userAccountIsBlocked'];
        blockField.editingVisible = false;
        adminBlockUnblockAccount(sharedStoreState.currentRoute.params.userId, action.block.value, action.reason.value);
    }

    [ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_SUCCESS] (state) {
        state.showAdminAccountBlockUnblockSuccessMessage = true;
        getUserGlobalInfo(state.currentPerson.personId);
    }

    [ActionTypes.ADMIN_BLOCK_UNBLOCK_ACCOUNT_ERROR] (state) {
        state.showAdminAccountBlockUnblockSuccessMessage = false;
        state.showLoadingIndicator = false;
        state.editUserDataForm.fields.userAccountIsBlocked.value = state.editUserDataForm.fields.userAccountIsBlocked.defaultValue;
        state.editUserDataForm.fields.userAccountBlockedReason.value = state.editUserDataForm.fields.userAccountBlockedReason.defaultValue;
    }

    [ActionTypes.OPEN_BLOCK_HISTORY_MODAL_BTN_CLICKED](state) {
        state.showBlockHistoryModal = true;
    }

    [ActionTypes.OPEN_IDENTITY_DOCUMENT_IMAGE_MODAL_BTN_CLICKED](state, action) {
        if (action.isPermit) {
            if (Object.keys(state.currentPerson.govIds[action.index].permitScannedDocument).length > 0) {
                state.identityDocumentImageToShow = state.currentPerson.govIds[action.index].permitScannedDocument.scannedDocumentImage
            } else {
                state.identityDocumentToShowIsUploaded = true;
                state.identityDocumentImageToShow = state.currentPerson.govIds[action.index].permitUploadedDocument.uploadedDocumentImage
                if (state.currentPerson.govIds[action.index].permitUploadedDocument.uploadedDocumentImageContentType == contentTypePdf) {
                    state.identityDocumentToShowIsPdf = true;
                }
            }
        } else {
            state.identityDocumentImageToShow = state.currentPerson.govIds[action.index].scannedDocumentImage
        }
        state.showIdentityDocumentImageModal = true;
    }

    submitEditUserDataForm(state, action, sharedStoreState, onlyCheckErrors=false) {
        action.storeKey = storeKey;
        action.formKey = 'editUserDataForm';
        state.onlyCheckForFormErrors = onlyCheckErrors;
        storeFormManager.onSubmitBtnClicked(state, action, sharedStoreState);
        state.onlyCheckForFormErrors = false;
    }


    onEditUserDataFormSubmit(state, values, sharedStoreState) {
        state.showChangedEmailDuplicateMessage = false;
        state.showEmailChangeSuccessMessage = false;
        state.showCancelEmailChangeSuccessMessage = false;
        state.showAdminEmailChangeInitiateModal = false;
        state.showAdminAccountBlockUnblockConfirmationModal = false;
        state.showBlockHistoryModal = false;
        state.showAdminAccountBlockUnblockSuccessMessage = false;

        if (!state.onlyCheckForFormErrors) {
            state.showLoadingIndicator = true;

            for (let fieldKey in state.editUserDataForm.fields) {
                state.editUserDataForm.fields[fieldKey]['editingVisible'] = false;
            }
            updateUserInfo(sharedStoreState.currentRoute.params.userId, values);
        }
    }

    [ActionTypes.UPDATE_USER_INFO_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        if (action.result.emailChangeToEmail) {
            if (action.result.duplicateEmail) {
                state.showChangedEmailDuplicateMessage = true;
                state.duplicatedEmail = action.userAccountEmail;
                state.editUserDataForm.fields.userAccountEmail.value = state.currentPerson.email;
            } else {
                state.showEmailChangeSuccessMessage = true;
                getUserGlobalInfo(state.currentPerson.personId);
                state.showLoadingIndicator = true;
            }
        }
    }

    [ActionTypes.UPDATE_USER_INFO_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ADMIN_CHANGE_EMAIL_CANCEL_BTN_CLICKED](state) {
        state.showLoadingIndicator = true;
        state.showEmailChangeSuccessMessage = false;
        state.showCancelEmailChangeSuccessMessage = false;
        adminChangeEmailCancel(state.currentPerson.personId);
    }

    [ActionTypes.ADMIN_INITIATE_EMAIL_CHANGE_BTN_CLICKED](state) {
        let field = state.editUserDataForm.fields['userAccountEmail'];
        if (!field.showError) {
            if (field.defaultValue != field.value) {
                state.showAdminEmailChangeInitiateModal = true;
            } else {
                field.editingVisible = false;
            }
        }
    }

    [ActionTypes.ADMIN_CHANGE_EMAIL_CANCEL_SUCCESS](state) {
        state.showCancelEmailChangeSuccessMessage = true;
        getUserGlobalInfo(state.currentPerson.personId);
    }

    [ActionTypes.ADMIN_CHANGE_EMAIL_CANCEL_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GLOBAL_SEARCH_SHOW_MODAL_BTN_CLICKED](state, action) {
        state.showMergePersonsModal = true;
        state.possiblePersonToMerge = action.personForMerge;
    }

    [ActionTypes.GLOBAL_USER_INFO_MERGE_PERSONS_BTN_CLICKED] (state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.showMergePersonsModal = false;
        let personIds = [state.possiblePersonToMerge, sharedStoreState.currentRoute.params.userId];
        adminMergesPersons({"personsList": personIds});
    }

    [ActionTypes.GLOBAL_USER_INFO_MERGE_PERSONS_SUCCESS](state, action, sharedStoreState) {
        if (action.result) {
            let mergedPersonId = action.result.mergedPerson;
            if (sharedStoreState.currentRoute.params.userId == mergedPersonId) {
                getUserGlobalInfo(mergedPersonId);
            } else {
                redirectToRoute(`/search-users-globally/view-user/${mergedPersonId}`, false, false);
            }
        }
    }

    [ActionTypes.GLOBAL_USER_INFO_MERGE_PERSONS_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default UsersGlobalSearchStore;
