import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let changePhone = function (data) {
    postToBackend('/profile/change-phone-number',
        ActionTypes.PROFILE_CHANGE_PHONE_SUCCESS,
        ActionTypes.PROFILE_CHANGE_PHONE_ERROR,
        data, data);
};
export default changePhone;
