import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let updateUserInfoWithStrongAuthInfoBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_BTN_CLICKED
    });
};

export default updateUserInfoWithStrongAuthInfoBtnClicked;
