import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { FormattedMessage } from 'react-intl';
import prepareUrl from '../../../helpers/PrepareUrl.js';


class RegistrationStep1Flow2Info extends StoreComponent {

    renderWhenReady() {

        return (
            <div>
                <div>
                    <p>
                        <FormattedMessage
                            id="profile.createAccount.idRequired"
                            defaultMessage="To use the ID06 system you need to create an account with a verified identity. This is done by signing in to the system by clicking the &quot;Continue to sign in&quot; button below. You are required to have one of the following:"
                        />
                        <ul>
                            <li className="nav-item">
                                <FormattedMessage
                                    id="profile.createAccount.idRequiredBank"
                                    defaultMessage="BankID (Swedish)"
                                />
                            </li>
                            <li className="nav-item">
                                <FormattedMessage
                                    id="profile.createAccount.idRequiredFreja"
                                    defaultMessage="Freja eID (Swedish or non-Swedish)"
                                />
                            </li>
                            <li className="nav-item">
                                <FormattedMessage
                                    id="profile.createAccount.idRequiredDokobit"
                                    defaultMessage="Dokobit eID (non-Swedish)"
                                />
                            </li>
                        </ul>
                    </p>
                </div>
                <div className="mt-3">
                    <p className="mb-0">
                        <FormattedMessage
                            id="profile.createAccount.accountNeeded"
                            defaultMessage="An account is needed when registering a company, to verify a card order, registering competences or to administer an existing company."
                        />
                    </p>
                </div>
                <div className="mt-3">
                    <p className="mb-0">
                        <FormattedMessage
                            id="profile.createAccount.accountNeededManualExistsYes"
                            defaultMessage="If you have created a manual account at one of our Manual ID verification partners, please use only this account!"
                        />
                    </p>
                </div>
                <div className="mt-3">
                    <p className="mb-0">
                        <FormattedMessage
                            id="profile.createAccount.accountNeededManualExistsNo"
                            defaultMessage="If you don't have one of the above BankID or eID, please visit one of the Manual ID verification partners."
                        />
                    </p>
                </div>
                <div>
                    <a
                        id="profile_create_account_continue_to_sign_in_2"
                        href={this.state.registrationStep1Flow2.signInUrl}
                        className="btn btn-primary btn-lg mt-6 text-white"
                        role="button"
                    >
                        <FormattedMessage
                            id="profile.createAccount.continueSignIn"
                            defaultMessage="Continue to sign in"
                        />
                    </a>
                </div>
                <div>
                    <div className="mt-6">
                        <p className="mb-0">
                            <FormattedMessage
                                id="profile.createAccount.personalDataCollected"
                                defaultMessage="Your personal data is collected, stored and processed in a safe and secure manner."
                            />
                        </p>
                        <p className="mb-0">
                            <FormattedMessage
                                id="profile.createAccount.personalDataRead"
                                defaultMessage="Read"
                            />
                            &nbsp;
                            <a id="registration_step1_flow_2_terms_of_service_printable_version_link" onClick={ (e) => prepareUrl.openUrlInPopUpWindow(e, this.state.registrationStep1Flow2.termsOfServicePdfUrl) } href="#">
                                <FormattedMessage id="registration.step1.personalDataPolicyPart1" defaultMessage="ID06 General Terms and Conditions" />     
                            </a>.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegistrationStep1Flow2Info;
