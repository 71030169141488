import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let changeEmailVerifyYouAccountWithFrejaEIDSwedishBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_FREJA_EID_SWEDISH_BTN_CLICKED
        });
    };

export default changeEmailVerifyYouAccountWithFrejaEIDSwedishBtnClicked;
