import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getGlobalSearchEmailVerificationLink = function(userId) {

    getFromBackend(`/users/generate-global-search-email-verification-link/${userId}`,
        ActionTypes.GENERATE_GLOBAL_SEARCH_EMAIL_VERIFICATION_LINK_SUCCESS,
        ActionTypes.GENERATE_GLOBAL_SEARCH_EMAIL_VERIFICATION_LINK_ERROR);
};

export default getGlobalSearchEmailVerificationLink;