import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let downloadJobResultBtnClicked = function(jobId, event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CSV_REPORT_DOWNLOAD_JOB_RESULT_BTN_CLICKED,
        jobId: jobId
    });
}

export default downloadJobResultBtnClicked;
