import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let resendUserInvitation = function (orgId, userId) {
    postToBackend(`/users/${orgId}/resend-invitation/${userId}`,
        ActionTypes.ORGANIZATION_USERS_RESEND_INVITATION_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_RESEND_INVITATION_ERROR
    );
};

export default resendUserInvitation;
