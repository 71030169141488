import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, defineMessages } from 'react-intl';
import {ConfirmationModal} from '@vaultit/stv-utils-frontend';
import redirectToServiceBtnClicked from '../../actions/actionCreators/navigation/RedirectToServiceBtnClicked.js';
import $ from 'jquery';

const modalMessages = defineMessages({
    redirectToServiceModalTitle: {
        id: 'serviceRedirect.modal.title',
        description: 'Redirection to other services modal window title',
        defaultMessage: 'CHANGING THE COMPANY'
    },
    redirectToServiceModalConfirmBtn: {
        id: 'serviceRedirect.modal.confirmBtn',
        description: 'Redirection to other services modal window confirm button',
        defaultMessage: 'Continue to {name}'
    },
    redirectToServiceModalCancelBtn: {
        id: 'serviceRedirect.modal.cancelBtn',
        description: 'Redirection to other services modal window cancel button',
        defaultMessage: 'Cancel'
    },
    redirectToServiceFirstPartOfMsg: {
        id: 'serviceRedirect.modal.message1',
        description: 'Redirection to other services modal window first part of message',
        defaultMessage: 'We are moving you to {service} service of company ',
    },
    redirectToServiceSecondPartOfMsg: {
        id: 'serviceRedirect.modal.message2',
        description: 'Redirection to other services modal window second part of message',
        defaultMessage: 'For now, one user can only use Raportti and Valvoja services of one company '
    },
    redirectToServiceReadMore: {
        id: 'serviceRedirect.modal.readMore',
        description: 'Redirection to other services modal window read more message',
        defaultMessage: '(read more)'
    },
    redirectToServiceRaportti: {
        id: 'services.raporti.title',
        description: 'Raportti service name',
        defaultMessage: 'Raportti'
    },
    redirectToServiceValvoja: {
        id: 'services.supplierMonitoring.title',
        description: 'Valvoja service name',
        defaultMessage: 'Supplier monitoring'
    }
});

class RedirectModalWrapper extends React.Component {

    static get propTypes() {
        return {
            modalData: PropTypes.object,
            modalState: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        let serviceName = this.props.modalData.serviceName == "reports"
                        ? formatMessage(modalMessages.redirectToServiceRaportti)
                        : formatMessage(modalMessages.redirectToServiceValvoja);

        let closeModal = () => {
            $('#redirect-to-default-service-modal').modal('toggle');
        };

        return (
            <ConfirmationModal
                id="redirect-to-default-service-modal"
                idPrefix="0"
                title={formatMessage(modalMessages.redirectToServiceModalTitle)}
                okButtonText={formatMessage(modalMessages.redirectToServiceModalConfirmBtn, {name: this.props.modalData.organisationName})}
                cancelButtonText={formatMessage(modalMessages.redirectToServiceModalCancelBtn)}
                handleActionType={() => redirectToServiceBtnClicked(this.props.modalData.serviceName)}
                okBtnClass="btn btn-primary"
                showModal={this.props.modalData && this.props.modalData.explanationUrl ? true : false}
                sharedStore={this.props.modalState}
            >
                <p>
                    {formatMessage(modalMessages.redirectToServiceFirstPartOfMsg, {service: serviceName})}
                    <b>{this.props.modalData.organisationName}</b>.
                </p>
                <p>
                    {formatMessage(modalMessages.redirectToServiceSecondPartOfMsg)}
                    <a href={this.props.modalData.explanationUrl} id="redirect-modal-read-more" onClick={closeModal}>
                        {formatMessage(modalMessages.redirectToServiceReadMore)}
                    </a>.
                </p>
            </ConfirmationModal>
        );
    }
}

export default RedirectModalWrapper;
