import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let proceedToCompanyBtnClicked = function(data) {
    dispatcher.dispatch({
        type: ActionTypes.PROCEED_TO_COMPANY_BTN_CLICKED,
        serviceName: data.actionName,
        company: data.orgId
    });
};

export default proceedToCompanyBtnClicked;