import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let getDeleteBtnClickedHandler = function(userId) {
    let deleteBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ORGANIZATION_USERS_DELETE_BTN_CLICKED,
            userId: userId
        });
    };

    return deleteBtnClicked;
};

export default getDeleteBtnClickedHandler;
