import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage, intlShape } from 'react-intl';
import {Message, PageHeader } from '@vaultit/stv-utils-frontend';

class InformIdentityVerifiedCheckYourEmail extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }


    renderWhenReady() {


        return (
            <div>
                <PageHeader
                    pageTitleMessageId="informIdentityVeirfiedCheckEmail.title"
                    sharedStore={this.state.shared}
                />
                <Message id="inform-identity-verified-check-your-email" type="warning" className="text-center">
                    <FormattedMessage
                        id="informIdentityVeirfiedCheckEmail.message"
                        defaultMessage="Your identity was verified. Check your email for an invitation to create an account. Click on the link in the email."
                    />
                </Message>
            </div>
        );
    }
}

export default InformIdentityVerifiedCheckYourEmail;
