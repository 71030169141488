import React from 'react';
import { FormattedMessage } from 'react-intl';
import StoreComponent from '../../base/StoreComponent.jsx';
import { FormRow, PageHeader, ValidationMessages, TextInput, FormColumn } from '@vaultit/stv-utils-frontend';

class ChangeName extends StoreComponent {

    renderWhenReady() {
        let form = this.state.yourDetails.changeNameForm;

        return (
            <div>
                <PageHeader pageTitleMessageId="profile.changeName.title" sharedStore={this.state.shared} />

                { form
                    ? <FormRow>
                        <FormColumn width="5">
                            <div className="form-group">
                                <ValidationMessages field={form.fields.firstName}>
                                    <TextInput
                                        id="profile-basic-details-firstName"
                                        field={form.fields.firstName}
                                        messageId="profile.changeName.firstName"
                                        defaultMessage="First name"
                                        required={true}
                                        autoFocus={true}
                                    />
                                </ValidationMessages>
                            </div>
                        </FormColumn>
                        <FormColumn width="5">
                            <div className="form-group">
                                <ValidationMessages field={form.fields.lastName}>
                                    <TextInput
                                        id="profile-basic-details-lastName"
                                        field={form.fields.lastName}
                                        messageId="profile.changeName.lastName"
                                        defaultMessage="Last name"
                                        required={true}
                                    />
                                </ValidationMessages>
                            </div>
                        </FormColumn>
                        <FormColumn width="12">
                            <div className="form-group mt-6">
                                <button
                                    id="profile-change-name-save"
                                    type="button"
                                    className="btn btn-primary btn-lg mr-3"
                                    onClick={form.handleOnSubmitBtnClicked}
                                    role="button"
                                >
                                    <FormattedMessage
                                        id="profile.changeName.save"
                                        defaultMessage="Save"
                                    />
                                </button>
                                <a
                                    id="profile-change-name-cancel"
                                    className="btn btn-default btn-lg"
                                    href={this.state.yourDetails.backToBaseDetailsUrl}
                                >
                                    <FormattedMessage
                                        id="profile.changeName.cancel"
                                        defaultMessage="Cancel"
                                    />
                                </a>
                            </div>
                        </FormColumn>
                    </FormRow>
                    : null
                }


            </div>
        );
    }
}

export default ChangeName;
