import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { FormattedMessage} from 'react-intl';
import authenticateToSignBtnClicked from '../../../actions/actionCreators/registration/AuthenticateToSignBtnClicked.js';
import Logo from '../../../components/common/Logo.jsx';

class ChooseAuthenticationMethod extends StoreComponent {

    renderWhenReady() {
        let invitationDetails = this.state.chooseAuthenticationMethodStore.invitationDetails;

        if (!invitationDetails) {
            return null;
        }

        let invitedPersonName = invitationDetails.firstName + ' ' + invitationDetails.lastName;
        let inviterFullName = invitationDetails.inviterFullName;
        let organisationName = invitationDetails.orgName;
        let allowOnlyCreateFullAccount = invitationDetails.allowOnlyFullRegistration;

        return (
            <div className="mt-6 p-6 bg-light">
                { invitationDetails
                    ?
                        <div className="p-5 bg-white">
                            <div className="logo-for-message mb-5 mt-3">
                                <Logo />
                            </div>
                            <div >
                                <div>
                                    <p>
                                        <FormattedMessage
                                            id="registration.chooseAuthentication.hello"
                                            defaultMessage="Hello"
                                        />
                                        &nbsp;
                                        {invitedPersonName},
                                    </p>
                                </div>
                                <div className="mt-3">
                                    <p className="mb-0">
                                        <FormattedMessage
                                            id="registration.chooseAuthentication.initiatedBy"
                                            defaultMessage="This registration was initiated by"
                                        />
                                        &nbsp;
                                        <b>{inviterFullName}</b>.
                                    </p>
                                    <p>
                                        <FormattedMessage
                                            id="registration.chooseAuthentication.invitedAsSignatory"
                                            defaultMessage="You are invited as signatory of"
                                        />
                                        &nbsp;
                                        <b>{organisationName}</b>
                                        &nbsp;
                                        <FormattedMessage
                                            id="registration.chooseAuthentication.toSignContract"
                                            defaultMessage="to sign service contract"
                                        />.
                                    </p>
                                </div>

                                {allowOnlyCreateFullAccount
                                ?
                                    <div>
                                        <div>
                                            <a id="authenticate_by_creating_account_from_start" className="btn btn-primary mb-6 mt-4" role="button" href={this.state.chooseAuthenticationMethodStore.createAccountLink}>
                                                <FormattedMessage
                                                    id="registration.chooseAuthentication.createAccount"
                                                    defaultMessage="Create account and Sign"
                                                />
                                            </a>
                                        </div>
                                        <div>
                                            <FormattedMessage id="registration.chooseAuthentication.youCanSignIn" defaultMessage="If you already have an existing account you can " />
                                            <a id="authenticate_to_existing_account" href={this.state.chooseAuthenticationMethodStore.signInLink}>
                                                <FormattedMessage id="registration.step1.youCanSignIn.signInHere" defaultMessage="sign in here" />
                                            </a>.
                                        </div>
                                    </div>
                                :
                                <div>
                                    <div>
                                        <p>
                                            <FormattedMessage
                                                id="registration.chooseAuthentication.clickAuthenticate"
                                                defaultMessage="You can click to assign"
                                            />
                                            &nbsp;
                                            <b>{inviterFullName}</b>
                                            &nbsp;
                                            <FormattedMessage
                                                id="registration.chooseAuthentication.giveMainUserPermission"
                                                defaultMessage="Main user administration rights to manage"
                                            />
                                            &nbsp;
                                            <b>{organisationName}</b>
                                            &nbsp;
                                            <FormattedMessage
                                                id="registration.chooseAuthentication.signContract"
                                                defaultMessage="account and sign the contract"
                                            />.
                                        </p>
                                    </div>
                                    <div className="mb-6 mt-6">
                                        <button id="authenticate_to_sign_with_strong_auth" className="btn btn-primary mr-3" type="button" role="button" onClick={authenticateToSignBtnClicked}>
                                            <FormattedMessage id="registration.chooseAuthentication.authenticateToSign" defaultMessage="Authenticate to sign" />
                                        </button>
                                    </div>
                                    <div>
                                        <p>
                                            <FormattedMessage
                                                id="registration.chooseAuthentication.orBecomeMainUser"
                                                defaultMessage="or become Main user yourself, then"
                                            />
                                            &nbsp;
                                            <a href={this.state.chooseAuthenticationMethodStore.createAccountLink} id="authenticate_by_creating_account_from_start">
                                                <FormattedMessage
                                                    id="registration.chooseAuthentication.createAccount"
                                                    defaultMessage="Create account and Sign"
                                                />
                                            </a>
                                        </p>
                                    </div>
                                </div>
                                }

                            </div>
                        </div>
                : null
                }
            </div>
        );
    }
}

export default ChooseAuthenticationMethod;
