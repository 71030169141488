import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { ConfirmationModal, DateLabel } from '@vaultit/stv-utils-frontend';
import closeModal from '../../actions/actionCreators/generic/confirmationModal/CloseModal.js';

const modalMessages = defineMessages({
    blockHistoryModalTitle: {
        id: 'users.global.list.blockHistoryModal.title',
        description: 'Block history modal window title',
        defaultMessage: 'BLOCK HISTORY'
    },
    blockHistoryModalCloseBtn: {
        id: 'users.global.list.blockHistoryModal.closeBtn',
        description: 'Block history modal window close button text',
        defaultMessage: 'Close'
    },
    blockHistoryModalTextNo: {
        id: 'users.global.list.blockHistoryModal.textNo',
        description: 'Block history modal window No text',
        defaultMessage: 'No'
    },
    blockHistoryModalTextYes: {
        id: 'users.global.list.blockHistoryModal.textYes',
        description: 'Block history modal window Yes text',
        defaultMessage: 'Yes'
    }
});

class BlockHistoryModalWrapper extends React.Component {

    static get propTypes() {
        return {
            showModal: PropTypes.bool,
            blockHistory: PropTypes.array
        };
    }

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        return (
            <ConfirmationModal
                id="block_history_modal"
                idPrefix="block_history"
                title={formatMessage(modalMessages.blockHistoryModalTitle)}
                cancelButtonText={formatMessage(modalMessages.blockHistoryModalCloseBtn)}
                showModal={this.props.showModal}
                hideConfirmButton={true}
                modalDialogClass="modal-lg"
                onCloseAction={closeModal}
            >
                {this.props.blockHistory
                    ?
                    (
                        <table id="user_globalsearch_users_list" className="table table-striped table-hover table-fixed-layout">
                            <thead>
                                <tr>
                                    <th>
                                        <FormattedMessage id="users.global.list.blockHistoryModal.titleBlocked" defaultMessage="Blocked" />
                                    </th>
                                    <th style={{width: '20%'}}>
                                        <FormattedMessage id="users.global.list.blockHistoryModal.titleModifiedBy" defaultMessage="Modified by" />
                                    </th>
                                    <th style={{width: '50%'}}>
                                        <FormattedMessage id="users.global.list.blockHistoryModal.titleReason" defaultMessage="Reason" />
                                    </th>
                                    <th >
                                        <FormattedMessage id="users.global.list.blockHistoryModal.titleTime" defaultMessage="Time" />
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.blockHistory.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                {item.block ? formatMessage(modalMessages.blockHistoryModalTextYes) : formatMessage(modalMessages.blockHistoryModalTextNo)}
                                            </td>
                                            <td>
                                                {item.modified_by}
                                            </td>
                                            <td>
                                                {item.reason}
                                            </td>
                                            <td>
                                                <DateLabel id={item.timestamp} format="YYYY-MM-DD HH:mm">{item.timestamp}</DateLabel>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    )
                    : null
                }

            </ConfirmationModal>
        );
    }
}

export default BlockHistoryModalWrapper;
