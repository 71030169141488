import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';
import updateUserInfoWithStrongAuthInfoBtnClicked from '../../actions/actionCreators/profile/yourDetails/UpdateUserNameWithStrongAuthInfoBtnClicked.js';


const messages = defineMessages({
    continue: {
        id: 'updateUsername.submitBtn',
        description: 'Update username from strong auth info submit btn',
        defaultMessage: 'Continue'
    }
});

class UpdateUserInfoToMatchOfficialSource extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        if (!this.state.shared.authInfo.userProfile) {
            return null;
        }

        let strongAuthNameMismatch = this.state.shared.authInfo.userProfile.strong_auth_info.user_name_mismatch;
        let strongAuthDateOfBirthMismatch = this.state.shared.authInfo.userProfile.strong_auth_info.date_of_birth_mismatch;

        let strongAuthFirstName = this.state.shared.authInfo.userProfile.strong_auth_info.first_name;
        let strongAuthLastName = this.state.shared.authInfo.userProfile.strong_auth_info.last_name;
        let strongAuthDateOfBirth = this.state.shared.authInfo.userProfile.strong_auth_info.date_of_birth;

        let title = strongAuthNameMismatch && strongAuthDateOfBirthMismatch ? "updateUsername.nameAndDateOfBirthMismatchTitle" : strongAuthNameMismatch
            ? "updateUsername.nameMismatchTitle" : "updateUsername.dateOfBirthMismatchTitle";

        return (
            <div>
                <PageHeader
                    pageTitleMessageId={title}
                    sharedStore={this.state.shared}
                />
                <Message type="success">
                    {strongAuthNameMismatch && strongAuthDateOfBirthMismatch
                    ?
                        <FormattedMessage
                            id="updateUsername.nameAndDateOfBirthMismatchMsg"
                            values={{
                                firstName: <strong>{strongAuthFirstName}</strong>,
                                lasttName: <strong>{strongAuthLastName}</strong>,
                                dateOfBirth: <strong>{strongAuthDateOfBirth}</strong>
                            }}
                            defaultMessage="Your name and surname do not match the official information. The name and surname information will be changed to {firstName} {lasttName} according to official information. Your date of birth will be set to {dateOfBirth}."
                        />
                    : null}

                    {strongAuthNameMismatch && !strongAuthDateOfBirthMismatch
                    ?
                        <FormattedMessage
                            id="updateUsername.nameMismatchMsg"
                            values={{
                                firstName: <strong>{strongAuthFirstName}</strong>,
                                lasttName: <strong>{strongAuthLastName}</strong>
                            }}
                            defaultMessage="Your name and surname do not match the official information. The name and surname information will be changed to {firstName} {lasttName} according to official information."
                        />
                    : null}

                    {!strongAuthNameMismatch && strongAuthDateOfBirthMismatch
                    ?
                        <FormattedMessage
                            id="updateUsername.dateOfBirthMismatchMsg"
                            values={{
                                dateOfBirth: <strong>{strongAuthDateOfBirth}</strong>
                            }}
                            defaultMessage="Your date of birth does not match the official information. Your date of birth will be set to {dateOfBirth}."
                        />
                    : null}
                </Message>
                <input
                    id="update_username_with_strong_auth_info"
                    type="submit"
                    className="btn btn-primary btn-lg"
                    value={formatMessage(messages.continue)}
                    onClick={updateUserInfoWithStrongAuthInfoBtnClicked}
                    role="button"
                />
            </div>
        );
    }
}

export default UpdateUserInfoToMatchOfficialSource;
