import ActionTypes from '../../actions/ActionTypes.js';
import FormStore from '../base/FormStore.js';

import storeFormManager from '../validation/StoreFormManager.js';
import {whiteSpaceRestrictor, getBlacklistedEmailValidator,emailValidator, getMatchValidator, getMaxLengthValidator, passwordValidator, requiredValidator} from '@vaultit/stv-utils-frontend';

import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';

import sendPasswordResetEmail from '../../actions/actionCreators/profile/passwordReset/SendPasswordResetEmail.js';
import checkResetPasswordToken from '../../actions/actionCreators/profile/passwordReset/CheckResetPasswordToken.js';
import resetPassword from '../../actions/actionCreators/profile/passwordReset/ResetPassword.js';

import constants from '../../Constants.js';
import prepareUrl from '../../helpers/PrepareUrl.js';

const forgotPasswordFormKey = 'forgotPasswordForm';
const resetPasswordFormKey = 'resetPasswordForm';
const storeKey = 'passwordReset';

class PasswordResetStore extends FormStore {
    static get formKey() {
        return [forgotPasswordFormKey, resetPasswordFormKey];
    }

    static get storeKey() {
        return storeKey;
    }

    getInitialState() {
        let state = {
            visibleElements: {},
            forgotPasswordResendEmailButtonEnabled: true,
            showLoadingIndicator: false,
            passwordValidationMinimumDigits: constants.passwordValidationMinimumDigits,
            showPasswordResetTimedOutMsg: false,
            resetPasswordUrl: ''
        };
        let fieldDefinitionsForForgotPasswordForm = {
            emailAddress: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            }
        };

        storeFormManager.defineForm(state, this.constructor.storeKey,
            forgotPasswordFormKey, fieldDefinitionsForForgotPasswordForm, this.onForgotPasswordSubmitted);

        let fieldDefinitionsForResetPasswordForm = {
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
        };

        storeFormManager.defineForm(state, this.constructor.storeKey,
            resetPasswordFormKey, fieldDefinitionsForResetPasswordForm, this.onResetPasswordSubmitted);

        return state;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/forgot-password',
                loadHandler: this.onForgotPasswordLoaded
            },
            {
                fullPath: '/profile/forgot-password-email-sent',
                loadHandler: this.onForgotPasswordEmailSentLoaded
            },
            {
                fullPath: '/profile/reset-password/:token',
                loadHandler: this.onResetPasswordLoaded
            }
        ];
    }

    onForgotPasswordLoaded(state, action, sharedStoreState) {
        state.showPasswordResetTimedOutMsg = false;

        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };

        storeFormManager.setFormInitialState(state, forgotPasswordFormKey);
        state.visibleElements = {
            forgotPasswordForm: true
        };
    }

    onForgotPasswordSubmitted(state, values) {
        sendPasswordResetEmail(values);
        state.forgotPasswordResendEmailButtonEnabled = false;
    }

    [ActionTypes.PROFILE_PASSWORD_RESET_SEND_EMAIL_SUCCESS](state) {
        state.visibleElements = {
        };
        state.forgotPasswordResendEmailButtonEnabled = true;

        redirectToRoute("/profile/forgot-password-email-sent", true);
    }

    [ActionTypes.PROFILE_PASSWORD_RESET_SEND_EMAIL_ERROR](state) {
        state.forgotPasswordResendEmailButtonEnabled = true;
    }

    onForgotPasswordEmailSentLoaded(state) {
        state.showPasswordResetTimedOutMsg = false;
        state.visibleElements = {
            sendSucceeded: true
        };
    }

    onResetPasswordLoaded(state, action, sharedStoreState) {
        let token = sharedStoreState.currentRoute.params.token;
        state.showLoadingIndicator = true;
        state.showPasswordResetTimedOutMsg = false;
        checkResetPasswordToken(token);
    }

    [ActionTypes.PROFILE_PASSWORD_RESET_CHECK_TOKEN_SUCCESS](state, action) {
        storeFormManager.setFormInitialState(state, resetPasswordFormKey);
        state.showLoadingIndicator = false;

        if (action.result.passwordResetTimedOut) {
            state.showPasswordResetTimedOutMsg = true;
            state.resetPasswordUrl = prepareUrl.prepareRouteUrl('/profile/forgot-password');
        } else {
            state.visibleElements = {
                resetPasswordForm: true
            };
        }
    }

    [ActionTypes.PROFILE_PASSWORD_RESET_CHECK_TOKEN_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    onResetPasswordSubmitted(state, values, sharedStoreState) {
        let token = sharedStoreState.currentRoute.params.token;
        resetPassword(token, values.password);

        state.showLoadingIndicator = true;
    }

    [ActionTypes.PROFILE_PASSWORD_RESET_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        if (action.result.passwordResetTimedOut) {
            state.showPasswordResetTimedOutMsg = true;
            state.resetPasswordUrl = prepareUrl.prepareRouteUrl('/profile/forgot-password');
            state.visibleElements = {
                resetPasswordForm: false
            };
        } else {

            state.visibleElements = {
                resetPasswordSuccess: true
            };

            state.visibleElements.additionallyEmailWasVerified = action.result.emailVerificationOk;

            state.signInUrl = prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl("/", action.result.username));
        }
    }

    [ActionTypes.PROFILE_PASSWORD_RESET_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default PasswordResetStore;
