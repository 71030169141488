import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes';
import constants from '../../Constants.js';

import storeFormManager from '../validation/StoreFormManager.js';

import addNewUser from '../../actions/actionCreators/users/AddNewUser.js';
import searchCpbUsers from '../../actions/actionCreators/users/SearchCpbUsers.js';
import searchAllUsers from '../../actions/actionCreators/users/SearchAllUsers.js';
import resendAccountActivationLink from '../../actions/actionCreators/users/ResendAccountActivationLink.js';
import createApiAcccount from '../../actions/actionCreators/users/createApiAcccount.js';
import resendUserInvitation from '../../actions/actionCreators/users/ResendUserInvitation.js';
import updateUser from '../../actions/actionCreators/users/UpdateUser.js';
import grantUserPermissions from '../../actions/actionCreators/users/GrantUserPermissions.js';
import deleteUser from '../../actions/actionCreators/users/DeleteUser.js';
import removeUserPermissions from '../../actions/actionCreators/users/RemoveUserPermissions.js';
import getOrganizationUsers from '../../actions/actionCreators/organization/GetOrganizationUsers';
import getCpbOrganizationUsers from '../../actions/actionCreators/organization/GetCpbOrganizationUsers';
import getEditUserInfo from '../../actions/actionCreators/users/GetEditUserInfo.js';
import getPermissionsManagedByCurrentUser from '../../actions/actionCreators/users/GetPermissionsManagedByCurrentUser.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import getUsersListRowClickedHandler from '../../actions/actionCreators/users/UsersListRowClicked.js';
import getUserRepresentedOrgs from '../../actions/actionCreators/users/GetUserRepresentedOrgs.js';
import updateUserMainOrg from '../../actions/actionCreators/users/UpdateUserMainOrg.js';
import getPasswordRecoveryLink from '../../actions/actionCreators/users/GetPasswordRecoveryLink.js';
import getEmailVerificationLink from '../../actions/actionCreators/users/GetEmailVerificationLink.js';
import getInvitationLink from '../../actions/actionCreators/users/GetInvitationLink.js';
import checkTokenOfRequestToJoinOrg from '../../actions/actionCreators/users/CheckTokenOfRequestToJoinOrg.js';
import cookie from 'react-cookie';
import { whiteSpaceRestrictor,
    getBlacklistedEmailValidator, emailValidator, emailsStringValidator,
    getMatchValidator, getMaxLengthValidator, requiredValidator,
    passwordValidator, arrayHelpers} from '@vaultit/stv-utils-frontend';


import prepareUrl from '../../helpers/PrepareUrl.js';

const storeKey = "users";

class UsersStore extends FormStore {

    getInitialState() {
        let initialState = {
            showEditingUserForm: false,
            showLoadingIndicator: false,
            showDeletedSuccessMessage: false,
            showPermissionsRemovedSuccessMessage: false,
            showUpdatedSuccessMessage: false,
            showSearchStatusMessages: false,
            userList: [],
            cpbUserList: [],
            searchCpbUserList: [],
            searchAllUserList: [],
            allFetchedUsers: [],
            servicesManagedByCurrentUser:{
                permissionCheckboxIsVisibleForCurrentUser: {},
                permissionIsAddedByDefaultForInvitedUsersByCurrentUser: {},
                showNoticeMsgNextToCheckboxForCurrentUser: {}
            },
            removedUsersFromList: [],
            showFastPermissionAssignment: false,
            fastPermissionAssignmentBtnDisabled: false,
            showPermissionsGrantedSuccessMessage: false,
            showInvitationResendSuccessMessage: false,
            showInviteUserButton: true,
            showCreateNewApiAccountButton: false,
            userChangingHisMainType: false,
            userEditedFieldsValues: [],
            reloadUsers: true,
            canViewAllPermissions: false,
            canInviteMainUser: false,
            canRemoveUsers: false,
            canViewBuildSiteRegTab: false,
            canManageGlobalPermissions: false,
            canManageAdminAndQATPermissions: false,
            canViewGlobalPermissions: false,
            selectedPermissionFilter: false,
            canEditOnlyIlmoita: false,
            showIlmoitaPermissionEditNotice: false,
            permissionsToShowInAddForm: [],
            permissionsToPreselectInAddForm: [],
            hideUserTypesInAddForm: false,
            createNewApiAccountPageUrl: false,
            showApiAccountSuccessMessage: false,
            showAddAndImportUsersBtn: true,
            uiConfiguration: {},
            editingUser: {},
            inactiveRoleOptionIsVisible: false,
            signatoryRegisteredThroughEmailInvitation: false,
            showSignatoryRegisteredThroughEmailInvitationMessage: false,
            userModalData: {
                firstName : '',
                lastName : '',
                email: '',
                organizationName: ''
            },
            constants: {
                userTypes: constants.userTypes,
                permissions: constants.permissions,
                maxNoOfUsersThatCanBeAddedAtTime: constants.maxNoOfUsersThatCanBeAddedAtTime,
                userPermissions: {}
            },
            userMainOrganisationInfo: {
                orgName: '',
                orgCode: '',
                orgCountryCode: ''
            },
            showChangeUserMainOrgForm: false,
            changeMainUserOrg: {
                showSuccessMessage: false
            },
            showAdminPanelInEditForm: false,
            currentFormName: null,
            dismissImportEmailsModalAfterClick: false,
            disabledOptionForInactiveOrgUser: false,
            editFormShowRolesManagement: false,
            showGeneratePasswordResetLink: false,
            showGenerateEmailVerificationLink: false,
            showGenerateInvitationLink: false,
            showPasswordResetLinkModal: false,
            showEmailVerificationLinkModal: false,
            showInvitationLinkModal:false,
            generatedLink: '',
            disablePasswordResetLink: false,
            showChangeMainOrgForRapportiValvojaServices: false,
            showResendAccountActivationLinkNoManualIdVerificationMsg: false,
            showAccountActivationLinkSuccessfullyResentMsg: false,
            resendAccountActivationLinkEmail: '',
            showUserAlreadyBelongsToOrgMessage: false,
            emailOfAlreadyExistingOrgUser: null,
            exceededUsersWithoutCompanyLimit: false,
            canEditUserPermissions: false
        };

        let filterUsersFormFieldDefinitions = {
            usersFilterValue: {
                defaultValue: '',
            }
        };

        let findCpbUsersFormFieldDefinitions = {
            usersFilterValue: {
                defaultValue: '',
            }
        };

        let findAllUsersFormFieldDefinitions = {
            usersFilterValue: {
                defaultValue: '',
            }
        };

        let quickPermissionGrantFormFieldDefinitions = {
            permission: {
                defaultValue: '',
            },
            email: {
                defaultValue: '',
            }
        };

        let creatingApiAccountFormFieldDefinitions = {
            email: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            },
            firstName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
            userType: {
                defaultValue: '',
                validators: [requiredValidator]
            }
        };

        let editingUserFormFieldDefinitions = {
            name: {
                defaultValue: '',
            },
            email: {
                defaultValue: '',
            },
            userType: {
                defaultValue: '',
                validators: [requiredValidator]
            },
            isActive: {
                defaultValue: ''
            },
            firstInvitedAt: {
                defaultValue: ''
            }
        };

        let addingUserFormFieldDefinitions = {
            email: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                isRepeatedField: true,
                repeatedFieldCount: 1 // one email field by default
            },
            userType: {
                defaultValue: '',
                validators: [requiredValidator]
            },
        };

        let changeMainOrgFieldDefinitions = {
            mainOrg: {
                defaultValue: ''
            }
        };

        let importEmailsFieldDefinitions = {
            importedEmails: {
                defaultValue: '',
                validators: [emailsStringValidator]
            }
        };

        let resendAccountActivationLinkFieldDefinitions = {
            email: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            }
        }

        storeFormManager.defineForm(initialState, storeKey, "quickPermissionsGrantForm", quickPermissionGrantFormFieldDefinitions, this.onQuickPermissionGrantFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, "filterUsersForm", filterUsersFormFieldDefinitions);

        storeFormManager.defineForm(initialState, storeKey, "findCpbUsersForm", findCpbUsersFormFieldDefinitions, this.onFindCpbUsersFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, "findAllUsersForm", findAllUsersFormFieldDefinitions, this.onFindAllUsersFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, "addingUserForm", addingUserFormFieldDefinitions, this.onAddUserFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, "creatingApiAccountForm", creatingApiAccountFormFieldDefinitions, this.onCreateApiAccountFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, "editingUserForm", editingUserFormFieldDefinitions, this.onEditUserFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, "changeUserMainOrgForm", changeMainOrgFieldDefinitions, this.onChangeUserMainOrgSubmitted);

        storeFormManager.defineForm(initialState, storeKey, 'importUserEmailsForm', importEmailsFieldDefinitions, this.onImportUserEmailsFormSubmitted);

        storeFormManager.defineForm(initialState, storeKey, 'resendAccountActivationLinkForm', resendAccountActivationLinkFieldDefinitions, this.onResendAccountActivationLinkFormSubmitted);

        return initialState;
    }

    getFieldValueChangedHandlerCfg() {
        return [
            {
                storeKey: storeKey,
                formKey: 'quickPermissionsGrantForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'addingUserForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'editingUserForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'changeUserMainOrgForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'creatingApiAccountForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'importUserEmailsForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'findCpbUsersForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'findAllUsersForm',
                handler: this.fieldValueChanged
            },
            {
                storeKey: storeKey,
                formKey: 'resendAccountActivationLinkForm',
                handler: this.fieldValueChanged
            }

        ];
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/organization/users',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_ORGANIZATION_PERSON_ACCOUNT',
                loadHandler: this.onOrganizationUsersRouteLoadedRedirectUser
            },
            {
                fullPath: '/users/list/all',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_ALL_PERMISSIONS',
                loadHandler: this.onOrganizationUsersRouteLoaded
            },
            {
                fullPath: '/users/list/:serviceName',
                loadHandler: this.onOrganizationUsersRouteLoaded
            },
            {
                fullPath: '/users/list/' + constants.permissions.global,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS',
                loadHandler: this.onOrganizationUsersRouteLoaded
            },
            {
                fullPath: '/users/add',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_INVITE',
                loadHandler: this.addingUserRouteLoaded
            },
            {
                fullPath: '/users/add/:email',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_INVITE',
                loadHandler: this.addingUserRouteLoaded
            },
            {
                fullPath: '/users/verify-company-access-request/:token',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_INVITE',
                loadHandler: this.verifyCompanyAccessRequestRouteLoaded
            },
            {
                fullPath: '/users/createapiaccount',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_ORGANIZATION_USERS_CREATE_API_ACCOUNT',
                loadHandler: this.creatingApiAccountRouteLoaded
            },
            {
                fullPath: '/users/edit/:userId',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_EDIT_ORGANIZATION_PERSON_ACCOUNT',
                loadHandler: this.editingUserRouteLoaded
            },
            {
                fullPath: '/users/changemainorg/:userId',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CHANGE_MAIN_USER_TILAAJAVASTUU_ACCOUNT_ORGANISATION',
                loadHandler: this.changeMainUserOrgRouteLoaded
            },
            {
                fullPath: '/cpb-users/:cpbOrgId',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_CPB_USERS',
                loadHandler: this.onCpbUsersRouteLoaded
            },
            {
                fullPath: '/cpb-find-users',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_CPB_USERS',
                loadHandler: this.onCpbFindUsersRouteLoaded
            },
            {
                fullPath: '/find-users',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_SEARCH_ALL_USERS_WITHOUT_COMPANY',
                loadHandler: this.onAllFindUsersRouteLoaded
            },
            {
                fullPath: '/resend-account-activation-link(/:email)',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_RESEND_ACCOUNT_ACTIVATION_LINK',
                loadHandler: this.onResendAccountActivationLinkRouteLoaded
            }
        ];
    }

    onOrganizationUsersRouteLoadedRedirectUser(state, action, sharedStoreState) {
        if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_ALL_PERMISSIONS')) {
            redirectToRoute("/users/list/all", true, false, sharedStoreState.representedOrganizationCode);
        }
    }

    onResendAccountActivationLinkRouteLoaded(state, action, sharedStoreState) {
        if (sharedStoreState.currentRoute.params && sharedStoreState.currentRoute.params.email) {
            state.formDefinitions.resendAccountActivationLinkForm.fieldDefinitions.email.defaultValue = sharedStoreState.currentRoute.params.email;
        }

        storeFormManager.setFormInitialState(state, "resendAccountActivationLinkForm");
        state.showResendAccountActivationLinkNoManualIdVerificationMsg = false;
        state.showAccountActivationLinkSuccessfullyResentMsg = false;

        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };
    }

    filterUsersBySelectedPermission(state, action, sharedStoreState) {
        state.userList = [];
        state.removedUsersFromList = [];

        let tab = sharedStoreState.currentRoute.params.serviceName
            ? sharedStoreState.currentRoute.params.serviceName
            : sharedStoreState.currentRoute.fullPath.split('/').pop();

        let permission = state.selectedPermissionFilter ? state.selectedPermissionFilter : tab;

        if (!state.selectedPermissionFilter && tab === constants.permissions.global) {
            let userCanManageAdminAndQATPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_SERVICE_PORTAL_ADMIN_AND_QAT_PERMISSIONS');
            if (userCanManageAdminAndQATPermissions) {
                permission = sharedStoreState.classifiers.globalPermissions[0].code;
            } else {
                let permissionsManagedOnlyBySuperAdmin = sharedStoreState.classifiers.permissionsManagedBySuperAdmin;
                for (var i in sharedStoreState.classifiers.globalPermissions) {
                    if (permissionsManagedOnlyBySuperAdmin.indexOf(sharedStoreState.classifiers.globalPermissions[i].code) == -1) {
                        permission = sharedStoreState.classifiers.globalPermissions[i].code;
                        break;
                    }
                }
            }
        }

        if (tab === 'all') {
            state.userList = state.allFetchedUsers;
            state.showFastPermissionAssignment = false;
            state.showInviteUserButton = true;
            state.showCreateNewApiAccountButton = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_ORGANIZATION_USERS_CREATE_API_ACCOUNT');
        } else {
            state.showFastPermissionAssignment = true;
            state.showInviteUserButton = false;
            state.showCreateNewApiAccountButton = false;

            state.allFetchedUsers.forEach(function(user) {

                let userHasPermission = (state.canManageGlobalPermissions && user.globalPermissions
                    && user.globalPermissions.indexOf(permission) > -1) || user.permissions.indexOf(permission) > -1;

                if (tab === constants.permissions.global) {

                    let userHasAnyOfGlobalPermission = (state.canManageGlobalPermissions && user.globalPermissions && user.globalPermissions.length);

                    if (userHasAnyOfGlobalPermission) {
                        state.userList.push(user);
                    }

                } else {
                    if (!userHasPermission) {
                        let joinedPermission = false;
                        let serviceJoinedByPermission = '';
                        for (var serviceName in state.uiConfiguration.services) {
                            let service = state.uiConfiguration.services[serviceName];

                            if (service.permission == permission) {
                                serviceJoinedByPermission = service.joinedByPermission && state.uiConfiguration.services[serviceJoinedByPermission]
                                    ? state.uiConfiguration.services[serviceJoinedByPermission]
                                    : '';

                                if (serviceJoinedByPermission && serviceJoinedByPermission.permission) {
                                    joinedPermission = serviceJoinedByPermission.permission;
                                    break;
                                }
                            }
                        }
                        userHasPermission = joinedPermission && user.permissions.indexOf(joinedPermission) > -1;
                    }
                    if (userHasPermission) {
                        state.userList.push(user);
                    }
                }

                if (!userHasPermission) {
                    state.removedUsersFromList.push(user);
                }
            });

            state.formDefinitions['quickPermissionsGrantForm'].fieldDefinitions['permission'].defaultValue = permission;
        }

        state.userList.forEach((user) => {
            user.handleRowClick = getUsersListRowClickedHandler(user.userId, sharedStoreState.representedOrganizationCode);
        });

        storeFormManager.setFormInitialState(state, "quickPermissionsGrantForm");
        state.showLoadingIndicator = false;
    }


    onOrganizationUsersRouteLoaded(state, action, sharedStoreState) {
        storeFormManager.setFormInitialState(state, "filterUsersForm");

        state.changeMainUserOrg.showSuccessMessage = false;
        state.hideUserTypesInAddForm = false;
        state.showInvitedSuccessMessage = state.showInvitedSuccessMessageInListAfterRedirect;
        state.showInvitedSuccessMessageInListAfterRedirect = false;
        state.showDeletedSuccessMessage = false;
        state.showPermissionsRemovedSuccessMessage = false;
        state.showUpdatedSuccessMessage = false;
        state.showApiAccountSuccessMessage = false;
        state.showPermissionsGrantedSuccessMessage = false;
        state.showInvitationResendSuccessMessage = false;
        state.showUserAlreadyBelongsToOrgMessage = state.emailOfAlreadyExistingOrgUser;
        state.emailOfAlreadyExistingOrgUser = null;
        state.addUserPageUrl = prepareUrl.prepareRouteUrl('/users/add', false, sharedStoreState.representedOrganizationCode);
        state.createNewApiAccountPageUrl = prepareUrl.prepareRouteUrl('/users/createapiaccount', false, sharedStoreState.representedOrganizationCode);
        state.userIsInEditingPage = false;
        state.selectedPermissionFilter = false;
        state.canViewAllPermissionsTab = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_ALL_PERMISSIONS');
        state.canManageGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.canManageAdminAndQATPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_SERVICE_PORTAL_ADMIN_AND_QAT_PERMISSIONS');
        state.canViewGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VIEW_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.canInviteNewUsers = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_INVITE');
        state.canRemoveUsers = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_DELETE_ALL');
        state.canEditUserPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_EDIT_ORGANIZATION_PERSON_ACCOUNT');
        state.fastPermissionAssignmentBtnDisabled = !this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_GRANT_PERMISSIONS');
        state.backToListUrl = prepareUrl.prepareRouteUrl('/organization/users', false, sharedStoreState.representedOrganizationCode);
        state.notSupportedUserPermissionsWarningUrl = prepareUrl.prepareRouteUrl('/users/notsupportedpermission', false, sharedStoreState.representedOrganizationCode);
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        state.signatoryRegisteredThroughEmailInvitation = cookie.load(constants.cookieNames.signatoryRegisteredThroughEmailInvitation);
        state.showSignatoryRegisteredThroughEmailInvitationMessage = state.signatoryRegisteredThroughEmailInvitation;

        this.setConstantUserPermissions(state);

        cookie.remove(constants.cookieNames.signatoryRegisteredThroughEmailInvitation);

        if (state.reloadUsers) {
            state.showLoadingIndicator = true;
            getOrganizationUsers(sharedStoreState.representedOrganizationCode);
        } else {
            this.filterUsersBySelectedPermission(state, action, sharedStoreState);
        }
    }

    onCpbUsersRouteLoaded(state, action, sharedStoreState) {
        storeFormManager.setFormInitialState(state, "filterUsersForm");
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};

        state.showLoadingIndicator = true;

        this.setConstantUserPermissions(state);

        let cpbOrgId = sharedStoreState.currentRoute.params.cpbOrgId;
        getCpbOrganizationUsers(sharedStoreState.representedOrganizationCode, cpbOrgId);
    }

    setConstantUserPermissions(state) {
        for (let serviceName in state.uiConfiguration.services) {
            let service = state.uiConfiguration.services[serviceName];
            if (service.permission) {
                state.constants.userPermissions[service.permission] = service.title
            }
        }
    }

    onCpbFindUsersRouteLoaded(state, action, sharedStoreState) {
        state.showSearchStatusMessages = false;
        state.exceededUsersWithoutCompanyLimit = false;
        state.searchCpbUserList = [];
        storeFormManager.setFormInitialState(state, "findCpbUsersForm");
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
    }

    onAllFindUsersRouteLoaded(state, action, sharedStoreState) {
        state.showSearchStatusMessages = false;
        storeFormManager.setFormInitialState(state, "findAllUsersForm");
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
    }

    [ActionTypes.ROUTE_ENTERED](state, action) {
        if (action.route.fullPath !== '/users/list/all'
            && action.route.fullPath !== '/users/list/:serviceName'
            && action.route.fullPath !== '/users/list/' + constants.permissions.global) {
            state.reloadUsers = true;
        }

        if (action.route.fullPath !== '/users/add') {
            state.permissionsToShowInAddForm = [];
            state.permissionsToPreselectInAddForm = [];
            state.hideUserTypesInAddForm = false;
        }
    }

    [ActionTypes.ORGANIZATION_USERS_GET_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.allFetchedUsers = action.result.userList;
        state.servicesManagedByCurrentUser = action.result.servicesManagedByCurrentUser;

        state.reloadUsers = false;
        this.filterUsersBySelectedPermission(state, action, sharedStoreState);

        if (state.showDeletedSuccessMessageAfterUsersLoaded) {
            state.showDeletedSuccessMessageAfterUsersLoaded = false;
            state.showDeletedSuccessMessage = true;
        }

        if (state.showUpdatedSuccessMessageAfterUsersLoaded) {
            state.showUpdatedSuccessMessageAfterUsersLoaded = false;
            state.showUpdatedSuccessMessage = true;
        }

        if (state.showApiAccountSuccessMessageAfterUsersLoaded) {
            state.showApiAccountSuccessMessageAfterUsersLoaded = false;
            state.showApiAccountSuccessMessage = true;
        }

        if (state.showPermissionsGrantedSuccessMessageAfterUsersLoaded) {
            state.showPermissionsGrantedSuccessMessageAfterUsersLoaded = false;
            state.showPermissionsGrantedSuccessMessage = true;
        }

        if (state.showPermissionsRemovedSuccessMessageAfterUsersLoaded) {
            state.showPermissionsRemovedSuccessMessageAfterUsersLoaded = false;
            state.showPermissionsRemovedSuccessMessage = true;
        }
    }

    [ActionTypes.ORGANIZATION_USERS_GET_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CPB_ORGANIZATION_USERS_GET_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.cpbUserList = action.result.userList;
        state.selectedOrgName = action.result.selectedOrgName;
    }

    [ActionTypes.CPB_ORGANIZATION_USERS_GET_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.SEARCH_CPB_USERS_GET_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.searchCpbUserList = action.result.userList;
        state.exceededUsersWithoutCompanyLimit = action.result.showTooManyPeopleMessage;
        state.selectedOrgName = action.result.selectedOrgName;
        state.showSearchStatusMessages = true;
    }

    [ActionTypes.SEARCH_CPB_USERS_GET_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = true;
    }

    [ActionTypes.SEARCH_ALL_USERS_GET_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.searchAllUserList = action.result.userList
        state.showSearchStatusMessages = true;
    }

    [ActionTypes.SEARCH_ALL_USERS_GET_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showSearchStatusMessages = true;
    }

    creatingApiAccountRouteLoaded(state, action, sharedStoreState) {
        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };
        sharedStoreState.classifiers.globalPermissions.forEach(function(value) {
            state.formDefinitions['creatingApiAccountForm'].fieldDefinitions[value.code] = {defaultValue: ''};
        });
        state.canManageGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.canManageAdminAndQATPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_SERVICE_PORTAL_ADMIN_AND_QAT_PERMISSIONS');
        state.backToListUrl = prepareUrl.prepareRouteUrl('/organization/users', false, sharedStoreState.representedOrganizationCode);
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        state.currentFormName = 'creatingApiAccountForm';
        getPermissionsManagedByCurrentUser(sharedStoreState.representedOrganizationCode);
    }

    addingUserRouteLoaded(state, action, sharedStoreState) {
        state.showInvitedSuccessMessageInListAfterRedirect = false;
        state.showMessageInCard = false;
        state.addedUsersList = null;
        state.skippedAlreadyInOrgUsersList = null;
        state.skippedWithManyOrgUsersList = null;
        state.userIsInEditingPage = false;
        state.dismissImportEmailsModalAfterClick = false;

        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };

        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};

        this.hideAllMessagesOnAddingRouteEntered(state, sharedStoreState);
        state.canManageGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.canManageAdminAndQATPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_SERVICE_PORTAL_ADMIN_AND_QAT_PERMISSIONS');
        state.canViewGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VIEW_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.backToListUrl = prepareUrl.prepareRouteUrl('/organization/users', false, sharedStoreState.representedOrganizationCode);
        state.canInviteMainUser = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_INVITE_MAIN_USER');

        sharedStoreState.classifiers.globalPermissions.forEach(function(value) {
            state.formDefinitions['addingUserForm'].fieldDefinitions[value.code] = {defaultValue: ''};
        });
        state.currentFormName = 'addingUserForm';
        getPermissionsManagedByCurrentUser(sharedStoreState.representedOrganizationCode);
    }

    verifyCompanyAccessRequestRouteLoaded(state, action, sharedStoreState) {
        this.hideAllMessagesOnAddingRouteEntered(state, sharedStoreState);
        state.showLoadingIndicator = true;
        let routeParams = sharedStoreState.currentRoute.params;
        let token = routeParams.token;
        let orgId = sharedStoreState.representedOrganizationCode;
        checkTokenOfRequestToJoinOrg(orgId, {'token': token});
    }

    [ActionTypes.CHECK_REQUEST_TOKEN_SUCCESS] (state, action, sharedStoreState) {
        let result = action.result;
        let username = result.email;
        let userAllreadyInOrg = result.userBelongsToOrg;
        let orgId = sharedStoreState.representedOrganizationCode;
        if (userAllreadyInOrg) {
            state.emailOfAlreadyExistingOrgUser = username;
            redirectToRoute(`/users/list/all`, false, false, orgId);
        } else {
            redirectToRoute(`/users/add/${username}`, false, false, orgId);
        }
    }

    [ActionTypes.CHECK_REQUEST_TOKEN_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    hideAllMessagesOnAddingRouteEntered(state, sharedStoreState) {
        state.showInvitedSuccessMessageInListAfterRedirect = false;
        state.showMessageInCard = false;
        state.addedUsersList = null;
        state.skippedAlreadyInOrgUsersList = null;
        state.skippedWithManyOrgUsersList = null;
        state.userIsInEditingPage = false;
        state.dismissImportEmailsModalAfterClick = false;
        state.emailOfAlreadyExistingOrgUser = null;

        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
    }

    [ActionTypes.GET_PERMISSIONS_MANAGED_BY_CURRENT_USER_SUCCESS](state, action, sharedStoreState) {
        state.servicesManagedByCurrentUser = action.result.permissionsManagedByCurrentUser;

        state.formDefinitions[state.currentFormName].fieldDefinitions['email'].repeatedFieldCount = 1;

        for (var service in state.uiConfiguration.services) {
            let serviceName = service.charAt(0).toUpperCase() + service.slice(1);
            let isVisible = state.servicesManagedByCurrentUser.permissionCheckboxIsVisibleForCurrentUser[service];
            let isChecked = state.servicesManagedByCurrentUser.permissionIsAddedByDefaultForInvitedUsersByCurrentUser[service];

            if (isVisible && state.uiConfiguration.services[service]['permission']) {
                state.formDefinitions[state.currentFormName].fieldDefinitions['permissionToUse' + serviceName] = {
                    defaultValue: isChecked
                };
            }
        }

        storeFormManager.setFormInitialState(state, state.currentFormName);

        if (state.currentFormName === 'addingUserForm') {

            if (sharedStoreState.currentRoute.params.email) {
                state.addingUserForm.fields.email[0].value = sharedStoreState.currentRoute.params.email;
            }

            if (state.permissionsToPreselectInAddForm) {
                state.permissionsToPreselectInAddForm.forEach(function(permission) {

                    state.addingUserForm.fields.userType.value = constants.userTypes.basic;
                    state.permissionsToShowInAddForm = [permission];
                    state.hideUserTypesInAddForm = true;
                    let config = state.uiConfiguration.services;
                    for (let serviceFromConfig in config) {
                        if (config[serviceFromConfig].permission && config[serviceFromConfig].permission === permission) {
                            let serviceName = serviceFromConfig.charAt(0).toUpperCase() + serviceFromConfig.slice(1);
                            state.addingUserForm.fields['permissionToUse' + serviceName].value = true;
                            break;
                        }
                    };
                    if (state.canManageGlobalPermissions) {
                        for (let i in sharedStoreState.classifiers.globalPermissions) {
                            if (sharedStoreState.classifiers.globalPermissions[i].code === permission) {
                                state.addingUserForm.fields[sharedStoreState.classifiers.globalPermissions[i].code].value = true;
                                state.addingUserForm.fields.userType.value = '';
                                state.permissionsToShowInAddForm = [];
                                state.hideUserTypesInAddForm = false;
                                break;
                            }
                        }
                    }
                });
            }
        }

        state.showLoadingIndicator = false;
    }

    [ActionTypes.GET_PERMISSIONS_MANAGED_BY_CURRENT_USER_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    onQuickPermissionGrantFormSubmitted(state, values, sharedStoreState) {
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = null;
        let neededValues = {};

        if (values.permission && !values.email) {
            redirectToRoute("/users/add", false, false, orgId);
            if (state.canManageGlobalPermissions && values.permission === 'qat_admin') {
                state.permissionsToPreselectInAddForm = ['qat_admin', 'qat_user'];
            } else {
                state.permissionsToPreselectInAddForm = [values.permission];
            }
        } else {
            state.fastPermissionAssignmentBtnDisabled = true;

            state.allFetchedUsers.forEach(function(user) {
                if (user.email === values.email) {
                    userId = user.userId;
                    neededValues['userType'] = user.userRole;

                    for (var service in state.uiConfiguration.services) {
                        if (state.uiConfiguration.services[service]['permission'] &&
                        (state.uiConfiguration.services[service]['permission'] === values.permission
                        || user.permissions.indexOf(state.uiConfiguration.services[service]['permission']) > -1)) {
                            let serviceName = service.charAt(0).toUpperCase() + service.slice(1);
                            let formFieldName = 'permissionToUse' + serviceName.charAt(0).toUpperCase() + serviceName.slice(1);
                            neededValues[formFieldName] = true;
                        }
                    }

                    if (state.canManageGlobalPermissions) {
                        sharedStoreState.classifiers.globalPermissions.forEach(function(value) {
                            neededValues[value.code] = (values.permission === value.code || (user.globalPermissions && user.globalPermissions.indexOf(value.code) > -1) ? true: false);
                        });
                        if (neededValues['qat_admin'] === true) {
                            neededValues['qat_user'] = true;
                        }
                    }
                }
            });

            state.showLoadingIndicator = true;
            grantUserPermissions(orgId, userId, neededValues);
        }
    }

    [ActionTypes.ORGANIZATION_USERS_PERMISSIONS_GRANTED_SUCCESS](state, action, sharedStoreState) {
        state.showPermissionsGrantedSuccessMessageAfterUsersLoaded = true;
        state.fastPermissionAssignmentBtnDisabled = false;
        getOrganizationUsers(sharedStoreState.representedOrganizationCode);
    }

    [ActionTypes.ORGANIZATION_USERS_PERMISSIONS_GRANTED_ERROR](state) {
        state.showLoadingIndicator = false;
        state.fastPermissionAssignmentBtnDisabled = false;
    }

    onAddUserFormSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        let orgId = sharedStoreState.representedOrganizationCode;
        addNewUser(orgId, values);
    }

    onFindCpbUsersFormSubmitted(state, values, sharedStoreState) {
        if (state.findCpbUsersForm.fields.usersFilterValue.value.length > 2) {
            state.showLoadingIndicator = true;
            state.showSearchStatusMessages = false;
            searchCpbUsers(values, sharedStoreState.representedOrganizationCode);
        }
    }

    onFindAllUsersFormSubmitted(state, values, sharedStoreState) {
        if (state.findAllUsersForm.fields.usersFilterValue.value.length > 2) {
            state.showLoadingIndicator = true;
            state.showSearchStatusMessages = false;
            searchAllUsers(values, sharedStoreState.representedOrganizationCode);
        }
    }

    onResendAccountActivationLinkFormSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.showResendAccountActivationLinkNoManualIdVerificationMsg = false;
        state.showAccountActivationLinkSuccessfullyResentMsg = false;
        let orgId = sharedStoreState.representedOrganizationCode;
        resendAccountActivationLink(values.email, orgId);

    }

    onCreateApiAccountFormSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        createApiAcccount(sharedStoreState.representedOrganizationCode, values);
    }

    fieldValueChanged(state, action, sharedStoreState) {
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, false, false, '');
        if (action.storeKey === storeKey) {

            state.showInvitedSuccessMessageInListAfterRedirect = false;
            state.emailOfAlreadyExistingOrgUser = null;
            state.showMessageInCard = false;
            state.userChangingHisMainType = false;
            state.clearTypeaheadInput = false;

            if ((action.fieldKey === 'qat_admin' && action.newValue === true && state[action.formKey].fields['qat_user'].value !== true) ||
                (action.fieldKey === 'qat_user' && action.newValue === false && state[action.formKey].fields['qat_admin'].value == true)) {
                state[action.formKey].fields['qat_user'].value = true;
            }

            const bolAdmin = 'permissionToUseBol_admin';
            const bol = 'permissionToUseBolagsdeklaration';
            if ((action.fieldKey === bolAdmin && action.newValue === true && state[action.formKey].fields[bol].value !== true) ||
                (action.fieldKey === bol && action.newValue === false && state[action.formKey].fields[bolAdmin].value === true)) {
                state[action.formKey].fields[bol].value = true;
            }

            if (action.formKey === 'quickPermissionsGrantForm' && action.fieldKey === 'permission') {
                state.selectedPermissionFilter = action.newValue;
                this.filterUsersBySelectedPermission(state, action, sharedStoreState);
            }

            if (action.formKey === 'editingUserForm') {

                if (constants.userTypes.main === state.editingUser.userRole &&
                    constants.userTypes.basic === state.editingUserForm.fields.userType.value &&
                    sharedStoreState.profile &&
                    state.editingUser.userId === sharedStoreState.profile.personId) {
                    state.userChangingHisMainType = true;
                }

                if (state.inactiveRoleOptionIsVisible) {
                    state.disabledOptionForInactiveOrgUser = constants.userTypes.inactive === state.editingUserForm.fields.userType.value;
                }
            }

            if (action.formKey === 'importUserEmailsForm') {
                if (sharedStoreState.authInfo.topMenuParameters.configuration.style.validateWhileTyping) {
                    state.dismissImportEmailsModalAfterClick = state.importUserEmailsForm.isValid;
                }
            }

            if (action.formKey == 'addingUserForm') {
                state.showAddAndImportUsersBtn = state.addingUserForm.fields.email.length < constants.maxNoOfUsersThatCanBeAddedAtTime;
            }

            if (action.formKey == 'findCpbUsersForm') {
                state.showSearchStatusMessages = false;
            }

            if (action.formKey == 'findAllUsersForm') {
                state.showSearchStatusMessages = false;
            }
        }
    }

    [ActionTypes.ORGANIZATION_USERS_ADD_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.addedUsersList = action.result.successfullySentEmails;
        state.skippedAlreadyInOrgUsersList = action.result.skippedBecauseAlreadyExists;
        state.skippedWithManyOrgUsersList = action.result.skippedBecauseHaveMaxNumberOfRepresentations;
        state.showInvitedSuccessMessageInListAfterRedirect = false;
        state.showMessageInCard = false;
        state.messageType = 'success';

        state.allAdded = false;
        state.someAddedSomeSkipped = false;
        state.allAlreadyAddedSkipped = false;
        state.allHaveTooManyOrg = false;
        state.allAddedAndError = false;
        state.someAddedSomeSkippedAndError = false;
        state.someUsersWereSkipped = false;
        state.allAlreadyAddedSkippedAndError = false;
        state.showAddAndImportUsersBtn = true;

        let someUsersWereAdded = state.addedUsersList.length > 0;
        let someExistingUsersWereSkipped = state.skippedAlreadyInOrgUsersList.length > 0;
        let someWithManyOrgsUsersWereSkipped = state.skippedWithManyOrgUsersList.length > 0;

        // When all users added
        if (someUsersWereAdded && !someExistingUsersWereSkipped && !someWithManyOrgsUsersWereSkipped) {
            state.showInvitedSuccessMessageInListAfterRedirect = true;
            state.messageType = 'success';
            state.allAdded = true;

        // When some users added and some of them skipped
        } else if ((someUsersWereAdded && someExistingUsersWereSkipped && !someWithManyOrgsUsersWereSkipped)
            || (someUsersWereAdded && !someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped)
            || (someUsersWereAdded && someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped)) {
            state.showInvitedSuccessMessageInListAfterRedirect = true;
            state.messageType = 'success';
            state.someAddedSomeSkipped = true;

        // When all existing users skipped
        } else if (!someUsersWereAdded && someExistingUsersWereSkipped && !someWithManyOrgsUsersWereSkipped) {
            state.showMessageInCard = true;
            state.messageType = 'warning';
            state.allAlreadyAddedSkipped = true;

        // When all users with too many representations were skipped
        } else if (!someUsersWereAdded && !someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped) {
            state.showMessageInCard = true;
            state.messageType = 'warning';
            state.allHaveTooManyOrg = true;

        // When some skipped users already existed or had too many representations
        } else if (!someUsersWereAdded && someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped) {
            state.showMessageInCard = true;
            state.messageType = 'warning';
            state.someUsersWereSkipped = true;
        }

        if (state.showInvitedSuccessMessageInListAfterRedirect) {
            redirectToRoute("/organization/users", true, false, sharedStoreState.representedOrganizationCode);
        }
    }

    [ActionTypes.ORGANIZATION_USERS_ADD_ERROR](state, action) {
        state.showLoadingIndicator = false;
        state.addedUsersList = action.errorData && action.errorData.successfullySentEmails ? action.errorData.successfullySentEmails : [];
        state.skippedAlreadyInOrgUsersList = action.errorData && action.errorData.skippedBecauseAlreadyExists ? action.errorData.skippedBecauseAlreadyExists : [];
        state.skippedWithManyOrgUsersList = action.errorData && action.errorData.skippedBecauseHaveMaxNumberOfRepresentations ? action.errorData.skippedBecauseHaveMaxNumberOfRepresentations : [];
        state.showInvitedSuccessMessageInListAfterRedirect = false;
        state.showMessageInCard = false;
        state.messageType = 'success';

        state.allAdded = false;
        state.someAddedSomeSkipped = false;
        state.allAlreadyAddedSkipped = false;
        state.allAddedAndError = false;
        state.someAddedSomeSkippedAndError = false;
        state.allAlreadyAddedSkippedAndError = false;

        let someUsersWereAdded = state.addedUsersList.length > 0;
        let someExistingUsersWereSkipped = state.skippedAlreadyInOrgUsersList.length > 0;
        let someWithManyOrgsUsersWereSkipped = state.skippedWithManyOrgUsersList.length > 0;

        // When all users added + Error occurred
        if (someUsersWereAdded && !someExistingUsersWereSkipped && !someWithManyOrgsUsersWereSkipped) {
            state.showMessageInCard = true;
            state.messageType = 'success';
            state.allAddedAndError = true;
        }
        // When some users added and some of them skipped + Error occurred
        if ((someUsersWereAdded && someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped)
        || (someUsersWereAdded && !someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped)
        || (someUsersWereAdded && someExistingUsersWereSkipped && !someWithManyOrgsUsersWereSkipped)) {
            state.showMessageInCard = true;
            state.messageType = 'success';
            state.someAddedSomeSkippedAndError = true;
        }
        // When all users skipped + Error occurred
        if ((!someUsersWereAdded && someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped)
        || (!someUsersWereAdded && !someExistingUsersWereSkipped && someWithManyOrgsUsersWereSkipped)
        || (!someUsersWereAdded && someExistingUsersWereSkipped && !someWithManyOrgsUsersWereSkipped)) {
            state.showMessageInCard = true;
            state.messageType = 'warning';
            state.allAlreadyAddedSkippedAndError = true;
        }
    }

    editingUserRouteLoaded(state, action, sharedStoreState) {
        state.showEditingUserForm = false;
        state.userIsInEditingPage = true;
        state.showLoadingIndicator = true;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, false, false, '');
        state.changeMainOrgUrl = prepareUrl.prepareRouteUrl('/users/changemainorg/' + sharedStoreState.currentRoute.params.userId, false, sharedStoreState.representedOrganizationCode);
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};

        state.canManageGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.canManageAdminAndQATPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_SERVICE_PORTAL_ADMIN_AND_QAT_PERMISSIONS');
        state.canViewGlobalPermissions = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VIEW_ORGANIZATION_PERSONS_GLOBAL_PERMISSIONS');
        state.canInviteMainUser = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_MANAGE_ORGANIZATION_PERSONS_INVITE_MAIN_USER');
        state.showChangeMainOrgForRapportiValvojaServices = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CHANGE_MAIN_USER_TILAAJAVASTUU_ACCOUNT_ORGANISATION');

        sharedStoreState.classifiers.globalPermissions.forEach(function(value) {
            state.formDefinitions['editingUserForm'].fieldDefinitions[value.code] = {defaultValue: ''};
        });

        state.backToListUrl = prepareUrl.prepareRouteUrl('/organization/users', false, sharedStoreState.representedOrganizationCode);

        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = sharedStoreState.currentRoute.params.userId;
        getEditUserInfo(orgId, userId);
    }

    [ActionTypes.EDIT_USER_INFO_GET_SUCCESS](state, action, sharedStoreState) {
        state.servicesManagedByCurrentUser = action.result.servicesManagedByCurrentUser;

        for (var service in state.uiConfiguration.services) {
            let serviceName = service.charAt(0).toUpperCase() + service.slice(1);
            let isVisible = state.servicesManagedByCurrentUser.permissionCheckboxIsVisibleForCurrentUser[service];

            if (isVisible && state.uiConfiguration.services[service]['permission']) {
                state.formDefinitions['editingUserForm'].fieldDefinitions['permissionToUse' + serviceName] = {
                    defaultValue: action.result.permissions.indexOf(state.uiConfiguration.services[service]['permission']) >= 0
                };
            }
        }

        state.inactiveRoleOptionIsVisible =
            state.uiConfiguration.servicePortal.userManagement.enabledInactiveUserRole
            && action.result.userRole === state.constants.userTypes.inactive;

        state.disabledOptionForInactiveOrgUser = state.inactiveRoleOptionIsVisible;

        storeFormManager.setFormInitialState(state, "editingUserForm");

        if (action.result.isActive) {
            state.editingUserForm.fields.name.value = action.result.fullName;
        }

        state.editingUser = action.result;

        state.showGeneratePasswordResetLink = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_GENERATE_PASSWORD_RECOVERY_LINK_FOR_OTHER_USER');
        state.disablePasswordResetLink = action.result && (!action.result.userHasUsername || !action.result.userAccountIsActive);
        state.showGenerateEmailVerificationLink = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_GENERATE_EMAIL_VERIFICATION_LINK_FOR_OTHER_USER') && action.result && action.result.emailCanBeVerified;
        state.showGenerateInvitationLink = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_GENERATE_USER_INVITATION_LINK_FOR_OTHER_USER') && action.result && !action.result.userBegunRegistrationProcess;
        state.editingUserForm.fields.isActive.value = action.result.isActive;
        state.editingUserForm.fields.email.value = action.result.email;
        state.editingUserForm.fields.userType.value = action.result.userRole;
        state.editingUserForm.fields.firstInvitedAt.value = action.result.firstInvitedAt;

        state.showAdminPanelInEditForm = state.showChangeMainOrgForRapportiValvojaServices
        || state.showGeneratePasswordResetLink  || state.showGenerateEmailVerificationLink || state.showGenerateInvitationLink;

        sharedStoreState.classifiers.globalPermissions.forEach(function(value) {
            state.editingUserForm.fields[value.code].value = action.result.globalPermissions && action.result.globalPermissions.indexOf(value.code) >= 0;
        });

        if (action.result.mainRepresentation) {
            state.userMainOrganisationInfo.orgName = action.result.mainRepresentation.name;
            state.userMainOrganisationInfo.orgCode = action.result.mainRepresentation.number;
            state.userMainOrganisationInfo.orgCountryCode = action.result.mainRepresentation.country;
        }

        state.showLoadingIndicator = false;
        state.showEditingUserForm = true;

    }

    [ActionTypes.EDIT_USER_INFO_GET_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    onEditUserFormSubmitted(state, values, sharedStoreState) {
        state.changeMainUserOrg.showSuccessMessage = false;
        if (!state.userChangingHisMainType) {
            state.showLoadingIndicator = true;
            let orgId = sharedStoreState.representedOrganizationCode;
            let userId = sharedStoreState.currentRoute.params.userId;
            updateUser(orgId, userId, values);
        } else {
            state.confirmationModalUserId = sharedStoreState.currentRoute.params.userId;
            state.userEditedFieldsValues = values;
            state.userModalData = {
                organizationName: sharedStoreState.representedOrgName
            };
        }
    }

    [ActionTypes.ORGANIZATION_USERS_UPDATE_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        if (state.userChangingHisMainType) {
            state.userChangingHisMainType = false;
            redirectToRoute("/organization/overview", true, false, sharedStoreState.representedOrganizationCode);
        } else {
            state.showUpdatedSuccessMessageAfterUsersLoaded = true;
            redirectToRoute("/organization/users", true, false, sharedStoreState.representedOrganizationCode);
        }
    }

    [ActionTypes.ORGANIZATION_USERS_UPDATE_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    changeMainUserOrgRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.changeMainUserOrg.showSuccessMessage = false;
        state.showChangeUserMainOrgForm = false;

        let userId = sharedStoreState.currentRoute.params.userId;
        let orgId = sharedStoreState.representedOrganizationCode;

        state.backToEditUrl = prepareUrl.prepareRouteUrl('users/edit/' + userId, false, orgId);
        state.formDefinitions['changeUserMainOrgForm'].fieldDefinitions[status.code] = {defaultValue: ''};
        storeFormManager.setFormInitialState(state, "changeUserMainOrgForm");

        getUserRepresentedOrgs(orgId, userId);
    }

    [ActionTypes.GET_USER_REPRESENTED_ORGS_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        state.allUserRepresentedOrgs = action.result.representations;
        state.representedUser = {
            id: action.result.userId,
            email: action.result.email,
            fullName: action.result.fullName,
        };

        state.allUserRepresentedOrgs.forEach((org) => {
            if (org.is_main_org) {
                state.changeUserMainOrgForm.fields.mainOrg.value = org.id;
                state.newUserMainOrgId = org.id;
            }
        });

        state.showChangeUserMainOrgForm = true;

    }

    [ActionTypes.GET_USER_REPRESENTED_ORGS_ERROR](state) {
        state.showLoadingIndicator = false;
        state.showChangeUserMainOrgForm = false;
    }

    onChangeUserMainOrgSubmitted(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;

        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = sharedStoreState.currentRoute.params.userId;

        updateUserMainOrg(orgId, userId, values);
    }

    [ActionTypes.ORGANIZATION_USERS_UPDATE_MAIN_ORG_SUCCESS](state, action, sharedStoreState) {
        redirectToRoute("/users/edit/"+ sharedStoreState.currentRoute.params.userId, true, false, sharedStoreState.representedOrganizationCode);
        state.showLoadingIndicator = false;
        state.changeMainUserOrg.showSuccessMessage = true;
    }

    [ActionTypes.ORGANIZATION_USERS_UPDATE_MAIN_ORG_ERROR](state) {
        state.showLoadingIndicator = false;
    }


    [ActionTypes.ORGANIZATION_USERS_RESEND_INVITATION_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        if (action.userId) {
            let orgId = sharedStoreState.representedOrganizationCode;
            resendUserInvitation(orgId, action.userId);
        }
        state.showInvitedSuccessMessage = false;
        state.showDeletedSuccessMessage = false;
        state.showPermissionsRemovedSuccessMessage = false;
        state.showUpdatedSuccessMessage = false;
        state.showApiAccountSuccessMessage = false;
        state.showPermissionsGrantedSuccessMessage = false;
        state.showInvitationResendSuccessMessage = false;
        state.showSignatoryRegisteredThroughEmailInvitationMessage = false;
    }

    [ActionTypes.ORGANIZATION_USERS_RESEND_INVITATION_SUCCESS](state) {
        state.showInvitationResendSuccessMessage = true;
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ORGANIZATION_USERS_RESEND_INVITATION_ERROR](state) {
        state.showInvitationResendSuccessMessage = false;
        state.showLoadingIndicator = false;
    }


    [ActionTypes.ORGANIZATION_USERS_REMOVE_PERMISSIONS_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.showInvitedSuccessMessage = false;
        state.showDeletedSuccessMessage = false;
        state.showPermissionsRemovedSuccessMessage = false;
        state.showUpdatedSuccessMessage = false;
        state.showApiAccountSuccessMessage = false;
        state.showPermissionsGrantedSuccessMessage = false;
        state.showInvitationResendSuccessMessage = false;
        state.showSignatoryRegisteredThroughEmailInvitationMessage = false;
        removeUserPermissions(sharedStoreState.representedOrganizationCode, action.userId, action.permission);
    }

    [ActionTypes.ORGANIZATION_USERS_DELETE_BTN_CLICKED](state, action, sharedStoreState) {

        state.userModalData = {
            firstName : '',
            lastName : '',
            email: '',
            organizationName: ''
        };

        if (state.userIsInEditingPage) {

            state.confirmationModalUserId = state.editingUser.userId;

            if (state.editingUser.isActive) {
                state.userModalData.firstName = state.editingUser.firstName;
                state.userModalData.lastName = state.editingUser.lastName;
            }
            state.userModalData.email = state.editingUser.email;
            state.userModalData.organizationName = sharedStoreState.representedOrgName;
        } else {
            state.confirmationModalUserId = action.userId;

            state.userList.forEach(function(user) {
                if (action.userId === user.userId) {
                    if (user.isActive) {
                        state.userModalData.firstName = user.firstName;
                        state.userModalData.lastName = user.lastName;
                    }
                    state.userModalData.email = user.email;
                    state.userModalData.organizationName = sharedStoreState.representedOrgName;
                }
            });
        }

        state.showInvitedSuccessMessage = false;
        state.showDeletedSuccessMessage = false;
        state.showPermissionsRemovedSuccessMessage = false;
        state.showUpdatedSuccessMessage = false;
        state.showApiAccountSuccessMessage = false;
        state.showPermissionsGrantedSuccessMessage = false;
        state.showInvitationResendSuccessMessage = false;
        state.showSignatoryRegisteredThroughEmailInvitationMessage = false;
    }


    [ActionTypes.CONFIRMATION_MODAL_OK_BTN_CLICKED](state, action, sharedStoreState) {
        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = state.confirmationModalUserId;
        state.showLoadingIndicator = true;

        if (state.userChangingHisMainType) {
            updateUser(orgId, userId, state.userEditedFieldsValues);
        } else {
            deleteUser(orgId, userId);
        }

    }

    [ActionTypes.ORGANIZATION_USERS_PERMISSIONS_REMOVE_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.showPermissionsRemovedSuccessMessageAfterUsersLoaded = true;
        getOrganizationUsers(sharedStoreState.representedOrganizationCode);
    }

    [ActionTypes.ORGANIZATION_USERS_PERMISSIONS_REMOVE_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ORGANIZATION_USERS_DELETE_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;

        if (sharedStoreState.profile && state.confirmationModalUserId === sharedStoreState.profile.personId) {

            if (!sharedStoreState.authInfo || sharedStoreState.loadingAuthInfo) {
                state.redirectToBasicDetailsAfterAuthStateWasLoaded = true;
            } else {
                this.redirectToUserBasicDetails();
            }

        } else {
            if (state.userIsInEditingPage) {
                state.reloadUsers = true;
                redirectToRoute("/organization/users", true, false, sharedStoreState.representedOrganizationCode);
            } else {
                getOrganizationUsers(sharedStoreState.representedOrganizationCode);
            }
            state.showDeletedSuccessMessageAfterUsersLoaded = true;
        }
    }

    [ActionTypes.AUTH_INFO_GET_SUCCESS](state) {
        if (state.redirectToBasicDetailsAfterAuthStateWasLoaded) {
            state.redirectToBasicDetailsAfterAuthStateWasLoaded = false;
            this.redirectToUserBasicDetails();
        }
    }

    redirectToUserBasicDetails() {
        redirectToRoute("/profile/basic-details", true);
    }

    [ActionTypes.ORGANIZATION_USERS_DELETE_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ORGANIZATION_USERS_CLEAR_FILTER_BTN_CLICKED](state) {
        storeFormManager.setFormInitialState(state, "filterUsersForm");
        storeFormManager.setFormInitialState(state, "findCpbUsersForm");
        storeFormManager.setFormInitialState(state, "findAllUsersForm");
    }

    [ActionTypes.ORGANIZATION_USERS_CREATE_API_ACCOUNT_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        state.showApiAccountSuccessMessageAfterUsersLoaded = true;
        redirectToRoute("/organization/users", true, false, sharedStoreState.representedOrganizationCode);
    }

    [ActionTypes.ORGANIZATION_USERS_CREATE_API_ACCOUNT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.ORGANIZATION_USERS_LIST_ROW_CLICKED](state, action) {
        if (state.canEditUserPermissions) {
          redirectToRoute("/users/edit/" + action.userId, false, false, action.orgCode);
        }
    }

    [ActionTypes.OPEN_IMPORT_EMAILS_MODAL_BTN_CLICKED](state) {
        state.dismissImportEmailsModalAfterClick = false;
        storeFormManager.setFormInitialState(state, "importUserEmailsForm");
    }

    onImportUserEmailsFormSubmitted(state, values, sharedStoreState) {
        let importedEmailsString = values.importedEmails.replace(/,/g, ';');
        let emails = importedEmailsString.split(";").map((v) => { return v.trim(); }).filter((v) => { return v !=''; });
        let language = sharedStoreState.localizations.messagesLanguage;

        for (let emailIndex in emails) {
            let email = emails[emailIndex];
            let emptyValueUsed = false;

            for (let i=0; i < state.addingUserForm.fields.email.length; i++) {
                if (state.addingUserForm.fields['email' + i] && state.addingUserForm.fields['email' + i].value == '') {
                    state.addingUserForm.fields['email' + i].value = email;
                    emptyValueUsed = true;
                    break;
                }
            }

            if (!emptyValueUsed) {
                let repeatedField = {
                    fieldDefinitionKey: 'email',
                    formKey: 'addingUserForm',
                    storeKey: 'users'
                };
                storeFormManager.onRepeatedFieldAdded(state, repeatedField);
                state.addingUserForm.fields['email' + (state.addingUserForm.fields.email.length - 1)].value = email;
            }
        }
        arrayHelpers.sortAlphabetically(state.addingUserForm.fields.email, (item) => item.value, language);
    }

    [ActionTypes.GENERATE_PASSWORD_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = sharedStoreState.currentRoute.params.userId;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, false, false, '');
        getPasswordRecoveryLink(orgId, userId);
    }

    [ActionTypes.GET_PASSWORD_RECOVERY_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, true, false, action.result.passwordRecoveryLink);
    }

    [ActionTypes.GET_PASSWORD_RECOVERY_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GENERATE_EMAIL_VERIFICATION_BTN_CLICKED](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = sharedStoreState.currentRoute.params.userId;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, false, false, '');
        getEmailVerificationLink(orgId, userId);
    }

    [ActionTypes.GET_EMAIL_VERIFICATION_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, true, false, false, action.result.emailVerificationLink);
    }

    [ActionTypes.GET_EMAIL_VERIFICATION_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.GENERATE_INVITATION_LINK_BTN_CLICKED] (state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        let orgId = sharedStoreState.representedOrganizationCode;
        let userId = sharedStoreState.currentRoute.params.userId;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, false, false, '');
        getInvitationLink(orgId, userId);
    }

    [ActionTypes.GET_GENERATED_INVITATION_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        this.visibilityOfAdminGeneratedLinksAndModalWindows(state, false, false, true, action.result.invitationLink);
    }

    [ActionTypes.GET_GENERATED_INVITATION_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        state.resendAccountActivationLinkEmail = action.result.email;
        if (action.result.noManualIdVerificationFound) {
          state.showResendAccountActivationLinkNoManualIdVerificationMsg = true;
        } else {
          state.showAccountActivationLinkSuccessfullyResentMsg = true;
        }
    }

    [ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_BACK_BTN_CLICKED] () {
        redirectToRoute('/', true);
    }

    visibilityOfAdminGeneratedLinksAndModalWindows(state, emailVerificationLink, passwordVerificationLink, ivitationLink, generatedLink) {
        state.showEmailVerificationLinkModal = emailVerificationLink;
        state.showInvitationLinkModal = ivitationLink;
        state.showPasswordResetLinkModal = passwordVerificationLink;
        state.generatedLink = generatedLink;
    }

}

export default UsersStore;
