import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let globalSearchBlockUserCancelIconClicked = function(event, blockValue, reasonValue) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GLOBAL_SEARCH_BLOCK_USER_CANCEL_ICON_CLICKED,
        block: blockValue,
        reason: reasonValue
    });
}

export default globalSearchBlockUserCancelIconClicked;