import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';

let okBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CONFIRMATION_MODAL_OK_BTN_CLICKED
    });
};

export default okBtnClicked;
