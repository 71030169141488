import BaseStore from '../base/BaseStore.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';

class CardReviewLandingPageStore extends BaseStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false
        };
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/confirm-order(/:cardSupplier)',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CONFIRM_CARD_ORDER',
                loadHandler: this.onConfirmOrderRouteLoaded
            }
        ];
    }

    onConfirmOrderRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        if (sharedStoreState.authInfo) {
            let myCardsLink = sharedStoreState.authInfo.topMenuParameters.topMenuLinks.myCardsLink;
            redirectToUrl(myCardsLink, true, true);
        }
    }
}

export default CardReviewLandingPageStore;
