import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';

import { intlShape, defineMessages, FormattedMessage } from 'react-intl';

import { FormRow, PageHeader, FormColumn, FormGroup, ValidationMessages, CheckboxInput, Message } from '@vaultit/stv-utils-frontend';
import prepareUrl from '../../helpers/PrepareUrl.js';

const messages = defineMessages({
    continue: {
        id: 'acceptToS.continue',
        description: 'Accept ToS continue button text',
        defaultMessage: 'Continue'
    }
});

class AcceptTOS extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let store = this.state.acceptToS;

        let form = store.acceptToSForm;

        if (!form) {
            return null;
        }

        let fields = form ? form.fields : null;

        let userName = store.userName;

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters && this.state.shared.authInfo.topMenuParameters
            ? this.state.shared.authInfo.topMenuParameters.configuration
            : null;

        let brandLogo = uiConfiguration && uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow
            ? <span className="brand-logo"></span>
            : null;

        let currentLanguage = this.state.shared.localizations.messagesLanguage;

        let userProfile = this.state.shared.authInfo.userProfile;

        return (
            <div>
                { brandLogo }
                <PageHeader
                    pageTitleMessageId="acceptToS.title"
                    sharedStore={this.state.shared}
                    hideSeparator={true}
                />
                {userProfile.personTosAccepted
                    ?
                        <div>
                            <Message id="infoMessage" type="success" className="mb-6">
                                <FormattedMessage id="acceptToS.alreadyAccepted.message" defaultMessage="Terms of service already accepted." />
                            </Message>
                            <a
                                id="registration_create_user_success_to_the_service"
                                className="btn btn-primary btn-lg"
                                href="/"
                            >
                                <FormattedMessage id="acceptToS.alreadyAccepted.toTheServiceBtn" defaultMessage="To service" />
                            </a>
                        </div>
                    :
                    <div>
                        <h3>
                            <FormattedMessage
                                id="acceptToS.message"
                                description="Accept ToS info message"
                                defaultMessage="Hello {userName}, and welcome to {serviceName} services! To continue, please accept the terms of service below."
                                values={{
                                    userName: userName,
                                    serviceName: uiConfiguration.serviceNameToDisplay[currentLanguage]
                                }}
                            />
                        </h3>
                        <form name="acceptToSForm" noValidate="">
                            <FormRow>
                                <FormColumn width="12">
                                    <FormGroup>
                                        <ValidationMessages field={fields.acceptTOS} required="registration.step1.TOS.required">
                                            <CheckboxInput
                                                id="accept_tos_terms_of_service_cbx"
                                                checked={fields.acceptTOS.value}
                                                field={fields.acceptTOS}
                                                messageId="acceptToS.acceptServices"
                                                defaultMessage="I accept the "
                                            >
                                                &nbsp;
                                                <a id="accept_tos_terms_of_service_printable_version_link" onClick={ (e) => prepareUrl.openUrlInPopUpWindow(e, this.state.acceptToS.termsOfServicePdfUrl) } href="#">
                                                    <FormattedMessage id="acceptToS.generalTOS" defaultMessage="general terms of service" />
                                                </a>.
                                            </CheckboxInput>

                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                            <FormGroup>
                                <input
                                    id="accept_tos_continue_btn"
                                    type="submit"
                                    className="btn btn-primary btn-lg mt-3"
                                    value={formatMessage(messages.continue) || ""}
                                    onClick={form.handleOnSubmitBtnClicked}
                                    role="button"
                                />
                            </FormGroup>
                        </form>
                    </div>
                }

            </div>
        );
    }
}

export default AcceptTOS;
