import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let searchAllUsers = function(filter) {

    postToBackend('/users/search-all-users',
        ActionTypes.SEARCH_ALL_USERS_GET_SUCCESS,
        ActionTypes.SEARCH_ALL_USERS_GET_ERROR,
        filter,
        {
            clearPreviousErrors: true
        });

};

export default searchAllUsers;
