import React from 'react';

import StoreComponent from '../../base/StoreComponent.jsx';

import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { FormRow, PageHeader, Message, ValidationMessages, FormGroup, TextInput, FormColumn } from '@vaultit/stv-utils-frontend';
import changeEmailContinueAfterChangeBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailContinueAfterChangeBtnClicked.js';
import changeEmailVerifyYouAccountWithBankIdBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailVerifyYouAccountWithBankIdBtnClicked.js';
import changeEmailVerifyYouAccountWithDokobitBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailVerifyYouAccountWithDokobitBtnClicked.js';
import changeEmailVerifyYouAccountWithFrejaEIDSwedishBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailVerifyYouAccountWithFrejaEIDSwedishBtnClicked.js';
import changeEmailVerifyYouAccountWithFrejaEIDNonSwedishBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailVerifyYouAccountWithFrejaEIDNonSwedishBtnClicked.js';

const messages = defineMessages({
    continue: {
        id: 'profile.changeEmail.continueToCompanyAccount',
        description: 'Continue to company account button text',
        defaultMessage: 'Continue to company account'
    },
    currentPassword: {
        id: 'profile.changeEmail.currentPassword',
        description: 'Current password placeholder',
        defaultMessage: 'Your current password'
    },
    newPasswordPlaceholder: {
        id: 'profile.changePassword.newPassword',
        description: 'New password placeholder',
        defaultMessage: 'Password'
    },
    newPasswordAgainPlaceholder: {
        id: 'profile.changePassword.newPasswordAgain',
        description: 'New password again placeholder',
        defaultMessage: 'Password again'
    }
});

class ChangeEmailVerification extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        }
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters && this.state.shared.authInfo.topMenuParameters
            ? this.state.shared.authInfo.topMenuParameters.configuration
            : null;

        let brandLogo = uiConfiguration && uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow
            ? <span className="brand-logo"></span>
            : null;

        let form = this.state.changeEmailVerification.changeEmailForm;
        let fields = form ? form.fields : null;

        let changeEmailSetPasswordForm = this.state.changeEmailVerification.changeEmailSetPasswordForm;
        let changeEmailSetPasswordFields = changeEmailSetPasswordForm ? changeEmailSetPasswordForm.fields : null;

        let userIsAlreadyVerifiedMessageLinks = {
            resetLink: <a href={this.state.changeEmailVerification.forgotPasswordLink}>
                <FormattedMessage id="profile.changeEmail.hereLink" defaultMessage="here" />
            </a>,
            signInLink: <a href={this.state.shared.authInfo.otherUiInfo.frontendUrl}>
                <FormattedMessage id="profile.changeEmail.hereLink" defaultMessage="here" />
            </a>
        };

        let userVerifiedWithDokobit = this.state.shared.authInfo.otherUiInfo.userIsVerifiedByDokobit;
        let userVerifiedWithBankId = this.state.shared.authInfo.otherUiInfo.userIsVerifiedByBankId;
        let userVerifiedWithFrejaEID = this.state.shared.authInfo.otherUiInfo.userIsVerifiedByFrejaEid;
        let userVerifiedWithFrejaEIDExt = this.state.shared.authInfo.otherUiInfo.userIsVerifiedByFrejaEidExt;
        let userAuthenticationIsSelfAssured = this.state.shared.authInfo.otherUiInfo.userAuthenticationIsSelfAssured;

        let isSignedIn = this.state.shared.authInfo.otherUiInfo.isSignedIn;

        let verifyWithBankIdBtn = (
            <a href="#" id="profile-verify-with-bankid" onClick={changeEmailVerifyYouAccountWithBankIdBtnClicked}>
                <FormattedMessage id="profile.changeEmail.verifyWithBankId" defaultMessage="BankID" />
            </a>
        );

        let verifyWithDokobitBtn = (
            <a href="#" id="profile-verify-with-dokobit"  onClick={changeEmailVerifyYouAccountWithDokobitBtnClicked}>
                <FormattedMessage id="profile.changeEmail.verifyWithDokobit" defaultMessage="eID (non Swedish users)" />
            </a>
        );

        let verifyWithFrejaEID = (
            <a href="#" id="profile-verify-with-frejaeid" onClick={changeEmailVerifyYouAccountWithFrejaEIDSwedishBtnClicked}>
                <FormattedMessage id="profile.changeEmail.verifyWithFrejaEIDSwedish" defaultMessage="Freja eID for Swedish users" />
            </a>
        );

        let verifyWithFrejaEIDExt = (
            <a href="#" id="profile-verify-with-frejaeidext" onClick={changeEmailVerifyYouAccountWithFrejaEIDNonSwedishBtnClicked}>
                <FormattedMessage id="profile.changeEmail.verifyWithFrejaEIDNonSwedish" defaultMessage="Freja eID for non Swedish users" />
            </a>
        );

        return (
            <div>
                { brandLogo }
                <PageHeader
                    pageTitleMessageId="emailverification.title"
                    sharedStore={this.state.shared}
                />

                { this.state.changeEmailVerification.visibleElements.passwordVerificationNeeded
                    ? <div>
                        { this.state.changeEmailVerification.showChangedEmailInvalidPasswordMessage
                            ?
                                <Message id="errorMessage" type="danger">
                                    <FormattedMessage
                                        id="profile.changeEmail.passwordIncorrect"
                                        defaultMessage="Oops! Please type the correct password to continue"
                                    />
                                </Message>
                            : null
                        }
                        { this.state.changeEmailVerification.showChangedEmailDuplicateEmailMessage
                            ?
                                <Message id="errorMessage" type="danger">
                                    <FormattedMessage
                                        id="profile.changeEmail.duplicateEmail"
                                        defaultMessage="E-mail {email} is already used for another user account. If you want to change this account to use the reserved e-mail address and it's yours, first sign in to that account to change the e-mail address of that account to something else, or contact our customer service."
                                        values={{
                                            email: null
                                        }}
                                    />
                                </Message>
                            : null
                        }
                        { form
                            ? <FormRow>
                                <FormColumn width="12">
                                    <div className="alert alert-default my-5">
                                        <FormattedMessage id="profile.changeEmail.passwordNotice" defaultMessage="For security reasons you have enter your current password to change email" />
                                    </div>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.currentPassword}>
                                            <TextInput
                                                id="change-email-current-password"
                                                type="password"
                                                field={fields.currentPassword}
                                                messageId="profile.changeEmail.currentPassword"
                                                defaultMessage="Password"
                                                required={true}
                                                placeholder={formatMessage(messages.currentPassword) }
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="12">
                                    <div className="form-group mt-6">
                                        <button
                                            id="profile-change-email-continue"
                                            type="button"
                                            className="btn btn-primary btn-lg mr-3 mb-3"
                                            onClick={form.handleOnSubmitBtnClicked}
                                            role="button"
                                        >
                                            <FormattedMessage
                                                id="emailverification.continue"
                                                defaultMessage="Continue"
                                            />
                                        </button>

                                        {isSignedIn
                                            ? !userAuthenticationIsSelfAssured && (userVerifiedWithDokobit || userVerifiedWithBankId || userVerifiedWithFrejaEID)
                                                ?
                                                    <div>
                                                        <FormattedMessage id="emailverification.verifyWith" defaultMessage="or Verify your account with" />
                                                        &nbsp;
                                                        {  userVerifiedWithDokobit
                                                                ? verifyWithDokobitBtn
                                                                : userVerifiedWithBankId
                                                                    ? verifyWithBankIdBtn
                                                                    : userVerifiedWithFrejaEIDExt
                                                                        ? verifyWithFrejaEIDExt
                                                                        : userVerifiedWithFrejaEID
                                                                            ? verifyWithFrejaEID
                                                            : null
                                                        }
                                                    </div>
                                                : null
                                            :
                                            <div>
                                                <FormattedMessage id="emailverification.verifyWith" defaultMessage="or Verify your account with" />
                                                &nbsp;{verifyWithBankIdBtn}
                                                &nbsp;<FormattedMessage id="emailverification.verifyWithOr" defaultMessage="or" />
                                                &nbsp;{verifyWithFrejaEID}
                                                &nbsp;<FormattedMessage id="emailverification.verifyWithOr" defaultMessage="or" />
                                                &nbsp;{verifyWithFrejaEIDExt}
                                                &nbsp;<FormattedMessage id="emailverification.verifyWithOr" defaultMessage="or" />
                                                &nbsp;{verifyWithDokobitBtn}
                                            </div>
                                        }
                                    </div>
                                </FormColumn>
                            </FormRow>
                            : null
                        }
                    </div>
                    : null }

                { this.state.changeEmailVerification.visibleElements.userMismatch
                    ?
                        <Message type="danger">
                            <FormattedMessage
                                id="profile.changeEmail.userMismatch"
                                defaultMessage="You have requested to change your user account email {email}. You must sign in to the same account to change it."
                                values={{email: <strong>{this.state.changeEmailVerification.usernameWhichInitiated}</strong>}}
                            />
                        </Message>
                    : null
                }

                { this.state.changeEmailVerification.visibleElements.ninValidationFailed
                    ?
                        <Message type="danger">
                            <FormattedMessage
                                id="profile.changeEmail.ninValidationFailed"
                                defaultMessage="User {email} authentication failed."
                                values={{email: <strong>{this.state.changeEmailVerification.usernameWhichInitiated}</strong>}}
                            />
                        </Message>
                    : null
                }

                { this.state.changeEmailVerification.visibleElements.setPasswordNeeded
                    ? <div>
                        <Message type="default">
                            <FormattedMessage id="profile.changeEmail.newPasswordNotice" defaultMessage="For security reasons, you have to enter new password to change the email." />
                        </Message>

                        { this.state.changeEmailVerification.showChangedEmailPasswordSameAsPreviousMessage
                            ?
                                <Message id="errorMessage" type="danger">
                                    <FormattedMessage
                                        id="profile.changeEmail.passwordMustBeDifferent"
                                        defaultMessage="The new password must be different from the old one"
                                    />
                                </Message>
                            : null
                        }

                        <FormGroup className="mt-6">
                            <FormattedMessage
                                id="profile.changePassword.selectNewPassword"
                                defaultMessage="Select the new password. Password has to contain at least 8 characters:"
                            />
                        </FormGroup>
                        <FormRow>
                            <FormColumn width="6">
                                <FormGroup>
                                    <ValidationMessages field={changeEmailSetPasswordFields.newPassword} mismatch="errors.validation.field.mismatch.passwords">
                                        <TextInput
                                            id="change_password_new_password"
                                            type="password"
                                            field={changeEmailSetPasswordFields.newPassword}
                                            messageId="profile.changePassword.newPassword"
                                            defaultMessage="New password"
                                            required={true}
                                            placeholder={formatMessage(messages.newPasswordPlaceholder) }
                                        />
                                    </ValidationMessages>
                                </FormGroup>
                            </FormColumn>
                            <FormColumn width="6">
                                <FormGroup>
                                    <ValidationMessages field={changeEmailSetPasswordFields.newPasswordAgain} match="errors.validation.field.match.passwords">
                                        <TextInput
                                            id="change_password_new_password_again"
                                            type="password"
                                            field={changeEmailSetPasswordFields.newPasswordAgain}
                                            messageId="profile.changePassword.newPasswordAgain"
                                            defaultMessage="Current password"
                                            required={true}
                                            placeholder={formatMessage(messages.newPasswordAgainPlaceholder) }
                                        />
                                    </ValidationMessages>
                                </FormGroup>
                            </FormColumn>
                            <FormColumn width="12">
                                <div className="form-group mt-6">
                                    <button
                                        id="profile-change-email-set-new-password"
                                        type="button"
                                        className="btn btn-primary btn-lg mr-3"
                                        onClick={changeEmailSetPasswordForm.handleOnSubmitBtnClicked}
                                        role="button"
                                    >
                                        <FormattedMessage id="profile.changePassword.setPassword" defaultMessage="Set password" />
                                    </button>
                                </div>
                            </FormColumn>
                        </FormRow>
                    </div>
                    : null
                }

                { this.state.changeEmailVerification.visibleElements.emailChangeSuccessMessageAndContinueBtn
                    ? <div>
                        <Message id="SuccessMessage-3" type="success">
                            <FormattedMessage
                                id="profile.changeEmail.yourEmailIsNow"
                                description="Your email address is now aaa@bbb.lt"
                                defaultMessage="Your email address is now {email}"
                                values={{email: <b>{this.state.changeEmailVerification.updatedUsername}</b>}}
                            />
                            <br />
                            <FormattedMessage
                                id="profile.changeEmail.pleaseUseNewEmail"
                                description="From now on, always use your new email address"
                                defaultMessage="From now on, always use your new email address to sign in. The old email has been removed from the system."
                            />
                            <br />
                            <input
                                id="email_verification_continue_registration_btn"
                                type="submit"
                                className="btn btn-primary btn-lg mt-6"
                                value={formatMessage(messages.continue) || ""}
                                onClick={changeEmailContinueAfterChangeBtnClicked}
                                role="button"
                            />
                        </Message>


                    </div>
                    : null }

                { this.state.changeEmailVerification.userIsAlreadyVerified
                    ? <div>
                        <Message id="SuccessMessage-3" type="success">
                            <FormattedMessage
                                id="profile.changeEmail.emailIsAlreadyVerified"
                                description="Email has already been successfully verified after email change"
                                defaultMessage="Your email has been already verified. Please sign in {signInLink}. If you forgot your password please reset it {resetLink}."
                                values={userIsAlreadyVerifiedMessageLinks}
                            />
                        </Message>
                    </div>
                    : null}
            </div>
        )
    }
}

export default ChangeEmailVerification;
