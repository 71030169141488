import ActionTypes from '../ActionTypes.js';
import {getFromBackend} from './generic/CallBackend.js';

let redirectToExternalApp = function() {

    getFromBackend('/general/redirect-back-to-app-that-initiated-login',
        ActionTypes.REDIRECT_TO_EXTERNAL_APP_SUCCESS,
        ActionTypes.REDIRECT_TO_EXTERNAL_APP_ERROR);
};

export default redirectToExternalApp;
