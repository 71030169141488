import BaseStore from './base/BaseStore.js';
import prepareUrl from '../helpers/PrepareUrl.js';
import redirectToUrl from '../actions/actionCreators/navigation/RedirectToUrl.js';
import redirectToRoute from '../actions/actionCreators/navigation/RedirectToRoute.js';
import { getUrlForOtherApps, spFrameworkUtilsConstants } from '@vaultit/stv-utils-frontend';
import log from '../singletons/Log.js';

class PageLoaderStore extends BaseStore {

    getInitialState() {
        return {
        };
    }

    onAnyAction(state, action, sharedStoreState) {

        if (sharedStoreState.authInfo
            && !sharedStoreState.loadingAuthInfo
            && !sharedStoreState.savingUsedLanguage) {

            let isInHomePage = sharedStoreState.currentRoute.fullPath === '/';
            let isInUserUpdateUserInfoFromStrongAuthPage = sharedStoreState.currentRoute.fullPath === '/profile/update-user-info';
            let uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
            let updateUsernameFromStrongAuth = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_UPDATE_USER_INFO_FROM_STRONGAUTH_INFO');
            let identityVerificationIsHidden = this.userHasPermission(sharedStoreState, 'SEPPO_IDENTITY_VERIFICATION_IS_HIDDEN');
            let identityVerificationIsHiddenPage = sharedStoreState.currentRoute.fullPath.indexOf('/profile/identity-verification-is-hidden') !== -1;
            let forceCreateUserAccount = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_FORCE_CREATE_FULL_ACCOUNT');
            let forceCreateUserAccountPage = sharedStoreState.currentRoute.fullPath.indexOf('/profile/create-full-account') !== -1;
            let anonymousAuthenticatedThroughProvisionMode = this.userHasPermission(sharedStoreState, 'SEPO_ACTION_ANONYMOUS_AUTHENTICATED_TROUGH_PROVISION_MODE');
            let isInBasicDetailsPage = sharedStoreState.currentRoute.fullPath === '/profile/basic-details';

            let forceVisitManualIdPartnerAfterRegistration = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_FORCE_VISIT_MANUAL_ID_PARTNER_AFTER_REGISTRATION');
            let isInForceVisitManualIdPartnerAfterRegistrationPage = sharedStoreState.currentRoute.fullPath === '/profile/visit-manual-id-verification-partner';

            let identityVerifiedCheckYourEmail = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CHECK_EMAIL_IDENTITY_VERIFIED_WITH_MANUAL_ID');
            let isInIdentityVerifiedCheckEmailPage = sharedStoreState.currentRoute.fullPath === '/profile/identity-verified-check-your-email';

            if (identityVerificationIsHidden) {
                if (!identityVerificationIsHiddenPage) {
                    redirectToRoute("/profile/identity-verification-is-hidden", true);
                }
            } else if ((!forceCreateUserAccountPage && forceCreateUserAccount) || (isInBasicDetailsPage && anonymousAuthenticatedThroughProvisionMode)) {
                redirectToRoute("/profile/create-full-account", true);
            } else if (!isInUserUpdateUserInfoFromStrongAuthPage && updateUsernameFromStrongAuth) {
                redirectToRoute("/profile/update-user-info", true);
            } else if (!isInIdentityVerifiedCheckEmailPage && identityVerifiedCheckYourEmail) {
                redirectToRoute("/profile/identity-verified-check-your-email", true);
            } else if (!isInForceVisitManualIdPartnerAfterRegistrationPage && forceVisitManualIdPartnerAfterRegistration) {
                redirectToRoute("/profile/visit-manual-id-verification-partner", true);
            } else if (isInHomePage) {
                if (sharedStoreState.isSignedIn) {
                    let userName =  sharedStoreState.authInfo.userProfile.username;
                    let isInCompanyContext = sharedStoreState.representedOrganizationCode;
                    if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_USER_AUTHENTICATED_BUT_NOT_SIGNATORY')) {
                        let companyAppUrl = getUrlForOtherApps(uiConfiguration, spFrameworkUtilsConstants.companiesAppKey);
                        let url = `${companyAppUrl}/#/not-allowed-to-sign`;
                        redirectToUrl(url, true, true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_REDIRECT_TO_APP_THAT_INITIATED_LOGIN')) {
                        log.logIndividualAction({
                            'message': `User - '${userName}' have permission 'SEPPO_ACTION_REDIRECT_TO_APP_THAT_INITIATED_LOGIN' and is redirected to cards that initiated login`
                        });
                        let pageToHandleRedirects = "/redirect-to-external-app";
                        redirectToRoute(pageToHandleRedirects, true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_FORCE_REVIEW_PENDING_CARD_ORDERS')) {
                        log.logIndividualAction({
                            'message': `User - '${userName}' have permission 'SEPPO_ACTION_FORCE_REVIEW_PENDING_CARD_ORDERS' and is redirected to cards app`
                        });
                        let myCardsLink = sharedStoreState.authInfo.topMenuParameters.topMenuLinks.myCardsLink;
                        redirectToUrl(myCardsLink, true, true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_FORCE_REDIRECT_TO_FIND_COMPANY_IF_USER_HAS_NO_ORG')) {
                        log.logIndividualAction({
                            'message': `User - '${userName}' automatically redirected to 'Register a new company' page`
                        });
                        redirectToUrl(uiConfiguration.servicePortal.registrationSettings.registerNewCompanyLink, true, true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VERIFY_EMAIL')) {
                        redirectToRoute("/profile/emailverification/verificationneeded", true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VERIFY_NATIONAL_IDENTITY_NUMBER')) {
                        let redirectUrl = prepareUrl.getStrongAuthRelativeUrl(uiConfiguration, sharedStoreState.authInfo.userProfile.personId, null, true);
                        redirectToUrl(redirectUrl, true, true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_CHANGE_NATIONAL_IDENTITY_NUMBER')) {
                        redirectToRoute("/profile/updatestrongauthinfo", true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_ACCEPT_PERSON_TOS')) {
                        redirectToRoute("/profile/accept-tos", true);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_INFORM_EPEREHDYTYS_USER')) {
                        redirectToRoute('/profile/insufficient-session-open');
                    } else {
                        if (isInCompanyContext) {
                            if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_COMPANY_REGISTRATION_IN_PROGRESS')) {
                                let companyAppUrl = getUrlForOtherApps(uiConfiguration, spFrameworkUtilsConstants.companiesAppKey);
                                let url = `${companyAppUrl}/#/${sharedStoreState.representedOrganizationCode}/`;
                                redirectToUrl(url, true, true);
                            } else {
                                redirectToRoute("/dashboard", true, false, sharedStoreState.representedOrganizationCode);
                            }
                        } else {
                            if (sharedStoreState.authInfo.otherUiInfo.orgCodeToRedirectToByDefault) {
                                redirectToRoute("/", true, false, sharedStoreState.authInfo.otherUiInfo.orgCodeToRedirectToByDefault);
                            } else {
                                redirectToRoute("/profile/permissions", true);
                            }
                        }
                    }
                } else {
                    if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_REJECTED_PERSONAL_DATA_POLICY')) {
                        redirectToRoute("/personal-data-policy-rejected", true, false);
                    } else if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_SMS_AUTHENTICATION_ERROR')) {
                        redirectToRoute("failed-sms-verification", true, false, false);
                    } else {
                        let urlFromCfg = uiConfiguration.urlToRedirectAnonymousUsers;
                        sharedStoreState.showOnlyLoadingIndicator = true;
                        if (urlFromCfg) {
                            redirectToUrl(urlFromCfg, false, true);
                        } else {
                            let isInCompanyContext = sharedStoreState.representedOrganizationCode;
                            let returnUrl = null;
                            if (isInCompanyContext) {
                                let returnRouteUrl = prepareUrl.prepareRouteUrl('/', false, sharedStoreState.representedOrganizationCode);
                                let returnUrlEncoded = encodeURIComponent(prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnRouteUrl));
                                returnUrl = returnUrlEncoded;
                            }
                            redirectToUrl(prepareUrl.getSignInRelativeUrl(returnUrl));
                        }
                    }
                }
            }
        }
    }
}

export default PageLoaderStore;
