import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let resendAccountActivationLinkBackBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_BACK_BTN_CLICKED
    });
};

export default resendAccountActivationLinkBackBtnClicked;
