import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage } from 'react-intl';
import { FormRow, PageHeader, FormColumn, InputLabel, SelectInput} from '@vaultit/stv-utils-frontend';

class ChangingUserMainOrg extends StoreComponent {
    renderWhenReady() {
        let form = this.state.users.changeUserMainOrgForm;
        let fields = form ? form.fields : null;

        let userOrgs = this.state.users.showChangeUserMainOrgForm ? this.state.users.allUserRepresentedOrgs : [];

        let organisationOptions = [];

        userOrgs.forEach((representation) => {
            let text = representation.org_name + ", " + representation.org_number + " (" + representation.org_country + ")";
            organisationOptions.push({
                value: representation.id,
                displayedText: text
            });
        });

        return (
            <div className="users-management">
                <PageHeader
                    smallerPageTitle={false}
                    hideSeparator={false}
                    pageTitleMessageId="users.changeMainOrg.title"
                    sharedStore={this.state.shared}
                />
                { this.state.users.showChangeUserMainOrgForm ?
                    <form name="changeUserMainOrgForm" noValidate="">
                        <FormRow>
                            <FormColumn width="5">
                                <InputLabel
                                    htmlFor="change-main-org-user-name"
                                    messageId="users.changeMainOrg.user"
                                    defaultMessage="User"
                                    bold={true}
                                />
                                <p>{this.state.users.representedUser.fullName} ({this.state.users.representedUser.email})</p>
                                <SelectInput
                                    id="user_tilaajavastuu_account_representations"
                                    field={fields.mainOrg}
                                    options={organisationOptions}
                                    messageId="users.changeMainOrg.mainOrg"
                                    defaultMessage="Main TV organisation"
                                    bold={true}
                                />
                            </FormColumn>
                        </FormRow>
                        <FormRow>
                            <FormColumn width="12" className="mt-6">
                                <button
                                    id="users_changing_main_org_submit"
                                    type="button"
                                    className="btn btn-primary btn-lg mr-3"
                                    role="button"
                                    onClick={form.handleOnSubmitBtnClicked}
                                >
                                    <FormattedMessage id="users.editing.saveBtn" defaultMessage="Save" />
                                </button>
                                <a
                                    id="users_changing_main_org_cancel"
                                    className="btn btn-default btn-lg mr-4"
                                    href={this.state.users.backToEditUrl}
                                >
                                    <FormattedMessage id="users.editing.cancelBtn" defaultMessage="Cancel" />
                                </a>
                            </FormColumn>
                        </FormRow>
                    </form>
                    : null
                }
            </div>
        );
    }
}

export default ChangingUserMainOrg;
