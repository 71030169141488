import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';

class YourDetailsNav extends React.Component {

    static get propTypes() {
        return {
            activeTab: PropTypes.string.isRequired,
            showBasicDetailsTab: PropTypes.bool,
            showDeleteAccountTab: PropTypes.bool,
            ulClassName: PropTypes.string,
        };
    }

    render() {
        let basePathToProfileView = '/profile/';
        let ulClassName = 'nav nav-pills';

        if (this.props.ulClassName) {
            ulClassName = ulClassName + ' ' + this.props.ulClassName;
        }

        return (
            <ul className={ulClassName} role="navigation">
                {/*<Link
                    id="profile-overview-tab"
                    to={basePathToProfileView + 'details'}
                    className={"nav-link " + (this.props.activeTab === 'overview' ? 'active' : '')}
                >
                    <FormattedMessage id="profile.details.overview" defaultMessage="Overview" />
                </Link>*/}
                { this.props.showBasicDetailsTab ?
                    <li className="nav-item">
                        <Link
                            id="profile-basic-details-tab"
                            to={basePathToProfileView + 'basic-details'}
                            className={"nav-link " + (this.props.activeTab === 'basic-details' ? 'active' : '')}
                        >
                            <FormattedMessage id="profile.details.basicDetails" defaultMessage="Basic details" />
                        </Link>
                    </li>
                    : null
                }
                <li className="nav-item">
                    <Link
                        id="profile-permissions-tab"
                        to={basePathToProfileView + 'permissions'}
                        className={"nav-link " + (this.props.activeTab === 'permissions' ? 'active' : '')}
                    >
                        <FormattedMessage id="profile.details.permissions" defaultMessage="Permissions" />
                    </Link>
                </li>
                { this.props.showDeleteAccountTab ?
                    <li className="nav-item">
                        <Link
                            id="profile-delete-my-account-tab"
                            to={basePathToProfileView + 'delete-my-account'}
                            className={"nav-link " + (this.props.activeTab === 'delete-my-account' ? 'active' : '')}
                        >
                            <FormattedMessage id="profile.details.accountDeletion" defaultMessage="Account deletion" />
                        </Link>
                    </li>
                    : null
                }
            </ul>
        );
    }
}

export default YourDetailsNav;
