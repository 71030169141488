import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getGlobalSearchPasswordRecoveryLink = function(userId) {

    getFromBackend(`/users/generate-global-search-password-recovery-link/${userId}`,
        ActionTypes.GET_GLOBAL_SEARCH_PASSWORD_RECOVERY_LINK_SUCCESS,
        ActionTypes.GET_GLOBAL_SEARCH_PASSWORD_RECOVERY_LINK_ERROR);
};

export default getGlobalSearchPasswordRecoveryLink;