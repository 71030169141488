import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let verifyEmailAndCreateAccount = function (values) {
    postToBackend(`/registration/verify-email-and-create-account`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_EMAIL_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_EMAIL_ERROR,
        values);
};

export default verifyEmailAndCreateAccount;
