import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let deleteUserAccount = function () {
    postToBackend('/profile/delete-user-account',
        ActionTypes.PROFILE_DELETE_USER_ACCOUNT_SUCCESS,
        ActionTypes.PROFILE_DELETE_USER_ACCOUNT_ERROR
    );
};
export default deleteUserAccount;
