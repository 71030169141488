import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let globalSearchCancelIconClicked = function(event, field) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GLOBAL_SEARCH_CANCEL_ICON_CLICKED,
        field: field
    });
}

export default globalSearchCancelIconClicked;