import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let changeName = function (data) {
    postToBackend('/profile/change-name',
        ActionTypes.PROFILE_CHANGE_NAME_SUCCESS,
        ActionTypes.PROFILE_CHANGE_NAME_ERROR,
        data, data);
};
export default changeName;
