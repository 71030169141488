import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let createFullUserAccountCancelBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CREATE_FULL_USER_ACCOUNT_CANCEL_BTN_CLICKED
    });
};

export default createFullUserAccountCancelBtnClicked;
