import React from 'react';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';

import StoreComponent from '../../base/StoreComponent.jsx';
import { FormRow, PageHeader, ValidationMessages, FormGroup, Message, TextInput, FormColumn } from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    emailPlaceholder: {
        id: 'profile.changeEmail.emailPlaceholder',
        description: 'Email address placeholder',
        defaultMessage: 'Your new email address'
    },
    emailAgainPlaceholder: {
        id: 'profile.changeEmail.emailAgainPlaceholder',
        description: 'Email address again placeholder',
        defaultMessage: 'Your new email address again'
    }
});

class ChangeEmail extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let form = this.state.yourDetails.changeEmailForm;
        let fields = form ? form.fields : null;

        return (
            <div>
                <PageHeader pageTitleMessageId="profile.changeEmail.title" sharedStore={this.state.shared} />
                { this.state.yourDetails.showChangedEmailDuplicateMessage
                    ?
                        <Message id="errorMessage" type="danger">
                            <FormattedMessage
                                id="profile.changeEmail.emailDuplicate"
                                defaultMessage="E-mail {email} is already used for another user account. If you want to change this account to use the reserved e-mail address and it's yours, first sign in to that account to change the e-mail address of that account to something else, or contact our customer service."
                                values={{
                                    email: this.state.yourDetails.duplicatedEmail
                                }}
                            />
                        </Message>
                    : null
                }
                { this.state.yourDetails.showChangedEmailInvalidPasswordMessage
                    ?
                        <Message id="errorMessage" type="danger">
                            <FormattedMessage
                                id="profile.changeEmail.userPasswordIncorrect"
                                defaultMessage="The password didn't match - please check your password to continue"
                            />
                        </Message>
                    : null
                }
                { form
                    ? <FormRow>
                        <FormColumn width="6">
                            <FormGroup>
                                <ValidationMessages field={fields.newEmail}>
                                    <TextInput
                                        id="change-email"
                                        field={fields.newEmail}
                                        messageId="profile.changeEmail.newEmailAddress"
                                        defaultMessage="Email address"
                                        placeholder={formatMessage(messages.emailPlaceholder) }
                                        required={true}
                                        autoFocus={true}
                                        onPaste={ (e) => e.preventDefault() }
                                    />
                                </ValidationMessages>
                            </FormGroup>
                        </FormColumn>
                        <FormColumn width="6">
                            <FormGroup>
                                <ValidationMessages field={fields.newEmailAgain}>
                                    <TextInput
                                        id="change-email-again"
                                        field={fields.newEmailAgain}
                                        messageId="profile.changeEmail.newEmailAddressAgain"
                                        defaultMessage="Email again"
                                        placeholder={formatMessage(messages.emailAgainPlaceholder) }
                                        required={true}
                                        onPaste={ (e) => e.preventDefault() }
                                    />
                                </ValidationMessages>
                            </FormGroup>
                        </FormColumn>
                        <FormColumn width="12">
                            <div className="form-group mt-6">
                                <button
                                    id="profile-change-email-save"
                                    type="button"
                                    className="btn btn-primary btn-lg mr-3"
                                    onClick={form.handleOnSubmitBtnClicked}
                                    role="button"
                                >
                                    <FormattedMessage
                                        id="profile.changeEmail.save"
                                        defaultMessage="Save"
                                    />
                                </button>
                                <a
                                    id="profile-change-email-cancel"
                                    className="btn btn-default btn-lg"
                                    href={this.state.yourDetails.backToBaseDetailsUrl}
                                >
                                    <FormattedMessage
                                        id="profile.changeEmail.cancel"
                                        defaultMessage="Cancel"
                                    />
                                </a>
                            </div>
                        </FormColumn>
                    </FormRow>
                    : null
                }
            </div>
        );
    }
}

export default ChangeEmail;
