import React from "react";

import StoreComponent from "./base/StoreComponent.jsx";
import { Message } from '@vaultit/stv-utils-frontend';

class Maintenance extends StoreComponent {
    render() {
        return (
            <div>
                <div className="top-menu-wrapper fixed-top">
                    <nav className="stv-primary-nav navbar navbar-toggleable-sm" role="navigation">
                        <a role="button" id="top_menu_logo" className="navbar-brand" href="/">
                            <span />
                        </a>
                    </nav>
                </div>
                <div className="main container">
                    <div className="page-header">
                        <h1>
                            <span>Maintenance</span>
                        </h1>
                        <hr className="mt-0 page-title-separator" />
                    </div>
                    <Message id="SuccessMessage-2" type="info">
                        <span>We are currently doing maintenance work. Thanks for your patience.</span>
                    </Message>
                    <a href="/" className="btn btn-primary btn-lg">
                        <span>Reload page</span>
                    </a>
                </div>
            </div>
        );
    }
}

export default Maintenance;
