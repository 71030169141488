import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import { FormRow, PageHeader, FormTable, FormColumn, FormGroup, FormValidationMessage, ValidationMessages, VisibilityComponent,
    InputLabel, TextInput, CheckboxInput, RadioInput } from '@vaultit/stv-utils-frontend';


const messages = defineMessages({
    submit: {
        id: 'users.creatingApiAccount.submitBtn',
        description: 'Creating api account form submit btn',
        defaultMessage: 'Create'
    }
});

class CreatingApiAccount extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    displayPermissions() {
        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let permissions = [];
        let form = this.state.users.creatingApiAccountForm;

        for (var serviceName in configuration.services) {
            let service = configuration.services[serviceName];
            let joinedByPermissionService = service.joinedByPermission && configuration.services[service.joinedByPermission] ? configuration.services[service.joinedByPermission] : null;
            let isVisible = this.state.users.servicesManagedByCurrentUser.permissionCheckboxIsVisibleForCurrentUser[serviceName] && service['permission'];
            let permissionManagedByOtherService = service['permissionManagedByOtherService'] ? service['permissionManagedByOtherService'] : false;
            let permissionOptionIsVisible = isVisible && !permissionManagedByOtherService;
            let userPermission = joinedByPermissionService ? service['permission'] + ' ' + joinedByPermissionService['permission'] : service['permission'];
            let isDisabled = !this.state.users.servicesManagedByCurrentUser.permissionCanBeManagedByCurrentUser[serviceName] || !this.state.users.servicesManagedByCurrentUser.permissionCheckboxIsEnabledForCurrentUser[serviceName];
            let isNoticeVisible = this.state.users.servicesManagedByCurrentUser.showNoticeMsgNextToCheckboxForCurrentUser[serviceName];

            if (permissionOptionIsVisible) {

                let serviceTitle = joinedByPermissionService
                    ? service['title'][currentLanguageCode] + ', ' + joinedByPermissionService['title'][currentLanguageCode]
                    : service['title'][currentLanguageCode];
                let description = service['description'] && service['description'][currentLanguageCode] ? service['description'][currentLanguageCode] : '';
                let formField = form.fields['permissionToUse' + serviceName.charAt(0).toUpperCase() + serviceName.slice(1)];
                let noticeMessage = service['input_notice_message'] && service['input_notice_message'][currentLanguageCode] ? service['input_notice_message'][currentLanguageCode] : '';

                permissions.push(
                    <FormRow key={serviceName}>
                        <FormColumn width="3">
                            <CheckboxInput
                                id={"creating-api-account-permissions-" + serviceName.toLowerCase()}
                                value={userPermission}
                                checked={formField.value}
                                field={formField}
                                labelClassName="m-0"
                                name="user_permissions"
                                bold={true}
                                disabled={isDisabled}
                            >
                                { serviceTitle }
                            </CheckboxInput>
                        </FormColumn>
                        <FormColumn width="9">
                            <p>{ description }</p>
                            { isNoticeVisible
                                ?
                                    <p className="text-danger small">
                                        { noticeMessage }
                                    </p>
                                : null
                            }
                        </FormColumn>
                    </FormRow>
                );
            }
        }
        return permissions;
    }

    renderWhenReady() {

        const { formatMessage } = this.context.intl;

        let form = this.state.users.creatingApiAccountForm;
        let fields = form ? form.fields : null;

        let permissionsManagedBySuperAdmin = this.state.shared.classifiers.permissionsManagedBySuperAdmin;

        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let serviceCode = configuration['servicePortalServiceCode'];

        return (
            <div className="users-management">
                <h3 className="mt-6 mb-0 back-to-list">
                    <a id="users_adding_back_to_list_link" href={this.state.users.backToListUrl} className="hidden-sm-down">
                        <i className="fa fa-list fa-mr"></i>
                        <FormattedMessage
                            id="users.adding.backToList"
                            description="Back to list link"
                            defaultMessage="Back to list"
                        />
                    </a>
                    <div className="clearfix"></div>
                </h3>
                <PageHeader
                    smallerPageTitle={false}
                    hideSeparator={false}
                    pageTitleMessageId="users.management.title"
                    values={{organizationName: this.state.shared.representedOrgName}}
                    sharedStore={this.state.shared}
                />
                <div className="clearfix"></div>
                <h3>
                    <FormattedMessage id="users.creatingApiAccount.title" defaultMessage="New API account" />
                </h3>
                <hr />

                { form
                    ?
                        <div>
                            <form name="creatingApiAccountForm" noValidate="">
                                <div className="card card-default">
                                    <div className="card-block">
                                        <FormGroup row={true}>
                                            <FormColumn widthXs="12" widthSm="4" widthMd="3" widthLg="2">
                                                <InputLabel
                                                    htmlFor="creating-api-account-email"
                                                    messageId="users.creatingApiAccount.emailAddress"
                                                    defaultMessage="Email address:"
                                                    inline={true}
                                                />
                                            </FormColumn>
                                            <FormColumn widthXs="10" widthSm="7" widthMd="5" widthLg="6">
                                                <ValidationMessages field={fields.email}>
                                                    <TextInput
                                                        id="creating-api-account-email"
                                                        field={fields.email}
                                                        autoFocus={true}
                                                    />
                                                </ValidationMessages>
                                            </FormColumn>
                                        </FormGroup>
                                        <FormGroup row={true}>
                                            <FormColumn widthXs="12" widthSm="4" widthMd="3" widthLg="2">
                                                <InputLabel
                                                    htmlFor="creating-api-account-firstName"
                                                    messageId="users.creatingApiAccount.firstName"
                                                    defaultMessage="First name:"
                                                    inline={true}
                                                />
                                            </FormColumn>
                                            <FormColumn widthXs="10" widthSm="7" widthMd="5" widthLg="6">
                                                <ValidationMessages field={fields.firstName}>
                                                    <TextInput
                                                        id="creating-api-account-firstName"
                                                        field={fields.firstName}
                                                    />
                                                </ValidationMessages>
                                            </FormColumn>
                                        </FormGroup>
                                        <FormGroup row={true}>
                                            <FormColumn widthXs="12" widthSm="4" widthMd="3" widthLg="2">
                                                <InputLabel
                                                    htmlFor="creating-api-account-lastName"
                                                    messageId="users.creatingApiAccount.lastName"
                                                    defaultMessage="Last name:"
                                                    inline={true}
                                                />
                                            </FormColumn>
                                            <FormColumn widthXs="10" widthSm="7" widthMd="5" widthLg="6">
                                                <ValidationMessages field={fields.lastName}>
                                                    <TextInput
                                                        id="creating-api-account-lastName"
                                                        field={fields.lastName}
                                                    />
                                                </ValidationMessages>
                                            </FormColumn>
                                        </FormGroup>
                                        <FormGroup row={true}>
                                            <FormColumn widthXs="12" widthSm="4" widthMd="3" widthLg="2">
                                                <InputLabel
                                                    htmlFor="creating-api-account-password"
                                                    messageId="users.creatingApiAccount.password"
                                                    defaultMessage="Password:"
                                                    inline={true}
                                                />
                                            </FormColumn>
                                            <FormColumn widthXs="10" widthSm="7" widthMd="5" widthLg="6">
                                                <ValidationMessages field={fields.password}>
                                                    <TextInput
                                                        id="creating-api-account-password"
                                                        type="password"
                                                        field={fields.password}
                                                    />
                                                </ValidationMessages>
                                            </FormColumn>
                                        </FormGroup>
                                        <FormGroup row={true} className="mb-0">
                                            <FormColumn widthXs="12" widthSm="4" widthMd="3" widthLg="2">
                                                <InputLabel
                                                    htmlFor="creating-api-account-password-again"
                                                    messageId="users.creatingApiAccount.passwordAgain"
                                                    defaultMessage="Password again:"
                                                    inline={true}
                                                />
                                            </FormColumn>
                                            <FormColumn widthXs="10" widthSm="7" widthMd="5" widthLg="6">
                                                <ValidationMessages field={fields.passwordAgain} match="errors.validation.field.match.passwords">
                                                    <TextInput
                                                        id="creating-api-account-password-again"
                                                        type="password"
                                                        field={fields.passwordAgain}
                                                    />
                                                </ValidationMessages>
                                            </FormColumn>
                                        </FormGroup>
                                    </div>
                                </div>

                                <h3>
                                    <FormattedMessage id="users.creatingApiAccount.userType" defaultMessage="User type:" />
                                </h3>

                                <FormTable striped={true}>
                                    <ValidationMessages field={fields.userType} required="users.creatingApiAccount.type.required">
                                        <FormRow>
                                            <FormColumn width="3">
                                                <RadioInput
                                                    id="creating-api-account-user-type-main"
                                                    name="user-type"
                                                    field={fields.userType}
                                                    value={this.state.users.constants.userTypes.main || ""}
                                                    checked={fields.userType.value === this.state.users.constants.userTypes.main}
                                                    messageId="users.creatingApiAccount.userTypeMain"
                                                    defaultMessage="Main user"
                                                    bold={true}
                                                    labelClassName="m-0"
                                                />
                                            </FormColumn>
                                            <FormColumn width="9">
                                                {configuration[serviceCode].userManagement['mainUserDescription']
                                                    ? <p>{configuration[serviceCode].userManagement['mainUserDescription'][currentLanguageCode]}</p>
                                                    : null
                                                }
                                            </FormColumn>
                                        </FormRow>
                                        <FormRow>
                                            <FormColumn width="3">
                                                <RadioInput
                                                    id="creating-api-account-user-type-basic"
                                                    name="user-type"
                                                    field={fields.userType}
                                                    value={this.state.users.constants.userTypes.basic || ""}
                                                    checked={fields.userType.value === this.state.users.constants.userTypes.basic}
                                                    messageId="users.creatingApiAccount.userTypeBasic"
                                                    defaultMessage="Basic user"
                                                    bold={true}
                                                    labelClassName="m-0"
                                                />
                                            </FormColumn>
                                            <FormColumn width="9">
                                                {configuration[serviceCode].userManagement['basicUserDescription']
                                                    ? <p>{configuration[serviceCode].userManagement['basicUserDescription'][currentLanguageCode]}</p>
                                                    : null
                                                }
                                            </FormColumn>
                                        </FormRow>
                                    </ValidationMessages>
                                </FormTable>
                                <h3>
                                    <FormattedMessage id="users.creatingApiAccount.userPermissions" defaultMessage="Permissions:" />
                                </h3>
                                <FormTable striped={true}>
                                    { this.displayPermissions() }
                                </FormTable>

                                <VisibilityComponent isVisible={this.state.users.canManageGlobalPermissions}>
                                    <h3>
                                        <FormattedMessage id="users.creatingApiAccount.userGlobalPermissions" defaultMessage="Global permissions:" />
                                    </h3>
                                    <FormTable striped={true}>
                                        {this.state.shared.classifiers.globalPermissions.map((p, index) => {
                                            if (permissionsManagedBySuperAdmin.indexOf(p.code) == -1 ||
                                                        (permissionsManagedBySuperAdmin.indexOf(p.code) > -1 && this.state.users.canManageAdminAndQATPermissions)) {
                                                return (
                                                    <FormRow key={index}>
                                                        <FormColumn width="3">
                                                            <CheckboxInput
                                                                id={"creating-api-account-" + p.code + "_permission"}
                                                                value={p.code || ""}
                                                                checked={fields[p.code].value}
                                                                field={fields[p.code]}
                                                                labelClassName="m-0"
                                                                name="user-global-permissions"
                                                                bold={true}
                                                            >
                                                                { p.name }
                                                            </CheckboxInput>
                                                        </FormColumn>
                                                        <FormColumn width="9">
                                                            <p>
                                                                { p.description }
                                                            </p>
                                                        </FormColumn>
                                                    </FormRow>
                                                );
                                            }
                                        })}
                                    </FormTable>
                                </VisibilityComponent>

                                <FormValidationMessage form={form} messageId="users.creatingApiAccount.formHasErrors" />
                            </form>
                            <FormRow>
                                <FormColumn width="12" className="mt-6">
                                    <input
                                        id="creating-api-account-submit"
                                        type="submit"
                                        className="btn btn-primary btn-lg mr-3"
                                        value={formatMessage(messages.submit) || "" }
                                        onClick={form.handleOnSubmitBtnClicked}
                                        role="button"
                                    />
                                    <a
                                        id="users_adding_cancel"
                                        className="btn btn-default btn-lg"
                                        href={this.state.users.backToListUrl}
                                    >
                                        <FormattedMessage id="users.creatingApiAccount.cancelBtn" defaultMessage="Cancel" />
                                    </a>
                                </FormColumn>
                            </FormRow>
                        </div>
                    : null
                }
            </div>
        );
    }
}

export default CreatingApiAccount;
