import React from 'react';
import PropTypes from 'prop-types';
import StoreComponent from '../base/StoreComponent.jsx';


class Logo extends StoreComponent {

    static get propTypes() {
        return {
            className: PropTypes.string,
        };
    }

    render() {
        let configuration = this.state.chooseAuthenticationMethodStore.configuration;
        let logoClass = configuration.footerLogoClass ? configuration.footerLogoClass + '-logo' : 'default-logo';

        if (this.props.className) {
            logoClass += ' ' + this.props.className;
        }

        return (<span className={logoClass}></span>)
    }
}

export default Logo;