import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let cancelCreateFullUserAccount = function (data) {
    postToBackend('/profile/cancel-create-full-account',
        ActionTypes.CANCEL_CREATE_FULL_USER_ACCOUNT_SUCCESS,
        ActionTypes.CANCEL_CREATE_FULL_USER_ACCOUNT_ERROR,
        data);
};

export default cancelCreateFullUserAccount;