import React from 'react';
import PropTypes from 'prop-types';
import StoreComponent from '../../base/StoreComponent.jsx';
import DashboardServiceCard from '../DashboardServiceCard.jsx';
import SignatoryDashboardCard from './SignatoryDashboardCard.jsx';
import { getUrlForOtherApps, spFrameworkUtilsConstants } from '@vaultit/stv-utils-frontend';
import { FormattedMessage } from 'react-intl';


class BOLDashboardCard extends StoreComponent {

    renderWhenReady() {
        let noServiceAccessMessage = (
            <FormattedMessage
                id="bolagsdeklaration.dashboardCard.noServiceMessage"
                defaultMessage="Cannot access service. Contact the main user of your company to activate the Creditsafe account."
            />
        );

        if (this.state.servicesBasicInfo.canGoToBol) {
            return (
                <DashboardServiceCard
                    cols_lg={this.props.cols_lg}
                    cols_md={this.props.cols_md}
                    title={this.props.title}
                    description={this.props.description}
                    icon={this.props.icon}
                    url={this.props.url}
                    noServiceAccessMessage={this.props.noServiceAccessMessage}
                    serviceHasException={this.props.serviceHasException}
                    elementButtonId={this.props.elementButtonId}
                    service={this.props.service}
                />
            );
        } else if (this.state.servicesBasicInfo.canStartCreateCSAccount) {
            let configuration = this.state.shared.authInfo.topMenuParameters.configuration;
            let orgId = this.state.shared.authInfo.topMenuParameters.selectedRepresentedOrganizationId;
            let url = getUrlForOtherApps(configuration, spFrameworkUtilsConstants.companiesAppKey, "creditsafeToSPage", orgId);
            return (
                <SignatoryDashboardCard
                    cols_lg={this.props.cols_lg}
                    cols_md={this.props.cols_md}
                    title={this.props.title}
                    description={this.props.description}
                    icon={this.props.icon}
                    url={url}
                    elementButtonId={this.props.elementButtonId}
                    service={this.props.service}
                />
            );

        } else {
            return (
                <DashboardServiceCard
                    cols_lg={this.props.cols_lg}
                    cols_md={this.props.cols_md}
                    title={this.props.title}
                    description={this.props.description}
                    icon={this.props.icon}
                    noServiceAccessMessage={noServiceAccessMessage}
                    serviceHasException={this.props.serviceHasException}
                    elementButtonId={this.props.elementButtonId}
                    service={this.props.service}
                />
            );
        }
    }
}

BOLDashboardCard.propTypes = {
    cols_lg: PropTypes.number.isRequired,
    cols_md: PropTypes.number.isRequired,
    description: PropTypes.array.isRequired,
    elementButtonId: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    noServiceAccessMessage: PropTypes.string,
    service: PropTypes.string.isRequired,
    serviceHasException:PropTypes.bool,
    title: PropTypes.string.isRequired,
    url: PropTypes.string,
}

export default BOLDashboardCard;
