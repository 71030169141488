import "intl";
import React from 'react';
import ReactDOM from 'react-dom';
import log from './singletons/Log.js';
import '../styles/styles.scss';
import 'jquery';
import 'font-awesome/css/font-awesome.css';
import Routes from './Routes.js';
import { AppContainer } from 'react-hot-loader';

window.addEventListener('error', function(e) {
    log.addError(e);
});

/*
HACK: Does nothing but breaks the bfcache, meaning, that back button will always reload page.
This was needed to solve SP-491 bug (FF back button after logout opens up page without reloading anything).
Idea from: http://madhatted.com/2013/6/16/you-do-not-understand-browser-history
*/
window.addEventListener('unload', function() {});

/* Render router: */
Reflect.deleteProperty(AppContainer.prototype, 'unstable_handleError');

const render = () => {
    ReactDOM.render(
        <AppContainer>
            <Routes />
        </AppContainer>,
        document.getElementById('container')
    );
};

render(Routes);

if (module.hot) {
    module.hot.accept('./Routes.js', () => { render(Routes); });
}
