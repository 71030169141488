
import React from 'react';
import { FormattedMessage } from 'react-intl';

import StoreComponent from '../../base/StoreComponent.jsx';
import { FormRow, FormColumn, ValidationMessages, PhoneNumberInputComponent, InputLabel, PageHeader } from '@vaultit/stv-utils-frontend';

class ChangePhone extends StoreComponent {

    renderWhenReady() {
        let form = this.state.yourDetails.changePhoneForm;

        return (
            <div>
                <PageHeader pageTitleMessageId="profile.changePhone.title" sharedStore={this.state.shared} />

                { form
                    ? <FormRow>
                        <FormColumn width="5">
                            <div className="form-group">
                                <ValidationMessages field={form.fields.phoneNumber} componentCustomValidation="errors.validation.field.componentCustomValidation.phoneNumber">
                                    <InputLabel
                                        htmlFor="profile-basic-details-phoneNumber"
                                        messageId="profile.changePhone.phoneNumber"
                                        defaultMessage="Phone number"
                                        required={true}
                                    />
                                    <PhoneNumberInputComponent
                                        id="profile-basic-details-phoneNumber"
                                        className="form-control"
                                        value={form.fields.phoneNumber.value || ""}
                                        onChange={form.fields.phoneNumber.handleOnChange}
                                        preferredCountries={['fi', 'ee', 'se']}
                                    />
                                </ValidationMessages>
                            </div>
                        </FormColumn>

                        <FormColumn width="12">
                            <div className="form-group mt-6">
                                <button
                                    id="profile-change-phone-save"
                                    type="button"
                                    className="btn btn-primary btn-lg mr-3"
                                    onClick={form.handleOnSubmitBtnClicked}
                                    role="button"
                                >
                                    <FormattedMessage
                                        id="profile.changePhone.save"
                                        defaultMessage="Save"
                                    />
                                </button>
                                <a
                                    id="profile-change-phone-cancel"
                                    className="btn btn-default btn-lg"
                                    href={this.state.yourDetails.backToBaseDetailsUrl}
                                >
                                    <FormattedMessage
                                        id="profile.changePhone.cancel"
                                        defaultMessage="Cancel"
                                    />
                                </a>
                            </div>
                        </FormColumn>
                    </FormRow>
                    : null
                }


            </div>
        );
    }
}

export default ChangePhone;
