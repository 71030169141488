import React from 'react';
import { Link } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Message, PageHeader, FormRow, TextInput, FormGroup, FormColumn } from '@vaultit/stv-utils-frontend';
import StoreComponent from '../../base/StoreComponent.jsx';
import YourDetailsNav from '../../common/YourDetailsNav.jsx';
import changeEmailResendVerificationEmailBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailResendVerificationEmailBtnClicked.js';
import changeEmailCancelBtnClicked from '../../../actions/actionCreators/profile/yourDetails/ChangeEmailCancelBtnClicked.js';


class BasicDetails extends StoreComponent {

    renderWhenReady() {
        let basePath = '/profile/';
        let user = this.state.shared.profile || {};
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;
        let currentRegistrationFlow = uiConfiguration ? uiConfiguration.servicePortal.registrationSettings.registrationFlow : null;

        return (
            <div>
                <PageHeader pageTitleMessageId="profile.details.title" sharedStore={this.state.shared} />

                { this.state.yourDetails.showChangedNameSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="profile.changeName.success.message"
                                defaultMessage="Name changed successfully"
                            />
                        </Message>
                    : null
                }

                { this.state.yourDetails.showEmailChangeInitiateSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="profile.changeEmail.success.message"
                                defaultMessage="We sent you an email with a verification link. To verify your new email address, {clickTheLinkInThatEmail} Until that, your old email address stays valid."
                                values={{
                                    clickTheLinkInThatEmail: <b>
                                        <FormattedMessage
                                            id="profile.changeEmail.success.clickTheLinkInThatEmail"
                                            defaultMessage="click the link in that email."
                                        />
                                    </b>
                                }}
                            />
                        </Message>
                    : null
                }

                { this.state.yourDetails.showEmailChangeVericationResendSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="profile.changeEmail.resend.success.message"
                                defaultMessage="Verification email successfully resent"
                            />
                        </Message>
                    : null
                }

                { this.state.yourDetails.showCancelEmailChangeSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="profile.changeEmail.cancel.success.message"
                                defaultMessage="E-mail change request was cancelled"
                            />
                        </Message>
                    : null
                }

                { this.state.yourDetails.showChangedPhoneSuccessMessage
                    ?
                        <Message id="successMessage" type="success">
                            <FormattedMessage
                                id="profile.changePhone.success.message"
                                defaultMessage="Phone number changed successfully"
                            />
                        </Message>
                    : null
                }

                { this.state.yourDetails.showPasswordChangeSuccessMessage
                    ?
                        <Message id="SuccessMessage" type="success">
                            <FormattedMessage
                                id="profile.changePassword.passwordSuccessfullyChangedMessage"
                                defaultMessage="Your password was successfully changed"
                            />
                        </Message>
                    : null
                }

                {!this.state.shared.authInfo.topMenuParameters.configuration.thirdLevelMenu
                    ? <YourDetailsNav ulClassName="flex-column flex-md-row mb-4" activeTab="basic-details" {...this.state.yourDetails.tabVisibility} />
                    : null
                }

                <FormRow className="if-theme-needs-extend-row">
                    <FormColumn className="if-theme-needs-hide" widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                        <div className="alert alert-default mt-4">
                            <FormattedMessage id="profile.details.basicDetails.editingHint" defaultMessage="To change the information, click on the pencil icon on the right" />
                        </div>
                    </FormColumn>
                    <FormColumn widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                        <FormGroup>
                            <TextInput
                                id="profile-basic-details-name"
                                readOnlyValue={user.fullName}
                                messageId="profile.details.name"
                                defaultMessage="Full name"
                                inputGroup={true}
                                disabled={true}
                            >
                                { this.state.yourDetails.userCanUpdateUsername
                                    ?
                                        <Link
                                            id="profile-change-name"
                                            to={basePath + 'change-name'}
                                            className="input-group-addon addon-outside-right"
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </Link>
                                    : null
                                }
                            </TextInput>
                        </FormGroup>
                    </FormColumn>
                    { currentRegistrationFlow === 'flow-2'
                        ?
                            <FormColumn widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                                <FormGroup>
                                    <TextInput
                                        id="profile-basic-details-personal-number"
                                        readOnlyValue={user.nationalIdentityNumber ? user.nationalIdentityNumber.slice(0, -5) + '*****':''}
                                        messageId="profile.details.nationalIdentityNumber"
                                        defaultMessage="National Person ID number"
                                        disabled={true}
                                    />
                                </FormGroup>
                            </FormColumn>
                        : null
                    }
                    <FormColumn widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                        <FormGroup>
                            <TextInput
                                id="profile-basic-details-email"
                                readOnlyValue={user.username}
                                messageId="profile.details.email"
                                defaultMessage="E-mail"
                                disabled={true}
                                inputGroup={true}
                            >
                                { uiConfiguration && uiConfiguration.servicePortal.userDetails.basicDetails.emailIsEditable && this.state.yourDetails.changeEmail
                                    ?
                                        <Link
                                            id="profile-change-email"
                                            to={basePath + 'change-email'}
                                            className="input-group-addon addon-outside-right"
                                        >
                                            <i className="fa fa-pencil" aria-hidden="true"></i>
                                        </Link>
                                    : null
                                }
                            </TextInput>
                        </FormGroup>
                    </FormColumn>
                    { user.emailForChanging
                        ? <FormColumn width="8">
                            <FormGroup>
                                <p className="small py-4">
                                    <FormattedMessage
                                        id="profile.changeEmail.emailChangeRequestMessage"
                                        defaultMessage="E-mail change requested to address {email}, go and check your e-mail for the verification message."
                                        values={{email: <strong>{user.emailForChanging}</strong>}}
                                    />
                                    <br />
                                    <a id="resend-verification-email" href="#" onClick={changeEmailResendVerificationEmailBtnClicked}>
                                        <FormattedMessage
                                            id="profile.changeEmail.resendVerificationEmail"
                                            defaultMessage="Resend the verification e-mail"
                                        />
                                    </a>
                                    <strong className="px-2">|</strong>
                                    <a id="resend-verification-email" href="#" onClick={changeEmailCancelBtnClicked}>
                                        <FormattedMessage
                                            id="profile.changeEmail.cancelChangeRequest"
                                            defaultMessage="Cancel the change request"
                                        />
                                    </a>
                                </p>
                            </FormGroup>
                        </FormColumn>
                        : null
                    }

                    <FormColumn widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                        <FormGroup>
                            <TextInput
                                id="profile-basic-details-phoneNumber"
                                readOnlyValue={user.phoneNumber}
                                messageId="profile.details.phoneNumber"
                                defaultMessage="Phone number"
                                disabled={true}
                                inputGroup={true}
                            >
                                <Link
                                    id="profile-change-phoneNumber"
                                    to={basePath + 'change-phone'}
                                    className="input-group-addon addon-outside-right"
                                >
                                    <i className="fa fa-pencil" aria-hidden="true"></i>
                                </Link>
                            </TextInput>
                        </FormGroup>
                    </FormColumn>
                    <FormColumn widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                        <FormGroup>
                            <TextInput
                                id="profile-basic-details-password"
                                readOnlyValue="************"
                                messageId="profile.details.password"
                                defaultMessage="Password"
                                disabled={true}
                                inputGroup={true}
                            >
                                { this.state.yourDetails.changePassword
                                ?
                                    <Link
                                        id="profile-change-password"
                                        to={basePath + 'changepassword'}
                                        className="input-group-addon addon-outside-right"
                                    >
                                        <i className="fa fa-pencil" aria-hidden="true"></i>
                                    </Link>
                                : null
                                }
                            </TextInput>
                        </FormGroup>
                    </FormColumn>
                </FormRow>
                {this.state.yourDetails.showCreateFullAccountTab
                ?
                    <FormRow widthLg="8" widthMd="8" widthSm="10" widthXs="10">
                        <a className="mt-3 btn btn-warning btn-lg ml-4" href={this.state.yourDetails.createAccountUrl}>
                            <FormattedMessage id="profile.details.createFullAccount" defaultMessage="Create account" />
                        </a>
                    </FormRow>
                : null}
            </div>
        );
    }
}

export default BasicDetails;
