import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let emailExistsInfoClosed = function() {
    dispatcher.dispatch({
        type: ActionTypes.EMAIL_EXISTS_INFO_CLOSED
    });
};

export default emailExistsInfoClosed;
