import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let removeUserPermissions = function (orgId, userId, permission) {
    let routePart = permission === 'global' ? 'global-' : '';
    let actionToCall = 'remove-'+ routePart +'permissions';
    let removeUrl = `/users/${orgId}/${actionToCall}/${userId}`;
    let data = {permission: permission};

    postToBackend(removeUrl,
        ActionTypes.ORGANIZATION_USERS_PERMISSIONS_REMOVE_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_PERMISSIONS_REMOVE_ERROR,
        data
    );
};

export default removeUserPermissions;
