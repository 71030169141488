import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';

import { FormRow, ErrorMessage } from '@vaultit/stv-utils-frontend';
import DashboardServiceCard from './DashboardServiceCard.jsx';
import BOLDashboardCard from './bol/BOLDashboardCard.jsx';

import { FormattedMessage } from 'react-intl';

class OrganizationBasicInfo extends StoreComponent {
    breakLine(text) {
        var br = React.createElement('br');
        var regex = /(<br \/>)/g;
        return text.split(regex).map(function(line, index) {
            return line.match(regex) ? <br key={"key_" + index} /> : line;
        });
    };

    renderWhenReady() {
        let configuration = this.state.shared.authInfo.topMenuParameters.configuration;
        let langCode = this.state.shared.authInfo.topMenuParameters.selectedLanguageCode;
        let orgId = this.state.shared.authInfo.topMenuParameters.selectedRepresentedOrganizationId;
        let syncId = this.state.shared.authInfo.topMenuParameters.selectedRepresentedSyncId;
        let servicesInfoForDashboard = this.state.servicesBasicInfo.servicesInfoForDashboard;
        let showReadMore = false;

        return (
            <div className="services">
                <ErrorMessage errorMessage={this.state.shared.errorMessage} currentState={this.state} className="mt-6" />

                <div id="dashboard-title-wrapper">
                    <h1 className="master-page-title">
                        <FormattedMessage id="services.title" defaultMessage="Welcome to our company's account" />
                        <span>{ this.state.shared.representedOrgName }</span>
                    </h1>
                </div>

                { configuration.servicePortal.dashboard && configuration.servicePortal.dashboard.services && servicesInfoForDashboard
                ? (<FormRow id="services-list" className="justify-content-center">

                    {Object.keys(configuration.servicePortal.dashboard.services).map((service) => {
                        var items = [];
                        let displayServiceInDashboard = servicesInfoForDashboard.showServiceInDashboardForCurrentUser[service];

                        if (displayServiceInDashboard) {

                            showReadMore = true;
                            let serviceTitle = "";
                            let serviceDescription = "";
                            let serviceUrl = "";
                            let serviceIcon = "";
                            let serviceHasException =  false;
                            let elementButtonId = configuration.services[service] ? 'go-to-' + service + '-service' : 'go-to-service';
                            let noServiceAccessMessage = null;
                            let currentLanguage = this.state.shared.localizations.messagesLanguage;

                            if (configuration.services[service]) {

                                if (configuration.services[service].title &&
                                configuration.services[service].title[currentLanguage])
                                    serviceTitle = configuration.services[service].title[currentLanguage];

                                if (configuration.services[service].description &&
                                configuration.services[service].description[currentLanguage])
                                    serviceDescription = configuration.services[service].description[currentLanguage];

                                if (configuration.services[service].url)
                                    serviceUrl = configuration.services[service].url;

                                if (configuration.services[service].exceptional_behaviour_in_top_menu) {
                                    serviceHasException = true;
                                }
                            }


                            if (configuration.servicePortal.dashboard.services[service].title)
                                serviceTitle = configuration.servicePortal.dashboard.services[service].title[currentLanguage];

                            if (configuration.servicePortal.dashboard.services[service].description)
                                serviceDescription = configuration.servicePortal.dashboard.services[service].description[currentLanguage];

                            if (configuration.servicePortal.dashboard.services[service].icon)
                                serviceIcon = configuration.servicePortal.dashboard.services[service].icon;

                            if (configuration.servicePortal.dashboard.services[service].url) {
                                let urlIsLanguageSpecific = configuration.servicePortal.dashboard.services[service].urlIsLanguageSpecific || false;
                                let dasboardUrl = urlIsLanguageSpecific
                                                ? configuration.servicePortal.dashboard.services[service].url[currentLanguage]
                                                : configuration.servicePortal.dashboard.services[service].url;

                                if (serviceUrl.length > 0 && dasboardUrl && dasboardUrl.charAt(0) === "/")
                                    serviceUrl = serviceUrl + dasboardUrl;
                                else if (serviceUrl.length === 0 && dasboardUrl)
                                    serviceUrl = dasboardUrl;
                            }

                            if (servicesInfoForDashboard && servicesInfoForDashboard.showMissingAccessMsgInDashboardForCurrentUser[service]) {
                                noServiceAccessMessage = (
                                    <FormattedMessage
                                        id="services.noServiceAccessMessage"
                                        defaultMessage="Customer service has been informed about the registration. They will be in contact with you shortly in order to activate your company's {service} account."
                                        values={{service: serviceTitle}}
                                    />
                                );
                            }

                            // Fix url's
                            if (serviceUrl) {
                                serviceUrl = serviceUrl.replace("[LANG]", langCode);
                                serviceUrl = serviceUrl.replace("[ORG_ID]", orgId);
                                serviceUrl = serviceUrl.replace("[SYNC_ID]", syncId);
                            }

                            let serviceCard = (
                                <DashboardServiceCard
                                    key={service}
                                    cols_lg={configuration.servicePortal.dashboard.columns.lg}
                                    cols_md={configuration.servicePortal.dashboard.columns.md}
                                    title={serviceTitle}
                                    description={this.breakLine(serviceDescription)}
                                    icon={serviceIcon}
                                    url={serviceUrl}
                                    noServiceAccessMessage={noServiceAccessMessage}
                                    serviceHasException={serviceHasException}
                                    elementButtonId={elementButtonId}
                                    service={service}
                                />
                            );
                            if (service == 'bolagsdeklaration' && !noServiceAccessMessage) {
                                serviceCard = (
                                    <BOLDashboardCard
                                        key={service}
                                        cols_lg={configuration.servicePortal.dashboard.columns.lg}
                                        cols_md={configuration.servicePortal.dashboard.columns.md}
                                        title={serviceTitle}
                                        description={this.breakLine(serviceDescription)}
                                        icon={serviceIcon}
                                        url={serviceUrl}
                                        noServiceAccessMessage={noServiceAccessMessage}
                                        serviceHasException={serviceHasException}
                                        elementButtonId={elementButtonId}
                                        service={service}
                                    />
                                );

                            }
                            items.push(serviceCard);
                        }
                        return items;
                    }) }
                </FormRow>)
                : null}
                { showReadMore
                    ?
                        <div>
                            <hr />
                            <p className="readmore-link"><FormattedMessage id="services.readMore" defaultMessage="Read more about the services at" />
                                <a href={configuration.urlAfterSignOut}>
                                    {configuration.urlAfterSignOut.replace("https://", " ").replace("http://", " ").replace("www.", "")}
                                </a>
                            </p>
                        </div>
                    : null
                }
            </div>
        );
    }
}

export default OrganizationBasicInfo;
