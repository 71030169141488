import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let generateGlobalSearchPasswordBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GENERATE_GLOBAL_SEARCH_PASSWORD_BTN_CLICKED
    });
}

export default generateGlobalSearchPasswordBtnClicked;