import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

let beginEmailVerification = function(token) {

    getFromBackend('/profile/verify/' + token,
        ActionTypes.PROFILE_EMAIL_VERIFICATION_BEGIN_SUCCESS,
        ActionTypes.PROFILE_EMAIL_VERIFICATION_BEGIN_ERROR,
        { token: token });
};

export default beginEmailVerification;
