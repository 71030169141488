import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getCpbOrganizationUsers = function(representedOrgId, cpbOrgId) {
    getFromBackend('/users/' + representedOrgId + '/cpb-user-list/' + cpbOrgId,
        ActionTypes.CPB_ORGANIZATION_USERS_GET_SUCCESS,
        ActionTypes.CPB_ORGANIZATION_USERS_GET_ERROR);
};

export default getCpbOrganizationUsers;
