import FormStore from '../../base/FormStore.js';
import storeFormManager from '../../validation/StoreFormManager.js';
import redirectToRoute from "../../../actions/actionCreators/navigation/RedirectToRoute";
import getTransferToken
    from "../../../actions/actionCreators/registration/AfterManualIDVerification/GetTransferToken";
import ActionTypes from "../../../actions/ActionTypes";
import getEmailFromToken
    from "../../../actions/actionCreators/registration/AfterManualIDVerification/GetEmailFromToken";

const store = 'hasExistingAccountPromptStore';
const formKey = 'hasExistingAccountPromptForm';

class HasExistingAccountPromptStore extends FormStore {

    getInitialState() {
        let initialState = {
            useExistingEmail: true,
            emailAddress: null,
            error: false
        }

        let formDefinitions = {
            useExistingEmail: {
                defaultValue: true,
            }
        }

        storeFormManager.defineForm(initialState, store, formKey, formDefinitions, this.onSubmit);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/registration-after-manualid-verification/has-existing-account/:token',
                loadHandler: this.onLoad
            }
        ];
    }

    onLoad(state, action, sharedStoreState) {
        let token = sharedStoreState.currentRoute.params.token;

        state.token = token;
        state.useExistingEmail = true;
        state.showLoadingIndicator = true;
        storeFormManager.setFormInitialState(state, formKey);

        getEmailFromToken(token)
    }


    onSubmit(state, values, sharedStoreState) {
        const token = sharedStoreState.currentRoute.params.token;
        if (values.useExistingEmail) {
            state.showLoadingIndicator = true;
            getTransferToken(token)
        } else {
            redirectToRoute(`/registration-after-manualid-verification/create-account/${token}`, false, false, false);
        }
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_TRANSFER_TOKEN_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        window.location.href = `/api/registration/transfer/initiate/${action.result.transferToken}`
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_TRANSFER_TOKEN_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_EMAIL_FROM_TOKEN_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.emailAddress = action.result.emailAddress
        state.error = false
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_EMAIL_FROM_TOKEN_ERROR](state) {
        state.showLoadingIndicator = false;
        state.emailAddress = null
        state.error = true
    }

}

export default HasExistingAccountPromptStore;
