import React from 'react';
import { Router, Route, IndexRoute, Redirect, hashHistory } from 'react-router';
import routeOnEnterHook from './helpers/RouteOnEnterHook.js';

import MasterPage from './components/MasterPage.jsx';
import PageLoader from './components/PageLoader.jsx';
import Maintenance from './components/Maintenance.jsx';
import Http404 from './components/Http404.jsx';
import ProblemReport from './components/ProblemReport.jsx';
import GeneralError from './components/GeneralError.jsx';
import InfoPage from './components/InfoPage.jsx';

import RegistrationMasterPageForFlow2 from './components/registration/personRegistrationFlow/MasterPage.jsx';
import RegistrationStep1Flow2Info from './components/registration/personRegistrationFlow/Step1Info.jsx';
import ExpiredSmsInvitationToCreateAccountMessage from './components/registration/invitedSignatoryFlow/ExpiredSmsInvitationToCreateAccountMessage.jsx';
import ActivateAccount from './components/profile/ActivateAccount.jsx';
import UpdateStrongAuthInformation from './components/profile/UpdateStrongAuthInformation.jsx';
import AcceptTOS from './components/profile/AcceptTOS.jsx';
import UpdateUserInfoToMatchOfficialSource from './components/profile/UpdateUserInfoToMatchOfficialSource.jsx';
import InformIdentityVerifiedCheckYourEmail from './components/profile/InformIdentityVerifiedCheckYourEmail.jsx';
import InformToVisitManualIdVerificationPartner from './components/profile/InformToVisitManualIdVerificationPartner.jsx';
import IdentityVerificiationIsHidden from './components/profile/IdentityVerificiationIsHidden.jsx';

import ServicesBasicInfo from './components/services/BasicInfo.jsx';
import NoPermissionForServicePage from './components/services/NoPermissionForServicePage.jsx';
import ServiceExplanationPage from './components/services/ServiceExplanationPage.jsx';

import EmailVerification from './components/profile/EmailVerification.jsx';
import ForgotPassword from './components/profile/ForgotPassword.jsx';
import ResetPassword from './components/profile/ResetPassword.jsx';
import BasicDetails from './components/profile/yourDetails/BasicDetails.jsx';
import Permissions from './components/profile/yourDetails/Permissions.jsx';
import DeleteMyAccount from './components/profile/yourDetails/DeleteMyAccount.jsx';
import ChangeName from './components/profile/yourDetails/ChangeName.jsx';
import ChangePhone from './components/profile/yourDetails/ChangePhone.jsx';
import ChangeEmail from './components/profile/yourDetails/ChangeEmail.jsx';
import ChangeEmailVerification from './components/profile/yourDetails/ChangeEmailVerification.jsx';
import ChangePassword from './components/profile/yourDetails/ChangePassword.jsx';
import AddSecondaryEmail from './components/profile/AddSecondaryEmail.jsx';
import AddSecondaryEmailResult from './components/profile/AddSecondaryEmailResult.jsx';
import InformEperehdytysUser from './components/profile/InformEperehdytysUser.jsx';

import UserList from './components/users/UserList.jsx';
import AddingUser from './components/users/AddingUser.jsx';
import CreatingApiAccount from './components/users/CreatingApiAccount.jsx';
import EditingUser from './components/users/EditingUser.jsx';
import ChangingUserMainOrg from './components/users/ChangingUserMainOrg.jsx';
import CpbUserList from './components/users/CpbUserList.jsx';
import FindCpbUsers from './components/users/FindCpbUsers.jsx';
import FindAllUsers from './components/users/FindAllUsers.jsx';
import ResendAccountActivationLinkPage from './components/users/ResendAccountActivationLinkPage.jsx';
import ChooseAuthenticationMethod from './components/registration/invitedSignatoryFlow/ChooseAuthenticationMethod.jsx';
import CreateFullAccount from './components/profile/yourDetails/CreateFullAccount.jsx';
import PersonalDataPolicyRejected from './components/profile/PersonalDataPolicyRejected.jsx';
import InfoPageForDocumentDisplay from './components/InfoPageForDocumentDisplay.jsx';
import LandingPageProveYourIdentity from './components/registration/afterManualIDVerification/LandingPageProveYourIdentity.jsx';
import AccountRegistration from './components/registration/afterManualIDVerification/AccountRegistration.jsx';
import AccountRegistrationResultPage from './components/registration/afterManualIDVerification/AccountRegistrationResultPage.jsx';
import SmsLoginErrorPage from './components/profile/SmsLoginErrorPage.jsx';
import SearchUsersGlobally from './components/users/SearchUsersGlobally.jsx';
import ViewSingleUserGlobalSearchResults from './components/users/ViewUser.jsx';
import SearchID06LmaCardOrder from './components/registration/id06LmaUserFlow/SearchID06LmaCardOrder.jsx';
import CreateID06LmaUserAccount from './components/registration/id06LmaUserFlow/CreateID06LmaUserAccount.jsx';
import CreateID06LmaUserAccountEmailVerification from './components/registration/id06LmaUserFlow/CreateID06LmaUserAccountEmailVerification.jsx';
import CsvReports from './components/users/CsvReports.jsx';
import constants from './Constants.js';
import CompleteTransfer from "./components/registration/transfer/CompleteTransfer.jsx";
import HasExistingAccountPrompt
    from "./components/registration/afterManualIDVerification/HasExistingAccountPrompt.jsx";

class Routes extends React.Component {

    render() {
        return (
            <Router history={hashHistory} onUpdate={() => window.scrollTo(0, 0)}>
                <Route onEnter={routeOnEnterHook} path="maintenance" component={Maintenance} />
                <Route path="/" component={ MasterPage }>
                    <IndexRoute onEnter={routeOnEnterHook} component={ PageLoader } />
                    <Redirect from="-/registration/createaccount" to="registration/createaccount" />
                    <Redirect from="-/profile/forgot-password" to="profile/forgot-password" />

                    <Route onEnter={routeOnEnterHook} path="csv-reports" component={CsvReports} />
                    <Route onEnter={routeOnEnterHook} path="csv-reports/:pageNumber" component={CsvReports} />
                    <Route onEnter={routeOnEnterHook} path="transfer/complete/:token" component={CompleteTransfer} />
                    <Route path="registration" component={ RegistrationMasterPageForFlow2 }>
                        <Route onEnter={routeOnEnterHook} path="createaccount(/:emailToPrefill)" component={ RegistrationStep1Flow2Info } />
                        <Route onEnter={routeOnEnterHook} path="createaccount(/:emailToPrefill)(/:firstNameToPrefill)(/:lastNameToPrefill)(/:phoneNumberToPrefill)" component={ RegistrationStep1Flow2Info } />
                        <Route onEnter={routeOnEnterHook} path="continue" component={ RegistrationStep1Flow2Info } />
                    </Route>
                    <Route path="registration-after-manualid-verification">
                        <Route onEnter={routeOnEnterHook} path="begin/:token" component={ LandingPageProveYourIdentity } />
                        <Route onEnter={routeOnEnterHook} path="has-existing-account/:token" component={ HasExistingAccountPrompt } />
                        <Route onEnter={routeOnEnterHook} path="create-account/:token" component={ AccountRegistration } />
                        <Route onEnter={routeOnEnterHook} path="account-created" component={ AccountRegistrationResultPage } />
                        <Route onEnter={routeOnEnterHook} path="verify-email/:email" component={ EmailVerification } />
                        <Route onEnter={routeOnEnterHook} path="finish-verify-email/:token" component={ AccountRegistrationResultPage } />
                    </Route>
                    <Route path="profile">
                        <Route path="emailverification">
                            <Route onEnter={routeOnEnterHook} path="verificationneededafterstep1" component={ EmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="verificationneeded" component={ EmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="mergeverificationneeded" component={ EmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="begin/:token" component={ EmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="merge/:token" component={ EmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="loggedoff/:token/:username" component={ EmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="finish/:token" component={ EmailVerification } />
                        </Route>
                        <Route onEnter={routeOnEnterHook} path="create-full-account(/:email)(/:phone)" component={ CreateFullAccount } />
                        <Route onEnter={routeOnEnterHook} path="update-user-info" component={ UpdateUserInfoToMatchOfficialSource } />
                        <Route onEnter={routeOnEnterHook} path="identity-verified-check-your-email" component={ InformIdentityVerifiedCheckYourEmail } />
                        <Route onEnter={routeOnEnterHook} path="visit-manual-id-verification-partner" component={ InformToVisitManualIdVerificationPartner } />
                        <Route onEnter={routeOnEnterHook} path="identity-verification-is-hidden" component={ IdentityVerificiationIsHidden } />
                        <Route path="emailchange">
                            <Route onEnter={routeOnEnterHook} path="begin/:token" component={ ChangeEmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="finish/:token" component={ ChangeEmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="set-new-password/:token" component={ ChangeEmailVerification } />
                            <Route onEnter={routeOnEnterHook} path="success" component={ ChangeEmailVerification } />
                        </Route>
                        <Route onEnter={routeOnEnterHook} path="accept-tos" component={ AcceptTOS } />
                        <Route onEnter={routeOnEnterHook} path="forgot-password" component={ ForgotPassword } />
                        <Route onEnter={routeOnEnterHook} path="forgot-password-email-sent" component={ ForgotPassword } />
                        <Route onEnter={routeOnEnterHook} path="reset-password/:token" component={ ResetPassword } />
                        <Route onEnter={routeOnEnterHook} path="create/:token" component={ ActivateAccount } />
                        <Route onEnter={routeOnEnterHook} path="addAnotherEmailToAccount/:token" component={ AddSecondaryEmail } />
                        <Route onEnter={routeOnEnterHook} path="addAnotherEmailResult/:result" component={ AddSecondaryEmailResult } />
                        <Route onEnter={routeOnEnterHook} path="updatestrongauthinfo" component={UpdateStrongAuthInformation} />
                        {/*<Route onEnter={routeOnEnterHook} path="details" component={ Overview } />*/}
                        <Route onEnter={routeOnEnterHook} path="basic-details" component={ BasicDetails } />
                        <Route onEnter={routeOnEnterHook} path="permissions" component={ Permissions } />
                        <Route onEnter={routeOnEnterHook} path="delete-my-account" component={ DeleteMyAccount } />
                        <Route onEnter={routeOnEnterHook} path="change-name" component={ ChangeName } />
                        <Route onEnter={routeOnEnterHook} path="change-phone" component={ ChangePhone } />
                        <Route onEnter={routeOnEnterHook} path="change-email" component={ ChangeEmail } />
                        <Route onEnter={routeOnEnterHook} path="changepassword" component={ ChangePassword } />
                        <Route onEnter={routeOnEnterHook} path="insufficient-session-open" component={ InformEperehdytysUser } />
                    </Route>
                    <Route onEnter={routeOnEnterHook} path="redirect-to-external-app" />
                    <Route onEnter={routeOnEnterHook} path="choose-authentication-method" component={ ChooseAuthenticationMethod } />
                    <Route onEnter={routeOnEnterHook} path="error/:key(/:message)" component={ GeneralError } />
                    <Route onEnter={routeOnEnterHook} path="report" component={ ProblemReport } />
                    <Route onEnter={routeOnEnterHook} path="find-users" component={ FindAllUsers } />

                    <Route onEnter={routeOnEnterHook} path="search-users-globally" component={ SearchUsersGlobally } />
                    <Route onEnter={routeOnEnterHook} path="search-users-globally/view-user/:userId" component={ ViewSingleUserGlobalSearchResults } />
                    <Route onEnter={routeOnEnterHook} path="nopermissionforservice" component={ NoPermissionForServicePage } />
                    <Route onEnter={routeOnEnterHook} path="explanation" component={ ServiceExplanationPage } />
                    <Route onEnter={routeOnEnterHook} path="invitationexpired" component={ ExpiredSmsInvitationToCreateAccountMessage } />
                    <Route onEnter={routeOnEnterHook} path="confirm-order(/:cardSupplier)" />
                    <Route onEnter={routeOnEnterHook} path="personal-data-policy-rejected" component={ PersonalDataPolicyRejected } />
                    <Route onEnter={routeOnEnterHook} path="failed-sms-verification" component={ SmsLoginErrorPage } />
                    <Route onEnter={routeOnEnterHook} path="resend-account-activation-link(/:email)" component={ ResendAccountActivationLinkPage } />

                    <Route path="/:organizationCode/">
                        <IndexRoute onEnter={routeOnEnterHook} component={ PageLoader } />
                        <Redirect from="home" to="/:organizationCode/" />
                        <Redirect from="organization" to="organization/users" />
                        <Redirect from="users" to="users/list/all" />
                        <Route onEnter={routeOnEnterHook} path="cpb-users/:cpbOrgId" component={ CpbUserList } />
                        <Route onEnter={routeOnEnterHook} path="cpb-find-users" component={ FindCpbUsers } />
                        <Route onEnter={routeOnEnterHook} path="organization/users" component={ UserList } />
                        <Route path="users">
                            <Route onEnter={routeOnEnterHook} path="list/all" component={ UserList } />
                            <Route onEnter={routeOnEnterHook} path="list/:serviceName" component={ UserList } />
                            <Route onEnter={routeOnEnterHook} path={"list/" + constants.permissions.global} component={ UserList } />
                            <Route onEnter={routeOnEnterHook} path="add" component={ AddingUser } />
                            <Route onEnter={routeOnEnterHook} path="add/:email" component={ AddingUser } />
                            <Route onEnter={routeOnEnterHook} path="verify-company-access-request/:token" component={ AddingUser } />
                            <Route onEnter={routeOnEnterHook} path="createapiaccount" component={ CreatingApiAccount } />
                            <Route onEnter={routeOnEnterHook} path="edit/:userId" component={ EditingUser } />
                            <Route onEnter={routeOnEnterHook} path="changemainorg/:userId" component={ ChangingUserMainOrg } />
                            <Route onEnter={routeOnEnterHook} path="notsupportedpermission" component={ ServiceExplanationPage } />
                        </Route>
                        <Route onEnter={routeOnEnterHook} path="dashboard" component={ ServicesBasicInfo } />
                        <Route onEnter={routeOnEnterHook} path="emailverificationfinished" component={ EmailVerification } />
                        <Route onEnter={routeOnEnterHook} path="info" component={ InfoPage } />
                        <Route onEnter={routeOnEnterHook} path="info-page" component={ InfoPageForDocumentDisplay } />
                        <Route onEnter={routeOnEnterHook} path="resend-account-activation-link(/:email)" component={ ResendAccountActivationLinkPage } />
                    </Route>
                    <Redirect from="create-id06-lma-user" to="create-id06-lma-user/order-search" />
                    <Route path="create-id06-lma-user">
                        <Route onEnter={routeOnEnterHook} path="order-search" component={ SearchID06LmaCardOrder } />
                        <Route onEnter={routeOnEnterHook} path="create-account" component={ CreateID06LmaUserAccount } />
                        <Route onEnter={routeOnEnterHook} path="email-verification-needed/:emailToVerify" component={ CreateID06LmaUserAccountEmailVerification } />
                    </Route>
                    <Route onEnter={routeOnEnterHook} path="*" component={ Http404 } />
                </Route>
            </Router>
        );
    }
}

export default Routes;
