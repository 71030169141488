import ActionTypes from "../../../actions/ActionTypes";
import completeTransfer
    from "../../../actions/actionCreators/registration/AfterManualIDVerification/CompleteTansfer";
import BaseStore from "../../base/BaseStore";

class CompleteTransferStore extends BaseStore {

    getInitialState() {
        return {
            token: null,
            result: null,
            error: null,
            loading: false,
            success: false,
            wrongPersonId: false
        };
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/transfer/complete/:token',
                loadHandler: this.onPageLoaded
            }
        ];
    }

    onPageLoaded(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;

        state.result = null;
        state.error = null;
        state.loading = true;
        state.success = false;

        completeTransfer(sharedStoreState.currentRoute.params.token)
    }

    [ActionTypes.REGISTRATION_COMPLETE_TRANSFER_SUCCESS](state, action) {
        if (action.result.notSamePersonId) {
            state.logoutUrl = action.result.logoutUrl;
            state.error = true;
            state.wrongPersonId = true
        }

        state.showLoadingIndicator = false;
        state.result = action.result;
        state.loading = false;
        state.success = true;
    }

    [ActionTypes.REGISTRATION_COMPLETE_TRANSFER_ERROR](state, action) {
        if (action.httpErrorStatus === 403)
            return

        state.result = null;
        state.error = {error: true};
        state.loading = false;
        state.success = false;
    }
}

export default CompleteTransferStore;
