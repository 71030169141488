import dispatcher from '../Dispatcher.js';
import SharedStore from './base/SharedStore.js';
import CounterStore from './base/CounterStore.js';

import MenuItemsStore from './MenuItemsStore.js';
import Step1StoreForFlow2 from './registration/personRegistrationFlow/Step1Store.js';
import ActivateAccountStore from './profile/ActivateAccountStore.js';
import ProfileEmailVerificationStore from './profile/EmailVerificationStore.js';
import ProfilePasswordResetStore from './profile/PasswordResetStore.js';
import AddSecondaryEmailStore from './profile/AddSecondaryEmailStore.js';
import AcceptTOSStore from './profile/AcceptTOSStore.js';
import UpdateUserInfoToMatchOfficialSourceStore from './profile/UpdateUserInfoToMatchOfficialSourceStore.js';
import YourDetailsStore from './profile/YourDetailsStore.js';
import ProfileChangeEmailVerificationStore from './profile/ChangeEmailVerificationStore.js';
import UpdateStrongAuthInformation from './profile/UpdateStrongAuthInformationStore.js';
import PageLoaderStore from './PageLoaderStore.js';
import ServicesBasicInfoStore from './services/ServicesBasicInfoStore.js';
import UsersStore from './users/UsersStore.js';
import UsersGlobalSearchStore from './users/UsersGlobalSearchStore.js';
import InformEperehdytysUserStore from './profile/InformEperehdytysUserStore.js';
import CardReviewLandingPageStore from './cards/CardReviewLandingPageStore.js';
import CreateFullAccountStore from './profile/CreateFullAccountStore.js';
import PersonalDataPolicyRejectedStore from './profile/PersonalDataPolicyRejectedStore.js';
import InfoPageStore from './InfoPageStore.js';
import LandingPageProveYourIdentityStore from './registration/afterManualIDVerification/LandingPageProveYourIdentityStore.js';
import AccountRegistrationStore from './registration/afterManualIDVerification/AccountRegistrationStore.js';
import InformToVisitManualIdVerificationPartnerStore from './profile/InformToVisitManualIdVerificationPartnerStore.js';
import RedirectToExternalAppStore from './RedirectToExternalAppStore.js';
import ID06LmaUserStore from './registration/id06LmaUserFlow/ID06LmaUserStore.js';
import ChooseAuthenticationMethodStore from './registration/invitedSignatoryFlow/ChooseAuthenticationMethodStore.js';
import CsvReportStore from "./users/CsvReportStore";
import CompleteTransferStore from "./registration/transfer/CompleteTransferStore.js";
import HasExistingAccountPromptStore
    from "./registration/afterManualIDVerification/HasExistingAccountPromptStore";
/* This list must be maintained: */

let allStoreClasses = {
    menuItems: MenuItemsStore,
    registrationStep1Flow2: Step1StoreForFlow2,
    home: PageLoaderStore,
    emailVerification: ProfileEmailVerificationStore,
    passwordReset: ProfilePasswordResetStore,
    yourDetails: YourDetailsStore,
    changeEmailVerification: ProfileChangeEmailVerificationStore,
    servicesBasicInfo: ServicesBasicInfoStore,
    users: UsersStore,
    usersGlobalSearch: UsersGlobalSearchStore,
    activateAccount: ActivateAccountStore,
    updateStrongAuthInformation: UpdateStrongAuthInformation,
    addSecondaryEmail: AddSecondaryEmailStore,
    acceptToS: AcceptTOSStore,
    updateUsername: UpdateUserInfoToMatchOfficialSourceStore,
    InformEperehdytysUserStore: InformEperehdytysUserStore,
    cardReviewLandingPageStore: CardReviewLandingPageStore,
    createFullAccountStore: CreateFullAccountStore,
    personalDataPolicyRejectedStore: PersonalDataPolicyRejectedStore,
    infoPageStore: InfoPageStore,
    landingPageProveYourIdentityStore: LandingPageProveYourIdentityStore,
    accountRegistrationStore: AccountRegistrationStore,
    informToVisitManualIdVerificationPartnerStore: InformToVisitManualIdVerificationPartnerStore,
    redirectToExternalAppStore: RedirectToExternalAppStore,
    id06LmaUserStore: ID06LmaUserStore,
    chooseAuthenticationMethodStore: ChooseAuthenticationMethodStore,
    csvReportStore: CsvReportStore,
    mergeIdentityVerificationStore: CompleteTransferStore,
    hasExistingAccountPromptStore: HasExistingAccountPromptStore
};

/* Create all FLUX stores: */

let allStores = {};

let sharedStore = new SharedStore(dispatcher);
allStores['shared'] = sharedStore;

Object.keys(allStoreClasses).forEach((storeKey) => {
    let storeClass = allStoreClasses[storeKey];
    allStores[storeKey] = new storeClass(dispatcher, sharedStore);
});

allStores['counter'] = new CounterStore(dispatcher, sharedStore);

export default allStores;
