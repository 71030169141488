import StoreComponent from "../../base/StoreComponent.jsx";
import React from "react";
import {PageHeader, RadioInput} from "@vaultit/stv-utils-frontend";
import {FormattedMessage} from "react-intl";

const Header = ({state}) =>
    <PageHeader
        pageTitleMessageId="registration.afterManualIdVerification.hasExistingAccount"
        sharedStore={state}
        hideSeparator={false}
        description={false}
    />

class HasExistingAccountPrompt extends StoreComponent {
    renderWhenReady() {
        const {hasExistingAccountPromptForm, emailAddress, error} = this.state.hasExistingAccountPromptStore;
        const form = hasExistingAccountPromptForm;
        const fields = form ? form.fields : null;

        if (!fields)
            return null

        if (error)
            return <h2>Something went wrong</h2>

        if (!emailAddress)
            return null

        return (
            <div>
                <Header state={this.state.shared} />
                <FormattedMessage
                    id="registration.afterManualIdVerification.emailAlreadyInUseInfoPart1"
                    defaultMessage={`An existing ID06 account with the email address`}
                />

                <strong>&nbsp;"{emailAddress}"&nbsp;</strong>

                <FormattedMessage
                    id="registration.afterManualIdVerification.emailAlreadyInUseInfoPart2"
                    defaultMessage={`was found. If this is your email address, please select "Use my existing account".`}
                />

                <p />
                <FormattedMessage
                    id="registration.afterManualIdVerification.emailAlreadyInUseInfoPart3"
                    defaultMessage={'If this is not your email address, please select "Create a new account with another email address"'}
                />
                <p style={{"margin-top": "20pt"}} />

                <form name="useExistingEmailForm">
                    <RadioInput
                        id="users_editing_user_type_main"
                        name="useExistingEmail"
                        field={fields.useExistingEmail}
                        value={true}
                        checked={fields.useExistingEmail.value}
                        messageId="registration.afterManualIdVerification.promptUseMyExistingAccount"
                        defaultMessage={`Use my existing account (${emailAddress})`}
                        bold={true}
                        labelClassName="m-0"
                        disabled={false}
                    />

                    <p style={{"margin-top": "10pt"}} />

                    <RadioInput

                        name="useExistingEmail"
                        field={fields.useExistingEmail}
                        value={false}
                        checked={!fields.useExistingEmail.value}
                        messageId="registration.afterManualIdVerification.promptCreateNewAccount"
                        defaultMessage="Create a new account with another email address"
                        bold={true}
                        labelClassName="m-0"
                        disabled={false}
                    />
                    <p style={{"margin-top": "20pt"}} />
                    <button
                        className="btn btn-primary btn-lg"
                        role="button"
                        onClick={form.handleOnSubmitBtnClicked}
                    >
                        <FormattedMessage
                            id="registration.afterManualIdVerification.promptContinue"
                            defaultMessage="Continue"
                        />
                    </button>
                </form>

            </div>
        )
    }
}

export default HasExistingAccountPrompt;
