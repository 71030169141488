import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let adminMergesPersons = function (data) {
    postToBackend('/users/get-user-global-info/merge-persons',
        ActionTypes.GLOBAL_USER_INFO_MERGE_PERSONS_SUCCESS,
        ActionTypes.GLOBAL_USER_INFO_MERGE_PERSONS_ERROR,
        data);
};

export default adminMergesPersons;
