import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let resendMergeVerificationEmail = function () {
    postToBackend('/profile/resend-merge-verify-email',
        ActionTypes.PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_SUCCESS,
        ActionTypes.PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_ERROR);
};

export default resendMergeVerificationEmail;
