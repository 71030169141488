import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let addNewUser = function (orgId, data) {
    postToBackend('/users/' + orgId + '/users-list',
        ActionTypes.ORGANIZATION_USERS_ADD_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_ADD_ERROR,
        data);
};

export default addNewUser;
