import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let adminChangeEmailCancelBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.ADMIN_CHANGE_EMAIL_CANCEL_BTN_CLICKED
        });
    };

export default adminChangeEmailCancelBtnClicked;
