import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let userAccountDeleted = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.PROFILE_USER_ACCOUNT_DELETED_SUCCESS
        });
    };

export default userAccountDeleted;
