import ActionTypes from '../ActionTypes.js';
import {postToBackend} from './generic/CallBackend.js';

let getPersonalNumberValidation = function(country, personalNumber) {
    postToBackend('/general/personal-number-validation',
    ActionTypes.VALIDATE_PERSONAL_NUMBER_SUCCESS,
    ActionTypes.VALIDATE_PERSONAL_NUMBER_ERROR,
    {
        country: country,
        personalNumber: personalNumber
    });
};

export default getPersonalNumberValidation;
