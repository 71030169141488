import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let resendAccountActivationLink = function (email, orgId=null, userLanguageCode=null) {

    let url = `/users/resend-account-activation-link`;
    if (orgId) {
        url = `/users/${orgId}/resend-account-activation-link`;
    }

    postToBackend(url,
        ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_SUCCESS,
        ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_ERROR,
        {
            'email': email,
            'userLanguageCode': userLanguageCode
        }
    );
};

export default resendAccountActivationLink;
