import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let getPermissionRowClickedHandler = function(orgCode, url) {
    let permissionsRowClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.PROFILE_YOUR_DETAILS_PERMISSIONS_ROW_CLICKED,
            orgCode: orgCode,
            url: url
        });
    };

    return permissionsRowClicked;
};

export default getPermissionRowClickedHandler;
