import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import {FormattedMessage} from 'react-intl';
import {PageHeader, FormRow, FormColumn} from '@vaultit/stv-utils-frontend';


class CreateID06LmaUserAccountEmailVerification extends StoreComponent {

    renderWhenReady() {
        let store = this.state.id06LmaUserStore;
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="createID06LmaUserAccountEmailVerification.pageTitle"
                    sharedStore={this.state.shared}
                />

                <FormRow>
                    <FormColumn width="12">
                        <p>
                            <FormattedMessage
                                id="createID06LmaUserAccountEmailVerification.verificationNeededLine1"
                                defaultMessage="To continue, we need to check that the e-mail address {email} is yours."
                                values={{email: (<strong>{store.emailToVerify}</strong>)}}
                            />
                        </p>
                        <p>
                            <FormattedMessage
                                id="createID06LmaUserAccountEmailVerification.verificationNeededLine2"
                                defaultMessage="Open the email we sent you and click the verification link."
                            />
                        </p>
                        <hr />
                        <FormattedMessage
                            id="createID06LmaUserAccountEmailVerification.verificationNeededLine3Part3"
                            defaultMessage="If you run into any problems, you can always contact "
                        />
                        &nbsp;
                        <a href={'mailto:' + uiConfiguration.customerSupportEmail}>
                            <FormattedMessage
                                id="createID06LmaUserAccountEmailVerification.verificationNeededLine3Part4"
                                defaultMessage="our customer service."
                            />
                        </a>
                    </FormColumn>
                </FormRow>
            </div>
        );
    }


}
export default CreateID06LmaUserAccountEmailVerification;
