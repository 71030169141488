import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import prepareUrl from '../../../helpers/PrepareUrl.js';
import { FormColumn, PageHeader, FormRow, FormGroup, TextInput, ValidationMessages, SelectInput, InputLabel, CheckboxInput, Message, PhoneNumberInputComponent } from '@vaultit/stv-utils-frontend';
import constructTranslations from '../../../helpers/ConstructTranslations.js';

const messages = defineMessages({
    firstNamePlaceholder: {
        id: 'registration.step1.firstName',
        description: 'First name placeholder',
        defaultMessage: 'First name'
    },
    lastNamePlaceholder: {
        id: 'registration.step1.lastName',
        description: 'Last name placeholder',
        defaultMessage: 'Last name'
    },

    countryPlaceholder: {
        id: 'registration.step1.flow2.country',
        description: 'Country placeholder',
        defaultMessage: 'Country'
    },

    emailPlaceholder: {
        id: 'registration.step1.email',
        description: 'Email address placeholder',
        defaultMessage: 'Email address'
    },

    emailAgainPlaceholder: {
        id: 'registration.step1.flow2.repeatEmail',
        description: 'Email address again placeholder',
        defaultMessage: 'Email again'
    },

    passwordPlaceholder: {
        id: 'registration.step1.password',
        description: 'Password placeholder',
        defaultMessage: 'Password'
    },
    passwordAgainPlaceholder: {
        id: 'registration.step1.flow2.repeatPassword',
        description: 'Password again placeholder',
        defaultMessage: 'Password again'
    },

    countrySelectionInfo: {
        id: 'registration.step1.flow2.country.explanation',
        description: 'Country selection explanation',
        defaultMessage: 'Country of your National Person ID number'
    },

    nationalIdentityNumberInfo: {
        id: 'registration.step1.flow2.nationalIdentityNumber.explanation',
        description: 'National Person ID number input field explanation',
        defaultMessage: 'Required to verify your identity'
    }
});

class AccountRegistration extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    constructGovIDValueTranslationKey(identityType, countryValue) {
        let translationKey = "registration.afterManualIdVerification.value"
        let messageId = constructTranslations.getTranslationKeyManualIdPages(identityType, translationKey);
        let elementId = 'create_account_after_identity_verification_national_identity_number';
        if (countryValue) {
            elementId += `-${countryValue}`;
        }

        return {
            id: elementId,
            messageId: messageId,
            defaultMessage: 'National Person ID number'
        }
    }

    renderWhenReady() {

        const { formatMessage } = this.context.intl;

        let store = this.state.accountRegistrationStore;

        let form = store.createAccountAfterIDVerificationForm;
        let fields = form ? form.fields: null;

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration.style;

        let personCodeCountryOptions =[];
        for (let key in this.state.shared.classifiers.countries) {
            let item = this.state.shared.classifiers.countries[key];
            if (item.code) {
                personCodeCountryOptions.push({
                    value: item.code,
                    displayedText: item.name
                });
            } else {
                personCodeCountryOptions.push({disbled:true});
            }
        }

        let personalNumberFieldTexts = this.constructGovIDValueTranslationKey(store.identityType, fields ? fields.country.value : null);

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="registration.afterManualIdVerification.createAccountTitle"
                    sharedStore={this.state.shared}
                    hideSeparator={false}
                    description={false}
                />

                {form
                ?
                    <div>
                        {store.emailAlreadyBelongsToUser || store.ninIsNotMatching
                            ?
                                <Message type="info" id="create_account_after_identity_verification_email_needs_verification">
                                    { store.emailAlreadyBelongsToUser
                                        ?
                                            <React.Fragment>

                                                <FormattedMessage
                                                    id="registration.afterManualIdVerification.emailAlreadyInUseWarningPart1"
                                                    defaultMessage={`An existing ID06 account with the email address`}
                                                />
                                                <strong>&nbsp;{fields.email.value}&nbsp;</strong>
                                                <FormattedMessage
                                                    id="registration.afterManualIdVerification.emailAlreadyInUseWarningPart2"
                                                    defaultMessage={`was found. If this is your email address, please login with your existing account to complete the registration.`}
                                                />
                                                <p />

                                                <strong>
                                                    <a href={`/api/registration/transfer/initiate/${store.mergePersonsToken}`}>
                                                        <FormattedMessage
                                                            id="registration.afterManualIdVerification.loginWithExistingAccount"
                                                            defaultMessage={`Login with my existing account`}
                                                        />

                                                    </a>
                                                </strong>
                                            </React.Fragment>
                                        : null

                                    }
                                    {store.ninIsNotMatching
                                        ?
                                            <FormattedMessage
                                                id="registration.afterManualIdVerification.anotherUserVerifiedEmail"
                                                defaultMessage="Another person has verified that email-address, you can not use it.
                                                    if this other person is you please authenticate"
                                            />
                                        : null
                                    }
                                </Message>
                            : null
                        }

                        <form name="createAccountAfterIDVerificationForm" noValidate="">
                            <FormRow>
                                <FormColumn width="8">
                                    <FormRow>

                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.firstName}>
                                                    <TextInput
                                                        id="create_account_after_identity_verification_first_name"
                                                        field={fields.firstName}
                                                        messageId="registration.step1.firstName"
                                                        defaultMessage="First name"
                                                        placeholder={formatMessage(messages.firstNamePlaceholder) }
                                                        required={true}
                                                        disabled={true}
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.lastName}>
                                                    <TextInput
                                                        id="create_account_after_identity_verification_last_name"
                                                        field={fields.lastName}
                                                        messageId="registration.step1.lastName"
                                                        defaultMessage="Last name"
                                                        placeholder={formatMessage(messages.lastNamePlaceholder) }
                                                        required={true}
                                                        disabled={true}
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>

                                    </FormRow>
                                </FormColumn>
                            </FormRow>

                            <FormRow>
                                <FormColumn width="8">
                                    <FormRow>

                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.country}>
                                                    <SelectInput
                                                        id="create_account_after_identity_verification_country"
                                                        field={fields.country}
                                                        options={personCodeCountryOptions}
                                                        messageId="registration.step1.flow2.country"
                                                        defaultMessage="Country"
                                                        required={true}
                                                        disabled={true}
                                                    />
                                                </ ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.nationalIdentityNumber}>
                                                    <TextInput
                                                        type={uiConfiguration.ninIsHidden ? 'password' : 'text'}
                                                        field={fields.nationalIdentityNumber}
                                                        required={true}
                                                        disabled={true}
                                                        {...personalNumberFieldTexts}
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>

                                    </FormRow>
                                </FormColumn>
                            </FormRow>

                            <FormRow>
                                <FormColumn width="8">
                                    <FormGroup>
                                        <ValidationMessages field={fields.email}>
                                            <TextInput
                                                id="create_account_after_identity_verification_email"
                                                field={fields.email}
                                                messageId="registration.step1.email"
                                                defaultMessage="Email address"
                                                placeholder={formatMessage(messages.emailPlaceholder) }
                                                required={true}
                                                disabled={false}
                                                autoFocus={true}
                                                onPaste={ (e) => e.preventDefault() }
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>

                            <FormRow>
                                <FormColumn width="8">
                                    <FormGroup>
                                        <ValidationMessages field={fields.emailAgain}>
                                            <TextInput
                                                id="create_account_after_identity_verification_email_again"
                                                field={fields.emailAgain}
                                                messageId="registration.step1.flow2.repeatEmail"
                                                defaultMessage="Email again"
                                                placeholder={formatMessage(messages.emailAgainPlaceholder) }
                                                required={true}
                                                disabled={false}
                                                onPaste={ (e) => e.preventDefault() }
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>

                            <FormRow>
                                <FormColumn width="8">
                                    <FormGroup>
                                        <ValidationMessages field={fields.phoneNumber} componentCustomValidation="errors.validation.field.componentCustomValidation.phoneNumber">
                                            <InputLabel
                                                required={true}
                                                htmlFor="create_account_after_identity_verification_phone_number"
                                                messageId="registration.step1.phoneNumber"
                                                defaultMessage="Phone number"
                                            />
                                            <PhoneNumberInputComponent
                                                id="create_account_after_identity_verification_phone_number"
                                                className="form-control"
                                                value={fields.phoneNumber.value || ""}
                                                onChange={fields.phoneNumber.handleOnChange}
                                                preferredCountries={['fi', 'ee', 'se']}
                                                disabled={true}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>

                            <FormRow>
                                <FormColumn width="8">
                                    <FormRow>

                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.password}>
                                                    <TextInput
                                                        id="create_account_after_identity_verification_password"
                                                        type="password"
                                                        field={fields.password}
                                                        messageId="registration.step1.password"
                                                        defaultMessage="Your password"
                                                        required={true}
                                                        placeholder={formatMessage(messages.passwordPlaceholder) }
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="6">
                                            <FormGroup>
                                                <ValidationMessages field={fields.passwordAgain} match="errors.validation.field.match.passwords">
                                                    <TextInput
                                                        id="create_account_after_identity_verification_password_again"
                                                        type="password"
                                                        field={fields.passwordAgain}
                                                        messageId="registration.step1.flow2.repeatPassword"
                                                        defaultMessage="Password again"
                                                        required={true}
                                                        placeholder={formatMessage(messages.passwordAgainPlaceholder) }
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                    </FormRow>
                                </FormColumn>
                                <FormColumn width="4" className="if-theme-needs-hide">
                                    <label className="d-inline-block w-100 form-control-label hidden-xs-down">&nbsp;</label>
                                    <small className="form-control-description my-5 my-sm-0">
                                        <span>
                                            <i className="fa fa-info-circle fa-mr"></i>
                                            <FormattedMessage id="registrations.step1.flow2.passwordExplanatiopnText" defaultMessage="Password must be at least 8 characters long." />
                                        </span>
                                    </small>
                                </FormColumn>
                            </FormRow>


                            <FormRow>
                                <FormColumn width="8">
                                    <FormGroup>
                                        <ValidationMessages field={fields.acceptTOS} required={"registration.step1.personalDataPolicy.required"}>
                                            <CheckboxInput
                                                id="create_account_after_identity_verification_terms_of_service_cbx"
                                                checked={fields.acceptTOS.value}
                                                field={fields.acceptTOS}
                                                messageId="registration.step1.acceptanceOfTOS"
                                                defaultMessage="I accept the "
                                            >
                                                <a id="create_account_after_identity_verification_terms_of_service_cbx" onClick={ (e) => prepareUrl.openUrlInPopUpWindow(e, store.termsOfServicePdfUrl) } href="#">
                                                    <FormattedMessage id="registration.step1.personalDataPolicyPart1" defaultMessage="ID06 General Terms and Conditions" />
                                                </a>
                                                <FormattedMessage id="registration.step1.personalDataPolicyPart2" defaultMessage="and agree that my information is processed in accordance with the terms of this policy." />
                                            </CheckboxInput>
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                        </form>

                        {/*
                            Submit button is outside the form on purpose - form contains password field and we want to disable password manager here.
                            Default button behaviour is done manually through 'handleOnEnterKeyDownToSubmitForm' handlers.
                        */}
                        <FormRow>
                            <FormColumn width="8">
                                <FormGroup className="mt-6">
                                    <button
                                        id="create_account_after_identity_verification_submit"
                                        className="btn btn-primary btn-lg ml-3"
                                        onClick={form.handleOnSubmitBtnClicked}
                                        role="button"
                                    >
                                        <FormattedMessage id="registration.step1.continue" defaultMessage="Continue" />
                                        <i aria-hidden="true" className="fa fa-angle-right fa-ml"></i>
                                    </button>
                                </FormGroup>
                            </FormColumn>
                        </FormRow>
                    </div>
                : null
                }


            </div>
        );
    }


}

export default AccountRegistration;
