import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

export default function (token, password, firstName, lastName, phoneNumber, country, personalNumber) {
    postToBackend('/profile/activate-account/' + token,
        ActionTypes.PROFILE_ACTIVATE_ACCOUNT_SUCCESS,
        ActionTypes.PROFILE_ACTIVATE_ACCOUNT_ERROR,
        {
            password: password,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            country: country,
            personalNumber: personalNumber
        });
};
