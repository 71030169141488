import React from 'react';
import PropTypes from 'prop-types';
import StoreComponent from './base/StoreComponent.jsx';

class ThirdLevelMenu extends StoreComponent {

    static get propTypes() {
        return {
            menuItems: PropTypes.array,
            currentOrgId: PropTypes.string,
            currentOrgStateIsActive: PropTypes.bool,
            currentLanguage: PropTypes.string,
            activeRoute: PropTypes.string,
            maximumRepresentedOrgsLimitReached: PropTypes.bool,
            userCanManageOrgAndUsers: PropTypes.bool
        };
    }

    renderWhenReady() {

        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;
        let backLinkTitle = uiConfiguration.backToWebsiteLink.title[currentLanguageCode];
        let backLinkUrl = uiConfiguration.backToWebsiteLink.url;

        let menuItemIsActive = (menuItem) => {
            if (menuItem.showAsActive) {
                if (menuItem.showAsActive.forRoutesWithFragment) {
                    return this.props.activeRoute.indexOf(menuItem.showAsActive.forRoutesWithFragment) !== -1;
                }
            }
        };

        let getMenuItem = (menuItem, isSubItem) => {
            let url = null;

            if (menuItem.url) {
                url = menuItem.url.replace('[ORG_ID]', this.props.currentOrgId);
            }

            let isActive = menuItemIsActive(menuItem);

            let itemClass = isActive ? 'nav-link active' : 'nav-link';

            let menuItemTitle = menuItem.title[this.props.currentLanguage];

            let hideBecauseOfRepresentationLimit = menuItem.displayOnlyIfRepresentationsLimitNotReached && this.props.maximumRepresentedOrgsLimitReached;

            let isInActiveOrgContext = this.props.currentOrgId && this.props.currentOrgStateIsActive;
            let hideBecauseNotInActiveOrgContext = menuItem.displayOnlyInActiveOrgContext && !isInActiveOrgContext;

            let hideBecauseUserCanNotManageOrgAndUsers = menuItem.displayOnlyIfUserCanManageOrgAndUsers && !this.props.userCanManageOrgAndUsers;


            if (!hideBecauseOfRepresentationLimit && !hideBecauseNotInActiveOrgContext && !hideBecauseUserCanNotManageOrgAndUsers) {
                return (
                    <li key={menuItem.id} className="nav-item">
                        {!isSubItem
                            ?
                                <h3 id={ menuItem.id + (url ? "_parent" : "") }>
                                    {url
                                        ? <a className="nav-link" id={menuItem.id} href={url}>{menuItemTitle}</a>
                                        : menuItemTitle
                                    }
                                </h3>
                            : <a id={menuItem.id} href={url} className={itemClass}>{menuItemTitle}</a>
                        }

                        { menuItem.subItems
                            ?
                                <ul className="navbar-nav">
                                    { menuItem.subItems.map((menuSubItem) => {
                                        return getMenuItem(menuSubItem, true);
                                    })
                                    }
                                </ul>
                            : null
                        }
                    </li>
                );
            }

        };

        let thirdLevelMenuItems = this.props.menuItems.map((menuItem) => {
            return getMenuItem(menuItem);
        });

        return (
            <nav className="stv-side-nav navbar navbar-toggleable-sm">
                <div className="navbar-collapse collapse" id="third-level-nav">
                    <ul className="navbar-nav">
                        <li>
                            <a href={backLinkUrl} className="back-to-home" id='back_to_home_link'>
                                <i className="fa fa-mail-reply"></i>
                                {backLinkTitle}
                            </a>
                        </li>
                        {thirdLevelMenuItems}
                    </ul>
                </div>
            </nav>
        );
    }
}

export default ThirdLevelMenu;
