import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

let finishEmailVerification = function(token) {

    getFromBackend('/profile/verified/' + token,
        ActionTypes.PROFILE_EMAIL_VERIFICATION_END_SUCCESS,
        ActionTypes.PROFILE_EMAIL_VERIFICATION_END_ERROR,
        { token: token });
};

export default finishEmailVerification;
