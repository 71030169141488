import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let getControlValue = function(control) {
    if (control.type === "checkbox") {
        return control.checked;
    } else {
        return control.value;
    }
};


let getHandlerForFormFieldBlur = function(storeKey, formKey, fieldKey, isSensitiveInformation) {
    return function(event) {

        let actionToDispatch = {
            type: ActionTypes.FORM_FIELD_BLUR,
            storeKey: storeKey,
            formKey: formKey,
            fieldKey: fieldKey,
            isSensitiveInformation: isSensitiveInformation
        };

        actionToDispatch.newValue = getControlValue(event.target);
        actionToDispatch.additionalInfo = event.target.additionalInfo;

        dispatcher.dispatch(actionToDispatch);
    };
};

export default getHandlerForFormFieldBlur;
