import FormStore from '../../base/FormStore.js';
import ActionTypes from '../../../actions/ActionTypes';
import constants from '../../../Constants.js';
import redirectToRoute from '../../../actions/actionCreators/navigation/RedirectToRoute.js';
import createPersonAccount from '../../../actions/actionCreators/registration/CreatePersonAccount.js';
import resendAccountActivationLink from '../../../actions/actionCreators/users/ResendAccountActivationLink.js';
import prepareUrl from '../../../helpers/PrepareUrl.js';
import storeFormManager from '../../validation/StoreFormManager.js';
import {
    whiteSpaceRestrictor, getBlacklistedEmailValidator, emailValidator,
    getMatchValidator, getMaxLengthValidator, passwordValidator,
    requiredValidator, getMinLengthValidator, getPersonalNumberValidator,
    getPersonalNumberMask
} from '@vaultit/stv-utils-frontend';

const storeKey = "registrationStep1Flow2";

class Step1StoreForFlow2 extends FormStore {

    getInitialState() {
        let initialState = {
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            neededClassifiersLoadingInProgress: false,
            passwordValidationMinimumDigits: constants.passwordValidationMinimumDigits,
            uiConfiguration: null,
            currentRegistrationFlowSettings: null,
            showNINAlreadyVerifiedMessage: false,
            showUserLoggedInThroughBankIdButDidNotRegisteredMessage: false,
            showUserLoggedInThroughDokobitButDidNotRegisteredMessage: false,
            showUserCompletedManualIdButDidNotRegisteredMessage: false,
            userLoggedInThroughDokobitButDidNotRegistered: false,
            continueButtonIsDisabled: false,
            emailFieldIsDisabled: false,
            resendAccountActivationLinkEmail: null,
            showResendAccountActivationLinkNoManualIdVerificationMsg: false,
            showAccountActivationLinkSuccessfullyResentMsg: false
        };

        let registrationStep1Flow2FormFieldDefinitions = {
            firstName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            country: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            personalNumber: {
                validateOnAnyFieldChange: true,
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getPersonalNumberValidator('country'),
                    getMaxLengthValidator(),
                    getMinLengthValidator(4)
                ],
                masks: [
                    getPersonalNumberMask('country')
                ]
            },
            email: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            },
            emailAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'email',
                        validator: getMatchValidator('email')
                    }
                ]
            },
            phoneNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
            acceptTOS: {
                defaultValue: false,
                validators: [
                    requiredValidator
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, "registrationStep1Flow2Form", registrationStep1Flow2FormFieldDefinitions, this.onStep1FormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/registration/continue',
                loadHandler: this.onContinueRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CONTINUE_PERSON_REGISTRATION'
            },
            {
                fullPath: '/registration/createaccount(/:emailToPrefill)',
                loadHandler: this.onStep1RouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_BEGIN_PERSON_REGISTRATION_FLOW_2'
            },
            {
                fullPath: '/registration/createaccount(/:emailToPrefill)(/:firstNameToPrefill)(/:lastNameToPrefill)(/:phoneNumberToPrefill)',
                loadHandler: this.onStep1RouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_BEGIN_PERSON_REGISTRATION_FLOW_2'
            },
            {
                fullPath: '/registration/addorg/:country/:code/:name',
                loadHandler: this.onAddOrgRouteLoaded,
                loadOnlyIfHasPermission: 'SEPPO_ACTION_COMPANY_REGISTRATION_STEP1'
            }
        ];
    }

    onContinueRouteLoaded() {
        redirectToRoute("/");
    }

    onStep1RouteLoaded(state, action, sharedStoreState) {
        this.resetVisibleMessages(state);
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;

        let currentRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.registrationFlow;
        state.currentRegistrationFlowSettings = state.uiConfiguration.servicePortal.registrationSettings[currentRegistrationFlow];

        state.isSignedIn = sharedStoreState.isSignedIn;

        this.blacklistedEmailAddresses = {
            blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
            emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
        };

        state.formDefinitions.registrationStep1Flow2Form.fieldDefinitions.country.defaultValue = state.currentRegistrationFlowSettings.defaultNINCountry;
        storeFormManager.setFormInitialState(state, "registrationStep1Flow2Form");

        let routeParams = sharedStoreState.currentRoute.params;

        if (routeParams && routeParams.emailToPrefill) {
            let emailToPrefill = routeParams.emailToPrefill && !emailValidator(routeParams.emailToPrefill) ? routeParams.emailToPrefill : '';

            state.emailFieldIsDisabled = emailToPrefill && !(routeParams.firstNameToPrefill && routeParams.lastNameToPrefill && routeParams.phoneNumberToPrefill) ? true : false;

            state.registrationStep1Flow2Form.fields.email.value = emailToPrefill;
            state.registrationStep1Flow2Form.fields.emailAgain.value = emailToPrefill;
            state.registrationStep1Flow2Form.fields.firstName.value = routeParams.firstNameToPrefill ? routeParams.firstNameToPrefill : '';
            state.registrationStep1Flow2Form.fields.lastName.value = routeParams.lastNameToPrefill ? routeParams.lastNameToPrefill : '';
            state.registrationStep1Flow2Form.fields.phoneNumber.value = routeParams.phoneNumberToPrefill ? routeParams.phoneNumberToPrefill.toString() : '';
        }

        state.initialPhoneNumberCountry = state.uiConfiguration ? state.uiConfiguration.defaultCountry : null;

        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
    }

    onStep1FormSubmitted(state, values, sharedStoreState, correctThis) {
        correctThis.resetVisibleMessages(state);
        values.userLanguageCode = sharedStoreState.localizations.messagesLanguage;

        if (state.registrationStep1Flow2Form.fields.phoneNumber && state.registrationStep1Flow2Form.fields.phoneNumber.isVisible && state.registrationStep1Flow2Form.fields.phoneNumber.additionalInfo) {
            values.phoneNumber = state.registrationStep1Flow2Form.fields.phoneNumber.additionalInfo.fullNumber;
        }

        state.showLoadingIndicator = true;
        createPersonAccount(values);
    }

    onAnyAction(state, action, sharedStoreState) {

        let tosType = "person";
        let currentLang = sharedStoreState.localizations.messagesLanguage;

        let needToRecalculateTermsOfServicePdfUrl = !state.termsOfServicePdfUrlCachedForLanguage
            || state.termsOfServicePdfUrlCachedForLanguage !== currentLang || sharedStoreState.authInfo;

        if (needToRecalculateTermsOfServicePdfUrl) {
            state.termsOfServicePdfUrlCachedForLanguage = currentLang;
            state.termsOfServicePdfUrl = "/binary/" + sharedStoreState.tosPrefix + "_" + tosType + "_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";
        }

        if (action.type == ActionTypes.FORM_FIELD_VALUE_CHANGED && action.storeKey == storeKey) {
            state.continueButtonIsDisabled = false;
        }
    }

    [ActionTypes.REGISTRATION_STEP1_FLOW2_SUBMIT_SUCCESS](state, action, sharedStoreState) {
        state.showLoadingIndicator = false;

        if (action.result && action.result.ninIsAlreadyVerified) {
            let returnUrl = prepareUrl.prepareRouteUrl('/');
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            let signInUrlWithReturnUrl = prepareUrl.getSignInRelativeUrl(returnUrlEncoded);
            state.signInToYourAccount = prepareUrl.prepareAbsoluteUrl(signInUrlWithReturnUrl);

            state.showNINAlreadyVerifiedMessage = true;
        } else if (action.result && action.result.userCompletedManualIdButDidNotRegistered) {
            state.showUserCompletedManualIdButDidNotRegisteredMessage = true;
        } else if (action.result && action.result.userAlreadyExists) {
            let emailToSignIn = state.registrationStep1Flow2Form.fields.email.value;
            let returnUrl = prepareUrl.prepareRouteUrl('/');
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            let signInUrlWithReturnUrl = prepareUrl.getSignInRelativeUrl(returnUrlEncoded, emailToSignIn);
            state.signInToYourAccount = prepareUrl.prepareAbsoluteUrl(signInUrlWithReturnUrl);

            state.showAlreadyExistingUserMessage = true;
            state.continueButtonIsDisabled = true;
        } else if (action.result && (action.result.userLoggedInThroughBankIdButDidNotRegistered || action.result.userLoggedInThroughDokobitButDidNotRegistered)) {
            let email = state.registrationStep1Flow2Form.fields.email.value;
            let phoneNumber = state.registrationStep1Flow2Form.fields.phoneNumber.value;
            let returnUrl = prepareUrl.prepareRouteUrl(`/profile/create-full-account/${email}/${phoneNumber}`);
            returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
            let returnUrlEncoded = encodeURIComponent(returnUrl);
            const acrValue = action.result.userLoggedInThroughDokobitButDidNotRegistered ? 'dokobit_identitygw_provision' : 'bankid_provision';
            state.signInToYourAccount = prepareUrl.getStrongAuthForAnonymousPath(state.uiConfiguration, returnUrlEncoded, false, acrValue);

            state.showUserLoggedInThroughBankIdButDidNotRegisteredMessage = action.result.userLoggedInThroughBankIdButDidNotRegistered;
            state.showUserLoggedInThroughDokobitButDidNotRegisteredMessage = action.result.userLoggedInThroughDokobitButDidNotRegistered;
        } else {
            sharedStoreState.emailToVerify = state.registrationStep1Flow2Form.fields.email.value;

            // Set data to session if user wants to return to edit data
            sessionStorage.setItem('registrationStep1EmailToPrefill', state.registrationStep1Flow2Form.fields.email.value);
            sessionStorage.setItem('registrationStep1FirstNameToPrefill', state.registrationStep1Flow2Form.fields.firstName.value);
            sessionStorage.setItem('registrationStep1LastNameToPrefill', state.registrationStep1Flow2Form.fields.lastName.value);
            sessionStorage.setItem('registrationStep1PhoneNumberToPrefill', state.registrationStep1Flow2Form.fields.phoneNumber.value);
            redirectToRoute("/profile/emailverification/verificationneededafterstep1");
        }
    }

    [ActionTypes.REGISTRATION_STEP1_FLOW2_SUBMIT_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REGISTRATION_STEP1_RESEND_ACCOUNT_ACTIVATION_LINK_FOR_MANUAL_ID_USER](state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        resendAccountActivationLink(
            state.registrationStep1Flow2Form.fields.email,
            null,
            sharedStoreState.localizations.messagesLanguage
        );
    }

    [ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_SUCCESS](state, action) {
        this.resetVisibleMessages(state);
        state.showLoadingIndicator = false;
        state.resendAccountActivationLinkEmail = action.result.email;

        if (action.result.noManualIdVerificationFound) {
            state.showResendAccountActivationLinkNoManualIdVerificationMsg = true;
        } else {
            state.showAccountActivationLinkSuccessfullyResentMsg = true;
        }
    }

    [ActionTypes.RESEND_ACCOUNT_ACTIVATION_LINK_ERROR](state) {
        state.showLoadingIndicator = false;
    }

    onAddOrgRouteLoaded(state, action, sharedStoreState) {

        let p = sharedStoreState.currentRoute.params;

        let country = sharedStoreState.classifiers.countries.find((c) => c.code === p.country);

        let findCompanyFormValues = {
            'countryCode': p.country,
            'countryName': country.name,
            'name': p.name,
            'code': p.code,
            'codeFIN': p.code,
            'codeEST': p.code,
            'codeSWE': p.code,
            'codePL': p.code,
            'codeLV': p.code,
            'codeLT': p.code
        };

        sessionStorage.setItem('registrationFindCompanyValues', JSON.stringify(findCompanyFormValues));

        redirectToRoute("/registration/step1");
    }

    resetVisibleMessages(state) {
        state.showAlreadyExistingUserMessage = false;
        state.showNINAlreadyVerifiedMessage = false;
        state.showUserLoggedInThroughBankIdButDidNotRegisteredMessage = false;
        state.showUserLoggedInThroughDokobitButDidNotRegisteredMessage = false;
        state.showUserCompletedManualIdButDidNotRegisteredMessage = false;
        state.resendAccountActivationLinkEmail = null;
        state.showResendAccountActivationLinkNoManualIdVerificationMsg = false;
        state.showAccountActivationLinkSuccessfullyResentMsg = false;
    }
}

export default Step1StoreForFlow2;
