import ActionTypes from '../../../ActionTypes.js';
import {getFromBackend} from '../../generic/CallBackend.js';

const getEmailFromToken = function (token) {
    getFromBackend(`/registration/transfer/get-email-from-token/${token}`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_EMAIL_FROM_TOKEN_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_EMAIL_FROM_TOKEN_ERROR);
};

export default getEmailFromToken;
