import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let createFullUserAccountCanceled = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CREATE_FULL_USER_ACCOUNT_CANCELED_SUCCESS
    });
};

export default createFullUserAccountCanceled;
