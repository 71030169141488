import BaseStore from '../base/BaseStore.js';
import PostToHubSpot from '../../actions/actionCreators/generic/PostToHubSpot.js';
import constants from '../../Constants.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import getDashboardInfo from '../../actions/actionCreators/menu/GetDashboardInfo.js';
import ActionTypes from '../../actions/ActionTypes';

class ServicesBasicInfoStore extends BaseStore {

    getInitialState() {
        return {
            canGoToBol: false,
            canStartCreateCSAccount: false,
            constants: {
                reliablePartner: constants.reliablePartner
            }
        };
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/dashboard',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_DASHBOARD',
                loadHandler: this.onServicesDashboardRouteLoaded
            }
        ];
    }

    onServicesDashboardRouteLoaded(state, action, sharedStoreState) {
        let cfg = sharedStoreState.authInfo.topMenuParameters.configuration;
        let url = cfg && cfg.servicePortal && cfg.servicePortal.dashboard ? cfg.servicePortal.dashboard.redirectToUrlInsteadOfDashboard : null;

        if (url) {
            url = url.replace('[ORG_ID]', sharedStoreState.representedOrganizationCode);
            redirectToUrl(url, true, true);
        } else {
            state.showInfoMsg = true;

            if (cfg.enableHubSpot) {
                PostToHubSpot({
                    orgId: sharedStoreState.representedOrganizationCode
                });
            }
        }
        state.showLoadingIndicator = true;
        state.canGoToBol = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_ALLOW_GO_TO_BOL');
        state.canStartCreateCSAccount = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_ALLOW_CREATE_CREDITSAFE_ACCOUNT');
        getDashboardInfo(sharedStoreState.representedOrganizationCode);
    }

    [ActionTypes.DASHBOARD_INFO_GET_SUCCESS](state, action) {
        state.showLoadingIndicator = false;
        state.servicesInfoForDashboard = action.result.servicesInfoForDashboard;
    }

    [ActionTypes.DASHBOARD_INFO_GET_ERROR](state) {
        state.showLoadingIndicator = false;
    }
}

export default ServicesBasicInfoStore;
