import React from 'react';
import { intlShape, defineMessages } from 'react-intl';

import icon from '../../../img/icon-sorvi.png';

const messages = defineMessages({
    serviceRedirectTitle: {
        id: 'serviceRedirect.explanation.title',
        description: 'Explanation page message title',
        defaultMessage: 'We are building new'
    },
    serviceRedirectMainMessage:{
        id: 'serviceRedirect.explanation.mainMessage',
        description: 'Explanation page main message',
        defaultMessage: 'After the first step, one user can get user permissions for several \
                         organisations. For now, however, the access of Raportti and Valvoja \
                         services is still restricted to the first company, the user has registered to.'
    },
    serviceRedirectConclusion:{
        id: 'serviceRedirect.explanation.conclusion',
        description: 'Explanation page message conclusion',
        defaultMessage: 'We inform you about the new releases.'
    }
});

class ServiceExplanationPage extends React.Component {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        return (
            <div id="service-explanation-content" className="alert alert-default mt-6 d-flex flex-md-row flex-column p-6">
                <div className="p-4">
                    <p className="h2 mb-5">{formatMessage(messages.serviceRedirectTitle)}</p>
                    <p className="h3 mb-5">{formatMessage(messages.serviceRedirectMainMessage)}</p>
                    <p className="h3">{formatMessage(messages.serviceRedirectConclusion)}</p>
                </div>
                <div>
                    <img src={icon} className="img-fluid" />
                </div>
            </div>
        );
    }
}

export default ServiceExplanationPage;
