import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes.js';
import deleteJobResult from '../../actions/actionCreators/users/DeleteJobResult';
import downloadJobResult from '../../actions/actionCreators/users/DownloadJobResult';
import generateCsvReport from '../../actions/actionCreators/users/GenerateCsvReport.js';
import getCsvReportJobs from '../../actions/actionCreators/users/GetCsvReportJobs.js';
import getUnfinishedCsvReportJobs from '../../actions/actionCreators/users/GetUnfinishedCsvReportJobs.js';
import storeFormManager from '../validation/StoreFormManager';
import { fileHelpers, requiredValidator } from '@vaultit/stv-utils-frontend';
const csvReportForm = 'csvReportForm';
const storeKey = 'csvReportStore';
import prepareUrl from '../../helpers/PrepareUrl.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import constants from '../../Constants.js';

const LOOP_INTERVAL = 15000;


class CsvReportStore extends FormStore {
     static get formKey() {
        return [csvReportForm];
    }

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            showCsvReportSuccessMsg: false,
            showForm: false,
            submitted: false,
            jobList: [],
            unfinishedJobs: [],
            permission: "",
            permissions: [],
            fullPathForPagination: "",
            uiConfiguration: {},
            confirmationModalJobId: null,
            showConfirmationModal: false,
            constants: {
                permissions: constants.permissions,
            },

        };
        let fieldDefinitionsCsvReportForm = {
            permission: {
                defaultValue: "",
                validators: [requiredValidator]
            },
        };
        storeFormManager.defineForm(initialState, storeKey,
            csvReportForm, fieldDefinitionsCsvReportForm, this.onCsvReportSubmitted);
        return initialState;
    }

    onCsvReportSubmitted(state, action) {
        generateCsvReport(action.permission)
        state.showLoadingIndicator = true;
        state.submitted = true;
    }

    onChangePermission (state, action, sharedStoreState) {
        state.showCsvReportSuccessMsg = false;
        this.clearValidationErrors(state);
        sharedStoreState.errorMessage.show = false;
        state.permission = action.permission
    }


    getCsvJobs(page=1) {
        if ( page < 1 ) {
            this.redirectToMain();
        } else {
            getCsvReportJobs(page);
        }
    }

    getFieldValueChangedHandlerCfg() {
        return [
            {
                storeKey: storeKey,
                formKey: csvReportForm,
                handler: this.onChangePermission
            }
        ];
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/csv-reports',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_CSV_REPORTS',
                loadHandler: this.onCsvReportLoaded
            },
            {
                fullPath: '/csv-reports/:pageNumber',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_VIEW_CSV_REPORTS',
                loadHandler: this.onCsvReportLoaded
            }
        ];
    }

    redirectToMain() {
        redirectToRoute('/csv-reports', true, false);
    }

    getJobs(page=1) {
        if ( page < 1 ) {
            this.redirectToMain();
        } else {
            getCsvReportJobs(page);
        }
    }

    onCsvReportLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        storeFormManager.setFormInitialState(state, csvReportForm);
        state.currentCsvReportListPage = this.getCurrentPage(sharedStoreState);
        let routeUrl = sharedStoreState.currentRoute.fullPathWithOrgCode;
        state.fullPathForPagination = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, routeUrl);
        this.clearValidationErrors(state);
        state.showCsvReportSuccessMsg = false;
        this.getJobs(state.currentCsvReportListPage);
        state.showForm = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_GENERATE_CSV_REPORT');
        state.uiConfiguration = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters ? sharedStoreState.authInfo.topMenuParameters.configuration : {};
     }

    getCurrentPage(sharedStoreState) {
        let currentCsvReportListPage = parseInt(sharedStoreState.currentRoute.params.pageNumber || 1);
        return isNaN(currentCsvReportListPage) ? 1 : currentCsvReportListPage;
    }

    clearValidationErrors(state) {
         state.showCsvReportValidationErrorMsg = false;
    }

    [ActionTypes.CSV_REPORT_SUCCESS] (state, action, sharedStoreState) {
        sharedStoreState.errorMessage.show = false;
        storeFormManager.setFormInitialState(state, csvReportForm);
        state.showCsvReportSuccessMsg = true;
        state.showLoadingIndicator = false;
        state.submitted = false;
        state.permission = "";
        let firstPage = 1;
        let currentPage = this.getCurrentPage(sharedStoreState);
        if (currentPage !== firstPage) {
            this.redirectToMain();
        } else {
            this.getJobs();
        }
    }

    [ActionTypes.CSV_REPORT_ERROR] (state) {
        storeFormManager.setFormInitialState(state, csvReportForm);
        state.showLoadingIndicator = false;
        state.submitted = false;
        state.permission = "";
        this.getJobs();
    }

    [ActionTypes.CSV_REPORT_GET_UNFINISHED_JOBS_SUCCESS] (state, action) {
         const unfinishedJobList = action.result.jobs;
         unfinishedJobList.forEach((u) => {
             state.jobList.forEach((j, i) => {
                 if (u.id === j.id) {
                     state.jobList[i] = u;
                 }
             });
         });
        state.unfinishedJobs = state.jobList.filter((job) => (job.status === "pending" || job.status === "in_progress"));
        if (state.unfinishedJobs.length > 0) {
            const ids = state.unfinishedJobs.map((j) => j.id);
            setTimeout(getUnfinishedCsvReportJobs, LOOP_INTERVAL, ids);
        }
    }

    [ActionTypes.CSV_REPORT_GET_UNFINISHED_JOBS_ERROR] (state) {
        // Error occurred, check the list again for the unfinished jobs and try again
        state.unfinishedJobs = state.jobList.filter((job) => (job.status === "pending" || job.status === "in_progress"));
        if (state.unfinishedJobs.length > 0) {
            const ids = state.unfinishedJobs.map((j) => j.id);
            setTimeout(getUnfinishedCsvReportJobs, LOOP_INTERVAL, ids);
        }
    }

    [ActionTypes.CSV_REPORT_GET_JOBS_SUCCESS] (state, action) {
        state.jobList = action.result.jobs;
        state.jobListTotal = action.result.pagination.totalItems;
        state.jobListItemsPerPage = action.result.pagination.itemsPerPage;
        state.showLoadingIndicator = false;
        state.permissions = action.result.permissions;
        state.unfinishedJobs = state.jobList.filter((job) => (job.status === "pending" || job.status === "in_progress"));
        if (state.unfinishedJobs.length > 0) {
            const ids = state.unfinishedJobs.map((j) => j.id);
            setTimeout(getUnfinishedCsvReportJobs, LOOP_INTERVAL, ids);
        }
    }

    [ActionTypes.CSV_REPORT_GET_JOBS_ERROR] (state) {
        state.showLoadingIndicator = false;
    }
    [ActionTypes.CSV_REPORT_DOWNLOAD_JOB_RESULT_BTN_CLICKED] (state, action) {
        state.showLoadingIndicator = true;
        downloadJobResult(action.jobId);
    }

    [ActionTypes.CSV_REPORT_DELETE_JOB_RESULT_BTN_CLICKED] (state, action) {
        state.showConfirmationModal = true;
        state.confirmationModalJobId = action.jobId;
    }
    [ActionTypes.CSV_REPORT_DELETE_JOB_CONFIRMED_BTN_CLICKED] (state) {
        deleteJobResult(state.confirmationModalJobId);
        state.showConfirmationModal = false;
        state.showLoadingIndicator = true;
    }

    [ActionTypes.CSV_REPORT_DELETE_JOB_RESULT_SUCCESS] (state) {
        const jobList = state.jobList.filter((j) => j.id != state.confirmationModalJobId);
        state.jobList = jobList;
        state.confirmationModalJobId = null;
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CSV_REPORT_DELETE_JOB_RESULT_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.CSV_REPORT_DOWNLOAD_JOB_RESULT_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        if (action.result && action.resultForDownload) {
            fileHelpers.downloadFile(action.result.data, action.result.fileName);
        }
    }

    [ActionTypes.CSV_REPORT_DOWNLOAD_JOB_RESULT_ERROR] (state) {
        state.showLoadingIndicator = false;
        state.confirmationModalJobId = null;
    }

}

export default CsvReportStore;
