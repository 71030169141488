import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

export default function (country, personalNumber) {
    postToBackend('/profile/update-strong-auth-data',
        ActionTypes.PROFILE_UPDATE_STRONG_AUTH_INFO_SUCCESS,
        ActionTypes.PROFILE_UPDATE_STRONG_AUTH_INFO_ERROR,
        {
            country: country,
            personalNumber: personalNumber
        });
};
