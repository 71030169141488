import ActionTypes from '../../ActionTypes.js';
import {getFileFromBackend} from '../generic/CallBackend.js';

let downloadJobResult = function(jobId) {
    getFileFromBackend('users/csv-reports/' + jobId + '/result/download',
        ActionTypes.CSV_REPORT_DOWNLOAD_JOB_RESULT_SUCCESS,
        ActionTypes.CSV_REPORT_DOWNLOAD_JOB_RESULT_ERROR
    );
};

export default downloadJobResult;
