import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let updateUserInfo = function(userId, data) {
    postToBackend(`/users/get-user-global-info/update/${userId}`,
        ActionTypes.UPDATE_USER_INFO_SUCCESS,
        ActionTypes.UPDATE_USER_INFO_ERROR,
        data);
};

export default updateUserInfo;