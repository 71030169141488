import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getPermissionsManagedByCurrentUser = function(orgId) {

    getFromBackend('/users/' + orgId + '/get-permissions-managed-by-current-user',
        ActionTypes.GET_PERMISSIONS_MANAGED_BY_CURRENT_USER_SUCCESS,
        ActionTypes.GET_PERMISSIONS_MANAGED_BY_CURRENT_USER_ERROR);
};

export default getPermissionsManagedByCurrentUser;
