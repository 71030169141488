import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { intlShape, FormattedMessage } from 'react-intl';
import { FormRow, PageHeader, FormGroup, TextInput, ValidationMessages, Message, InputLabel, FormColumn } from '@vaultit/stv-utils-frontend';
import constants from '../../../Constants.js';
import constructTranslations from '../../../helpers/ConstructTranslations.js';

class LandingPageProveYourIdentity extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {

        let store = this.state.landingPageProveYourIdentityStore;

        let form = store.identityProvingWithNINForm;
        let fields = form ? form.fields: null;

        let translationValues = {
            'username': (<strong>{" (" +store.gluuUsername + ")"}</strong>)
        }

        let infoMsgId = store.cardHolderRegistration ? 'card_holder_registration_after_id_verification_info_message' : 'signatory_registration_after_id_verification_info_message';

        let caseWhenPersonIsWithoutNin = store.identityType !== constants.idVerificationType.socialSecurityNumber;
        let ninInputMsgId = constructTranslations.getTranslationKeyManualIdPages(store.identityType, "registration.afterManualIdVerification.enter")
        if (store.lmaNumberWithVersion) {
            ninInputMsgId += "withVersion";
        }
        let incorrectValue = constructTranslations.getTranslationKeyManualIdPages(store.identityType, 'registration.afterManualIdVerification.incorrect');
        let infoMsgPart2 = constructTranslations.getTranslationKeyManualIdPages(store.identityType, 'registration.afterManualIdVerification.infoMsgPrt2');
        let infoMsgPart1 = caseWhenPersonIsWithoutNin ? constructTranslations.getTranslationKeyManualIdPages(store.identityType, 'registration.afterManualIdVerification.infoMsgPrt1') : "";

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="registration.afterManualIdVerification.beginTitle"
                    sharedStore={this.state.shared}
                    hideSeparator={false}
                    description={false}
                />

                {store.initialPersonSituationChecked && form && !store.alreadyExistingGluuUser
                ?
                    <div>


                        <Message type="info" id={infoMsgId} className="text-center mt-6 mb-6">

                            { caseWhenPersonIsWithoutNin
                                ?
                                    <span>
                                        <FormattedMessage
                                            id={infoMsgPart1}
                                            defaultMessage="To continue with registration you need to enter your identity document number."
                                        />
                                        <br />
                                        <FormattedMessage
                                            id={infoMsgPart2}
                                            defaultMessage="Please enter your document number that you provided to Manual ID verification partner."
                                        />
                                    </span>
                                :
                                <span>
                                    {store.cardHolderRegistration
                                        ?
                                            <FormattedMessage
                                                id="registration.afterManualIdVerification.infoMsgPrt1"
                                                defaultMessage="To see card order(s) and continue with registration you need to enter your national identification number"
                                            />
                                        :
                                        <FormattedMessage
                                            id="registration.afterManualIdVerification.infoMsgPrt1ForSignatory"
                                            defaultMessage="To continue with registration you need to enter your national identification number"
                                        />
                                    }.
                                    <br />
                                    <FormattedMessage
                                        id={infoMsgPart2}
                                        defaultMessage="Please enter national identification number from document that you provided to Manual ID verification partner"
                                    />.
                                </span>
                            }

                        </Message>

                        {store.ninVerificationFailed
                        ?
                            <Message type="danger" id="registration_after_id_verification_nin_error_message" className="text-center mb-6">
                                <FormattedMessage
                                    id={incorrectValue}
                                    defaultMessage="Provided national identification number does not match with the number on Manual ID verification. Please try again."
                                />
                            </Message>
                        : null
                        }
                        <form name="identityProvingWithNINForm">
                            {fields
                            ?
                                <FormRow>
                                    <FormColumn width="6">
                                        <FormGroup>
                                            <ValidationMessages field={fields.ninValue}>
                                                <InputLabel
                                                    required={true}
                                                    htmlFor="registration_after_id_verification_nin_field"
                                                    messageId={ninInputMsgId}
                                                    defaultMessage="National identification number"
                                                />
                                                <TextInput
                                                    id="registration_after_id_verification_nin_field"
                                                    field={fields.ninValue}
                                                />
                                            </ValidationMessages>
                                        </FormGroup>
                                    </FormColumn>

                                    <FormColumn width="6">
                                        <FormGroup className="mt-5">
                                            <button
                                                className="btn btn-primary btn-lg"
                                                role="button"
                                                onClick={form.handleOnSubmitBtnClicked}
                                            >
                                                <FormattedMessage
                                                    id="registration.afterManualIdVerification.verifyNINBtn"
                                                    defaultMessage="Verify national identification number"
                                                />
                                            </button>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>
                            : null
                            }
                        </form>
                    </div>
                : null
                }

                {store.initialPersonSituationChecked && store.alreadyExistingGluuUser
                ?
                    <div>
                        <Message type="warning" id="registration_after_id_verification_warning_user_already_exists" className="text-center mt-6 mb-6">
                            {store.cardHoldersRegistration
                            ?
                                <FormattedMessage
                                    id="registration.afterManualIdVerification.userAlreadyExistsCards"
                                    defaultMessage="It looks like you already have a user account {username}. To review your cards please"
                                    values={translationValues}
                                />
                            :
                            <FormattedMessage
                                id="registration.afterManualIdVerification.userAlreadyExists"
                                defaultMessage="It looks like you already have a user account {username}. Please"
                                values={translationValues}
                            />
                            }
                            &nbsp;
                            <a
                                href={store.signInUrl}
                                id="registration_after_id_verification_sign_in"
                            >
                                <FormattedMessage
                                    id="registration.afterManualIdVerification.signIn"
                                    defaultMessage="sign in"
                                />
                            </a>
                            .
                        </Message>
                    </div>
                : null
                }
            </div>
        );
    }

}

export default LandingPageProveYourIdentity;
