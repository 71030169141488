import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let resendVerificationEmail = function () {
    postToBackend('/profile/send-verify-email',
        ActionTypes.PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_SUCCESS,
        ActionTypes.PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_ERROR);
};

export default resendVerificationEmail;
