import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let openIdentityDocumentImageModalBtnClicked = function(event, indexValue, isPermitValue) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.OPEN_IDENTITY_DOCUMENT_IMAGE_MODAL_BTN_CLICKED,
        index: indexValue,
        isPermit: isPermitValue
    });
};

export default openIdentityDocumentImageModalBtnClicked;
