import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { FormRow, FormColumn, FormGroup, ValidationMessages, FormValidationMessage,
    Message, PageHeader, InputLabel, SelectInput, CheckboxInput,
    PhoneNumberInputComponent, TextInput, prepareUrl } from '@vaultit/stv-utils-frontend';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';

const messages = defineMessages({
    continue: {
        id: 'profile.activateAccount.submitBtn',
        description: 'Creating user form submit btn',
        defaultMessage: 'Proceed'
    },

    optionFinland: {
        id: 'registration.step1.flow2.country.finland',
        description: 'Finland option',
        defaultMessage: 'Finland'
    },

    optionOtherCountry: {
        id: 'registration.step1.flow2.country.otherCountry',
        description: 'Other country option',
        defaultMessage: 'Other country'
    },

    countrySelectionInfo: {
        id: 'registration.step1.flow2.country.explanation',
        description: 'Country selection explanation',
        defaultMessage: 'Country of your National Person ID number'
    },

    nationalIdentityNumberInfo: {
        id: 'registration.step1.flow2.nationalIdentityNumber.explanation',
        description: 'National Person ID number input field explanation',
        defaultMessage: 'Required to verify your identity'
    },

    optionSweden: {
        id: 'registration.step1.flow2.country.sweden',
        description: 'Sweden option',
        defaultMessage: 'Sweden'
    },

    nationalIdentityNumberEgGeneral: {
        id: 'registration.step1.flow2.nationalIdentityNumberEgGeneral',
        description: 'National Person ID number format example',
        defaultMessage: 'E.g. {exampleFormat}'
    }
});


class activateAccount extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    };

    renderWhenReady() {

        const { formatMessage } = this.context.intl;

        let store = this.state.activateAccount;

        let form = store.activateAccountForm;
        let fields = form ? form.fields : null;

        let registrationsSettings = store.currentRegistrationFlowSettings;
        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration.style;
        let currentLanguage = this.state.shared.localizations.messagesLanguage;
        let fullConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;

        let currentServiceProvider = fullConfiguration ? fullConfiguration.serviceNameToDisplay[currentLanguage] : '';

        let personCodeCountryOptions = [];

        for (let key in this.state.shared.classifiers.countries) {
            let item = this.state.shared.classifiers.countries[key];
            if (item.code) {
                if (registrationsSettings.availableNationalPersonIdNumberCountries.indexOf(item.code) > -1) {
                    personCodeCountryOptions.push({
                        value: item.code,
                        displayedText: item.name
                    });
                }
            }
        }

        let personalNumberFieldTexts = {"id":"registration_create_user_national_identity_number",
                                        "messageId":"registration.step1.flow2.nationalIdentityNumberTitle",
                                        "defaultMessage":"National Person ID number",
                                        "placeholder": fields ? formatMessage(messages.nationalIdentityNumberEgGeneral, {exampleFormat: fields.ssnFormats.value}) : null};

        let pwdMessageValues = {
            numberOfDigits: store.passwordValidationMinimumDigits
        };

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.activateAccount.title"
                    sharedStore={this.state.shared}
                    hideSeparator={true}
                />

                { store.visibleElements.activateAccountSuccess || store.visibleElements.alreadyActivated
                    ? <div>
                        <Message id="SuccessMessage" type="success">
                            { store.visibleElements.activateAccountSuccess
                                ? <span>
                                    <FormattedMessage id="profile.activateAccount.success.part1" defaultMessage="Thank you," />
                                    <span>&nbsp;{ fields.firstName.value }!</span>
                                    <span>&nbsp;</span>
                                    <FormattedMessage id="profile.activateAccount.success.part2" defaultMessage="Your user account is now ready." />
                                </span>
                                : null
                            }
                            { store.visibleElements.alreadyActivated
                                ? <FormattedMessage id="profile.activateAccount.alreadyActivated" defaultMessage="Your account has already been created before." />
                                : null
                            }
                        </Message>

                        <FormGroup className="mt-6">
                            <span className="text-center">
                                <a
                                    id="registration_create_user_success_to_the_service"
                                    className="btn btn-primary btn-lg"
                                    href={store.signInUrl}
                                >
                                    <FormattedMessage id="profile.activateAccount.toTheServiceBtn" defaultMessage="To the service" />
                                </a>
                            </span>
                        </FormGroup>
                    </div>
                    : null
                }

                { store.visibleElements.activateAccountForm
                    ? <div>
                        { store.showSignInWithOtherYourAccountLink
                            ?
                                <Message id="register_with_your_other_account" type="info">
                                    <FormattedMessage id="profile.activateAccount.signInWithYourOtherAccountPart1" defaultMessage="If you already have an existing account, sign in " />
                                    &nbsp;
                                    <a id="profile_activate_account_sign_in_existing_user_link" href={store.signInAndAddSecondaryEmail}>
                                        <FormattedMessage id="profile.activateAccount.signInWithYourOtherAccountHere" defaultMessage="here" />
                                    </a>
                                    <FormattedMessage id="profile.activateAccount.signInWithYourOtherAccountPart2" defaultMessage=" to add {currentEmail} to your email addresses" values={{currentEmail: store.currentEmail}} />.&nbsp;
                                </Message>
                            : null
                        }

                        {store.showMessageNINIsAlreadyVerified
                        ?
                            <Message id="profile_activate_account_error_nin_is_already_verified" type="warning">
                                <span>
                                    <FormattedMessage
                                        id="registration.step1.ninIsVerified"
                                        defaultMessage="This National Person ID number is already used for another {serviceProvider} account. Please check your National Person ID number and try again. "
                                        values={{serviceProvider: currentServiceProvider}}
                                    />
                                    <br />
                                    <FormattedMessage id="registration.step1.youCanSignIn" defaultMessage="If you already have an existing account you can " />
                                    <a id="profile_activate_account_sign_in_to_your_account" href={store.signInAndAddSecondaryEmail}>
                                        <FormattedMessage id="registration.step1.youCanSignIn.signInHere" defaultMessage="sign in here" />
                                    </a>.&nbsp;
                                    <FormattedMessage id="registration.step1.sendRemindPasswordEmail" defaultMessage="In case you forgot your password - you can " />
                                    <a id="profile_activate_account_forgot_your_password_link" href={store.forgotPasswordLink}>
                                        <FormattedMessage id="registration.step1.sendRemindPasswordEmail.resetItHere" defaultMessage="reset it here" />
                                    </a>.
                                </span>
                            </Message>
                        : null}

                        <form name="activateAccountForm" noValidate="">
                            <FormGroup>
                                <TextInput
                                    id="registration_create_user_email"
                                    field={fields.email}
                                    messageId="profile.activateAccount.email"
                                    defaultMessage="Your e-mail"
                                    disabled={true}
                                />
                            </FormGroup>
                            <FormRow>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.firstName}>
                                            <TextInput
                                                id="registration_create_user_first_name"
                                                field={fields.firstName}
                                                messageId="profile.activateAccount.firstName"
                                                defaultMessage="First name"
                                                required={true}
                                                autoFocus={true}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.lastName}>
                                            <TextInput
                                                id="registration_create_user_last_name"
                                                field={fields.lastName}
                                                messageId="profile.activateAccount.lastName"
                                                defaultMessage="Last name"
                                                required={true}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>

                            <FormRow>
                                <FormColumn width="12">
                                    <FormGroup>
                                        <ValidationMessages field={fields.phoneNumber} componentCustomValidation="errors.validation.field.componentCustomValidation.phoneNumber">
                                            <InputLabel
                                                required={true}
                                                htmlFor="registration_create_user_phone_number"
                                                messageId="profile.activateAccount.phoneNumber"
                                                defaultMessage="Phone number"
                                            />
                                            <PhoneNumberInputComponent
                                                id="registration_create_user_phone_number"
                                                className="form-control"
                                                value={fields.phoneNumber.value || ""}
                                                onChange={fields.phoneNumber.handleOnChange}
                                                initialCountry={this.state.activateAccount.countryCode}
                                                preferredCountries={['fi', 'ee', 'se']}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                            { store.registrationFlow2Enabled
                            ?
                                <FormRow>
                                    <FormColumn width="6">
                                        <FormGroup>
                                            <ValidationMessages field={fields.country}>
                                                <SelectInput
                                                    id="registration_create_user_country"
                                                    field={fields.country}
                                                    options={personCodeCountryOptions}
                                                    messageId="registration.step1.flow2.country"
                                                    defaultMessage="Country"
                                                    required={true}
                                                    disabled={false}
                                                />
                                            </ ValidationMessages>
                                            <div className="text-muted small mt-2">
                                                <em>
                                                    {formatMessage(messages.countrySelectionInfo) }
                                                </em>
                                            </div>
                                        </FormGroup>
                                    </FormColumn>
                                    <FormColumn width="6">
                                        <FormGroup>
                                            <ValidationMessages field={fields.personalNumber}>
                                                <TextInput
                                                    type={uiConfiguration.ninIsHidden ? 'password' : 'text'}
                                                    field={fields.personalNumber}
                                                    required={true}
                                                    {...personalNumberFieldTexts}
                                                />
                                            </ValidationMessages>
                                            <div className="text-muted small mt-2">
                                                <em>
                                                    {formatMessage(messages.nationalIdentityNumberInfo) }
                                                </em>
                                            </div>
                                        </FormGroup>
                                    </FormColumn>
                                </FormRow>
                                : null
                            }
                            <FormGroup>
                                <FormattedMessage
                                    id="profile.activateAccount.passwordMinLength"
                                    defaultMessage="Select a new password. The password should be at least {numberOfDigits} characters long."
                                    values={pwdMessageValues}
                                />
                            </FormGroup>
                            <FormRow>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.password}>
                                            <TextInput
                                                id="registration_create_user_password"
                                                type="password"
                                                field={fields.password}
                                                messageId="profile.activateAccount.password"
                                                defaultMessage="Your password"
                                                required={true}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.passwordAgain} match="errors.validation.field.match.passwords">
                                            <TextInput
                                                id="registration_create_user_password_again"
                                                type="password"
                                                field={fields.passwordAgain}
                                                messageId="profile.activateAccount.passwordAgain"
                                                defaultMessage="Password again"
                                                required={true}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                            <FormRow>
                                <FormColumn width="12">
                                    <FormGroup>
                                        <ValidationMessages field={fields.acceptTOS} required={registrationsSettings.displayPersonDataPolicyInsteadOfTOS ? "registration.step1.personalDataPolicy.required" :"registration.step1.TOS.required"}>
                                            <CheckboxInput
                                                id="registration_create_user_terms_of_service_cbx"
                                                checked={fields.acceptTOS.value}
                                                field={fields.acceptTOS}
                                                messageId="registration.step1.acceptServices"
                                                defaultMessage="I accept the XYZ service's"
                                            >
                                                <a id="registration_step1_terms_of_service_printable_version_link" onClick={ (e) => prepareUrl.openUrlInPopUpWindow(e, this.state.activateAccount.termsOfServicePdfUrl) } href="#">
                                                    {
                                                        registrationsSettings.displayPersonDataPolicyInsteadOfTOS
                                                            ? <FormattedMessage id="registration.step1.personalDataPolicyPart1" defaultMessage="ID06 General Terms and Conditions" />
                                                            : <FormattedMessage id="registration.step1.generalTOS" defaultMessage="general terms of service" />
                                                    }
                                                </a>
                                                {
                                                    registrationsSettings.displayPersonDataPolicyInsteadOfTOS
                                                        ? <FormattedMessage id="registration.step1.personalDataPolicyPart2" defaultMessage="and agree that my information is processed in accordance with the terms of this policy." />
                                                        : '.'
                                                }
                                            </CheckboxInput>
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                        </form>
                        {/*
                            Submit button is outside the form on purpose - form contains password field and we want to disable password manager here.
                            Default button behaviour is done manually through 'handleOnEnterKeyDownToSubmitForm' handlers.
                        */}
                        <FormValidationMessage form={form} />

                        <FormGroup className="mt-6">
                            <input
                                id="registration_create_user_submit"
                                type="submit"
                                className="btn btn-primary btn-lg"
                                value={formatMessage(messages.continue) || "" }
                                onClick={form.handleOnSubmitBtnClicked}
                                role="button"
                            />
                        </FormGroup>
                        { store.showSignInLinkForUser
                        ?
                            <FormGroup>
                                <a id="registration_create_user_link_to_sign_in" href={store.signInUrl}>
                                    <FormattedMessage id="registration.step1.flow2.signInToExistingAccount" defaultMessage="Sign in to existing account" />
                                </a>
                            </FormGroup>
                        : null
                        }
                    </div>
                    : null
                }
            </div>
        );
    }
}

export default activateAccount;
