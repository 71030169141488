import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let deleteJobConfirmedBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.CSV_REPORT_DELETE_JOB_CONFIRMED_BTN_CLICKED,
    });
}

export default deleteJobConfirmedBtnClicked;
