import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let checkTokenOfRequestToJoinOrg = function(orgId, values) {

    postToBackend(`/users/${orgId}/check-token-of-request-to-join-org`,
        ActionTypes.CHECK_REQUEST_TOKEN_SUCCESS,
        ActionTypes.CHECK_REQUEST_TOKEN_ERROR,
        values)

};

export default checkTokenOfRequestToJoinOrg;
