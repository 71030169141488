import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let openBlockHistoryModalBtnClicked = function() {
    dispatcher.dispatch({
        type: ActionTypes.OPEN_BLOCK_HISTORY_MODAL_BTN_CLICKED
    });
};

export default openBlockHistoryModalBtnClicked;
