import React from 'react';
import { intlShape, defineMessages } from 'react-intl';


const messages = defineMessages({
    serviceRedirectTitle: {
        id: 'noPermissionForService.title',
        description: 'Page message title',
        defaultMessage: 'You don’t have a permission to use this service'
    },
    serviceRedirectMainMessage:{
        id: 'noPermissionForService.mainMessage',
        description: 'Page main message',
        defaultMessage: 'Contact customer service to activate your company\'s account.'
    }
});

class NoPermissionForServicePage extends React.Component {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        return (
            <div id="service-explanation-content" className="alert alert-default mt-6 d-flex flex-md-row flex-column p-6">
                <div className="p-4">
                    <p className="h2 mb-5">{formatMessage(messages.serviceRedirectTitle)}</p>
                    <p className="h3 mb-5">{formatMessage(messages.serviceRedirectMainMessage)}</p>
                </div>
            </div>
        );
    }
}

export default NoPermissionForServicePage;
