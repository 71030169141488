import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let getCsvReportJobs = function(page=1) {
    postToBackend('users/csv-reports/jobs',
        ActionTypes.CSV_REPORT_GET_JOBS_SUCCESS,
        ActionTypes.CSV_REPORT_GET_JOBS_ERROR,
        {'page': page}
    );
};

export default getCsvReportJobs;
