import React from "react";
import StoreComponent from "../../base/StoreComponent.jsx";
import {FormattedMessage} from "react-intl";
import {Message} from "@vaultit/stv-utils-frontend";

const Redirect = ({to}) => {
    window.location.href = to;
    return null;
}

class CompleteTransfer extends StoreComponent {

    renderWhenReady() {
        const {error, loading, wrongPersonId, logoutUrl} = this.state.mergeIdentityVerificationStore;

        if (loading) {
            return null;
        } else {
            if (error) {
                return (<div style={{marginTop: "100px"}}>

                    {wrongPersonId && <Message id="transfer-complete-error" type="warning">
                        <FormattedMessage
                            id="registration.afterManualIdVerification.emailNotMatchingToken"
                            defaultMessage="You have logged in with an account that does have an email address matching the one from your passport scanning."
                        />
                        <p />
                        <strong>
                            <a href={logoutUrl}>Logout and restart registration</a>
                        </strong>
                    </Message>}


                    {!wrongPersonId && <Message id="transfer-complete-error" type="warning">
                        <FormattedMessage
                            id="errors.general"
                            defaultMessage="Something went wrong when completing the registration."
                        />
                    </Message>}
                </div>)
            } else {
                return <Redirect to="/" />
            }
        }
    }
}

export default CompleteTransfer
