import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';
import continueToSignSpaceBtnClicked from '../../actions/actionCreators/profile/addSecondaryEmail/ContinueToSignSpaceBtnClicked.js';
const messages = defineMessages({
    continue: {
        id: 'profile.addSecondaryEmail.continueToSignSpaceBtn',
        description: 'Continue to SignSpace btn',
        defaultMessage: 'Continue to SignSpace'
    }
});

class addSecondaryEmailResult extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;
        let store = this.state.addSecondaryEmail;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="profile.addSecondaryEmail.title"
                    sharedStore={this.state.shared}
                    hideSeparator={true}
                />
                { store.visibleElements.emailAndOrgAddedToAccount
                    ? <div>
                        <Message id="email-successfully-added-to-account" type="success">
                            <FormattedMessage
                                id="profile.addSecondaryEmail.emailWasAdded"
                                description="Email successfully added to your account"
                                defaultMessage="Email successfully added to your account."
                            />
                        </Message>
                    </div>
                    : null }
                { store.visibleElements.onlyEmailAddedToAccount
                    ? <div>
                        <Message id="only-email-successfully-added-to-account" type="success">
                            <FormattedMessage
                                id="profile.addSecondaryEmail.onlyEmailWasAdded"
                                description="Only email successfully added to your account"
                                defaultMessage="{userEmail} was successfully added to your SignSpace accounts. This email cannot be linked to an organisation due to one organisation limitation during pilot."
                                values={{userEmail: store.newUserEmail}}
                            />
                        </Message>
                    </div>
                    : null }
                { store.visibleElements.alreadyActivated
                ? <div>
                    <Message id="email-is-already-activated" type="success">
                        <FormattedMessage
                            id="profile.addSecondaryEmail.emailAlreadyActivated"
                            description="Email that you are trying to add is already activated"
                            defaultMessage="Email that you are trying to add is already activated"
                        />
                    </Message>
                </div>
                : null }
                <input
                    id="add_continue_to_signspace_button"
                    type="submit"
                    className="btn btn-primary btn-lg"
                    value={formatMessage(messages.continue)}
                    onClick={continueToSignSpaceBtnClicked}
                    role="button"
                />
            </div>
        );
    }
}

export default addSecondaryEmailResult;
