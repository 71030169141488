import { ReduceStore } from 'flux/utils';

import log from '../../singletons/Log.js';

import prepareUrl from '../../helpers/PrepareUrl.js';
import redirectToErrorPage from '../../actions/actionCreators/navigation/RedirectToErrorPage.js';
import redirectToUrl from '../../actions/actionCreators/navigation/RedirectToUrl.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import ActionTypes from '../../actions/ActionTypes';

const ON_ANY_ACTION_METHOD_NAME = 'onAnyAction';
const GET_ROUTE_LOAD_HANDLERS_METHOD_NAME = 'getRouteLoadHandlers';

class BaseStore extends ReduceStore {

    constructor(dispatcher, sharedStore) {
        super(dispatcher);
        this.sharedStore = sharedStore;

        this.routeLoadHandlers = null;
        this.loadedRoutePathWithParams = null;
    }

    reduce(state, action) {

        let sharedStoreState = this.sharedStore.getState();
        let newState = Object.assign({}, state);

        if (sharedStoreState.redirectAfterWait && action.type && [ActionTypes.REGISTRATION_COMPLETE_REGISTRATION_SUCCESS_MSG_TIME_PASSED, ActionTypes.ROUTE_ENTERED, ActionTypes.DISPLAY_LOADING_INDICATOR].indexOf(action.type) === -1) {
            sharedStoreState.redirectAfterWait = false;
        }

        this.callCustomHandlersIfDefined(newState, action, sharedStoreState);

        if (sharedStoreState.authInfo
            && !sharedStoreState.loadingAuthInfo
            && !sharedStoreState.savingUsedLanguage) {
            this.callRouteLoadedHandlersForNeededRoutes(newState, action, sharedStoreState);
        }

        return newState;
    }

    callCustomHandlersIfDefined(newState, action, sharedStoreState, ...optionalArgs) {
        // Call handlers for any action:
        if (this[ON_ANY_ACTION_METHOD_NAME]) {
            this[ON_ANY_ACTION_METHOD_NAME](newState, action, sharedStoreState, ...optionalArgs);
        }

        // Call handlers for specific action types:
        if (this[action.type]) {
            this[action.type](newState, action, sharedStoreState, ...optionalArgs);
        }
    }

    callRouteLoadedHandlersForNeededRoutes(newState, action, sharedStoreState) {

        this.ensureRouteHandlers();

        let currentRoutePath = sharedStoreState.currentRoute.fullPath;
        let currentRoutePathWithParams = sharedStoreState.currentRoute.fullPathWithOrgCode;

        let routeJustChanged = currentRoutePathWithParams !== this.loadedRoutePathWithParams;
        if (routeJustChanged) {
            this.loadedRoutePathWithParams = null;
        }

        for (let route of this.routeLoadHandlers) {

            // If this is a route that is being entered:

            if (route.fullPath === currentRoutePath
                && currentRoutePathWithParams !== this.loadedRoutePathWithParams) {
                this.callRouteLoadedHandlersOnEnteringRoute(route, newState, action, sharedStoreState, currentRoutePathWithParams);
            }
        }
    }

    callRouteLoadedHandlersOnEnteringRoute(route, newState, action, sharedStoreState, currentRoutePathWithParams) {
        // If user has needed permission:

        let userHasNeededPermission = true;

        if (route.loadOnlyIfHasPermission) {
            if (sharedStoreState.authInfo && !sharedStoreState.loadingAuthInfo) {

                // Redirect to pending orgs page from any route:

                let hasPendingOrgsToAccept = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_VERIFY_PENDING_INVITATION_TO_ORG');
                let isInPendingOrgsPage = route.fullPath === '/pending-org-invitations';
                let needToRedirectToPendingOrgsPage = hasPendingOrgsToAccept && !isInPendingOrgsPage;

                if (needToRedirectToPendingOrgsPage) {

                    let firstPendingOrgId = sharedStoreState.authInfo && sharedStoreState.authInfo.userProfile
                        ? sharedStoreState.authInfo.userProfile.firstPendingRepresentationOrgId
                        : null;

                    redirectToRoute("/pending-org-invitations", true, false, firstPendingOrgId);
                    userHasNeededPermission = false;
                } else {

                    userHasNeededPermission = this.userHasPermission(sharedStoreState, route.loadOnlyIfHasPermission);

                    if (!userHasNeededPermission) {

                        // If user cannot see this route, need to redirect.
                        if (sharedStoreState.isSignedIn) {

                            log.addRouteUnauthorizedInfo(route.loadOnlyIfHasPermission, sharedStoreState.authInfo.currentPermissions);

                            // To error page with unauthorized message, if user is signed in
                            redirectToErrorPage('unauthorized');
                        } else {
                            // To Gluu login page, if not signed in:
                            console.log('Redirecting to Gluu because ' + route.loadOnlyIfHasPermission + ' is missing, while trying to load ' + route.fullPath);
                            let returnUrl = prepareUrl.prepareRouteUrl(sharedStoreState.currentRoute.fullUrl, true, sharedStoreState.currentRoute.params.organizationCode);
                            returnUrl = prepareUrl.prepareAbsoluteFrontendUrl(sharedStoreState, returnUrl);
                            let returnUrlEncoded = encodeURIComponent(returnUrl);
                            redirectToUrl(prepareUrl.getSignInRelativeUrl(returnUrlEncoded));
                        }
                    }
                }
            } else {
                // Permissions not loaded yet:
                userHasNeededPermission = false;
            }
        }

        if (userHasNeededPermission
            && !sharedStoreState.automaticallyRedirectingToOtherRoute
            && sharedStoreState.classifiers) {
            if (route.loadHandler) {
                let loadHandlerWithCorrectThis = route.loadHandler.bind(this);
                loadHandlerWithCorrectThis(newState, action, sharedStoreState);
                this.loadedRoutePathWithParams = currentRoutePathWithParams;
            }
        }
    }

    ensureRouteHandlers() {
        if (this.routeLoadHandlers === null) {
            if (this[GET_ROUTE_LOAD_HANDLERS_METHOD_NAME]) {
                this.routeLoadHandlers = this[GET_ROUTE_LOAD_HANDLERS_METHOD_NAME]();
            } else {
                this.routeLoadHandlers = [];
            }
        }
    }

    userHasPermission(sharedStoreState, permission) {
        return sharedStoreState.authInfo
            && sharedStoreState.authInfo.currentPermissions
            && sharedStoreState.authInfo.currentPermissions.indexOf(permission) >= 0;
    }
}

export default BaseStore;
