import React, { useState, useMemo } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import worker from 'pdfjs-dist/build/pdf.worker.min.mjs';

const PdfViewer = (file) => {
    const [page, setPage] = useState(1);
    const [pages, setNumPages] = useState();

  const renderPagination = useMemo(() => {
        let previousButton = <i className="fa fa-arrow-circle-left" onClick={() => setPage(page - 1)}></i>;
        if (page === 1) {
            previousButton = <i className="fa fa-arrow-circle-left"></i>;
        }
        let nextButton = <i className="fa fa-arrow-circle-right " onClick={() => setPage(page + 1)}></i>;
        if (page === pages) {
            nextButton = <i className="fa fa-arrow-circle-right"></i>;
        }
        return (
            <div>
                {previousButton} {page}/{pages} {nextButton}
            </div>
          );
    }, [page, pages, setPage]);

  const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
  };

  return (
      <div className="pdf-display-wrapper">
          <Document file={file.file} onLoadSuccess={onDocumentLoadSuccess}>
              <Page
                  pageNumber={page}
                  renderAnnotationLayer={false} // Disable annotations
                  renderTextLayer={false}      // Disable text layer
              />
          </Document>
          {renderPagination}
      </div>
  );
};

export default PdfViewer;
