import ActionTypes from '../../../ActionTypes.js';
import { getFromBackend } from '../../generic/CallBackend.js';

let initiateStrongAuthForAnonymous = function () {
    getFromBackend('/registration/strong-auth-anonymous',
        ActionTypes.REGISTRATION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS_SUCCESS,
        ActionTypes.REGISTRATION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS_ERROR
    );
};

export default initiateStrongAuthForAnonymous;