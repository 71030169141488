import React from 'react';
import StoreComponent from './base/StoreComponent.jsx';
import { PageHeader } from '@vaultit/stv-utils-frontend';

class InfoPageForDocumentDisplay extends StoreComponent {

    renderWhenReady() {

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;
        let infoData = uiConfiguration.servicePortal.subMenu.info;
        let pdfUrl = this.state.infoPageStore.userGuideUrl;
        if (!infoData.enabled) {
            return null;
        }

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="infoPage.pageTitle"
                    sharedStore={this.state.shared}
                />
                {pdfUrl
                ?
                    <div className="pdf-display-wrapper">
                        <object
                            data={pdfUrl}
                            width="100%"
                            height="800px"
                            type='application/pdf'
                            id="user_guide_pdf"
                        />
                    </div>
                : null
                }
            </div>
        );
    }
}

export default InfoPageForDocumentDisplay;
