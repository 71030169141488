import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import { FormRow, PageHeader, FormColumn, ValidationMessages, InputLabel, TextInput, FormGroup, Message, PhoneNumberInputComponent, ConfirmationModal } from '@vaultit/stv-utils-frontend';
import createFullUserAccountCancelBtnClicked from '../../../actions/actionCreators/profile/createFullAccount/CreateFullUserAccountCancelBtnClicked.js';
import emailExistsInfoBtnClicked from '../../../actions/actionCreators/profile/createFullAccount/EmailExistsInfoBtnClicked.js';
import emailExistsInfoClosed from '../../../actions/actionCreators/profile/createFullAccount/EmailExistsInfoClosed.js';
import createFullUserAccountCanceled from '../../../actions/actionCreators/profile/createFullAccount/CreateFullUserAccountCanceled.js';


const messages = defineMessages({
    emailPlaceholder: {
        id: 'profile.createFullAccount.email',
        description: 'Email address placeholder',
        defaultMessage: 'Email address'
    },
    emailAgainPlaceholder: {
        id: 'profile.createFullAccount.emailAgain',
        description: 'Email address placeholder',
        defaultMessage: 'Email again'
    },
    passwordPlaceholder: {
        id: 'profile.createFullAccount.password',
        description: 'Password field placeholder',
        defaultMessage: 'Password'
    },
    passwordAgainPlaceholder: {
        id: 'profile.createFullAccount.passwordAgain',
        description: 'Password field placeholder',
        defaultMessage: 'Password again'
    },
    cancelAccountCreationModalOkBtnText: {
        id: 'profile.createFullAccount.cancelAccountCreationModalOkBtnText',
        description: 'Modal yes btn text',
        defaultMessage: 'Yes'
    },
    cancelAccountCreationModalCancelBtnText: {
        id: 'profile.createFullAccount.cancelAccountCreationModalCancelBtnText',
        description: 'Modal cancel btn text',
        defaultMessage: 'No'
    },
    cancelAccountCreationModalTitleText: {
        id: 'profile.createFullAccount.cancelAccountCreationModalTitleText',
        description: 'Modal title',
        defaultMessage: 'Cancel your registration'
    },
    emailExistsInfoModalOkBtnText: {
        id: 'profile.createFullAccount.emailExistsInfoModalOkBtnText',
        description: 'Modal yes btn text',
        defaultMessage: 'Yes'
    },
    emailExistsInfoModalCancelBtnText: {
        id: 'profile.createFullAccount.emailExistsInfoModalCancelBtnText',
        description: 'Modal cancel btn text',
        defaultMessage: 'Cancel'
    },
    emailExistsInfoModalTitleText: {
        id: 'profile.createFullAccount.emailExistsInfoModalTitleText',
        description: 'Modal title',
        defaultMessage: 'The email already exists'
    },
    canceledAccountCreationModalOkBtnText: {
        id: 'profile.createFullAccount.canceledAccountCreationModalOkBtnText',
        description: 'Modal ok text',
        defaultMessage: 'Confirm'
    },
    canceledAccountCreationModalTitleText: {
        id: 'profile.createFullAccount.canceledAccountCreationModalTitleText',
        description: 'Modal title text',
        defaultMessage: 'Account deleted'
    },
    cancelAccountCreationModalTitleUserNotDeletedText: {
        id: 'profile.createFullAccount.cancelAccountCreationModalTitleUserNotDeletedText',
        description: 'Modal title text',
        defaultMessage: 'Account is not deleted'
    },
});


class CreateFullAccount extends StoreComponent {
    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;
        let form = this.state.createFullAccountStore.createFullAccountForm;
        let fields = form ? form.fields : null;
        return (
            <div>
                {this.state.createFullAccountStore.showSessionExpiredInContractSignMessage
                ?
                    <Message id="create_full_account_user_already_exist" type="warning">
                        <FormattedMessage
                            id="profile.createFullAccount.sessionExpiredInContractSign"
                            defaultMessage="Your session has expired, please restart from the link in your email."
                        />.
                    </Message>
                : null
                }
                <PageHeader sharedStore={this.state.shared} pageTitleMessageId="profile.createFullAccount.title" />
                { form
                ?
                    <div>
                        {this.state.createFullAccountStore.showAnotherUserVerifiedEmailMessage
                        ?
                            <Message id="enother_user_verified_email" type="warning">
                                <FormattedMessage
                                    id="profile.createFullAccount.anotherUserVerifiedEmail"
                                    defaultMessage="Another person has verified that email-address, you can not use it."
                                />
                            </Message>
                        : null
                        }
                        <form name="createFullAccountForm" noValidate="">
                            <FormRow>
                                <FormColumn width="12">

                                    <FormRow>
                                        <FormColumn width="8">
                                            <FormGroup>
                                                <ValidationMessages field={fields.email}>
                                                    <TextInput
                                                        id="create_full_account_email"
                                                        field={fields.email}
                                                        messageId="profile.createFullAccount.email"
                                                        defaultMessage="Email address"
                                                        placeholder={formatMessage(messages.emailPlaceholder)}
                                                        required={true}
                                                        disabled={false}
                                                        onPaste={ (e) => e.preventDefault() }
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <label className="d-inline-block w-100 form-control-label hidden-xs-down">&nbsp;</label>
                                            <small className="form-control-description my-5 my-sm-0">
                                                <span>
                                                    <i className="fa fa-info-circle fa-mr"></i>
                                                    <FormattedMessage id="registrations.step1.flow2.emailExplanationText" defaultMessage="Email will require verification." />
                                                </span>
                                            </small>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="8">
                                            <FormGroup>
                                                <ValidationMessages field={fields.emailAgain}>
                                                    <TextInput
                                                        id="create_full_account_email_again"
                                                        field={fields.emailAgain}
                                                        messageId="profile.createFullAccount.emailAgain"
                                                        defaultMessage="Email again"
                                                        placeholder={formatMessage(messages.emailAgainPlaceholder)}
                                                        required={true}
                                                        disabled={false}
                                                        onPaste={ (e) => e.preventDefault() }
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="8">
                                            <FormGroup>
                                                <ValidationMessages field={fields.phoneNumber} componentCustomValidation="errors.validation.field.componentCustomValidation.phoneNumber">
                                                    <InputLabel
                                                        required={true}
                                                        htmlFor="create_full_account_phone_number"
                                                        messageId="profile.createFullAccount.phoneNumber"
                                                        defaultMessage="Phone number"
                                                    />
                                                    <PhoneNumberInputComponent
                                                        id="create_full_account_phone_number"
                                                        className='form-control'
                                                        value={fields.phoneNumber.value || ""}
                                                        onChange={fields.phoneNumber.handleOnChange}
                                                        initialCountry='se'
                                                        preferredCountries={['se', 'ee', 'fi']}
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                    </FormRow>

                                    <FormRow>
                                        <FormColumn width="4">
                                            <FormGroup>
                                                <ValidationMessages field={fields.password}>
                                                    <TextInput
                                                        id="create_full_account_password"
                                                        type="password"
                                                        field={fields.password}
                                                        messageId="profile.createFullAccount.password"
                                                        defaultMessage="Password"
                                                        placeholder={formatMessage(messages.passwordPlaceholder)}
                                                        required={true}
                                                        disabled={false}
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <FormGroup>
                                                <ValidationMessages field={fields.passwordAgain} match="errors.validation.field.match.passwords">
                                                    <TextInput
                                                        id="create_full_account_password_again"
                                                        type="password"
                                                        field={fields.passwordAgain}
                                                        messageId="profile.createFullAccount.passwordAgain"
                                                        defaultMessage="Password again"
                                                        placeholder={formatMessage(messages.passwordAgainPlaceholder)}
                                                        required={true}
                                                        disabled={false}
                                                    />
                                                </ValidationMessages>
                                            </FormGroup>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <label className="d-inline-block w-100 form-control-label hidden-xs-down">&nbsp;</label>
                                            <small className="form-control-description my-5 my-sm-0">
                                                <span>
                                                    <i className="fa fa-info-circle fa-mr"></i>
                                                    <FormattedMessage id="registrations.step1.flow2.passwordExplanatiopnText" defaultMessage="Password must be at least 8 characters long." />
                                                </span>
                                            </small>
                                        </FormColumn>
                                    </FormRow>

                                </FormColumn>
                            </FormRow>
                        </form>
                        {/*
                            Submit button is outside the form on purpose - form contains password field and we want to disable password manager here.
                            Default button behaviour is done manually through 'handleOnEnterKeyDownToSubmitForm' handlers.
                        */}
                        <FormRow>
                            <FormColumn width="8">
                                <FormGroup className="mt-6">
                                    <button
                                        id="create_full_account_submit"
                                        className="btn btn-primary btn-lg mr-3"
                                        onClick={form.handleOnSubmitBtnClicked}
                                        role="button"
                                    >
                                        <FormattedMessage id="profile.createFullAccount.createFullAccount" defaultMessage="Create" />
                                    </button>
                                    <button
                                        id="create_full_account_submit"
                                        className="btn btn-primary btn-lg mr-3"
                                        role="button"
                                        data-toggle="modal"
                                        data-target="#cancel-account-creation-modal"
                                    >
                                        <FormattedMessage id="profile.createFullAccount.cancelRegistration" defaultMessage="Cancel registration" />
                                    </button>
                                    <ConfirmationModal
                                        id='cancel-account-creation-modal'
                                        okButtonText={formatMessage(messages.cancelAccountCreationModalOkBtnText)}
                                        cancelButtonText={formatMessage(messages.cancelAccountCreationModalCancelBtnText)}
                                        title={formatMessage(messages.cancelAccountCreationModalTitleText)}
                                        handleActionType={createFullUserAccountCancelBtnClicked}
                                        okBtnClass="btn btn-primary"
                                        modalClass="text-left"
                                        dismissModalAfterClick={true}
                                        sharedStore={this.state.shared}
                                    >
                                        <FormattedMessage
                                            id="profile.createFullAccount.cancelAccountCreationModalText"
                                            defaultMessage="Are you sure you want to cancel your registration?"
                                        />
                                    </ConfirmationModal>
                                    <ConfirmationModal
                                        id='successfully-cancel-account-creation-modal'
                                        okButtonText={formatMessage(messages.canceledAccountCreationModalOkBtnText)}
                                        title={
                                            this.state.createFullAccountStore.accountIsNotDeleted
                                            ? formatMessage(messages.cancelAccountCreationModalTitleUserNotDeletedText)
                                            : formatMessage(messages.canceledAccountCreationModalTitleText)
                                        }
                                        okBtnClass="btn btn-primary"
                                        handleActionType={createFullUserAccountCanceled}
                                        modalClass="text-left"
                                        dismissModalAfterClick={true}
                                        showModal={this.state.createFullAccountStore.showUserCanceledUserAccountCreationSuccessModal}
                                        hideCloseButton={true}
                                        sharedStore={this.state.shared}
                                    >
                                        { this.state.createFullAccountStore.showUserIsNotDeletedHasCardsMsg
                                            ?
                                                <FormattedMessage
                                                    id="profile.createFullAccount.cancelAccountCreationModalTextUserHasCards"
                                                    defaultMessage="Account is not deleted because user has cards."
                                                />
                                            :
                                            <FormattedMessage
                                                id="profile.createFullAccount.canceledAccountCreationModalText"
                                                defaultMessage="Your account has been successfully deleted."
                                            />
                                        }
                                    </ConfirmationModal>
                                    <ConfirmationModal
                                        id='email-exists-info-modal'
                                        okButtonText={formatMessage(messages.emailExistsInfoModalOkBtnText)}
                                        cancelButtonText={formatMessage(messages.emailExistsInfoModalCancelBtnText)}
                                        title={formatMessage(messages.emailExistsInfoModalTitleText)}
                                        handleActionType={emailExistsInfoBtnClicked}
                                        onCloseAction={emailExistsInfoClosed}
                                        okBtnClass="btn btn-primary"
                                        modalClass="text-left"
                                        dismissModalAfterClick={true}
                                        showModal={this.state.createFullAccountStore.showAnotherUserVerifiedEmailModal}
                                        sharedStore={this.state.shared}
                                    >
                                        <FormattedMessage
                                            id="profile.createFullAccount.emailExistsInfoModalText"
                                            defaultMessage="The email {email} is already used by another verified account. If you are the owner of that account, please click {yes} to proceed confirming the ownership of that account. Otherwise, you need to {cancel} and specify a different email address."
                                            values={{email: <b><i>{fields.email.value}</i></b>,
                                                     yes: <b><i>{formatMessage(messages.emailExistsInfoModalOkBtnText)}</i></b>,
                                                     cancel: <b><i>{formatMessage(messages.emailExistsInfoModalCancelBtnText)}</i></b>}}
                                        />
                                    </ConfirmationModal>

                                </FormGroup>
                            </FormColumn>
                        </FormRow>
                    </div>
                : null
                }
            </div>
        );
    }

}
export default CreateFullAccount;
