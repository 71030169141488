import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let registerUserAccount = function (values) {
    postToBackend(`/registration/register-account`,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CREATE_ACCOUNT_SUCCESS,
        ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CREATE_ACCOUNT_ERROR,
        values);
};

export default registerUserAccount;
