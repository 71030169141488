import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

class AdminPanel extends React.Component {
    static get propTypes() {
        return {
            isAdmin: PropTypes.bool,
            className: PropTypes.string
        };
    }

    render() {
        let className = this.props.className ? ' ' + this.props.className : '';

        return this.props.isAdmin ? (
            <div className={"card" + className}>
                <div className="card-header">
                    <FormattedMessage
                        id="adminPanel.title"
                        defaultMessage="Admin settings"
                    />
                </div>
                <div className="card-block">
                    { this.props.children }
                </div>
            </div>
        )
        : null;
    }
}

export default AdminPanel;
