import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getUserRepresentedOrgs = function(orgId, userId) {

    getFromBackend('/users/' + orgId + '/get_represented-orgs/' + userId,
        ActionTypes.GET_USER_REPRESENTED_ORGS_SUCCESS,
        ActionTypes.GET_USER_REPRESENTED_ORGS_ERROR);
};

export default getUserRepresentedOrgs;
