import FormStore from '../../base/FormStore.js';
import storeFormManager from '../../validation/StoreFormManager.js';
import ActionTypes from '../../../actions/ActionTypes.js';
import constants from '../../../Constants.js';
import getPersonInfoForRegistration from '../../../actions/actionCreators/registration/AfterManualIDVerification/GetPersonInfoForRegistration.js';
import registerUserAccount from '../../../actions/actionCreators/registration/AfterManualIDVerification/RegisterUserAccount.js';
import prepareUrl from '../../../helpers/PrepareUrl.js';
import redirectToRoute from '../../../actions/actionCreators/navigation/RedirectToRoute.js';
import verifyEmailAndCreateAccount from '../../../actions/actionCreators/registration/AfterManualIDVerification/VerifyEmailAndCreateAccount.js';

import {
    whiteSpaceRestrictor, getBlacklistedEmailValidator, emailValidator,
    getMatchValidator, getMaxLengthValidator,
    passwordValidator, requiredValidator
} from '@vaultit/stv-utils-frontend';


const store = 'accountRegistrationStore';
const formKey = 'createAccountAfterIDVerificationForm';


class AccountRegistrationStore extends FormStore {

    getInitialState() {
        let initialState = {
            showLoadingIndicator: false,
            termsOfServicePdfUrl: '',
            accountSuccessfullyCreated: false,
            signInUrl: '',
            emailAlreadyBelongsToUser: false,
            ninIsNotMatching: false,
            email: '',
            cardHolderRegistration: false,
            identityType: ''
        }

        let createAccountAfterIDVerificationFieldDefinitions = {
            firstName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            lastName: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator()
                ]
            },
            email: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    emailValidator,
                    getBlacklistedEmailValidator(() => this.blacklistedEmailAddresses)
                ],
                restrictors: [
                    whiteSpaceRestrictor
                ]
            },
            emailAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'email',
                        validator: getMatchValidator('email')
                    }
                ]
            },
            phoneNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            password: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(constants.passwordValidationMaximumDigits),
                    passwordValidator
                ],
                isSensitiveInformation: true
            },
            passwordAgain: {
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getMaxLengthValidator(),
                    {
                        validateOnOtherFieldChange: 'password',
                        validator: getMatchValidator('password')
                    }
                ],
                isSensitiveInformation: true
            },
            country: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            nationalIdentityNumber: {
                defaultValue: '',
                validators: [
                    requiredValidator
                ]
            },
            acceptTOS: {
                defaultValue: false,
                validators: [
                    requiredValidator
                ]
            }
        }

        storeFormManager.defineForm(initialState, store, formKey, createAccountAfterIDVerificationFieldDefinitions,this.onCreateAccountAfterIDVerificationFormSubmited);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/registration-after-manualid-verification/create-account/:token',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_TRY_TO_REGISTER_ACCOUNT_AFTER_ID_VERIFICATION',
                loadHandler: this.onCreateAccountAfterManualIdRouteLoaded
            },
            {
                fullPath: '/registration-after-manualid-verification/account-created',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_TRY_TO_REGISTER_ACCOUNT_AFTER_ID_VERIFICATION',
                loadHandler: this.onAccountCreatedRouteLoaded
            },
            {
                fullPath: '/registration-after-manualid-verification/finish-verify-email/:token',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_TRY_TO_REGISTER_ACCOUNT_AFTER_ID_VERIFICATION',
                loadHandler: this.onFinishEmailVerificationRouteLoaded
            }
        ];
    }

    onCreateAccountAfterManualIdRouteLoaded(state, action, sharedStoreState) {
        state.cardHolderRegistration = false;
        state.accountSuccessfullyCreated = false;
        state.emailAlreadyBelongsToUser = false;
        state.ninIsNotMatching = false;
        state.signInUrl = '';
        state.email = '';
        state.identityType = '';
        state.showLoadingIndicator = true;
        let token = sharedStoreState.currentRoute.params.token;
        let tosType = "person";
        state.termsOfServicePdfUrl = "/binary/" + sharedStoreState.tosPrefix + "_" + tosType + "_termsOfService." + sharedStoreState.localizations.messagesLanguage + ".pdf";
        getPersonInfoForRegistration(token);
    }

    onAccountCreatedRouteLoaded(state, action, sharedStoreState) {
        state.accountSuccessfullyCreated = true;
        state.signInUrl = prepareUrl.getSmsAuthPath(sharedStoreState.authInfo.topMenuParameters.configuration);
        state.emailAlreadyBelongsToUser = false;
        state.ninIsNotMatching = false;
        sessionStorage.removeItem('registrationAfterManualidVerificationToken');
    }

    onFinishEmailVerificationRouteLoaded(state, action, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.accountSuccessfullyCreated = false;
        state.emailAlreadyBelongsToUser = false;
        state.ninIsNotMatching = false;
        let token = sharedStoreState.currentRoute.params.token;
        verifyEmailAndCreateAccount({"token": token});
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_PERSON_INFO_SUCCESS] (state, action, sharedStoreState) {
        let result = action.result;

        // Do not overwrite form if it was previously set
        if (!state.createAccountAfterIDVerificationForm) {

            this.blacklistedEmailAddresses = {
                blacklistedEmailAddresses: sharedStoreState.authInfo.topMenuParameters.configuration.blacklistedEmailAddresses,
                emailBlacklistingEnabled: JSON.parse(sharedStoreState.authInfo.topMenuParameters.configuration.emailBlacklistingEnabled)
            };

            storeFormManager.setFormInitialState(state, formKey);
            state.createAccountAfterIDVerificationForm.fields.firstName.value = result.firstName;
            state.createAccountAfterIDVerificationForm.fields.lastName.value = result.lastName;
            state.createAccountAfterIDVerificationForm.fields.nationalIdentityNumber.value = result.nin;
            state.createAccountAfterIDVerificationForm.fields.country.value = result.country ? result.country.toLowerCase(): '';
            state.createAccountAfterIDVerificationForm.fields.email.value = result.email;
            state.createAccountAfterIDVerificationForm.fields.emailAgain.value = result.email;
            state.createAccountAfterIDVerificationForm.fields.phoneNumber.value = result.phoneNumber;
            state.identityType = result.identityType;
        }
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_GET_PERSON_INFO_ERROR] (state) {
        state.showLoadingIndicator = true;
    }


    onCreateAccountAfterIDVerificationFormSubmited(state, values, sharedStoreState) {
        state.showLoadingIndicator = true;
        state.accountSuccessfullyCreated =  false;
        state.emailAlreadyBelongsToUser = false;
        state.ninIsNotMatching = false;
        let token = sharedStoreState.currentRoute.params.token;
        values["token"] = token;
        values["language"] = sharedStoreState.authInfo.otherUiInfo.uiLanguage;
        registerUserAccount(values);
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CREATE_ACCOUNT_SUCCESS] (state, action, sharedStoreState) {
        state.showLoadingIndicator = false;
        let result = action.result;

        if (result) {
            state.emailAlreadyBelongsToUser = result.emailAlreadyBelongsToUser;
            state.mergePersonsToken = result.mergePersonsToken;
            state.ninIsNotMatching = result.ninIsNotMatching;
            state.cardHolderRegistration = result.cardHolderRegistration;
            if (result.accountPrepared) {
                redirectToRoute("/registration-after-manualid-verification/account-created", true, false, false);
            } else if (result.verificationEmailSent) {

                sharedStoreState.emailToVerify = result.email;
                sessionStorage.setItem('registrationAfterManualidVerificationToken', sharedStoreState.currentRoute.params.token);

                redirectToRoute(`/registration-after-manualid-verification/verify-email/${result.email}`, true, false, false);
            }
        }
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_CREATE_ACCOUNT_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_EMAIL_SUCCESS] (state, action) {
        state.showLoadingIndicator = false;
        let result = action.result;

        if (result) {
            state.emailAlreadyBelongsToUser = result.emailAlreadyBelongsToUser;
            state.ninIsNotMatching = result.ninIsNotMatching;
            state.cardHolderRegistration = result.cardHolderRegistration;

            if (result.accountPrepared) {
                redirectToRoute("/registration-after-manualid-verification/account-created", true, false, false);
            }
        }
    }

    [ActionTypes.REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_EMAIL_ERROR] (state) {
        state.showLoadingIndicator = false;
    }

}

export default AccountRegistrationStore;
