import keymirror from 'keymirror';

const ActionTypes = keymirror({
    ACCEPT_PERSON_TOS_ERROR: null,
    ACCEPT_PERSON_TOS_SUCCESS: null,
    ADD_ANOTHER_EMAIL_TO_ACCOUNT_CHECK_TOKEN_ERROR: null,
    ADD_ANOTHER_EMAIL_TO_ACCOUNT_CHECK_TOKEN_SUCCESS: null,
    ADD_ANOTHER_EMAIL_TO_ACCOUNT_ERROR: null,
    ADD_ANOTHER_EMAIL_TO_ACCOUNT_SUCCESS: null,
    ADD_USER_ORG_LIST_BTN_CLICKED: null,
    ADMIN_BLOCK_UNBLOCK_ACCOUNT_CONFIRM_BTN_CLICKED: null,
    ADMIN_BLOCK_UNBLOCK_ACCOUNT_ERROR: null,
    ADMIN_BLOCK_UNBLOCK_ACCOUNT_PROMPT_BTN_CLICKED: null,
    ADMIN_BLOCK_UNBLOCK_ACCOUNT_SUCCESS: null,
    ADMIN_CHANGE_EMAIL_CANCEL_BTN_CLICKED: null,
    ADMIN_CHANGE_EMAIL_CANCEL_ERROR: null,
    ADMIN_CHANGE_EMAIL_CANCEL_SUCCESS: null,
    ADMIN_INITIATE_EMAIL_CHANGE_BTN_CLICKED: null,
    AUTHENTICATE_TO_SIGN_BTN_CLICKED: null,
    AUTH_INFO_GET_ERROR: null,
    AUTH_INFO_GET_SUCCESS: null,
    CANCEL_CREATE_FULL_USER_ACCOUNT_ERROR: null,
    CANCEL_CREATE_FULL_USER_ACCOUNT_SUCCESS: null,
    CHECK_FOR_EXISTING_EMAIL_ERROR: null,
    CHECK_FOR_EXISTING_EMAIL_SUCCESS: null,
    CHECK_POST_AUTH_ERROR_ERROR: null,
    CHECK_POST_AUTH_ERROR_SUCCESS: null,
    CHECK_REQUEST_TOKEN_ERROR: null,
    CHECK_REQUEST_TOKEN_SUCCESS: null,
    CLASSIFIERS_GET_ALL_ERROR: null,
    CLASSIFIERS_GET_ALL_SUCCESS: null,
    CLOSE_MODAL: null,
    CLOSE_REDIRECT_NOTICE_BTN_CLICKED: null,
    CONFIG_GET_ERROR: null,
    CONFIG_GET_SUCCESS: null,
    CONFIRMATION_MODAL_OK_BTN_CLICKED: null,
    CONTINUE_TO_SIGNSPACE_BTN_CLICKED: null,
    CPB_ORGANIZATION_USERS_GET_ERROR: null,
    CPB_ORGANIZATION_USERS_GET_SUCCESS: null,
    CREATE_FULL_USER_ACCOUNT_CANCELED_SUCCESS: null,
    CREATE_FULL_USER_ACCOUNT_CANCEL_BTN_CLICKED: null,
    CREATE_FULL_USER_ACCOUNT_ERROR: null,
    CREATE_FULL_USER_ACCOUNT_SUCCESS: null,
    CSV_REPORT_DELETE_JOB_CONFIRMED_BTN_CLICKED: null,
    CSV_REPORT_DELETE_JOB_RESULT_BTN_CLICKED: null,
    CSV_REPORT_DELETE_JOB_RESULT_ERROR: null,
    CSV_REPORT_DELETE_JOB_RESULT_SUCCESS: null,
    CSV_REPORT_DOWNLOAD_JOB_RESULT_BTN_CLICKED: null,
    CSV_REPORT_DOWNLOAD_JOB_RESULT_ERROR: null,
    CSV_REPORT_DOWNLOAD_JOB_RESULT_SUCCESS: null,
    CSV_REPORT_ERROR: null,
    CSV_REPORT_GET_JOBS_ERROR: null,
    CSV_REPORT_GET_JOBS_SUCCESS: null,
    CSV_REPORT_GET_UNFINISHED_JOBS_ERROR: null,
    CSV_REPORT_GET_UNFINISHED_JOBS_SUCCESS: null,
    CSV_REPORT_SUCCESS: null,
    DASHBOARD_INFO_GET_ERROR: null,
    DASHBOARD_INFO_GET_SUCCESS: null,
    DISPLAY_LOADING_INDICATOR: null,
    EDIT_USER_INFO_GET_ERROR: null,
    EDIT_USER_INFO_GET_SUCCESS: null,
    EMAIL_EXISTS_INFO_BTN_CLICKED: null,
    EMAIL_EXISTS_INFO_CLOSED: null,
    FORM_BACK_BTN_CLICKED: null,
    FORM_FIELD_BLUR: null,
    FORM_FIELD_REMOVED: null,
    FORM_FIELD_VALUE_CHANGED: null,
    FORM_REPEATED_FIELD_ADDED: null,
    FORM_REPEATED_FIELD_REMOVED: null,
    FORM_SUBMIT_BTN_CLICKED: null,
    GENERATE_EMAIL_VERIFICATION_BTN_CLICKED: null,
    GENERATE_GLOBAL_SEARCH_EMAIL_LINK_BTN_CLICKED: null,
    GENERATE_GLOBAL_SEARCH_EMAIL_VERIFICATION_LINK_ERROR: null,
    GENERATE_GLOBAL_SEARCH_EMAIL_VERIFICATION_LINK_SUCCESS: null,
    GENERATE_GLOBAL_SEARCH_PASSWORD_BTN_CLICKED: null,
    GENERATE_INVITATION_LINK_BTN_CLICKED: null,
    GENERATE_PASSWORD_BTN_CLICKED: null,
    GET_CURRENT_STRONG_AUTH_STATUS_ERROR: null,
    GET_CURRENT_STRONG_AUTH_STATUS_SUCCESS: null,
    GET_EMAIL_VERIFICATION_LINK_ERROR: null,
    GET_EMAIL_VERIFICATION_LINK_SUCCESS: null,
    GET_GENERATED_INVITATION_LINK_ERROR: null,
    GET_GENERATED_INVITATION_LINK_SUCCESS: null,
    GET_GLOBAL_SEARCH_CHANGE_EMAIL_LINK_ERROR: null,
    GET_GLOBAL_SEARCH_CHANGE_EMAIL_LINK_SUCCESS: null,
    GET_GLOBAL_SEARCH_PASSWORD_RECOVERY_LINK_ERROR: null,
    GET_GLOBAL_SEARCH_PASSWORD_RECOVERY_LINK_SUCCESS: null,
    GET_GLOBAL_SEARCH_USER_CARDS_LIST_ERROR: null,
    GET_PASSWORD_RECOVERY_LINK_ERROR: null,
    GET_PASSWORD_RECOVERY_LINK_SUCCESS: null,
    GET_PERMISSIONS_MANAGED_BY_CURRENT_USER_ERROR: null,
    GET_PERMISSIONS_MANAGED_BY_CURRENT_USER_SUCCESS: null,
    GET_SSN_FORMATS_ERROR: null,
    GET_SSN_FORMATS_SUCCESS: null,
    GET_USER_GLOBAL_INFO_ERROR: null,
    GET_USER_GLOBAL_INFO_SUCCESS: null,
    GET_USER_REPRESENTED_ORGS_ERROR: null,
    GET_USER_REPRESENTED_ORGS_SUCCESS: null,
    GLOBAL_SEARCH_BLOCK_USER_CANCEL_ICON_CLICKED: null,
    GLOBAL_SEARCH_CANCEL_ICON_CLICKED: null,
    GLOBAL_SEARCH_CHANGE_EMAIL_RESEND_VERIFICATION_BTN_CLICKED: null,
    GLOBAL_SEARCH_EDIT_ICON_CLICKED: null,
    GLOBAL_SEARCH_SAVE_ICON_CLICKED: null,
    GLOBAL_SEARCH_SHOW_MODAL_BTN_CLICKED: null,
    GLOBAL_USER_INFO_MERGE_PERSONS_BTN_CLICKED: null,
    GLOBAL_USER_INFO_MERGE_PERSONS_ERROR: null,
    GLOBAL_USER_INFO_MERGE_PERSONS_SUCCESS: null,
    ID06_LMA_CARD_ORDER_SEARCH_ERROR: null,
    ID06_LMA_CARD_ORDER_SEARCH_SUCCESS: null,
    ID06_LMA_CREATE_USER_ACCOUNT_ERROR: null,
    ID06_LMA_CREATE_USER_ACCOUNT_SUCCESS: null,
    LANGUAGE_CHANGE_ERROR: null,
    LANGUAGE_CHANGE_SUCCESS: null,
    LANGUAGE_SELECT_BTN_CLICKED: null,
    MANAGE_USERS_ORG_LIST_BTN_CLICKED: null,
    MERGE_ACCOUNTS_ERROR: null,
    MERGE_ACCOUNTS_SUCCESS: null,
    OPEN_BLOCK_HISTORY_MODAL_BTN_CLICKED: null,
    OPEN_IDENTITY_DOCUMENT_IMAGE_MODAL_BTN_CLICKED: null,
    OPEN_IMPORT_EMAILS_MODAL_BTN_CLICKED: null,
    ORGANIZATION_USERS_ADD_ERROR: null,
    ORGANIZATION_USERS_ADD_SUCCESS: null,
    ORGANIZATION_USERS_CLEAR_FILTER_BTN_CLICKED: null,
    ORGANIZATION_USERS_CREATE_API_ACCOUNT_ERROR: null,
    ORGANIZATION_USERS_CREATE_API_ACCOUNT_SUCCESS: null,
    ORGANIZATION_USERS_DELETE_BTN_CLICKED: null,
    ORGANIZATION_USERS_DELETE_ERROR: null,
    ORGANIZATION_USERS_DELETE_SUCCESS: null,
    ORGANIZATION_USERS_GET_ERROR: null,
    ORGANIZATION_USERS_GET_SUCCESS: null,
    ORGANIZATION_USERS_LIST_ROW_CLICKED: null,
    ORGANIZATION_USERS_PERMISSIONS_GRANTED_ERROR: null,
    ORGANIZATION_USERS_PERMISSIONS_GRANTED_SUCCESS: null,
    ORGANIZATION_USERS_PERMISSIONS_REMOVE_ERROR: null,
    ORGANIZATION_USERS_PERMISSIONS_REMOVE_SUCCESS: null,
    ORGANIZATION_USERS_REMOVE_PERMISSIONS_BTN_CLICKED: null,
    ORGANIZATION_USERS_RESEND_INVITATION_BTN_CLICKED: null,
    ORGANIZATION_USERS_RESEND_INVITATION_ERROR: null,
    ORGANIZATION_USERS_RESEND_INVITATION_SUCCESS: null,
    ORGANIZATION_USERS_UPDATE_ERROR: null,
    ORGANIZATION_USERS_UPDATE_MAIN_ORG_ERROR: null,
    ORGANIZATION_USERS_UPDATE_MAIN_ORG_SUCCESS: null,
    ORGANIZATION_USERS_UPDATE_SUCCESS: null,
    PERFORM_PLUGIN_ACTION_ERROR: null,
    PERFORM_PLUGIN_ACTION_SUCCESS: null,
    POST_TO_HUBSPOT_ERROR: null,
    POST_TO_HUBSPOT_SUCCESS: null,
    PROCEED_TO_COMPANY_BTN_CLICKED: null,
    PROFILE_ACTIVATE_ACCOUNT_CHECK_TOKEN_ERROR: null,
    PROFILE_ACTIVATE_ACCOUNT_CHECK_TOKEN_SUCCESS: null,
    PROFILE_ACTIVATE_ACCOUNT_ERROR: null,
    PROFILE_ACTIVATE_ACCOUNT_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_CANCEL_BTN_CLICKED: null,
    PROFILE_CHANGE_EMAIL_CANCEL_ERROR: null,
    PROFILE_CHANGE_EMAIL_CANCEL_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_CONTINUE_AFTER_CHANGE_BTN_CLICKED: null,
    PROFILE_CHANGE_EMAIL_INITIATE_ERROR: null,
    PROFILE_CHANGE_EMAIL_INITIATE_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_RESEND_EMAIL_VERIFICATION_BTN_CLICKED: null,
    PROFILE_CHANGE_EMAIL_RESEND_EMAIL_VERIFICATION_ERROR: null,
    PROFILE_CHANGE_EMAIL_RESEND_EMAIL_VERIFICATION_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_TOKEN_VERIFICATION_ERROR: null,
    PROFILE_CHANGE_EMAIL_TOKEN_VERIFICATION_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_VERIFICATION_AFTER_BANKID_FINISH_ERROR: null,
    PROFILE_CHANGE_EMAIL_VERIFICATION_AFTER_BANKID_FINISH_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_VERIFICATION_BEGIN_ERROR: null,
    PROFILE_CHANGE_EMAIL_VERIFICATION_BEGIN_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_VERIFICATION_FINISH_ERROR: null,
    PROFILE_CHANGE_EMAIL_VERIFICATION_FINISH_SUCCESS: null,
    PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_BANKID_BTN_CLICKED: null,
    PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_DOKOBIT_BTN_CLICKED: null,
    PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_FREJA_EID_NON_SWEDISH_BTN_CLICKED: null,
    PROFILE_CHANGE_EMAIL_VERIFY_YOUR_ACCOUNT_WITH_FREJA_EID_SWEDISH_BTN_CLICKED: null,
    PROFILE_CHANGE_NAME_ERROR: null,
    PROFILE_CHANGE_NAME_SUCCESS: null,
    PROFILE_CHANGE_PHONE_ERROR: null,
    PROFILE_CHANGE_PHONE_SUCCESS: null,
    PROFILE_DELETE_USER_ACCOUNT_BTN_CLICKED: null,
    PROFILE_DELETE_USER_ACCOUNT_ERROR: null,
    PROFILE_DELETE_USER_ACCOUNT_SUCCESS: null,
    PROFILE_EMAIL_VERIFICATION_BEGIN_ERROR: null,
    PROFILE_EMAIL_VERIFICATION_BEGIN_SUCCESS: null,
    PROFILE_EMAIL_VERIFICATION_CONTINUE_BTN_CLICKED: null,
    PROFILE_EMAIL_VERIFICATION_END_ERROR: null,
    PROFILE_EMAIL_VERIFICATION_END_SUCCESS: null,
    PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_BTN_CLICKED: null,
    PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_ERROR: null,
    PROFILE_EMAIL_VERIFICATION_RESEND_EMAIL_SUCCESS: null,
    PROFILE_GET_ALL_USER_PERMISSIONS_ERROR: null,
    PROFILE_GET_ALL_USER_PERMISSIONS_SUCCESS: null,
    PROFILE_GET_STRONG_AUTH_INFO_ERROR: null,
    PROFILE_GET_STRONG_AUTH_INFO_SUCCESS: null,
    PROFILE_GET_USER_ACCOUNT_DEPENDENCIES_ERROR: null,
    PROFILE_GET_USER_ACCOUNT_DEPENDENCIES_SUCCESS: null,
    PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_EMAIL_BTN_CLICKED: null,
    PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_ERROR: null,
    PROFILE_MERGE_EMAIL_VERIFICATION_RESEND_SUCCESS: null,
    PROFILE_PASSWORD_CHANGE_ERROR: null,
    PROFILE_PASSWORD_CHANGE_SUCCESS: null,
    PROFILE_PASSWORD_RESET_CHECK_TOKEN_ERROR: null,
    PROFILE_PASSWORD_RESET_CHECK_TOKEN_SUCCESS: null,
    PROFILE_PASSWORD_RESET_ERROR: null,
    PROFILE_PASSWORD_RESET_SEND_EMAIL_ERROR: null,
    PROFILE_PASSWORD_RESET_SEND_EMAIL_SUCCESS: null,
    PROFILE_PASSWORD_RESET_SUCCESS: null,
    PROFILE_UPDATE_STRONG_AUTH_INFO_ERROR: null,
    PROFILE_UPDATE_STRONG_AUTH_INFO_SUCCESS: null,
    PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_BTN_CLICKED: null,
    PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_ERROR: null,
    PROFILE_UPDATE_USERNAME_WITH_STRONG_AUTH_INFO_SUCCESS: null,
    PROFILE_USER_ACCOUNT_DELETED_SUCCESS: null,
    PROFILE_YOUR_DETAILS_PERMISSIONS_ROW_CLICKED: null,
    REDIRECT_TO_EXTERNAL_APP_ERROR: null,
    REDIRECT_TO_EXTERNAL_APP_SUCCESS: null,
    REDIRECT_TO_SERVICE_BTN_CLICKED: null,
    REGISTRATION_AFTER_MID_VERIFICATION_CHECK_TOKEN_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_CHECK_TOKEN_SUCCESS: null,
    REGISTRATION_AFTER_MID_VERIFICATION_CREATE_ACCOUNT_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_CREATE_ACCOUNT_SUCCESS: null,
    REGISTRATION_AFTER_MID_VERIFICATION_GET_EMAIL_FROM_TOKEN_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_GET_EMAIL_FROM_TOKEN_SUCCESS: null,
    REGISTRATION_AFTER_MID_VERIFICATION_GET_PERSON_INFO_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_GET_PERSON_INFO_SUCCESS: null,
    REGISTRATION_AFTER_MID_VERIFICATION_GET_TRANSFER_TOKEN_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_GET_TRANSFER_TOKEN_SUCCESS: null,
    REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_EMAIL_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_EMAIL_SUCCESS: null,
    REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_NIN_ERROR: null,
    REGISTRATION_AFTER_MID_VERIFICATION_VERIFY_NIN_SUCCESS: null,
    REGISTRATION_COMPLETE_REGISTRATION_SUCCESS_MSG_TIME_PASSED: null,
    REGISTRATION_COMPLETE_TRANSFER_ERROR: null,
    REGISTRATION_COMPLETE_TRANSFER_SUCCESS: null,
    REGISTRATION_GET_INVITATION_INFORMATION_ERROR: null,
    REGISTRATION_GET_INVITATION_INFORMATION_SUCCESS: null,
    REGISTRATION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS_ERROR: null,
    REGISTRATION_INITIATE_STRONG_AUTH_FOR_ANONYMOUS_SUCCESS: null,
    REGISTRATION_STEP1_FLOW2_SUBMIT_ERROR: null,
    REGISTRATION_STEP1_FLOW2_SUBMIT_SUCCESS: null,
    REGISTRATION_STEP1_RESEND_ACCOUNT_ACTIVATION_LINK_FOR_MANUAL_ID_USER: null,
    REPRESENTED_ORG_BTN_CLICKED: null,
    RESEND_ACCOUNT_ACTIVATION_LINK_BACK_BTN_CLICKED: null,
    RESEND_ACCOUNT_ACTIVATION_LINK_ERROR: null,
    RESEND_ACCOUNT_ACTIVATION_LINK_SUCCESS: null,
    ROUTE_ENTERED: null,
    SEARCH_ALL_USERS_GET_ERROR: null,
    SEARCH_ALL_USERS_GET_SUCCESS: null,
    SEARCH_CPB_USERS_GET_ERROR: null,
    SEARCH_CPB_USERS_GET_SUCCESS: null,
    SEARCH_USERS_GLOBALLY_GET_ERROR: null,
    SEARCH_USERS_GLOBALLY_GET_SUCCESS: null,
    SEARCH_USERS_GLOBALLY_LIST_ROW_CLICKED: null,
    SIGN_IN_BTN_CLICKED: null,
    SIGN_OUT_BTN_CLICKED: null,
    TOP_MENU_OR_DASHBOARD_ITEM_CLICKED: null,
    UPDATE_USER_INFO_ERROR: null,
    UPDATE_USER_INFO_SUCCESS: null,
    VALIDATE_PERSONAL_NUMBER_ERROR: null,
    VALIDATE_PERSONAL_NUMBER_SUCCESS: null
});

export default ActionTypes;
