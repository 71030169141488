import FormStore from '../base/FormStore.js';
import ActionTypes from '../../actions/ActionTypes';
import prepareUrl from '../../helpers/PrepareUrl.js';
import redirectToRoute from '../../actions/actionCreators/navigation/RedirectToRoute.js';
import getStrongAuthInfo from '../../actions/actionCreators/profile/strongAuth/GetStrongAuthInfo.js';
import updateStrongAuthInfo from '../../actions/actionCreators/profile/strongAuth/UpdateStrongAuthInfo.js';
import storeFormManager from '../validation/StoreFormManager.js';
import {
    requiredValidator,
    getMaxLengthValidator,
    getMinLengthValidator,
    getPersonalNumberValidator,
    getPersonalNumberMask
} from '@vaultit/stv-utils-frontend';
import constants from '../../Constants.js';


const storeKey = "updateStrongAuthInformation";

class UpdateStrongAuthInformationStore extends FormStore {

    getInitialState() {
        let initialState = {
            constants: {
                strongAuthErrorTypes: constants.strongAuthErrorTypes
            },
            visibleElements: {},
            signInUrl: prepareUrl.prepareAbsoluteUrl(prepareUrl.getSignInRelativeUrl()),
            showLoadingIndicator: false,
            showNINMismatchError: false,
            showFailedStrongAuthError: false,
            showcurrentNINAlreadyVerifiedError: false,
            currentRegistrationFlowSettings: {},
            showInformationAboutSPUpdate: false
        };

        let updateStrongAuthInformationFormFieldDefinitions = {
            firstName: {
                defaultValue: ''
            },
            lastName: {
                defaultValue: ''
            },
            country: {
                defaultValue: 'se',
                validators: [
                    requiredValidator
                ]
            },
            personalNumber: {
                validateOnAnyFieldChange: true,
                defaultValue: '',
                validators: [
                    requiredValidator,
                    getPersonalNumberValidator('country'),
                    getMaxLengthValidator(),
                    getMinLengthValidator(4)
                ],
                masks: [
                    getPersonalNumberMask('country')
                ]
            }
        };

        storeFormManager.defineForm(initialState, storeKey, "updateStrongAuthInformationForm", updateStrongAuthInformationFormFieldDefinitions, this.onUpdateStrongAuthInformationFormSubmitted);

        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/updatestrongauthinfo',
                loadHandler: this.onUpdateStrongAuthInformationLoaded
            }
        ];
    }

    onUpdateStrongAuthInformationLoaded(state, action, sharedStoreState) {
        state.showNINMismatchError = false;
        state.showFailedStrongAuthError = false;
        state.showcurrentNINAlreadyVerifiedError = false;
        state.showLoadingIndicator = true;
        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;
        sharedStoreState.showMinimalRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow;
        let currentRegistrationFlow = state.uiConfiguration.servicePortal.registrationSettings.registrationFlow;
        state.currentRegistrationFlowSettings = state.uiConfiguration.servicePortal.registrationSettings[currentRegistrationFlow];
        getStrongAuthInfo();

    }

    [ActionTypes.PROFILE_GET_STRONG_AUTH_INFO_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        if (action.result.reasonOfFailure === state.constants.strongAuthErrorTypes.mismatchBetweenValues) {
            state.showNINMismatchError = true;
        } else if (action.result.reasonOfFailure === state.constants.strongAuthErrorTypes.currentNINAlreadyVerified) {
            state.showcurrentNINAlreadyVerifiedError = true;
        } else if (action.result.reasonOfFailure === state.constants.strongAuthErrorTypes.strongAuthFailed) {
            state.showFailedStrongAuthError = true;
        }

        let duringNINVerificationErrorOccured = state.showNINMismatchError || state.showcurrentNINAlreadyVerifiedError || state.showFailedStrongAuthError;
        let displayMessageAboutSPUpdate = state.currentRegistrationFlowSettings.displayInfoAboutSPUpdate;
        state.showInformationAboutSPUpdate = displayMessageAboutSPUpdate && !duringNINVerificationErrorOccured;

        storeFormManager.setFormInitialState(state, "updateStrongAuthInformationForm");

        if (action.result.nationalIdentityNumber) {
            state.updateStrongAuthInformationForm.fields.personalNumber.value = action.result.nationalIdentityNumber;
        }

        state.updateStrongAuthInformationForm.fields.firstName.value = action.result.firstName;
        state.updateStrongAuthInformationForm.fields.lastName.value = action.result.lastName;
        if (action.result.nationalIdentityNumberCountry) {
            state.updateStrongAuthInformationForm.fields.country.value = action.result.nationalIdentityNumberCountry.toLowerCase();
        }

        state.visibleElements = {
            updateStrongAuthInformationForm: true
        };

    }

    [ActionTypes.PROFILE_GET_STRONG_AUTH_INFO_ERROR](state) {
        state.showLoadingIndicator = false;
        state.visibleElements = {};
    }

    onUpdateStrongAuthInformationFormSubmitted(state, values) {
        state.showLoadingIndicator = true;

        state.showFailedStrongAuthError = false;
        state.showcurrentNINAlreadyVerifiedError = false;
        state.showNINMismatchError = false;

        updateStrongAuthInfo(values.country, values.personalNumber);
    }

    [ActionTypes.PROFILE_UPDATE_STRONG_AUTH_INFO_SUCCESS](state, action) {
        state.showLoadingIndicator = false;

        if (action.result && action.result.ninIsAlreadyVerified) {
            state.showcurrentNINAlreadyVerifiedError = true;
        } else if (action.result.dokobitStrongAuthRequired || action.result.bankIdStrongAuthRequired) {
            redirectToRoute('/', true);
        }
    }

    [ActionTypes.PROFILE_UPDATE_STRONG_AUTH_INFO_ERROR](state) {
        state.showLoadingIndicator = false;
    }

}

export default UpdateStrongAuthInformationStore;
