/* System imports */
import React from 'react';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
/* Component related imports */
import StoreComponent from '../base/StoreComponent.jsx';
/* Form related imports */
import { ValidationMessages, PageHeader, FormRow, FormColumn, FormGroup, Message, TextInput } from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    button: {
        id: 'forgotPassword.button',
        description: 'Password reset button text',
        defaultMessage: 'Reset password'
    },
    placeholder: {
        id: 'forgotPassword.placeholder',
        description: 'Email address placeholder',
        defaultMessage: 'Your email address'
    }
});
const storeKey = 'passwordReset';

class ForgotPassword extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        /* Make sure form state is initialized. */
        let store = this.state ? this.state[storeKey] : {};
        let form = store.forgotPasswordForm;

        let fields = form ? form.fields : null;

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="forgotPassword.title"
                    sharedStore={this.state.shared}
                />

                { store.visibleElements.forgotPasswordForm
                    ? <div>
                        <p><FormattedMessage id="forgotPassword.description" /></p>
                        <form name="forgotPasswordForm">
                            <FormRow>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <ValidationMessages field={fields.emailAddress}>
                                            <TextInput
                                                id="forgot_password_email_address"
                                                field={fields.emailAddress}
                                                messageId="profile.activateAccount.email"
                                                defaultMessage="Your e-mail"
                                                autofocus={true}
                                                placeholder={formatMessage(messages.placeholder)}
                                            />
                                        </ValidationMessages>
                                    </FormGroup>
                                </FormColumn>
                                <FormColumn width="6">
                                    <FormGroup>
                                        <label className="form-control-label d-block hidden-sm-down">&nbsp;</label>
                                        <input
                                            id="forgot_password_reset_password"
                                            className="btn btn-primary"
                                            type="submit"
                                            value={formatMessage(messages.button) || ""}
                                            onClick={form.handleOnSubmitBtnClicked}
                                            disabled={!store.forgotPasswordResendEmailButtonEnabled}
                                            role="button"
                                        />
                                    </FormGroup>
                                </FormColumn>
                            </FormRow>
                        </form>
                    </div>
                    : null
                }

                { store.visibleElements.sendSucceeded
                    ? <Message id="SuccessMessage" type="success">
                        <FormattedMessage id="forgotPassword.success" />
                    </Message>
                    : null
                }

            </div>
        );
    }
}

export default ForgotPassword;
