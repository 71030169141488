import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

const completeTransfer = function (token) {
    postToBackend(`/registration/transfer/complete/${token}`,
        ActionTypes.REGISTRATION_COMPLETE_TRANSFER_SUCCESS,
        ActionTypes.REGISTRATION_COMPLETE_TRANSFER_ERROR);
};

export default completeTransfer;
