import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';

import { intlShape, defineMessages, FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';

import continueAfterVerificationBtnClicked from '../../actions/actionCreators/profile/emailVerification/ContinueAfterVerificationBtnClicked.js';
import resendVerificationEmailBtnClicked from '../../actions/actionCreators/profile/emailVerification/ResendVerificationEmailBtnClicked.js';
import resendMergeVerificationEmailBtnClicked from '../../actions/actionCreators/profile/emailVerification/ResendMergeVerificationEmailBtnClicked.js';
import signInBtnClicked from '../../actions/actionCreators/menu/SignInBtnClicked.js';

const messages = defineMessages({
    continue: {
        id: 'emailverification.continue',
        description: 'Email verification continue button text',
        defaultMessage: 'Continue'
    },
    here: {
        id: 'emailverification.verificationNeededEditInfo.here',
        description: 'Edit personal details link "here" text',
        defaultMessage: 'here'
    },
    signInWithBankIdLink: {
        id: 'errors.emailverification.sessionMismatch.signInLink',
        description: 'Sign in with bank id link text',
        defaultMessage: 'sign in with BankID'
    },
    signInWithDokobitLink: {
        id: 'errors.emailverification.sessionMismatch.dokobitSignInLink',
        description: 'Sign in with dokobit link text',
        defaultMessage: 'Mobile-ID, SmartID (non Swedish users)'
    }
});

class EmailVerification extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters && this.state.shared.authInfo.topMenuParameters
            ? this.state.shared.authInfo.topMenuParameters.configuration
            : null;

        let brandLogo = uiConfiguration && uiConfiguration.servicePortal.registrationSettings.showMinimalRegistrationFlow
            ? <span className="brand-logo"></span>
            : null;

        let activateAccountVerificationNeededLine1IsVisible = uiConfiguration
            ? uiConfiguration.textsVisibility.activateAccountVerificationNeededLine1IsVisible
            : true;

        let emailAddress = ""
        if (this.state.shared.profile != null) {
            emailAddress = <b>{this.state.shared.profile.username}</b>
        }

        if (this.state && this.state.menuItems && this.state.menuItems.topMenu
            && this.state.menuItems.topMenu.signedInUserEmail != null ) {
            emailAddress = <b>{this.state.menuItems.topMenu.signedInUserEmail}</b>;
        } else if (this.state && this.state.shared && this.state.shared.emailToVerify != null) {
            emailAddress = <b>{this.state.shared.emailToVerify}</b>;
        }

        let eIdSignInUrlMessage = null;

        if (this.state.emailVerification.visibleElements.showMessageThatUserNeedsToLoginWithBankId) {
            if (this.state.emailVerification.signInWithBankId) {
                eIdSignInUrlMessage = formatMessage(messages.signInWithBankIdLink);
            }
            if (this.state.emailVerification.signInWithDokobit) {
                eIdSignInUrlMessage = formatMessage(messages.signInWithDokobitLink);
            }
        }
        return (
            <div>
                { brandLogo }
                <PageHeader
                    pageTitleMessageId="emailverification.title"
                    sharedStore={this.state.shared}
                />

                { this.state.emailVerification.visibleElements.verificationNeededMsg
                    ? <div>
                        { activateAccountVerificationNeededLine1IsVisible
                            ?
                                <p>
                                    <FormattedMessage
                                        id="emailverification.verificationNeededLine1"
                                        description="Email verification needed info message line 1"
                                        defaultMessage="To continue, we need to check that the e-mail address {email} is yours."
                                        values={{email: emailAddress}}
                                    />
                                </p>
                            : null
                        }
                        <p>
                            <FormattedMessage
                                id="emailverification.verificationNeededLine2"
                                description="Email verification needed info message line 2"
                                defaultMessage="Open the email we sent you and click the verification link."
                                values={{email: emailAddress}}
                            />
                        </p>
                        <hr />
                        {this.state.emailVerification.showEditPersonalInformationLink
                        ?
                            <p>
                                <FormattedMessage
                                    id="emailverification.verificationNeededEditInfo"
                                    description="Email verification edit personal details message"
                                    defaultMessage="If you mistyped some of your data (e.g your email), you can edit your personal information {url}."
                                    values={{"url": <a href={this.state.emailVerification.editEnteredInfoUrl}>{formatMessage(messages.here)}</a>}}
                                />
                            </p>

                        : null}
                        <p>
                            <FormattedMessage
                                id="emailverification.verificationNeededLine3Part1"
                                description="Email verification needed info message line 3 part 1"
                                defaultMessage="In case you cannot find the email, check your spam folder. You can also "
                            />
                            { this.state.emailVerification.visibleElements.resendEmailBtn
                                ?
                                    <a id="resend_verification_email_link" href="#" onClick={resendVerificationEmailBtnClicked}>
                                        <FormattedMessage
                                            id="emailverification.verificationNeededLine3Part2"
                                            description="Email verification needed info message line 3 part 2"
                                            defaultMessage="request the verification email again"
                                        />
                                    </a>
                                : null
                            }
                            { this.state.emailVerification.visibleElements.resendMergeEmailBtn
                                ?
                                    <a id="resend_merge_verification_email_link" href="#" onClick={resendMergeVerificationEmailBtnClicked}>
                                        <FormattedMessage
                                            id="emailverification.verificationNeededLine3Part2"
                                            description="Email verification needed info message line 3 part 2"
                                            defaultMessage="request the verification email again"
                                        />
                                    </a>
                                : null
                            }
                            { this.state.emailVerification.visibleElements.resendEmailOnlyText
                                ?
                                    <span>
                                        <a href={this.state.emailVerification.signInUrl}>
                                            <FormattedMessage
                                                id="emailverification.verificationNeededLine3Part2OnlyText.signin"
                                                defaultMessage="sign in"
                                            />
                                        </a>
                                        <span>&nbsp;</span>
                                        <FormattedMessage
                                            id="emailverification.verificationNeededLine3Part2OnlyText"
                                            description="Email verification needed info message line 3 part 2"
                                            defaultMessage="and request the verification email again"
                                        />
                                    </span>
                                : null
                            }
                            <FormattedMessage
                                id="emailverification.verificationNeededLine3Part3"
                                description="Email verification needed info message line 3 part 3"
                                defaultMessage=". If you run into any problems, you can always contact "
                            />
                            &nbsp;
                            <a href={'mailto:' + uiConfiguration.customerSupportEmail}>
                                <FormattedMessage
                                    id="emailverification.verificationNeededLine3Part4"
                                    description="Email verification needed info message line 3 part 4"
                                    defaultMessage="our customer service."
                                />
                            </a>
                        </p>
                    </div>
                    : null }

                { this.state.emailVerification.visibleElements.verificationEmailResentSuccessfully
                    ? <div>
                        <Message id="SuccessMessage-1" type="success">
                            <FormattedMessage
                                id="emailverification.verificationEmailResentSuccessfully"
                                description="Verification email was sent successfully"
                                defaultMessage="Verification email was sent successfully. Please check your e-mail, and click the Verify link in the email we just sent."
                            />
                        </Message>
                    </div>
                    : null }

                { this.state.emailVerification.visibleElements.alreadyVerifiedUserNotLoggedIn
                    ? <div>
                        <Message id="SuccessMessage-2" type="success">
                            <FormattedMessage
                                id="emailverification.alreadyVerifiedUserNotLoggedIn"
                                description="Email is already verified - user is not yet logged in"
                                defaultMessage="Your email address is already verified. Please sing in to continue registration."
                            />
                        </Message>
                        { this.state.emailVerification.visibleElements.showContinueButtonForAlreadyVerifiedUserNotLoggedIn
                            ?
                                <input
                                    id="email_verification_already_verified_sign_in"
                                    type="submit"
                                    className="btn btn-primary btn-lg mt-6"
                                    value={formatMessage(messages.continue) || ""}
                                    onClick={signInBtnClicked}
                                    role="button"
                                />
                            : null
                        }
                    </div>
                    : null }

                { this.state.emailVerification.visibleElements.alreadyVerifiedUserLoggedInAndContinueBtn
                    ? <div>
                        { this.state.emailVerification.visibleElements.userNeedsToVerifyIdentity
                            ?
                                <Message id="SuccessMessage-3" type="success">
                                    <FormattedMessage
                                        id="emailverification.alreadyVerifiedUserLoggedInContinueRegistration"
                                        description="Email is already verified - user is logged in"
                                        defaultMessage="Your email address is already verified. You may continue registration."
                                    />
                                </Message>
                            :
                            <Message id="SuccessMessage-3" type="success">
                                <FormattedMessage
                                    id="emailverification.alreadyVerifiedUserLoggedIn"
                                    description="Email is already verified - user is logged in"
                                    defaultMessage="Your email address is already verified."
                                />
                            </Message>
                        }
                        <input
                            id="email_verification_continue_registration_btn"
                            type="submit"
                            className="btn btn-primary btn-lg mt-6"
                            value={formatMessage(messages.continue) || ""}
                            onClick={continueAfterVerificationBtnClicked}
                            role="button"
                        />
                    </div>
                    : null }

                { this.state.emailVerification.visibleElements.emailVerificationSuccessMessageAndContinueBtn
                    ? <div>
                        { this.state.emailVerification.visibleElements.userNeedsToVerifyIdentity
                            ?
                                <Message id="SuccessMessage-3" type="success">
                                    <FormattedMessage
                                        id="emailverification.successfullyVerifiedUserLoggedInVerifyIdentity"
                                        defaultMessage="Your email address has been successfully verified. Click continue to verify your identity."
                                    />
                                </Message>
                            :
                            <Message id="SuccessMessage-3" type="success">
                                <FormattedMessage
                                    id="emailverification.successfullyVerifiedUserLoggedIn"
                                    defaultMessage="Your email address has been successfully verified.."
                                />
                            </Message>
                        }

                        <input
                            id="email_verification_continue_registration_btn"
                            type="submit"
                            className="btn btn-primary btn-lg mt-6"
                            value={formatMessage(messages.continue) || ""}
                            onClick={continueAfterVerificationBtnClicked}
                            role="button"
                        />
                    </div>
                    : null }

                { this.state.emailVerification.visibleElements.showMessageThatUserNeedsToLoginWithBankId
                    ?
                        <Message id="login-with-bank-id" type="danger">
                            <FormattedMessage
                                id="errors.emailverification.sessionMismatch"
                                values={{
                                    signInWithEIdLink: (<a href={this.state.emailVerification.eIdSignInUrl}>{ eIdSignInUrlMessage }</a>)
                                }}
                                defaultMessage="Email verification error - please {signInWithEIdLink} and verify provided email in same browser session."
                            />
                        </Message>
                    : null
                }
            </div>
        );
    }
}

export default EmailVerification;
