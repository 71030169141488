import React from 'react';
import {PageHeader} from '@vaultit/stv-utils-frontend';
import StoreComponent from './base/StoreComponent.jsx';

class InfoPage extends StoreComponent {

    renderWhenReady() {

        let uiConfiguration = this.state.shared.authInfo.topMenuParameters.configuration;
        let currentLanguage = this.state.shared.localizations.messagesLanguage;
        let infoData = uiConfiguration.servicePortal.info;

        if (!infoData.enabled) {
            return null;
        }

        return (
            <div>
                <PageHeader
                    pageTitleMessageId="infoPage.pageTitle"
                    sharedStore={this.state.shared}
                />
                { uiConfiguration && infoData
                    ?
                        <table className="table table-striped table-hover mt-6">
                            <tbody>
                                {
                                    Object.keys(infoData).map((value, index) => {
                                        let infoObj = infoData[value];

                                        if (infoObj instanceof Object) {
                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <a href={infoObj.link[currentLanguage]} target="_blank" rel="noopener noreferrer">
                                                            <i className="fa fa-external-link fa-mr" aria-hidden="true"></i>
                                                            {infoObj.title[currentLanguage]}
                                                        </a>
                                                    </td>
                                                    <td>{infoObj.description[currentLanguage]}</td>
                                                </tr>
                                            );
                                        }
                                    })
                                }
                            </tbody>
                        </table>
                    : null
                }
            </div>
        );
    }
}

export default InfoPage;
