import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';


let redirectToServiceBtnClicked = function(data) {
    dispatcher.dispatch({
        type: ActionTypes.REDIRECT_TO_SERVICE_BTN_CLICKED,
        serviceName: data
    });
};

export default redirectToServiceBtnClicked;