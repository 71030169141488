import BaseStore from './base/BaseStore.js';
import ActionTypes from '../actions/ActionTypes';
import prepareUrl from '../helpers/PrepareUrl.js';
import redirectManager from '../singletons/RedirectManager.js';
import redirectToRoute from '../actions/actionCreators/navigation/RedirectToRoute.js';
import log from '../singletons/Log.js';


class MenuItemsStore extends BaseStore {

    getInitialState() {
        return {
            subMenu: {
                userCan: {}
            },
            orgMenu: {

            },
            modalInfo:{
                organisationName: null,
                serviceName: null,
                explanationUrl: null
            },
            proceedToOrgModal: {
                organisationName: null,
                actionName: null,
                organisationId: null
            },
            serviceToRedirectUrl: null,
            serviceToRedirectName: null,
            currentCompanyName: null,
            topMenuActionName:null,
            callTopMenuClickedFunction: false,
            showUserInactiveAccountMessage: false,
            uiConfiguration: null,
            eperehdytysMessageIsVisible: false
        };
    }

    [ActionTypes.AUTH_INFO_GET_SUCCESS](state, action, sharedStoreState) {

        state.showUserInactiveAccountMessage = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_SHOW_ACCOUNT_INACTIVE_MESSAGE');
        state.eperehdytysMessageIsVisible = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_INFORM_EPEREHDYTYS_USER');

        if (state.callTopMenuClickedFunction) {
            this.TOP_MENU_OR_DASHBOARD_ITEM_CLICKED(state, {"data":state.topMenuActionName}, sharedStoreState);
        }
    }

    [ActionTypes.AUTH_INFO_GET_ERROR]() {
    }

    [ActionTypes.TOP_MENU_OR_DASHBOARD_ITEM_CLICKED](state, action, sharedStoreState) {
        state.callTopMenuClickedFunction = false;

        let service_behaviour_code = sharedStoreState.authInfo && sharedStoreState.authInfo.topMenuParameters.configuration.services[action.data]
         && sharedStoreState.authInfo.topMenuParameters.configuration.services[action.data].exceptional_behaviour_in_top_menu
            ? sharedStoreState.authInfo.topMenuParameters.configuration.services[action.data].exceptional_behaviour_in_top_menu
            : "";

        if (service_behaviour_code === 'warning_about_main_representation') {
            this.redirectUserToValvojaOrRaportti(state, action, sharedStoreState);
        } else if (service_behaviour_code === 'warning_about_redirecting_to_main_org_context' ) {
            this.redirectUserToIlmoitaOrBuildigSiteRegister(state, action, sharedStoreState);
        }

        let userName = sharedStoreState.authInfo && sharedStoreState.authInfo.userProfile ? sharedStoreState.authInfo.userProfile.username : 'anonymous';

        if (action.data == "top_menu_org_info_new_organization") {
            log.logIndividualAction({
                'message': `User - '${userName}' clicked 'Register a new company' in top menu`
            });
        }

        if (action.data == "top_menu_my_cards") {
            log.logIndividualAction({
                'message': `User - '${userName}' clicked 'MY CARDS' in top menu`
            });
        }
    }

    [ActionTypes.REPRESENTED_ORG_BTN_CLICKED](state, action, sharedStoreState) {
        let newOrgCode = sharedStoreState.authInfo.topMenuParameters.allRepresentationCodes[action.index];
        redirectToRoute("/", false, false, newOrgCode);
    }

    [ActionTypes.REDIRECT_TO_SERVICE_BTN_CLICKED](state) {
        redirectManager.registerNeededRedirect(state.serviceToRedirectUrl, false);
    }

    [ActionTypes.PROCEED_TO_COMPANY_BTN_CLICKED](state, action) {
        redirectToRoute("/dashboard", false, false, action.company, false);
        state.topMenuActionName = action.serviceName;
        state.callTopMenuClickedFunction = true;
    }

    redirectUserToValvojaOrRaportti(state, action, sharedStoreState) {

        state.modalInfo.organisationName = sharedStoreState.authInfo.otherUiInfo.mainOrgName;

        let isMainOrganisation = this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_REDIRECT_USER_TO_SERVICE');

        state.uiConfiguration = sharedStoreState.authInfo.topMenuParameters.configuration;

        let langCode = sharedStoreState.authInfo.topMenuParameters.selectedLanguageCode;
        let syncId = sharedStoreState.authInfo.topMenuParameters.selectedRepresentedSyncId;

        state.serviceToRedirectUrl = action.data == "reports"
            ? state.uiConfiguration.services.reports.url + state.uiConfiguration.services.reports.relativePages.report.replace('[SYNC_ID]', syncId).replace('[LANG]', langCode)
            : state.uiConfiguration.services.valvoja.url + state.uiConfiguration.services.valvoja.relativePages.supervisor;

        if (isMainOrganisation) {
            redirectManager.registerNeededRedirect(state.serviceToRedirectUrl, false);
        } else {
            state.modalInfo.serviceName = action.data;

            if (sharedStoreState.representedOrganizationCode) {
                state.modalInfo.explanationUrl = prepareUrl.prepareRouteUrl('/users/notsupportedpermission', false, sharedStoreState.representedOrganizationCode);
            } else {
                state.modalInfo.explanationUrl = prepareUrl.prepareRouteUrl('/explanation', false);
            }
        }
    }

    redirectUserToIlmoitaOrBuildigSiteRegister(state, action, sharedStoreState) {
        if (sharedStoreState.authInfo.topMenuParameters.selectedRepresentedOrganizationId) {

            if (state.uiConfiguration.services.ilmoita) {

                let langCode = sharedStoreState.authInfo.topMenuParameters.selectedLanguageCode;
                let syncId = sharedStoreState.authInfo.topMenuParameters.selectedRepresentedSyncId;

                state.serviceToRedirectUrl = action.data == "ilmoita"
                    ? state.uiConfiguration.services.ilmoita.url + state.uiConfiguration.services.ilmoita.relativePages.report.replace('[SYNC_ID]', syncId).replace('[LANG]', langCode)
                    : state.uiConfiguration.services.ilmoita.url + state.uiConfiguration.services.ilmoita.relativePages.register.replace('[SYNC_ID]', syncId).replace('[LANG]', langCode);

            }

            redirectManager.registerNeededRedirect(state.serviceToRedirectUrl, false);

        } else {
            if (this.userHasPermission(sharedStoreState, 'SEPPO_ACTION_SHOW_COMPANY_REDIRECTION_MODAL')) {

                state.proceedToOrgModal.actionName = action.data;
                state.proceedToOrgModal.organisationId = sharedStoreState.authInfo.otherUiInfo.orgCodeToRedirectToByDefault;
                state.proceedToOrgModal.organisationName = sharedStoreState.authInfo.otherUiInfo.orgCodeToRedirectToByDefaultName;

            } else {
                redirectToRoute('/dashboard',false,false,sharedStoreState.authInfo.otherUiInfo.orgCodeToRedirectToByDefault, false);
                state.topMenuActionName = action.data;
                state.callTopMenuClickedFunction = true;
            }
        }
    }

    [ActionTypes.DISPLAY_LOADING_INDICATOR](state, action, sharedStoreState) {
        sharedStoreState.loadingIndicatorIsVisible = true;
    }
}

export default MenuItemsStore;
