import ActionTypes from '../../../ActionTypes.js';
import dispatcher from '../../../../Dispatcher.js';


let changeEmailGlobalSearchResendVerificationBtnClicked = function(event) {
        event.preventDefault();
        dispatcher.dispatch({
            type: ActionTypes.GLOBAL_SEARCH_CHANGE_EMAIL_RESEND_VERIFICATION_BTN_CLICKED
        });
    };

export default changeEmailGlobalSearchResendVerificationBtnClicked;