import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getOrganizationUsers = function(orgId) {
    getFromBackend('/users/' + orgId + '/user-list',
        ActionTypes.ORGANIZATION_USERS_GET_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_GET_ERROR);
};

export default getOrganizationUsers;
