import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import getDeleteBtnClickedHandler from '../../actions/actionCreators/users/GetDeleteBtnClickedHandler.js';
import okBtnClicked from '../../actions/actionCreators/generic/confirmationModal/OkBtnClicked.js';
import AdminPanel from '../common/AdminPanel.jsx';
import { Message, PageHeader, FormRow, FormTable, FormColumn, FormValidationMessage, ValidationMessages, VisibilityComponent,
    CheckboxInput, RadioInput, ConfirmationModal, DateLabel } from '@vaultit/stv-utils-frontend';
import generatePasswordBtnClicked from '../../actions/actionCreators/users/GeneratePasswordBtnClicked.js';
import generateEmailLinkBtnClicked from '../../actions/actionCreators/users/GenerateEmailLinkBtnClicked.js';
import generateInvitationLinkBtnClicked from '../../actions/actionCreators/users/GenerateInvitationLinkBtnClicked.js';
import {CopyToClipboard} from 'react-copy-to-clipboard';

const messages = defineMessages({
    confirmationModalTitle: {
        id: 'users.editing.remove.permissions.confirmation.modal.title',
        description: 'Confirmation modal title',
        defaultMessage: 'Remove user'
    },
    confirmationModalOk: {
        id: 'users.editing.remove.permissions.confirmation.modal.ok',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, remove the permissions'
    },
    confirmationModalYesContinue: {
        id: 'users.editing.remove.permissions.confirmation.modal.yesContinue',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, continue'
    },
    confirmationModalCancel: {
        id: 'users.editing.remove.permissions.confirmation.modal.cancel',
        description: 'Confirmation modal cancel button text',
        defaultMessage: 'No, cancel'
    },
    generatePswResetConfirmationTitle: {
        id: 'users.editing.generatePswConfirmationTitle',
        description: 'Password recovery modal title',
        defaultMessage: 'Generate a password reset link'
    },
    generateLinkGenerateBtn: {
        id: 'users.editing.generatePswConfirmationYesBtn',
        description: 'Password recovery modal continue button',
        defaultMessage: 'Yes, generate'
    },
    generateLinkCancelGenerateBtn: {
        id: 'users.editing.generatePswConfirmationNoBtn',
        description: 'Password recovery modal cancel button',
        defaultMessage: 'Cancel'
    },
    linkGeneratedPasswordTitle: {
        id: 'users.editing.passwordLinkGenerated',
        description: 'Password link generated title',
        defaultMessage: 'Password reset link generated'
    },
    linkGeneratedInvitationTitle: {
        id: 'users.editing.invitationLinkGenerated',
        description: 'Invitation link generated title',
        defaultMessage: 'Invitation link generated'
    },
    linkGeneratedEmailTitle: {
        id: 'users.editing.emailLinkGenerated',
        description: 'Email verification link generated titile',
        defaultMessage: 'Email verification link generated'
    },
    linkGeneratedCancelBtn: {
        id: 'users.editing.linkGeneratedCancelBtn',
        description: 'Generated link modal window cancel btn',
        defaultMessage: 'Cancel'
    },
    generateEmailLinkConfirmationTitle: {
        id: 'users.editing.generateEmailConfirmationTitle',
        description: 'Email verification modal title',
        defaultMessage: 'Generate an email verification link'
    },
    generateInvitationLinkTitle: {
        id: 'users.editing.invitationLinkModalTitle',
        description: 'Ivitation link generation modal window title',
        defaultMessage: 'Generate an invitation link'
    },
    spAdminDescription: {
        id: 'users.adding.spAdminPermission.description',
        description: 'Description for sp_admin permission',
        defaultMessage: 'Permission to manage organisation data, services and global permissions.'
    }
});

class EditingUser extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    displayPermissions() {
        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let permissions = [];
        let form = this.state.users.editingUserForm;

        for (var serviceName in configuration.services) {
            let service = configuration.services[serviceName];
            let joinedByPermissionService = service.joinedByPermission && configuration.services[service.joinedByPermission] ? configuration.services[service.joinedByPermission] : null;
            let isVisible = this.state.users.servicesManagedByCurrentUser.permissionCheckboxIsVisibleForCurrentUser[serviceName] && service['permission'];
            let permissionManagedByOtherService = service['permissionManagedByOtherService'] ? service['permissionManagedByOtherService'] : false;
            let permissionOptionIsVisible = isVisible && !permissionManagedByOtherService;
            let isDisabled = !this.state.users.servicesManagedByCurrentUser.permissionCanBeManagedByCurrentUser[serviceName] || !this.state.users.servicesManagedByCurrentUser.permissionCheckboxIsEnabledForCurrentUser[serviceName] || this.state.users.disabledOptionForInactiveOrgUser;
            let isNoticeVisible = this.state.users.servicesManagedByCurrentUser.showNoticeMsgNextToCheckboxForCurrentUser[serviceName];
            let userPermission = joinedByPermissionService ? service['permission'] + ' ' + joinedByPermissionService['permission'] : service['permission'];

            if (permissionOptionIsVisible) {

                let serviceTitle = joinedByPermissionService
                    ? service['title'][currentLanguageCode] + ', ' + joinedByPermissionService['title'][currentLanguageCode]
                    : service['title'][currentLanguageCode];
                let description = service['description'] && service['description'][currentLanguageCode] ? service['description'][currentLanguageCode] : '';
                let formField = form.fields['permissionToUse' + serviceName.charAt(0).toUpperCase() + serviceName.slice(1)];
                let noticeMessage = service['input_notice_message'] && service['input_notice_message'][currentLanguageCode] ? service['input_notice_message'][currentLanguageCode] : '';

                permissions.push(
                    <FormRow key={serviceName}>
                        <FormColumn width="3">
                            <CheckboxInput
                                id={"users_editing_user_permissions_" + serviceName.toLowerCase()}
                                value={userPermission}
                                checked={formField.value}
                                field={formField}
                                labelClassName="m-0"
                                name="user_permissions"
                                bold={true}
                                disabled={isDisabled}
                            >
                                { serviceTitle }
                            </CheckboxInput>
                        </FormColumn>
                        <FormColumn width="9">
                            <p>{ description }</p>
                            { isNoticeVisible
                                ?
                                    <p className="text-danger small">
                                        { noticeMessage }
                                    </p>
                                : null
                            }
                        </FormColumn>
                    </FormRow>
                );
            }
        }
        return permissions;
    }

    renderWhenReady() {

        const { formatMessage } = this.context.intl;

        let form = this.state.users.editingUserForm;

        let fields = form ? form.fields : null;

        let deleteUserConfirmationMessage = null;
        let deleteUserOkBtnMessage = messages.confirmationModalOk;
        let changingUserTypeConfirmationMessage = null;

        let permissionsManagedBySuperAdmin = this.state.shared.classifiers.permissionsManagedBySuperAdmin;
        let permissionsManagedByBasicAdmin = this.state.shared.classifiers.permissionsManagedByBasicAdmin;
        let permissionsManagedBySuperAdminOnly = this.state.shared.classifiers.permissionsManagedBySuperAdminOnly;
        let showAdminPanel = this.state.users.showAdminPanelInEditForm;

        let userMainOrg = null;
        let userMainOrganisationInfo = this.state.users.userMainOrganisationInfo;

        if (userMainOrganisationInfo.orgName && userMainOrganisationInfo.orgCode && userMainOrganisationInfo.orgCountryCode) {
            userMainOrg = `${userMainOrganisationInfo.orgName}, ${userMainOrganisationInfo.orgCode} (${userMainOrganisationInfo.orgCountryCode})`;
        }

        let generatedLinkModalWindowTitle = this.state.users.showEmailVerificationLinkModal
            ? formatMessage(messages.linkGeneratedEmailTitle)
            : this.state.users.showPasswordResetLinkModal
            ? formatMessage(messages.linkGeneratedPasswordTitle)
            : this.state.users.showInvitationLinkModal
            ? formatMessage(messages.linkGeneratedInvitationTitle): '';

        let showGeneratedLinkModal = this.state.users.showEmailVerificationLinkModal || this.state.users.showPasswordResetLinkModal || this.state.users.showInvitationLinkModal;

        if (this.state.users.userModalData) {
            if (this.state.shared.profile
                && this.state.users.confirmationModalUserId === this.state.shared.profile.personId) {
                deleteUserConfirmationMessage = (
                    <FormattedMessage
                        id="users.editing.remove.yourPermissions.confirmation.modal.message"
                        defaultMessage="You are about to drop ALL your permissions to use and manage {organizationName}. Do you really want to continue?"
                        values={this.state.users.userModalData}
                    />
                );
                deleteUserOkBtnMessage = messages.confirmationModalYesContinue;
            } else {
                deleteUserConfirmationMessage = (
                    <FormattedMessage
                        id="users.editing.remove.permissions.confirmation.modal.message"
                        defaultMessage="Do you really want to remove all {organizationName} permissions from user {firstName} {lastName} ({email})?"
                        values={this.state.users.userModalData}
                    />
                );
            }

            changingUserTypeConfirmationMessage = (
                <FormattedMessage
                    id="users.editing.changingMainTypeConfirmation"
                    defaultMessage="You are about to drop your permission to manage {organizationName} users and permissions. Do you really want to continue?"
                    values={this.state.users.userModalData}
                />
            );
        }

        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;
        let serviceCode = configuration['servicePortalServiceCode'];

        return (<div className="users-management">
            <h3 className="mt-6 mb-0 back-to-list">
                <a id="users_editing_back_to_list_link" href={this.state.users.backToListUrl} className="hidden-sm-down">
                    <i className="fa fa-list fa-mr"></i>
                    <FormattedMessage
                        id="users.editing.backToList"
                        description="Back to list link"
                        defaultMessage="Back to list"
                    />
                </a>
                <div className="clearfix"></div>
            </h3>
            <PageHeader
                smallerPageTitle={false}
                hideSeparator={false}
                pageTitleMessageId="users.management.title"
                values={{organizationName: this.state.shared.representedOrgName}}
                sharedStore={this.state.shared}
            />
            <div className="clearfix"></div>
            { this.state.users.changeMainUserOrg.showSuccessMessage
                ?
                    <Message id="changed_main_user_org_success" type="success">
                        <FormattedMessage
                            id="users.changedMainOrg.successMessage"
                            defaultMessage="User main organisation for Raportti and Valvoja services successfully changed."
                        />
                    </Message>
                : null
            }


            { this.state.users.showEditingUserForm
                ? <div>
                    <h3 id="users_editing_user_name">
                        {
                            fields.isActive.value
                            ? (fields.name.value)
                            : (<FormattedMessage id="users.editing.pendingInvitation" defaultMessage="(Pending invitation)" />)
                        }
                        &nbsp;({fields.email.value})
                        {
                            !fields.isActive.value && fields.firstInvitedAt.value
                            ?
                                <p id="users_list_first_invited_at" className="small text-muted">
                                    <FormattedMessage
                                        id="users.list.title.firstinvited"
                                        defaultMessage="First invited at"
                                    />:&nbsp;
                                    <DateLabel id="first-invited-at" format="YYYY-MM-DD HH:mm">{fields.firstInvitedAt.value}</DateLabel>
                                </p>
                            : null
                        }
                    </h3>
                    <hr />

                    <AdminPanel isAdmin={showAdminPanel}>
                        {this.state.users.showChangeMainOrgForRapportiValvojaServices
                         ?
                             <a id="go_to_change_main_user_org" href={this.state.users.changeMainOrgUrl}>
                                 <FormattedMessage id="users.editing.changeMainOrg" defaultMessage="Change the main organisation for Raportti and Valvoja services - now" />
                                 {userMainOrg}
                             </a>
                        :null}

                        {this.state.users.showGenerateEmailVerificationLink
                        ?
                            <p className="mb-0">
                                <a
                                    id="generate_new_email_verification_link"
                                    href="#edit_user_generate_email_verification_modal_window"
                                    data-toggle="modal"
                                >
                                    <FormattedMessage
                                        id="users.editing.generateEmailLink"
                                        defaultMessage="Generate an email verification link"
                                    />
                                </a>
                            </p>
                        : null
                        }

                        {this.state.users.showGenerateInvitationLink
                        ?
                            <p className="mb-0">
                                <a
                                    id="generate_invitation_link"
                                    href="#edit_user_generate_invitation_link_modal_window"
                                    data-toggle="modal"
                                >
                                    <FormattedMessage
                                        id="users.editing.generateInvitationLinkText"
                                        defaultMessage="Generate an invitation link"
                                    />
                                </a>
                            </p>
                        : null
                        }

                        {this.state.users.showGeneratePasswordResetLink && !this.state.users.disablePasswordResetLink
                        ?

                            <p className="mb-0">
                                <a
                                    id="generate_new_password_link"
                                    href="#edit_user_generate_password_modal_window"
                                    data-toggle="modal"
                                >
                                    <FormattedMessage
                                        id="users.editing.resetPasswordLink"
                                        defaultMessage="Generate a password reset link"
                                    />
                                </a>
                            </p>
                        : null
                        }

                        {this.state.users.showGeneratePasswordResetLink && this.state.users.disablePasswordResetLink
                        ?

                            <p className="mb-0">
                                <span className="text-muted">
                                    <FormattedMessage
                                        id="users.editing.resetPasswordLink"
                                        defaultMessage="Generate a password reset link"
                                    />
                                </span>
                                &nbsp;
                                <span className="text-muted ">
                                    (<FormattedMessage
                                        id="users.editing.passwordDissabledLinkMsg"
                                        defaultMessage="User has not begun his registration process - therefore password reset link cannot be generated for such user"
                                     />.)
                                </span>
                            </p>
                        : null
                        }

                    </AdminPanel>

                    <form name="editingUserForm" noValidate="">
                        <VisibilityComponent isVisible={!this.state.users.canEditOnlyIlmoita}>
                            <h3>
                                <FormattedMessage id="users.editing.userType" defaultMessage="User type:" />
                            </h3>
                            <FormTable striped={true}>
                                <ValidationMessages field={fields.userType} required="users.editing.type.required">
                                    <FormRow>
                                        <FormColumn width="3">
                                            <RadioInput
                                                id="users_editing_user_type_main"
                                                name="user_type"
                                                field={fields.userType}
                                                value={this.state.users.constants.userTypes.main || ""}
                                                checked={fields.userType.value === this.state.users.constants.userTypes.main}
                                                messageId="users.editing.userTypeMain"
                                                defaultMessage="Main user"
                                                bold={true}
                                                labelClassName="m-0"
                                                disabled={!this.state.users.canInviteMainUser}
                                            />
                                        </FormColumn>
                                        <FormColumn width="9">
                                            {configuration[serviceCode].userManagement['mainUserDescription']
                                                ? <p>{configuration[serviceCode].userManagement['mainUserDescription'][currentLanguageCode]}</p>
                                                : null
                                            }
                                        </FormColumn>
                                    </FormRow>
                                    <FormRow>
                                        <FormColumn width="3">
                                            <RadioInput
                                                id="users_editing_user_type_basic"
                                                name="user_type"
                                                field={fields.userType}
                                                value={this.state.users.constants.userTypes.basic || ""}
                                                checked={fields.userType.value === this.state.users.constants.userTypes.basic}
                                                messageId="users.editing.userTypeBasic"
                                                defaultMessage="Basic user"
                                                bold={true}
                                                labelClassName="m-0"
                                                disabled={!this.state.users.canInviteMainUser}
                                            />
                                        </FormColumn>
                                        <FormColumn width="9">
                                            {configuration[serviceCode].userManagement['basicUserDescription']
                                                ? <p>{configuration[serviceCode].userManagement['basicUserDescription'][currentLanguageCode]}</p>
                                                : null
                                            }
                                        </FormColumn>
                                    </FormRow>
                                    <VisibilityComponent isVisible={this.state.users.inactiveRoleOptionIsVisible} >
                                        <FormRow>
                                            <FormColumn width="3">
                                                <RadioInput
                                                    id="users_editing_user_type_inactive"
                                                    name="user_type"
                                                    field={fields.userType}
                                                    value={this.state.users.constants.userTypes.inactive || ""}
                                                    checked={fields.userType.value === this.state.users.constants.userTypes.inactive}
                                                    messageId="users.edditing.userTypeInactive"
                                                    defaultMessage="Inactive user"
                                                    bold={true}
                                                    labelClassName="m-0"
                                                />
                                            </FormColumn>
                                            <FormColumn width="9">
                                                {configuration[serviceCode].userManagement['inactiveUserDecription']
                                                    ? <p>{configuration[serviceCode].userManagement['inactiveUserDecription'][currentLanguageCode]}</p>
                                                    : null
                                                }
                                            </FormColumn>
                                        </FormRow>
                                    </ VisibilityComponent>
                                </ValidationMessages>
                            </FormTable>
                        </VisibilityComponent>


                        <VisibilityComponent isVisible={true}>
                            <h3>
                                <FormattedMessage id="users.editing.userPermissions" defaultMessage="Permissions:" />
                            </h3>
                            <FormTable striped={true}>
                                { this.displayPermissions() }
                            </FormTable>
                        </VisibilityComponent>

                        <VisibilityComponent isVisible={this.state.users.canManageGlobalPermissions || (this.state.users.canViewGlobalPermissions && this.state.users.editingUser.globalPermissions && this.state.users.editingUser.globalPermissions.length > 0)}>
                            <FormRow>
                                <FormColumn width="12">
                                    <h3>
                                        <FormattedMessage id="users.adding.userGlobalPermissions" defaultMessage="Global permissions:" />
                                    </h3>
                                </FormColumn>
                            </FormRow>
                            <FormTable striped={true}>
                                {this.state.shared.classifiers.globalPermissions.map((p, index) => {
                                    if ((this.state.users.canManageGlobalPermissions ||
                                        (permissionsManagedByBasicAdmin.indexOf(p.code) > -1 && (this.state.users.canManageGlobalPermissions || fields[p.code].value)) ||
                                        (permissionsManagedBySuperAdmin.indexOf(p.code) > -1 && (this.state.users.canManageAdminAndQATPermissions || fields[p.code].value))
                                    )) {
                                        let description = p.description;
                                        if (p.code === "sp_admin") {
                                          description = formatMessage(messages.spAdminDescription);
                                        }
                                        return (
                                            <FormRow key={index}>
                                                <FormColumn width="3">
                                                    <CheckboxInput
                                                        id={"users_adding_user_" + p.code + "_permission"}
                                                        value={p.code || ""}
                                                        checked={fields[p.code].value}
                                                        field={fields[p.code]}
                                                        labelClassName="m-0"
                                                        name="user_global_permissions"
                                                        bold={true}
                                                        disabled={!this.state.users.canManageGlobalPermissions || (!this.state.users.canManageGlobalPermissions && permissionsManagedByBasicAdmin.indexOf(p.code) > -1) || (!this.state.users.canManageAdminAndQATPermissions && permissionsManagedBySuperAdminOnly.indexOf(p.code) > -1) || this.state.users.disabledOptionForInactiveOrgUser}
                                                    >
                                                        { p.name }
                                                    </CheckboxInput>
                                                </FormColumn>
                                                <FormColumn width="9">
                                                    <p>
                                                        { description }
                                                    </p>
                                                </FormColumn>
                                            </FormRow>
                                            );
                                    }
                                })}
                            </FormTable>
                        </VisibilityComponent>

                        <FormValidationMessage form={form} messageId="users.editing.formHasErrors" />
                        <FormRow>
                            <FormColumn width="12" className="mt-6">
                                {
                                    this.state.users.userChangingHisMainType
                                    ?
                                        <button
                                            id="users_editing_submit"
                                            type="button"
                                            className="btn btn-primary btn-lg mr-3"
                                            onClick={form.handleOnSubmitBtnClicked}
                                            data-toggle="modal"
                                            data-target="#change-user-type-confirmation-modal"
                                            role="button"
                                        >
                                            <FormattedMessage id="users.editing.saveBtn" defaultMessage="Save" />
                                        </button>
                                    :
                                    <button
                                        id="users_editing_submit"
                                        type="button"
                                        className="btn btn-primary btn-lg mr-3"
                                        onClick={form.handleOnSubmitBtnClicked}
                                        role="button"
                                    >
                                        <FormattedMessage id="users.editing.saveBtn" defaultMessage="Save" />
                                    </button>
                                }


                                <a
                                    id="users_editing_cancel"
                                    className="btn btn-default btn-lg mr-4"
                                    href={this.state.users.backToListUrl}
                                >
                                    <FormattedMessage id="users.editing.cancelBtn" defaultMessage="Cancel" />
                                </a>
                                <button
                                    id="users_editing_delete"
                                    type="button"
                                    className="btn btn-danger btn-lg pull-right"
                                    data-toggle="modal"
                                    data-target="#remove-user-confirmation-modal"
                                    onClick={getDeleteBtnClickedHandler()}
                                    role="button"
                                    disabled={!this.state.users.canRemoveUsers}
                                >
                                    <FormattedMessage id="users.editing.removeUser" defaultMessage="Remove user" />
                                </button>
                                <div className="clearfix"></div>
                            </FormColumn>
                        </FormRow>

                    </form>

                    <ConfirmationModal
                        id="remove-user-confirmation-modal"
                        idPrefix="remove_user"
                        title={formatMessage(messages.confirmationModalTitle)}
                        okButtonText={formatMessage(deleteUserOkBtnMessage)}
                        cancelButtonText={formatMessage(messages.confirmationModalCancel)}
                        handleActionType={okBtnClicked}
                        sharedStore={this.state.users.shared}
                    >
                        { deleteUserConfirmationMessage }
                    </ConfirmationModal>

                    <ConfirmationModal
                        id="change-user-type-confirmation-modal"
                        idPrefix="1"
                        title={formatMessage(messages.confirmationModalTitle)}
                        okButtonText={formatMessage(messages.confirmationModalYesContinue)}
                        cancelButtonText={formatMessage(messages.confirmationModalCancel)}
                        handleActionType={okBtnClicked}
                        sharedStore={this.state.users.shared}
                    >
                        {changingUserTypeConfirmationMessage}
                    </ConfirmationModal>

                    <ConfirmationModal
                        idPrefix="edit_user_generate_password"
                        id="edit_user_generate_password_modal_window"
                        title={formatMessage(messages.generatePswResetConfirmationTitle)}
                        okButtonText={formatMessage(messages.generateLinkGenerateBtn)}
                        cancelButtonText={formatMessage(messages.generateLinkCancelGenerateBtn)}
                        handleActionType={generatePasswordBtnClicked}
                        sharedStore={this.state.users.shared}
                    >
                        <span>
                            <FormattedMessage
                                id="users.editing.confirmationMsg"
                                defaultMessage="Are you sure that you want to generate a password reset link for user"
                            />
                            &nbsp;
                            <b>{fields.email.value}</b>?
                        </span>
                    </ConfirmationModal>

                    <ConfirmationModal
                        idPrefix="edit_user_generate_email_verification"
                        id="edit_user_generate_email_verification_modal_window"
                        title={formatMessage(messages.generateEmailLinkConfirmationTitle)}
                        okButtonText={formatMessage(messages.generateLinkGenerateBtn)}
                        cancelButtonText={formatMessage(messages.generateLinkCancelGenerateBtn)}
                        handleActionType={generateEmailLinkBtnClicked}
                        sharedStore={this.state.users.shared}
                    >
                        <span>
                            <FormattedMessage
                                id="users.editing.areYouSureAboutLinkGeneration"
                                defaultMessage="Are you sure that you want to generate an email verification link for user"
                            />
                            &nbsp;
                            <b>
                                {fields.email.value}
                            </b>?
                            &nbsp;
                            <b>
                                <FormattedMessage
                                    id="users.editing.note"
                                    defaultMessage="Please note"
                                />
                            </b>
                            &nbsp;
                            <FormattedMessage
                                id="users.editing.noteMsg"
                                defaultMessage="that before using this function, it should be confirmed with other methods (sending an email from another address, other means) that the requester actually has access to this email address"
                            />.
                        </span>
                    </ConfirmationModal>

                    <ConfirmationModal
                        idPrefix="edit_user_generate_invitation"
                        id="edit_user_generate_invitation_link_modal_window"
                        title={formatMessage(messages.generateInvitationLinkTitle)}
                        okButtonText={formatMessage(messages.generateLinkGenerateBtn)}
                        cancelButtonText={formatMessage(messages.generateLinkCancelGenerateBtn)}
                        handleActionType={generateInvitationLinkBtnClicked}
                        sharedStore={this.state.users.shared}
                    >
                        <span>
                            <FormattedMessage
                                id="users.editing.sureAboutInivitationLinkGeneration"
                                defaultMessage="Are you sure that you want to generate an invitation link for user"
                            />
                            &nbsp;
                            <b>
                                {fields.email.value}
                            </b>?
                            &nbsp;
                            <b>
                                <FormattedMessage
                                    id="users.editing.note"
                                    defaultMessage="Please note"
                                />
                            </b>
                            &nbsp;
                            <FormattedMessage
                                id="users.editing.noteMsg"
                                defaultMessage="that before using this function, it should be confirmed with other methods (sending an email from another address, other means) that the requester actually has access to this email address"
                            />.
                        </span>
                    </ConfirmationModal>

                    <ConfirmationModal
                        idPrefix='generated_link'
                        id='generated_link_modal_window'
                        title={generatedLinkModalWindowTitle}
                        cancelButtonText={formatMessage(messages.linkGeneratedCancelBtn)}
                        hideConfirmButton={true}
                        showModal={showGeneratedLinkModal}
                        sharedStore={this.state.users.shared}
                    >
                        <p>
                            <FormattedMessage
                                id="users.editing.pleaseCopyGeneratedEmailLink"
                                defaultMessage="Please copy the link below, and send it to"
                            />
                            &nbsp;
                            <b>{fields.email.value}</b>:
                        </p>
                        <p>
                            <span className="text-wrap" id="generated_link_value_from_modal">
                                {this.state.users.generatedLink}
                                &nbsp;
                                <CopyToClipboard text={this.state.users.generatedLink}>
                                    <span role='button' className='text-success'>
                                        (
                                            <FormattedMessage
                                                id="users.editing.copyToClipboard"
                                                defaultMessage="copy to clipboard"
                                            />
                                        )
                                    </span>
                                </CopyToClipboard>
                            </span>
                        </p>
                    </ConfirmationModal>
                </div>
                : null
            }
        </div>
        );
    }
}

export default EditingUser;
