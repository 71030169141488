import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, defineMessages } from 'react-intl';
import { ValidationMessages, ConfirmationModal } from '@vaultit/stv-utils-frontend';

const modalMessages = defineMessages({
    importEmailsModalTitle: {
        id: 'users.adding.importModal.title',
        description: 'Import emails modal window title',
        defaultMessage: 'IMPORT EMAIL ADDRESSES'
    },
    importEmailsModalConfirmBtn: {
        id: 'users.adding.importModal.confirmBtn',
        description: 'Import emails modal window confirm button text',
        defaultMessage: 'Import'
    },
    importEmailsModalCancelBtn: {
        id: 'users.adding.importModal.cancelBtn',
        description: 'Import emails modal window cancel button text',
        defaultMessage: 'Cancel'
    },
    importModalExplanationMessage: {
        id: 'users.adding.importModal.explanation',
        description: 'Import emails window explanation message',
        defaultMessage: 'Email addresses must be separated by comma or semicolon symbols'
    }
});

class ImportUserEmailsModalWrapper extends React.Component {

    static get propTypes() {
        return {
            form: PropTypes.object,
            dismissModalAfterClick: PropTypes.bool,
            currentState: PropTypes.object
        };
    }

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    render() {
        const { formatMessage } = this.context.intl;

        let importEmailsForm = this.props.form;

        return (
            <ConfirmationModal
                id="import_user_emails_modal"
                idPrefix="import_emails"
                title={formatMessage(modalMessages.importEmailsModalTitle)}
                okButtonText={formatMessage(modalMessages.importEmailsModalConfirmBtn)}
                cancelButtonText={formatMessage(modalMessages.importEmailsModalCancelBtn)}
                handleActionType={() => importEmailsForm.handleOnSubmitBtnClicked()}
                okBtnClass="btn btn-primary"
                dismissModalAfterClick={this.props.dismissModalAfterClick}
                sharedStore={this.props.currentState}
            >
                {importEmailsForm
                    ?
                    (
                        <ValidationMessages field={importEmailsForm.fields.importedEmails}>
                            <small className="form-control-description my-5 my-sm-0">
                                <span>
                                    <i className="fa fa-info-circle fa-mr"></i>
                                    {formatMessage(modalMessages.importModalExplanationMessage)}
                                </span>
                            </small>
                            <textarea
                                className="form-control"
                                rows="6"
                                id="import_user_emails_modal_textarea"
                                onChange={importEmailsForm.fields.importedEmails.handleOnChange}
                                onInput={importEmailsForm.fields.importedEmails.handleOnChange}
                                value={importEmailsForm.fields.importedEmails.value}
                                autoFocus
                            />
                        </ ValidationMessages>
                    )
                    : null
                }

            </ConfirmationModal>
        );
    }
}

export default ImportUserEmailsModalWrapper;
