import React from 'react';
import StoreComponent from '../base/StoreComponent.jsx';
import clearFilterBtnClickedHandler from '../../actions/actionCreators/users/ClearFilterBtnClickedHandler.js';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import { Message, PageHeader, FormRow, FormTable, FormColumn, TextInput} from '@vaultit/stv-utils-frontend';

const messages = defineMessages({
    confirmationModalTitle: {
        id: 'users.list.remove.permissions.confirmation.modal.title',
        description: 'Confirmation modal title',
        defaultMessage: 'Remove user'
    },
    confirmationModalOk: {
        id: 'users.list.remove.permissions.confirmation.modal.ok',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, remove the permissions'
    },
    confirmationModalYesContinue: {
        id: 'users.list.remove.permissions.confirmation.modal.yesContinue',
        description: 'Confirmation modal confirm button text',
        defaultMessage: 'Yes, continue'
    },
    confirmationModalCancel: {
        id: 'users.list.remove.permissions.confirmation.modal.cancel',
        description: 'Confirmation modal cancel button text',
        defaultMessage: 'No, cancel'
    },
    selectedFastPermissionGlobal: {
        id: 'users.list.selectedFastPermissionGlobal',
        description: 'Selected tab permission name',
        default: 'Global',
    },
    selectPendingUser: {
        id: 'users.list.selectPendingUser',
        description: 'Selected tab permission name',
        default: 'Pending invitation',
    },
    selectNewUser: {
        id: 'users.list.selectNewUser',
        description: 'Selected new user',
        default: 'New user',
    },
    usersListSelectPlaceholder: {
        id: 'users.list.usersListSelectPlaceholder',
        description: 'Placeholder for custom dropdown',
        default: 'Select user',
    },
    usersListEmpty: {
        id: 'users.list.usersListEmpty',
        description: 'Value displayed when list is empty',
        default: 'List is empty',
    },
    userPendingInvitation: {
        id: 'users.list.pendingInvitation',
        description: 'User name when user is not active',
        default: '(Pending invitation)',
    },
    searchPlaceholder: {
        id: 'users.list.searchInputPlaceholder',
        description: 'Search input placeholder',
        default: 'Name, e-mail or permission',
    },
});

class CpbUserList extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }

    getTabClass(tab) {
        let currenTab = this.state.shared.currentRoute.params.serviceName
                        ? this.state.shared.currentRoute.params.serviceName
                        : this.state.shared.currentRoute.fullPath.split('/').pop();
        return tab === currenTab ? ' active' : '';
    }


    showUserAfterFiltering(user) {
        const { formatMessage } = this.context.intl;

        let filterValue = this.state.users.filterUsersForm.fields.usersFilterValue.value;

        if (filterValue && filterValue.length) {
            let filterValueLowerCase = filterValue.toLowerCase();

            if ((user.email && user.email.toLowerCase().includes(filterValueLowerCase)) ||
                (user.fullName && user.fullName.toLowerCase().includes(filterValueLowerCase))) {
                return true;
            }

            if (user.globalPermissions && user.globalPermissions.length) {

                let clGlobalPermissions = this.state.shared.classifiers.globalPermissions;

                for (let key in user.globalPermissions) {

                    for (let clPermissionKey in clGlobalPermissions) {

                        if (clGlobalPermissions[clPermissionKey].code === user.globalPermissions[key]) {
                            let permissionText = clGlobalPermissions[clPermissionKey].name;

                            if (permissionText && permissionText.toLowerCase().includes(filterValueLowerCase)) {
                                return true;
                            }
                        }
                    }

                }
            }

            if (user.permissions && user.permissions.length) {
                for (let key of user.permissions) {
                    let permission = this.state.users.constants.userPermissions[key];
                    if (permission) {
                        let permissionText = permission[this.state.shared.authInfo.otherUiInfo.uiLanguage];

                        if (permissionText && permissionText.toLowerCase().includes(filterValueLowerCase)) {
                            return true;
                        }
                    }
                }
            }

            if (user.userRole && user.permissions.length) {
                let userRoleText = formatMessage( { id: "users.list." + user.userRole + "UserText" } );

                if (userRoleText && userRoleText.toLowerCase().includes(filterValueLowerCase)) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    renderWhenReady() {
        const { formatMessage } = this.context.intl;
        const userTypes = this.state.users.constants.userTypes;

        let configuration = this.state.users.uiConfiguration;
        let currentLanguageCode = this.state.shared.authInfo.otherUiInfo.uiLanguage;

        let filterUsersForm = this.state.users.filterUsersForm;

        let users = [];
        let allUsers = this.state.users.cpbUserList;

        allUsers.forEach((user) => {

            user.fullName = user.maskUserInfo ? user.fullName : user.isActive ? user.lastName + ', ' + user.firstName : formatMessage(messages.userPendingInvitation);

            if (this.showUserAfterFiltering(user)) {
                users.push(user);
            }
        });

        let limit = this.state.shared.config ? this.state.shared.config.usersLimitInUsersList : 200;

        let exceededUsersLimit = users.length > limit;

        let permissionNameDisplay = (permission, index, isMainRepresentationForUser, key) => {

            for (var serviceName in configuration.services) {
                let service = configuration.services[serviceName];
                let joinedByPermissionService = service.joinedByPermission && configuration.services[service.joinedByPermission]
                    ? configuration.services[service.joinedByPermission]
                    : null;
                let joinedPermissionTitle = joinedByPermissionService
                    ? joinedByPermissionService['title'][currentLanguageCode]
                    : '';

                if (permission == service['permission']) {
                    return (
                        <div className="small" key={key}>
                            <div>
                                {service['title'][currentLanguageCode]}
                            </div>
                            {joinedPermissionTitle
                            ?
                                <div>
                                    {joinedPermissionTitle}
                                </div>
                            : null
                            }
                        </div>
                    );
                }
            }
        };

        return (
            <div className="users-management">
                <PageHeader
                    pageTitleMessageId="users.cpbUsers.title"
                    values={{organizationName: this.state.users.selectedOrgName}}
                    sharedStore={this.state.shared}
                />

                <div>
                    { filterUsersForm
                        ? (
                            <div>
                                <div id="users-filter" className="card card-default mb-0">
                                    <div className="card-block">
                                        <form>
                                            <FormRow>
                                                <FormColumn widthLg="6" widthMd="6">
                                                    <div className="form-group mb-0">
                                                        <TextInput
                                                            id="users_list_filter_input"
                                                            field={filterUsersForm.fields.usersFilterValue}
                                                            inputGroup={true}
                                                            addonPossition="left"
                                                            autoFocus={true}
                                                            placeholder={formatMessage(messages.searchPlaceholder) }
                                                        >
                                                            <span className="input-group-addon text-primary bg-white">
                                                                <i id="filter-icon" className="fa fa-search" aria-hidden="true"></i>
                                                            </span>
                                                        </TextInput>
                                                    </div>
                                                </FormColumn>
                                            </FormRow>
                                        </form>
                                    </div>
                                </div>
                                { filterUsersForm.fields.usersFilterValue.value.length
                                    ?
                                        <div role="button" id="clear-filters" className="text-primary text-right mb-6 mt-2 bg-white" onClick={clearFilterBtnClickedHandler}>
                                            <i className="fa fa-trash-o fa-mr" aria-hidden="true"></i>
                                            <FormattedMessage id="users.list.clearFilters" defaultMessage="Clear filters" />
                                        </div>
                                    : null
                                }
                            </div>
                        )
                        : null
                    }

                    {allUsers.length && !users.length
                        ?
                            <Message id="no-users-found-list" type="warning">
                                <FormattedMessage
                                    id="users.list.noUsersFoundMessage"
                                    defaultMessage="No users found."
                                />
                            </Message>
                        : null
                    }

                    {exceededUsersLimit
                        ?
                            <Message id="to-much-users-in-list" type="warning">
                                <FormattedMessage
                                    id="users.list.listLimitMessage"
                                    defaultMessage="To much users found. Please make your search more specific."
                                    values={{number: limit}}
                                />
                            </Message>
                        : null
                    }

                    {users.length && !exceededUsersLimit
                        ? (
                            <div className="mt-6">
                                <FormTable id="cpb_users_list" className="form-table-striped form-table-hover mt-4">

                                    <FormRow header={true}>
                                        <FormColumn width="4">
                                            <a id="user_column">
                                                <FormattedMessage id="users.list.title.user" defaultMessage="User" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <a id="user_type_column">
                                                <FormattedMessage id="users.list.title.userType" defaultMessage="User type" />
                                            </a>
                                        </FormColumn>
                                        <FormColumn width="4">
                                            <a id="permissions_column">
                                                <FormattedMessage id="users.list.title.permissions" defaultMessage="Permissions" />
                                            </a>
                                        </FormColumn>
                                    </FormRow>

                                    {users.map((u, i) => {
                                        return (
                                            <FormRow key={i} role="button">
                                                <FormColumn mobileHeader={true} width="12">
                                                    <FormattedMessage id="users.list.title.user" defaultMessage="User" />
                                                </FormColumn>
                                                <FormColumn width="4">
                                                    <div className="pull-left">
                                                        <p id={"users_list_name_" + i} className={u.isActive ? 'font-weight-bold':''}>{u.fullName}</p>
                                                        <p id={"users_list_email_" + i}  className="small text-muted">{u.email}</p>
                                                    </div>
                                                    <div className="pull-right hidden-md-up" onClick={(e) => e.stopPropagation()}>
                                                        <div id="mobile_users_list_options_dropdown" className="dropdown">
                                                            <button role="button" className="btn btn-link btn-sm py-1 px-3" id={"mobile_users_list_options_dropdown_" + i} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i className="fa fa-cog p-0 fa-lg text-secondary" aria-hidden="true"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className="clearfix"></div>
                                                </FormColumn>
                                                <FormColumn mobileHeader={true} width="12">
                                                    <FormattedMessage id="users.list.title.userType" defaultMessage="User type" />
                                                </FormColumn>
                                                <FormColumn width="4" onClick={u.handleRowClick}>
                                                    <p id={'users_list_user_type_' + i}>
                                                        { u.userRole === userTypes.main
                                                            ? <FormattedMessage id="users.list.mainUserText" defaultMessage="Main user" />
                                                            : u.userRole === userTypes.basic
                                                            ? <FormattedMessage id="users.list.basicUserText" defaultMessage="Basic user" />
                                                            : u.userRole === userTypes.inactive
                                                            ? <FormattedMessage id="users.list.inactiveUserText" defaultMessage="Inactive user" />
                                                            : null
                                                        }
                                                    </p>
                                                </FormColumn>
                                                <FormColumn mobileHeader={true} width="12">
                                                    <FormattedMessage id="users.list.title.permissions" defaultMessage="Permissions" />
                                                </FormColumn>
                                                <FormColumn width="4" id={"users_list_permission_" + i}>
                                                    {
                                                        u.permissions && u.permissions.length
                                                        ? u.permissions.map((p,j) => (
                                                            permissionNameDisplay(p, i, u.isMainRepresentationForUser, j)
                                                        ))
                                                        : null
                                                    }
                                                </FormColumn>
                                            </FormRow>
                                        );
                                    })}
                                </FormTable>
                            </div>
                        )
                            : null
                    }
                </div>
            </div>
        );

    }
}

export default CpbUserList;
