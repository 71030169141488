import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let openImportEmailsModalBtnClicked = function() {
    dispatcher.dispatch({
        type: ActionTypes.OPEN_IMPORT_EMAILS_MODAL_BTN_CLICKED
    });
};

export default openImportEmailsModalBtnClicked;
