import BaseStore from '../base/BaseStore.js';

class InformToVisitManualIdVerificationPartnerStore extends BaseStore {

    getInitialState() {
        let initialState = {};
        return initialState;
    }

    getRouteLoadHandlers() {
        return [
            {
                fullPath: '/profile/visit-manual-id-verification-partner',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_FORCE_VISIT_MANUAL_ID_PARTNER_AFTER_REGISTRATION',
                loadHandler: this.onInformToVisitManualIdVerificationPartnerRouteLoaded
            },
            {
                fullPath: '/profile/identity-verified-check-your-email',
                loadOnlyIfHasPermission: 'SEPPO_ACTION_CHECK_EMAIL_IDENTITY_VERIFIED_WITH_MANUAL_ID',
                loadHandler: this.onIdentityVerifiedCheckEmailRouteLoaded
            }
        ];
    }

    onInformToVisitManualIdVerificationPartnerRouteLoaded() {}

    onIdentityVerifiedCheckEmailRouteLoaded() {}
}

export default InformToVisitManualIdVerificationPartnerStore;
