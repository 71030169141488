import ActionTypes from '../../../ActionTypes.js';
import {postToBackend} from '../../generic/CallBackend.js';

let changePassword = function (data) {
    postToBackend('/profile/change-password',
        ActionTypes.PROFILE_PASSWORD_CHANGE_SUCCESS,
        ActionTypes.PROFILE_PASSWORD_CHANGE_ERROR,
        data, data);
};
export default changePassword;
