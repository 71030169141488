import ActionTypes from '../../ActionTypes.js';
import dispatcher from '../../../Dispatcher.js';

let generateGlobalSearchEmailLinkBtnClicked = function(event) {
    event.preventDefault();
    dispatcher.dispatch({
        type: ActionTypes.GENERATE_GLOBAL_SEARCH_EMAIL_LINK_BTN_CLICKED
    });
}

export default generateGlobalSearchEmailLinkBtnClicked;