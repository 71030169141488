import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage, intlShape, defineMessages } from 'react-intl';
import { Message } from '@vaultit/stv-utils-frontend';
import signOutBtnClicked from '../../actions/actionCreators/menu/SignOutBtnClicked.js';

const messages = defineMessages({
    signOut: {
        id: 'profile.informEperehdytysUser.signOut',
        description: 'Sign out button text',
        defaultMessage: 'Sign out from existing session'
    }
});

class InformEperehdytysUser extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }


    renderWhenReady() {
        const { formatMessage } = this.context.intl;

        return (
            <div>
                <Message id="inform_eperehdytys_user" type="warning" className="mt-6">
                    <FormattedMessage
                        id="profile.informEperehdytysUser.message"
                        defaultMessage="You have an insufficient session open. Please sign out and sign in with your Vaultit account, or register a new one"
                    />
                </Message>
                <input
                    id="signout_from_current_session_btn"
                    type="submit"
                    className="btn btn-primary btn-lg mt-3"
                    value={formatMessage(messages.signOut) || ""}
                    onClick={signOutBtnClicked}
                    role="button"
                />
            </div>
        );
    }
}

export default InformEperehdytysUser;
