import React from 'react';
import PropTypes from 'prop-types';
import { FormRow, FormColumn, Message } from '@vaultit/stv-utils-frontend';
import { FormattedMessage } from 'react-intl';
class AddingUserMessage extends React.Component {

    static get propTypes() {
        return {
            messageType: PropTypes.oneOf(['default', 'danger', 'warning', '', 'info', 'success']),
        };
    }

    render() {
        let type = 'success';
        let messageId = 'SuccessMessage';

        if (this.props.messageType) {
            if (this.props.messageType === 'danger') {
                type = 'danger';
                messageId = "ErrorMessage";
            }
            if (this.props.messageType === 'warning') {
                type = 'warning';
                messageId = 'WarningMessage';
            }
            if (this.props.messageType === 'info') {
                type = 'info';
                messageId = 'InfoMessage';
            }
        }

        let messageMain = null;
        let messageAddedUsers = null;
        let messageSkippedUsers = null;
        let messageSkippedUsersWithManyOrg = null;

        if (this.props.allAdded) {
            messageMain = <FormattedMessage id="users.adding.message.allAdded" description="Invitations sent successfully message" defaultMessage="Invitations sent successfully." />;
        } else if (this.props.someAddedSomeSkipped) {
            messageMain = <FormattedMessage id="users.adding.message.someAddedSomeSkipped" description="Invitations sent successfully message" defaultMessage="Invitations sent successfully." />;
        } else if (this.props.allAlreadyAddedSkipped) {
            messageMain = <FormattedMessage id="users.adding.message.allAlreadyAddedSkipped" description="Invited users already exists message" defaultMessage="Invited users already exists." />;
        } else if (this.props.allAddedAndError) {
            messageMain = <FormattedMessage id="users.adding.message.allAddedAndError" description="Before error occurred, invitations were sent message" defaultMessage="Before error occurred, invitations were sent." />;
        } else if (this.props.someAddedSomeSkippedAndError) {
            messageMain = <FormattedMessage id="users.adding.message.someAddedSomeSkippedAndError" description="Before error occurred, invitations were sent message" defaultMessage="Before error occurred, invitations were sent." />;
        } else if (this.props.allAlreadyAddedSkippedAndError) {
            messageMain = <FormattedMessage id="users.adding.message.allAlreadyAddedSkippedAndError" description="Before error occurred, invitations were not sent message" defaultMessage="Before error occurred, invitations were not sent." />;
        } else if (this.props.someUsersWereSkipped) {
            messageMain = <FormattedMessage id="users.adding.message.someUsersWereSkipped" description="Invited users either already exists or have too many representations" defaultMessage="Invited users either already exists or have too many representations." />;
        }

        if (this.props.addedUsersList && this.props.addedUsersList.length > 0) {
            messageAddedUsers = (
                <FormRow>
                    <FormColumn>
                        <FormattedMessage id="users.adding.message.invitedUsers" description="Invited users message" defaultMessage="Invited users:" />
                        <ul>
                            {this.props.addedUsersList.map((u, i) => <li key={i}><span>{u}</span></li>)}
                        </ul>
                    </FormColumn>
                </FormRow>
            );
        }

        if (this.props.skippedAlreadyInOrgUsersList && this.props.skippedAlreadyInOrgUsersList.length > 0) {
            messageSkippedUsers = (
                <FormRow>
                    <FormColumn>
                        <FormattedMessage id="users.adding.message.skippedUsers" description="Already existing users were skipped message" defaultMessage="Already existing users were skipped:" />
                        <ul>
                            {this.props.skippedAlreadyInOrgUsersList.map((u, i) => <li key={i}><span>{u}</span></li>)}
                        </ul>
                    </FormColumn>
                </FormRow>
            );
        }

        if (this.props.skippedWithManyOrgUsersList && this.props.skippedWithManyOrgUsersList.length > 0) {
            messageSkippedUsersWithManyOrg = (
                <FormRow>
                    <FormColumn>
                        <FormattedMessage id="users.adding.message.allHaveTooManyOrg" description="Invited users already have an invite for an organisation account or have joined an organisation." defaultMessage="Invited users already have an invite for an organisation account or have joined an organisation." />
                        <ul>
                            {this.props.skippedWithManyOrgUsersList.map((u, i) => <li key={i}><span>{u}</span></li>)}
                        </ul>
                        <FormattedMessage id="users.adding.message.skippedWithManyOrgUsersList.part1" description="Already existing users were skipped message" defaultMessage="Please contact " />
                        <a href={'mailto:' + this.props.customerSupport}>
                            { this.props.customerSupport }
                        </a>
                        <FormattedMessage id="users.adding.message.skippedWithManyOrgUsersList.part2" description="Already existing users were skipped message" defaultMessage=" for further assistance." />
                    </FormColumn>
                </FormRow>
            );
        }

        if (this.props.showMessage) {
            return (
                <Message id={messageId} type={type}>
                    {messageMain}

                    <div style={ messageMain ? { 'paddingTop': '15px' } : null }>
                        {messageAddedUsers}
                        {messageSkippedUsers}
                        {messageSkippedUsersWithManyOrg}
                    </div>

                </Message>
            );
        } else {
            return null;
        }

    }
}

export default AddingUserMessage;
