import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let updateUserMainOrg = function (orgId, userId, data) {
    postToBackend('/users/' + orgId + '/update-user-main_org/' + userId,
        ActionTypes.ORGANIZATION_USERS_UPDATE_MAIN_ORG_SUCCESS,
        ActionTypes.ORGANIZATION_USERS_UPDATE_MAIN_ORG_ERROR,
        data);
};

export default updateUserMainOrg;
