import React from 'react';

import StoreComponent from '../base/StoreComponent.jsx';
import { FormattedMessage, intlShape } from 'react-intl';
import { Message } from '@vaultit/stv-utils-frontend';


class SmsLoginErrorPage extends StoreComponent {

    static get contextTypes() {
        return {
            intl: intlShape.isRequired,
        };
    }


    renderWhenReady() {

        return (
            <div>
                <Message id="failed_sms_login" type="warning" className="mt-6">
                    <FormattedMessage
                        id="profile.smsLogin.failed"
                        defaultMessage="It looks like that your phone number is missing. Try to sign in and add phone number"
                    />.
                </Message>
            </div>
        );
    }
}

export default SmsLoginErrorPage;
