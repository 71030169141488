import ActionTypes from '../../ActionTypes.js';
import {postToBackend} from '../generic/CallBackend.js';

let adminChangeEmailCancel = function(userId) {
    postToBackend(`/users/admin-change-email-cancel/${userId}`,
        ActionTypes.ADMIN_CHANGE_EMAIL_CANCEL_SUCCESS,
        ActionTypes.ADMIN_CHANGE_EMAIL_CANCEL_ERROR);
};

export default adminChangeEmailCancel;
