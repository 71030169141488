import ActionTypes from '../../ActionTypes.js';
import {getFromBackend} from '../generic/CallBackend.js';

let getEmailVerificationLink = function(orgId, userId) {

    getFromBackend(`/users/${orgId}/generate-email-verification-link/${userId}`, 
        ActionTypes.GET_EMAIL_VERIFICATION_LINK_SUCCESS, 
        ActionTypes.GET_EMAIL_VERIFICATION_LINK_ERROR);
};

export default getEmailVerificationLink;