import React from 'react';
import StoreComponent from '../../base/StoreComponent.jsx';
import { FormattedMessage } from 'react-intl';
import { Message, PageHeader } from '@vaultit/stv-utils-frontend';

class ExpiredSmsInvitationToCreateAccountMessage extends StoreComponent {

    renderWhenReady() {
        return (
            <div>
                <PageHeader
                    pageTitleMessageId="registration.step1.flow2.createAccountInvitationExpiredHeader"
                    sharedStore={this.state.shared}
                />
                <Message id="expiredInvitationMessage" type="danger">
                    <FormattedMessage
                        id="registration.step1.flow2.createAccountInvitationExpired"
                        defaultMessage="This invitation by SMS link has expired. Use the invitation sent to your email."
                    />
                </Message>
            </div>
        );
    }
}

export default ExpiredSmsInvitationToCreateAccountMessage;
